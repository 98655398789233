import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
//import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { RecruitmentCandidateService } from '../../services/recruitment-candidate.service';



@Component({
  selector: 'kt-candidate-whatsapp',
  templateUrl: './candidate-whatsapp.component.html',
  styleUrls: ['./candidate-whatsapp.component.scss']
})
export class CandidateWhatsappComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public addWhatsAppForm: FormGroup;
  disabled:boolean;
  userPwdCheck:boolean;
  errorMes:String="";
  

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    private _rmcs:RecruitmentCandidateService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initAddWhatsAppForm();
    this.setByDefaultValue();
    this.disabled=false;
    this.userPwdCheck=false;
    
  }

  initAddWhatsAppForm(){
    this.addWhatsAppForm = this.fb.group({
      mobilePrefix: ['', Validators.compose([Validators.required ])],
      mobileNumber:['', Validators.compose([Validators.required ])]
     });
  }

  saveWhatsApp(){
    
    //console.log(this.addWhatsAppForm.value) 
    const controls = this.addWhatsAppForm.controls;
    if (this.addWhatsAppForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    
    const formData=this.addWhatsAppForm.value;
    this._rmcs.addCandidate(formData).subscribe(
      data => {
      //  console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          //this.OpenAlert(data['message']);
        }else{
          this.activeModal.close();
          this.addWhatsAppForm.reset();
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('SUCCESS');
        }
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );

  }

  setByDefaultValue(){
    this.addWhatsAppForm.controls['mobilePrefix'].setValue('+91');
  }

  Close(){
    this.activeModal.close();
    this.addWhatsAppForm.reset();
  }

  isDisabled() : boolean {
  return this.disabled;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addWhatsAppForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

}
