import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors,FormArray } from '@angular/forms';
import { CompaniesService } from '../../../company-management/services/companies.service';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '../../services/address.service';
import { countriesModel } from '../../../company-management/models/countries.model';
import { stateModel } from '../../../company-management/models/state.model';
import { cityModel } from '../../../company-management/models/city.model';
import { UploadImageService } from 'src/app/views/pages/shared/services/upload-image.service';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { ContactsService } from '../../../contact-management/services/contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitterService } from '../../services/event-emitter.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { OpportunitiesService } from '../../../opportunities/services/opportunities.service';
import { ProductService  } from '../../../product-management/services/product.service';

@Component({
  selector: 'kt-edit-companies-dialog',
  templateUrl: './edit-companies-dialog.component.html',
  styleUrls: ['./edit-companies-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditCompaniesDialogComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  systemAttributeEstimateArray=[];
  systemAttributecompaniesArray=[];
  systemAttributecontactsArray=[];
  systemAttributeopportunityArray=[];
  systemAttributeproductsArray=[];
  systemAttributeopportunityListArray=[];
  systemAttributecompanyListArray=[];
  systemAttributecontactsListArray=[];
  systemAttributeStatusArray=[];

  companyId:number|string;
  contactId:number;
  opportunityId:number;
  flag:String;

  skuItemsArray=[];
  skuList:any;
  totalSum: number = 0;
  showImageArray=[];
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  imgURL:any;
  images=[];
  imagesnameArray=[];
  systemAttribute11Array=[];
  systemAttribute6Array=[];
  newOpenDiv=[];
  openDivs=[];
  moduleType:Number;
  moduleName:String;
  customAttributeValues=[];
  customAttributesTestValues:[];
  isSubmitted:boolean=true;
  errorMes:string="";
  disabled:Boolean=false;
  maxDate= new Date();
  minDate= new Date();
  customAttributesList=[];
  customAttributesList2=[];
  parentPropertyId:Number;
  countriesList:countriesModel[];
  stateList:stateModel[];
  cityList:cityModel[];
  image:any;
  addCompaniesForm :FormGroup;
  editId:any;
  editOppVal:any;
  singularName: string;
  systemAttributeOwnerArray=[];
  showUploadImageIcon: boolean = true;
  imageUploadedCounter: number = 0;
  maxImgUploadAllowed: number = 0;
  imageUploadMandatoryFlag: number = 0;
  imageNameIndexMap = new Map();
  errorFlag:boolean;
  imageNoUploaded: boolean = false;
  isSubmitShow:any;
  myFormValueChanges$;
  DisplayDateFlag:boolean;
  DisplayDateMinFlag:boolean;
  DisplayDateMaxFlag:boolean;

  public singleDropdownFilter: FormControl = new FormControl();
  public multiDropdownFilter: FormControl = new FormControl();
  public countryNameFilter: FormControl = new FormControl();
  public stateNameFilter: FormControl = new FormControl();
  public cityNameFilter: FormControl = new FormControl();

  constructor(private fb: FormBuilder,
    private _cs:CompaniesService,
    public activeModal: NgbActiveModal,
    private _as:AddressService,
    private _us:UploadImageService,
    private _customAttrService:CustomAttributesService,
    private _contact_service:ContactsService,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    private _ps:ProductService,
    private _os:OpportunitiesService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
 	this.imageNameIndexMap.set("imageFileArray",[]);
    this.SpinnerService.show();

    this.getModuleName();
    this.DisplayDateFlag= false;
    this.DisplayDateMinFlag = false;
    this.DisplayDateMaxFlag = false;

    this.getCompanyDetails();
     //form builder 
     this.initaddCompanyForm();

     this.getAllCountries();
 
     this.getFields();
     this.errorFlag=false;
     if(this.isSubmitShow != false){  
      this.isSubmitShow="true";
     }

  }

  getModuleName(){
    switch (this.moduleType){
      case 1 :{
        this.moduleName="Contact";
        break;
      }
      case 2 :{
        this.moduleName="Company";
        break;
      }
      case 3 :{
        this.moduleName="Opportunity";
        break;
      }
      default:{
        this.moduleName="";
        break;
      }
    }
  }

  setStatus(){
    this.systemAttributeStatusArray.forEach(res=>{  
      console.log(res);
      var id='div_'+res.id;
      var division1=<HTMLElement>document.getElementById(id);
      console.log(division1);
      if(division1){              
        division1.classList.add('hidden');
      }
           
      this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res.lookupValues[0].id); 
      this.addCompaniesForm.controls[res.combinedAttributeName].disable();          
       
    })

  }


  getAllOwner(){
    console.log('owner filed loads');
    this._customAttrService.getOwner(this.selectedProject,Number(this.moduleType)).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.fullName;
            res.id=res.id;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeOwnerArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }

  getAllOpportunities(){
    if(this.companyId!=null && this.companyId!='undefined'){
    // this._os.getAllOpportunityDetails(this.flag=="company"? this.opportunityId : null).subscribe(
     this._os.getAllOpportunityDetails2(this.companyId).subscribe(
       data=>{
        
         data['responseData'].forEach(res=>{
           
             res.lookupValue=res.opportunityName;
             res.id=res.opportunityId;
           
         })
         var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeopportunityArray[0].id);
       
         if(removeIndex >-1){
           
           this.customAttributesList[removeIndex].lookupValues=data['responseData'];
           this.customAttributesList[removeIndex].hasChildAttribute=true;
   
         }
 
         if(this.opportunityId && this.flag=="opportunity"){
           console.log('into opp');
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].setValue(Number(this.opportunityId));
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].disable(); 
 
         }
 
       }
     ) 
    }
   }
 
   getAllOpportunitiesDependentInputs(id){
     //api to prefill opportunity inputs
     this._os.getOpportunity(id,this.selectedProject).subscribe(res=>{
       console.log('query params 2');
       console.log(this.systemAttributeopportunityListArray);
 
       var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
    
       res['responseData'][0].customAttributeValues.forEach(res1=>{
         console.log(res1);
         if(removeIndex >-1 && res1.attributeName=="Company Name" && this.opportunityId){
           
           this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(res1.value[0].id);   
           this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable(); 
         }
         this.systemAttributeopportunityListArray.forEach(res=>{
           
           
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
               if(res.systemAttribute.id==26){
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
               }else{                
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id);  
               }          
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
              
               if(res.systemAttribute.id==26){
                 console.log(res);
                 if(this.opportunityId && this.flag=="opportunity"){
 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
                 }
                 
               }else{                
                 if(res.attributeType.id==2){
                   var arraynew=[];
                   res1.value.forEach(d=>{
                     arraynew.push(d.id);
                   })
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);  
                 
                 }  
               }                
           
             }
           }         
         })
       })
     })
   }
 
   getAllCompanyDependentInputs(id){
     //api to prefill opportunity inputs
     this._cs.getCompanyDetails(id,this.selectedProject).subscribe(res=>{
 
       res['responseData'][0].customAttributeValues.forEach(res1=>{
       
         this.systemAttributecompanyListArray.forEach(res=>{
     
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
               
               }else{
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id); 
               
               }
                      
               
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
               
               }else{
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);  
               
               }
           
             }
           }
          
          
         })
       })
     })
   }
 
   getAllEstimates(){
    this._customAttrService.getActivityLookup(this.moduleType,this.selectedProject,this.companyId,this.opportunityId).subscribe(
      data=>{
        console.log('estimates');
        console.log(data['responseData']);

        data['responseData'].forEach(res=>{          
          res.lookupValue=res.activityName;
          res.id=res.activityId;          
        })

        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeEstimateArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.customAttributesList[removeIndex].hasChildAttribute=true;
  
        }
      }
    ) 
  }

   getAllProducts(){
     this._ps.getProductLookup(this.selectedProject).subscribe(
       data=>{
         this.skuList= data['responseData'];     
       }
     ) 
   }

   
  getCompanyDetails(){
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject).subscribe(
      data=>{    
        this.editOppVal=data['responseData'];
  
      }
    )
  }
  
  initaddCompanyForm(){

    this.addCompaniesForm = new FormGroup({   
    });
  }

  
  getAllCountries(){
    this._as.getAllCountries(this.selectedProject).subscribe(
		(result)=>{ 
       this.countriesList=result['responseData']; 
		},
		error => {
			console.log(error)
    })
  }

  changeCountry(countryId){
    //get states
    var id=countryId.split('@',2);
    this.getAllStates(id[1]);
  }

  getSKU() {
    
    return this.fb.group({
     sku: ['', Validators.compose([ Validators.required])],
     quantity: ['', Validators.compose([ Validators.required])],
     listPrice: [''],
     sellingPrice: ['', Validators.compose([ Validators.required])],
     discount: [''],
     tax: ['', Validators.compose([ Validators.required])],
     total: ['']
    });
  }

  getControls() {
    return (this.addCompaniesForm.get('skuItems') as FormArray).controls;
  }

  private updateItems(skuItems: any) {
    
    const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
    
    this.totalSum = 0;
    for (let i in skuItems) {
      if(skuItems[i].sku!==''){
        for(let sku of this.skuList){
          if(sku.productId == skuItems[i].sku){
            control.at(+i).get('listPrice').setValue(sku.price, {onlySelf: true, emitEvent: false});
          }
       }
      }

      if((skuItems[i].listPrice!=='' && skuItems[i].sellingPrice!=='') &&
          (skuItems[i].listPrice!==null && skuItems[i].sellingPrice!==null)){

          var discount=Number(skuItems[i].listPrice-skuItems[i].sellingPrice);
          discount=((discount/skuItems[i].listPrice)*100);
          discount=Number(discount.toFixed(2));
          control.at(+i).get('discount').setValue(discount, {onlySelf: true, emitEvent: false});

      }else{
        control.at(+i).get('discount').setValue('', {onlySelf: true, emitEvent: false});
      }

    
    if((skuItems[i].quantity!=='' && skuItems[i].sellingPrice!=='' && skuItems[i].tax!=='')
    && (skuItems[i].quantity!== null && skuItems[i].sellingPrice!==null && skuItems[i].tax!==null)){

      let total = skuItems[i].quantity*(Number(skuItems[i].sellingPrice)+Number((skuItems[i].sellingPrice * (skuItems[i].tax/100))));
      total=Number(total.toFixed(2));
      control.at(+i).get('total').setValue(total, {onlySelf: true, emitEvent: false});
       this.totalSum += total;

      }else{
        let total=0;
        control.at(+i).get('total').setValue('', {onlySelf: true, emitEvent: false});
       this.totalSum += total;
      }

    }
  }


  getAllStates(countryId){
    this._as.getStatesForCountry(countryId).subscribe(
      (result)=>{  
        this.stateList=result['responseData']; 
      },
      error => {
        console.log(error)
      }
    )
  }

  changeState(stateId){
    //get cities
    var id=stateId.split('@',2);
    console.log(id);
    this.getAllCities(id[1]);
  }

  getAllCities(stateId){
    this._as.getCitiesForState(stateId).subscribe(
      (result)=>{
        this.cityList=result['responseData']; 

      },
      error => {
        console.log(error)
      }
    )
  }

  getAllCompanies(){
     this._cs.getCompany(this.selectedProject).subscribe(
      data=>{
       
        console.log(data);
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.companyName;
            res.id=res.companyId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute11Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    )
 
  }

  getAllContacts(){
    this._contact_service.getAllContactDetails(this.selectedProject).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.contactName;
            res.id=res.contactId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute6Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }
  getFields(){
    console.log(this.editId); 
    console.log(this.moduleType);
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject).subscribe(
      data=>{       
        this.editOppVal=data['responseData'][0];
        console.log("my edit api result");
        console.log(this.editOppVal);
        
        this._customAttrService.getFields(this.moduleType, this.selectedProject).subscribe(
          res=>{
            res['responseData'] = res['responseData'].filter(ele => {
              if(ele.status == 1){
                if(ele.attributeType.id==4 ){
                  this.maxImgUploadAllowed = ele.maxImagesAllowed;
                  if(this.maxImgUploadAllowed === null){
                    this.maxImgUploadAllowed = 0;
                  }
                  this.imageUploadMandatoryFlag = ele.maxImagesAllowed;;
                  if( ele.allowBrowsing==1 ){
                    return 1;
                  }else{
                    return 0;
                  }
                }else{
                  return 1;
                }
              }
            })
                    
            this.SpinnerService.hide();
            console.log("my get fields api result");
            console.log(res['responseData']);
            res['responseData'].forEach(element=>{
              if(element.systemAttribute==null)
              {
                element.systemAttribute={};
              }
            
    
              if(element.attributeType.id==1 && element.systemAttribute['id']!==16 && element.systemAttribute['id']!==15 && element.systemAttribute['id']!==21){
                
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
     
                this.editOppVal.customAttributeValues.forEach(ele=>{
             
                  if(ele.attributeId==element.id){
        
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
              
                    }
                  }
                })

               
              }else{
                if(element.systemAttribute['id']==16 || element.systemAttribute['id']==15 || element.systemAttribute['id']==21){           
    
                  if(element.mandatoryFlag==1){
                    this.addCompaniesForm.addControl('countryName',  new FormControl('',Validators.compose([ Validators.required])));
                    this.addCompaniesForm.addControl('stateName',  new FormControl('',Validators.compose([ Validators.required])));
                    this.addCompaniesForm.addControl('cityName',  new FormControl('',Validators.compose([ Validators.required])));
      
                  }else{
    
                    this.addCompaniesForm.addControl('countryName',  new FormControl());
                    this.addCompaniesForm.addControl('stateName',  new FormControl());
                    this.addCompaniesForm.addControl('cityName',  new FormControl());
        
                  }

                  this.editOppVal.customAttributeValues.forEach(ele=>{
              
                    if(ele.attributeId==element.id){
                
                      if(ele.value.length>0){

                        if(ele.value.length > 0){
                          this.addCompaniesForm.controls['countryName'].setValue(ele.value[0].name+'@'+ele.value[0].id);
                        }
                        //after filling country get states 
                        this.getAllStates(ele.value[0].id);
                        if(ele.value.length > 1){
                          this.addCompaniesForm.controls['stateName'].setValue(ele.value[1].name+'@'+ele.value[1].id);
                            //after filling state get cities
                          this.getAllCities(ele.value[1].id);
                        }
                        
                        if(ele.value.length > 2){
                          this.addCompaniesForm.controls['cityName'].setValue(ele.value[2].name+'@'+ele.value[2].id);
                        }
                      }

                    }
                  })

                }
              }
    
              if(element.attributeType.id==2){
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                var multiSelectArray=[];
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(ele.value.length>0){
                        if(element.parentAttributeId !==0){
                          this.newOpenDiv.push('div_'+ele.attributeId)
                        }
                        this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                
                      }
                      ele.value.forEach(res=>{
                        multiSelectArray.push(res.id);
                      })
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(multiSelectArray);
                    }
                  }
                })
              }
    
              if(element.attributeType.id==5){
                //radio
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }
                  }
                })
              }
    
              if(element.attributeType.id==6){
                //text
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
               
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
    
              }
    
              if(element.attributeType.id==7){
                //numeric
                const pattern="^(0|[1-9][0-9]*)$";
                if(element.mandatoryFlag==1){
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.pattern(pattern)])));
                }else{
    
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('',Validators.compose([ Validators.pattern(pattern)])));
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                     this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
    
              if(element.attributeType.id==8){
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
    
              }
                
              if(element.attributeType.id==3){
               
                  this.maxDate= new Date();
      
                  this.minDate= new Date();
      
                  console.log(element);
                  if(element.dateAttributes){
            
                    if(element.dateAttributes.stdLookupAttribute != null){
                      this.DisplayDateMinFlag=false;
                      this.DisplayDateMaxFlag=false;
                      if(element.dateAttributes.stdLookupAttribute.id == 2){
                        this.DisplayDateFlag=true;
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 3){
                        this.DisplayDateFlag=true;
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 1);
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - 1);
                    
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 5){
                        this.DisplayDateFlag=true;
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - this.minDate.getDay());
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - this.maxDate.getDay()+6);
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 6){
                        this.DisplayDateFlag=true;
                        var date = new Date();
                        var date = new Date();
                        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                    
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 7){
                        this.DisplayDateFlag=true;
                        var date = new Date();
                        var date = new Date();
                        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                        element.dateAttributes.maxDate= new Date(date.getFullYear(), date.getMonth() + 1, 0);
                    
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 8){
                        this.DisplayDateFlag=true;
                        var now = new Date();
                        var start = new Date(now.getFullYear(), 0, 0);
                        var diff = Number(now) - Number(start);
                        var oneDay = 1000 * 60 * 60 * 24;
                        var day = Math.floor(diff / oneDay);
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                     
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 9){
                        this.DisplayDateFlag=true;
                        var now = new Date();
                        var start = new Date(now.getFullYear(), 0, 0);
                        var end = new Date(now.getFullYear(), 11, 31);
                        var diffStart = Number(now) - Number(start);
                        var diffend = Number(end) - Number(now) ;
                        var oneDay = 1000 * 60 * 60 * 24;
                        var day = Math.floor(diffStart / oneDay);
                        var dayEnd = Math.floor(diffend / oneDay);
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + (dayEnd + 1));
                       }
                    }else{
                        if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed == null){
                      
                          this.DisplayDateFlag=false;
                          this.DisplayDateMinFlag=false;
                          this.DisplayDateMaxFlag=false;
                        }
                        else if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed != null){
                        
                          this.DisplayDateMaxFlag=true;
                          this.DisplayDateMinFlag=false;
                          this.DisplayDateFlag=false;
                          if(element.dateAttributes.futureDateAllowed){
                            element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                          }
                        }
                        else if(element.dateAttributes.previousDateAllowed != null && element.dateAttributes.futureDateAllowed == null){
                      
                          this.DisplayDateMinFlag=true;
                          this.DisplayDateFlag=false;
                          this.DisplayDateMaxFlag=false;
                          if(element.dateAttributes.previousDateAllowed){
                            element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);    
                          }
                        }else{
                         
                          this.DisplayDateFlag=true;
                          this.DisplayDateMinFlag=false;
                          this.DisplayDateMaxFlag=false;
                          if(element.dateAttributes.previousDateAllowed){
                           element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
                          }
              
                          if(element.dateAttributes.futureDateAllowed){
                            element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                          }
                        } 
                     }
                          
                     
                  }
                 
                if(element.mandatoryFlag==1){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
    
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
    
              if(element.attributeType.id==4){
                console.log('element');
                console.log(element);
                              
                this.editOppVal.customAttributeValues.forEach(ele=>{
               
                  if(ele.attributeId==element.id){
                   
                    if(ele.value.length>0){                      
                      
                      this.showImageArray.push(
                        {
                          "element":element.combinedAttributeName,
                          "image":ele.value
                        }
                      );
                      console.log('jyg');
                      console.log(this.showImageArray);
                      
                      var imagesnameSubArray=[];
                      ele.value.forEach(img=>{
                        var splitted=img.name.split('/');
            
                        imagesnameSubArray.push(splitted[splitted.length -1]);
                      })
                      
                      this.imagesnameArray.push({
                        "name":element.combinedAttributeName,
                        "value":imagesnameSubArray
                      })                                      
                                            
                    }
                  }
                })
              }
             
              if(element.attributeType.id==9){
                if(element.mandatoryFlag==1){
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
                   // this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.email])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
                }

                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push('div_'+ele.attributeId)
                      }
                     this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
    
              }

             
              //other conditions
              if(element.systemAttribute.id==11 || element.systemAttribute.id==5){
                this.systemAttribute11Array.push(element);  
              }

              //CONDITION FOR CONTACTS LIST
              if(element.systemAttribute.id==6){
                this.systemAttribute6Array.push(element);  
              }
               //CONDITION FOR COMPANY OWNER LIST
              if(element.systemAttribute.id==34 || element.systemAttribute.id==35 ){
                console.log('condition for owner array');
               this.systemAttributeOwnerArray.push(element);   
              }

              if(element.systemAttribute.id==28){
          
                if(element.mandatoryFlag==1){
                  console.log('adding mandatory flag in sku')
                  this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()],[Validators.required]));
                  
                }else{
                  this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()]));
                }
                
        
                this.myFormValueChanges$ = this.addCompaniesForm.controls['skuItems'].valueChanges;
           
                this.myFormValueChanges$.subscribe(skuItems => this.updateItems(skuItems));
              }
   
            })
    
            this.customAttributesList=res['responseData']; 
           // console.log(this.customAttributesList);  
      
          },
          error => {
            this.disabled=false;
            this.errorMes=error.error.responseData.errorMsg;
    
          }
        )
      }
    )

    setTimeout(()=>{      
      this.showDivs();
      if(this.systemAttribute11Array.length >0){
      
        this.getAllCompanies();
 
      }
 
      if(this.systemAttribute6Array.length >0){
        
        this.getAllContacts();
 
      }
 
      if(this.systemAttributeopportunityArray.length >0){
        
        this.getAllOpportunities();

      }

      if(this.systemAttributeopportunityListArray.length >0){
 
        if(this.opportunityId && this.flag=="opportunity"){
          console.log('lllll');
          this.getAllOpportunitiesDependentInputs(this.opportunityId);

        }
        else{
          this.systemAttributeopportunityListArray.forEach(rep=>{
            if(rep.systemAttribute.id !==26){
              var divId='div_'+rep.id;
              var division1=<HTMLElement>document.getElementById(divId);
              if(division1){            
                division1.classList.add('hidden');
              }
            }
           
          })        
        }
      }

      if(this.systemAttributecompanyListArray.length >0){
 
        if(this.opportunityId && this.flag=="company"){
          this.getAllCompanyDependentInputs(this.opportunityId);

        }
      }



      if(this.systemAttributeproductsArray.length >0){
           
        this.getAllProducts();

      }

      if(this.systemAttributeEstimateArray.length >0){
        
        this.getAllEstimates();

      }

      if(this.systemAttributeStatusArray.length >0){
   
        this.setStatus();

      }

      if(this.systemAttributeOwnerArray.length >0){
        
        this.getAllOwner();

      }
   
      if(this.showImageArray.length>0){
        this.showImage();
      }
    },3000);
  }

  showImage(){

    console.log('imageDiv');
 	var imageCounter = 0;
 	var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
 	
     this.showImageArray.forEach(res=>{
      var imageDiv=document.getElementById('image'+res.element);
      var combinedAttributeName = res.element;
      this.imageNameIndexMap.set("combinedAttributeName", res.element);
      console.log(imageDiv);
         res.image.forEach(res=>{
	          imageCounter++
	          if(imageCounter <= this.maxImgUploadAllowed || this.maxImgUploadAllowed === 0){
	           	
	          /* 	
	           	var data='<span> <img src="'+res.name+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(imageCounter)+'/'+this.maxImgUploadAllowed+' </span>';
          var moreData = '<div id="removableDiv'+imageCounter+'" class="artist-collection-photo"> <button id="myImgCont'+imageCounter+'" class="closeImageButton" data-attributename='+combinedAttributeName+' data-imageindex='+imageCounter+' type="button">�</button>  </div> ';
          data = data + moreData;
          */

         var data = '<div id="removableDiv'+imageCounter+'" class="artist-collection-photo"> ' 
         + '<div class="remove-img-btn"><button id="myImgCont'+imageCounter+'" data-attributename='+combinedAttributeName+' data-imageindex='+imageCounter+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
         + '<img width="90" height="100" src="'+res.name+'" alt="image.jpg" class="img-thumbnail">	';
         if(this.maxImgUploadAllowed !== 0){
         	data =  data + '<div class="img-index"> '+(imageCounter)+'/'+this.maxImgUploadAllowed+' </div> ';
         }
         data =  data + '</div> ';

          imageDiv.innerHTML += data;   
	           	
	           
	            oldUploadedImageFileArray.push(res.name);
	         }
         })
     })
     this.imageUploadedCounter = imageCounter + 1;
     if(imageCounter >= this.maxImgUploadAllowed && this.maxImgUploadAllowed > 0){
        this.showUploadImageIcon = false;
     }
     
     // attach remove event
     let children = document.getElementsByClassName("closeImageButton");
      for (let i = 0; i < children.length; i++) {
        children[i].addEventListener("click", (event: Event) => {
          this.removeImage(event);
        });
      }
        	
     this.imageNameIndexMap.set("maxImage", this.maxImgUploadAllowed);
     this.imageNameIndexMap.set("mandatoryFlag", this.imageUploadMandatoryFlag);
    this.imageNameIndexMap.set("imageFileArray", oldUploadedImageFileArray);
     
  }
  showDivs(){
    this.newOpenDiv.forEach(res=>{
      var division1=<HTMLElement>document.getElementById(res);

      if(division1){              
        division1.classList.remove('hidden');
      }
    })
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addCompaniesForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Close(){
    this.activeModal.close();
    this.addCompaniesForm.reset();
  }

  clickImage(id){
    let element:HTMLElement =document.getElementById(id) as HTMLElement;
    element.click();
  }
  isError(val){
  //console.log('val----------'+val);
  }
  AddCompany(controls){
    this.customAttributeValues=[];
    //dyniamic fields
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;


      if((element.attributeType.id==1 ||
        element.attributeType.id==2 ||
        element.attributeType.id==5 ||
        element.attributeType.id==6 ||
        element.attributeType.id==7 || 
        element.attributeType.id==8 || 
        element.attributeType.id==9) &&
         (element.systemAttribute.id!==16) &&
          (element.systemAttribute.id!==15) &&
           (element.systemAttribute.id!==21)){

       
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              "name": String(this.addCompaniesForm.controls[id]['value']==null ? '':this.addCompaniesForm.controls[id]['value'].toString().trim())
            }
            
          ]
          
        })
      }else{
        if(element.systemAttribute.id==16 || element.systemAttribute.id==15 || element.systemAttribute.id==21){
          var country= this.addCompaniesForm.controls.countryName['value'];
          var state=this.addCompaniesForm.controls.stateName['value'];
          var city = this.addCompaniesForm.controls.cityName['value'];
          var address2='';
          if(country!=null && state!=null && city!=null){
            address2=(country.trim()+'->'+state.trim()+'->'+city.trim());
          }else if(country!=null && state==null && city==null){
           address2=(country.trim());
          }else if(country!=null && state!=null && city==null){
            address2=(country.trim()+'->'+state.trim());
          }else{
           address2='';
          }
        
          console.log(address2);
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":[
              {
                'name':address2
               }
              
            ]
            
          })
        }
      }

      if(element.attributeType.id==3){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': formatDate(this.addCompaniesForm.controls[id]['value'])
            }          
            
          ]
          
        })
      }

      if(element.attributeType.id==4){
        console.log('image control');
        console.log(this.addCompaniesForm.controls[id]);
        if(this.addCompaniesForm.controls[id]){
          var resultArrayOfImages=[];
          this.addCompaniesForm.controls[id]['value'].forEach(resultImage=>{
            resultArrayOfImages.push(
              {
                'name':resultImage
              }
            )
          })
          
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":resultArrayOfImages
            
          })       
        }
      }

    })


    function formatDate(date) {
      if(date == null || date == "")
        return "";
      else {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;    
    
        return [year, month, day].join('-');
      }
    }

    var addData;
    console.log(this.moduleType);
    if(this.moduleType==1){
      addData = {
        "contactId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==2){
      addData = {
        "companyId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==3){
      addData = {
        "opportunityId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==4){
      addData = {
        "productId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues  
      };
    }else{

    }
       
    return addData;
  }

  Save(){
    this.disabled=true;
    this.errorFlag=false;

    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==1){
        var min = element.minDigits;
        var max = element.maxDigits;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
      
        if(min!="" && min!=null){
          
            if(Number(min) > Number(elementValue.toString().length)){
              this.errorFlag=true;
              var message='Enter number greater than '+Number(min) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
        }
        else if(max!="" && max!=null){
         
            if(Number(elementValue.toString().length) > Number(max)){
              this.errorFlag=true;
              var message='Enter number less than '+Number(max) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
        }else if((min!=null && max!=null) && ((min!="0" || max!="0") || (min!=0 || max!=0))){
          if((Number(min) > Number(elementValue.toString().length)) || (Number(elementValue.toString().length) > Number(max))){
            this.errorFlag=true;
            var message='Enter number between '+Number(min) +' and '+Number(max) +' digits for '+element.combinedAttributeName;
            this.OpenAlertValidation(message);
            this.disabled=false;
          }
        }
      }
      }

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==2){
       
        var min = element.minNumber;
        var max = element.maxNumber;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
        //if(min!="" && max!=""){
          if(min!="" && min!=null){
              if(Number(min) > Number(elementValue)){
                this.errorFlag=true;
                var message='Enter value greater than '+Number(min) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          else if(max!="" && max!=null){
              if(Number(elementValue) > Number(max)){
                this.errorFlag=true;
                var message='Enter value less than '+Number(max) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }if(min!=null && max!=null){
            if((Number(min) > Number(elementValue)) || (Number(elementValue) > Number(max))){
              this.errorFlag=true;
              var message='Enter value between '+Number(min) +' and '+Number(max) +' for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
          } 
      }
    }

    // image validation section
    if(element.attributeType.id === 4 && element.mandatoryFlag === 1 ){      
      var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
      if(oldUploadedImageFileArray.length === 0){
        this.errorFlag=true;
        this.imageNoUploaded=true;
      }                 
    }

    })
    if(this.errorFlag){
      return;
    }
    const controls = this.addCompaniesForm.controls;
    if (this.addCompaniesForm.invalid) {
      Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
      return;
    }
    
    if (this.isSubmitted==false) {
      return;
    }

    console.log(controls);

    const addData=this.AddCompany(controls);
    console.log(addData);

    this._customAttrService.add(addData,this.moduleType).subscribe(

      data => {
        console.log(data);

        this.addCompaniesForm.reset();
        this.passEntry.emit('SUCCESS');
        this.activeModal.close('SUCCESS');
        this.eventEmitterService.onModalCloseResult('SUCCESS');
        
      },
      error => {
        this.disabled=false;
        this.errorMes=error.error.responseData.errorMsg;
        console.log(this.errorMes);
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
        
    );
  }

  invokeChildMethod(lookupvalue,attributeId,editdata){

    //cal  api
    this._customAttrService.getFieldRelation(attributeId,lookupvalue).subscribe(
      res=>{        
        if(res['responseData']){
          res['responseData'] = res['responseData'].filter(ele => {
            return ele.status == 1;
          })

          if(res['responseData'].length>0){
            res['responseData'].forEach(element=>{
              
              if(editdata){
                editdata.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
      
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    
                    }
                  }
                })
              }
              
              //serch for key
              var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
            
              var divId='div_'+element.id;

              if(removeIndex >-1){
                console.log('if big div exsits');
                console.log(this.openDivs[removeIndex]);
                console.log(divId);
                var removeIndexInside = this.openDivs[removeIndex].value.map(function(item) { return item; }).indexOf(String(divId));
                console.log(removeIndexInside);

                if(removeIndexInside >-1){
                  console.log('if small div exsits');
                  //yes exists then no need to do anything
                }else{
                  console.log('if small div not exsits');
                  this.openDivs[removeIndex].value.push(divId);
  
                  var division1=<HTMLElement>document.getElementById(divId);
                  if(division1){            
                    division1.classList.remove('hidden');
                  } 
                }
                               

              }else{
                console.log('this else div');
                this.openDivs.push({
                  attributeId:attributeId,
                  value:[
                    divId
                  ] 
                })

                var division1=<HTMLElement>document.getElementById(divId);
                console.log(division1);
                if(division1){            
                  division1.classList.remove('hidden');
                }               
          
              } 

              var removeIndex2 = this.customAttributesList.map(function(item) { return item.id; }).indexOf(element.id);
            
              if(removeIndex2 >-1){
                
                this.customAttributesList[removeIndex2].lookupValues=element.lookupValues;
        
              }

            })
          }else{
              
            var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
        
            if(removeIndex >-1){
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res=>{
                var division=<HTMLElement>document.getElementById(res);
                if(division){              
                  division.classList.add('hidden');
                }
              })
              //remove key an pairs
              this.openDivs.splice(removeIndex, 1);              

            }
            
          }

        }
      }
    )
  }

  processFile(event,combinedAttributeName,mandatoryFlag,maxImage){    
    var imageArray= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
    console.log(imageArray);
    console.log(maxImage);
    if(maxImage === null || maxImage === undefined){
      maxImage = 0;
    }
    if(imageArray >-1){          
      if(this.imagesnameArray[imageArray].value.length < maxImage || (maxImage === 0))
      {
        
        this.processArray(event,combinedAttributeName,mandatoryFlag);
      }else{
        this.disabled=false;
        this.errorMes="Max "+maxImage+ " images are allowed";
      }

    }else{      
      this.processArray(event,combinedAttributeName,mandatoryFlag);
    } 
  }

  processArray(event,combinedAttributeName,mandatoryFlag){
    //serach for image count
    var imageDiv=document.getElementById('image'+combinedAttributeName);
    var maxImage = this.maxImgUploadAllowed;
 
  // uploaded image count
    var imageArray = this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
    var uploadedImageCount = 0;
    if(imageArray >-1){
    	uploadedImageCount = this.imagesnameArray[imageArray].value.length;
    }
 
    if(mandatoryFlag==1){
      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
    }else{

      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl());
    }

var totalNumberOfUploadFilesAllowed = this.maxImgUploadAllowed - uploadedImageCount;
if(this.maxImgUploadAllowed === 0){
totalNumberOfUploadFilesAllowed = 1;
}
var imageFilesFromEvent = event.target.files;

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
       var numOfFilesAllowed = this.maxImgUploadAllowed - uploadedImageCount;
       if(this.maxImgUploadAllowed === 0){
      numOfFilesAllowed = 1;
      }
      for (let i = 0; ((i < filesAmount) && (numOfFilesAllowed > 0)); i++) {
         numOfFilesAllowed--;
        var reader = new FileReader();   

        reader.onload = (event:any) => {

           //insert data
            ++uploadedImageCount;
            /*
           var data='<span> <img src="'+event.target.result+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
          
          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
          data = data + moreData;
          */

         var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
         + '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
         + '<img width="90" height="100" src="'+event.target.result+'" alt="image.jpg" class="img-thumbnail">	';
         if(this.maxImgUploadAllowed !== 0){
         	data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
         }
         data = data + '</div> ';

          imageDiv.innerHTML += data;
          this.imageNoUploaded=false;
        
        let children = document.getElementsByClassName("closeImageButton");
		      for (let i = 0; i < children.length; i++) {
		        children[i].addEventListener("click", (event: Event) => {
		          this.removeImage(event);
		        });
		      }
        	this.imageUploadedCounter = uploadedImageCount + 1;
		    if(uploadedImageCount == this.maxImgUploadAllowed){
		    	this.showUploadImageIcon = false;
		    }
        
        }
        reader.readAsDataURL(event.target.files[i]);
       
      }
      
    }
    
    // upload file on server
     var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
    for (let i = 0; (i < filesAmount && totalNumberOfUploadFilesAllowed > 0); i++) {  
      totalNumberOfUploadFilesAllowed--;
      
        oldUploadedImageFileArray.push(event.target.files[i]);
    this.imageNameIndexMap.set("imageFileArray", oldUploadedImageFileArray);
      
      this._us.uploadImage(this.moduleType,event.target.files[i], this.selectedProject).subscribe(
        res => {     
          console.log('image res');
          console.log(res);
          var removeIndex= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
      
          if(removeIndex >-1){          
            this.imagesnameArray[removeIndex].value.push(res['responseData'].name);
    
          }else{
            this.imagesnameArray.push({
              "name":combinedAttributeName,
              "value":[res['responseData'].name]
            })
          }            
        },
        error=>{
          error => console.log("error occured")
        }
      )
    }

    console.log(this.imagesnameArray);
   
    setTimeout(()=>{
      var removeIndex2= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
      console.log(removeIndex2);
      this.addCompaniesForm.get(combinedAttributeName).setValue(this.imagesnameArray[removeIndex2].value);

    },3000)
    
  }

	removeImage(clickEvent){
		var imagePositionIndex = clickEvent.target.dataset.imageindex
		var imageAttributeName = clickEvent.target.dataset.attributename;
	console.log("imagePositionIndex = "+imagePositionIndex+" and imageAttributeName = "+imageAttributeName);
		var removableImageIndex = imagePositionIndex - 1;
	console.log("remove image position index = "+removableImageIndex);
	
	var imageFileArrayFromMap = this.imageNameIndexMap.get("imageFileArray");
	
	console.log("inside removeImage imageFileArrayFromMap.length = "+imageFileArrayFromMap.length);
	
	if(removableImageIndex > -1 && removableImageIndex < imageFileArrayFromMap.length){
		imageFileArrayFromMap.splice(removableImageIndex, 1);
		this.imageNameIndexMap.set("imageFileArray", imageFileArrayFromMap);
		// remove image from UI
    	document.getElementById("removableDiv"+imagePositionIndex).outerHTML = "";
	}
	
	
	
	
	var imageObjectIndex = this.imagesnameArray.map(function(item) { return item.name; }).indexOf(imageAttributeName);
    if(imageObjectIndex >-1){
    	var imageArray = this.imagesnameArray[imageObjectIndex].value;
    	var imageArrayLength = this.imagesnameArray[imageObjectIndex].value.length;
    	if(imageArrayLength > 0 && removableImageIndex > -1 && removableImageIndex < imageArrayLength){
    	 	this.imagesnameArray[imageObjectIndex].value.splice(removableImageIndex, 1);
    	}else{
    		console.log(imageArrayLength+" is the image array length, unable to remove image from index "+removableImageIndex);
    	}
    }else{
    	console.log("Image object not found with key "+imageAttributeName);
    	console.log(this.imagesnameArray);
    }
	
	
	
	// remove all images from UI and create from stating
	var combinedAttributeName = this.imageNameIndexMap.get("combinedAttributeName");
      var maxImage = this.maxImgUploadAllowed;
	var imageFileArray = this.imageNameIndexMap.get("imageFileArray");
    var imageDiv=document.getElementById('image'+imageAttributeName);
    imageDiv.innerHTML = "";
    var uploadedImageCount = 0;
    
   this.imageUploadedCounter = 0;
   var loopCounter = 0;
      
	for (let i = 0; i < imageFileArray.length; i++) {
		  loopCounter++;
		  var imgFileObj = imageFileArray[i];
		  console.log("for index ["+i+"] image "+(typeof imgFileObj !== 'string'));
		  if(typeof imgFileObj !== 'string' ){
		  		console.log("for index ["+i+"] image File "+imgFileObj);
		  	 	var reader = new FileReader();   
		        reader.onload = (event:any) => {
		        ++uploadedImageCount;
		        /* 
            var data='<span> <img src="'+event.target.result+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
		          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+imageAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
		          data = data + moreData;
              */

             var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
             + '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
             + '<img width="90" height="100" src="'+event.target.result+'" alt="image.jpg" class="img-thumbnail">	';
              if(this.maxImgUploadAllowed !== 0){
             	data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
             }
             data =  data + '</div> ';

		          imageDiv.innerHTML += data;
		           let children = document.getElementsByClassName("closeImageButton");
		      		console.log("inside children.length = "+children.length);
				    for (let i = 0; i < children.length; i++) {
				        children[i].addEventListener("click", (event: Event) => {
				          this.removeImage(event);
				        });
				      }
		        }
		        reader.readAsDataURL(imgFileObj);
		  }else{
		  	 ++uploadedImageCount;
		  	 console.log("for index ["+i+"] image URL "+imgFileObj);
		  	 /*
         var data='<span> <img src="'+imgFileObj+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
	          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+imageAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
	          data = data + moreData;
            */
           var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
           + '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+imageAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
           + '<img width="90" height="100" src="'+imgFileObj+'" alt="image.jpg" class="img-thumbnail">	';
           if(this.maxImgUploadAllowed !== 0){
           		data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
           	}
           data =  data + '</div> ';
	          imageDiv.innerHTML += data; 
		  }
      }
      
      let children = document.getElementsByClassName("closeImageButton");
      console.log(" children.length = "+children.length);
		    for (let i = 0; i < children.length; i++) {
		        children[i].addEventListener("click", (event: Event) => {
		          this.removeImage(event);
		        });
		      }
		      
		    
		    this.imageUploadedCounter = loopCounter + 1;
		    if(loopCounter == maxImage && maxImage !== 0){
		    	this.showUploadImageIcon = false;
		    }else{
		    	this.showUploadImageIcon = true;
		    }    
		    
		    if(imageFileArray.length == 0){
		    	this.imageUploadedCounter = 0;
          if(this.imageUploadMandatoryFlag === 1){
            this.imageNoUploaded = true;
          }   
		    }
	}
  OpenAlertValidation(message){
    const initialNotice = message;
     const dialogRef = this.dialog.open(AlertDialogComponent, {
       width: '600px',
       position: {top: '20px'},
       data: { title: 'Alert', body: initialNotice }
     });
  
}

}
