import { Component, OnInit } from '@angular/core';
import { CandidateService } from '../../Services/candidate.service';
//import { Candidateposition } from '../../Models/candidates.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { Jobtype } from '../../Models/job-type-list.model';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { Candidateposition } from '../../Models/candidate-position-list.model';
import { Candidates } from '../../Models/candidates.model';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'kt-candidate-position',
  templateUrl: './candidate-position.component.html',
  styleUrls: ['./candidate-position.component.scss']
})
export class CandidatePositionComponent implements OnInit {

  editCandidate:Candidates;
  modalReference: any;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public addUpdateCandidatePositionForm: FormGroup;
  Candidateposition: Candidateposition[];
  public candidatePositionFilter: FormControl = new FormControl();
  candidateName = '';
  savedCandiadtePosition = '';
  disabled:boolean;
  errorMes:String="";
  positionList:any;
  
  
  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public candidateService: CandidateService,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog){ }

  ngOnInit(): void {
    this.inCandidatePositionForm();
    this.getCandidatePositionList();
    this.disabled=false;
  }

  inCandidatePositionForm(){
    this.addUpdateCandidatePositionForm = this.fb.group({
      candidatePosition:[],
      position:[]
     });
  }

  getCandidatePositionList(){
    this.SpinnerService.show('candidate-position-loader');
    this.candidateService.getCandidatePositionList(this.selectedProject).subscribe( 
      (result)=>{
        //console.log('all city', result);
        var reSultArray = result['content'];
        console.log('positions-reSultArray', reSultArray);
        var positionListTempArray = [];
        reSultArray.forEach(function (value) {
          var tempArray = {"id": value.positionId, "positionName": value.budgetName}
          positionListTempArray.push(tempArray);
        })
        this.positionList = positionListTempArray;
        this.SpinnerService.hide('candidate-position-loader');
      },
      error => {
        console.log(error)
        this.SpinnerService.hide('candidate-position-loader');
      }
    )
  }

  Close(){
    this.activeModal.close();
    this.addUpdateCandidatePositionForm.reset();
  }

  AddPosition(controls){
    var currentUserObj = JSON.parse(localStorage.getItem('currentUser'));

    const addData = {
        "candidateId":this.editCandidate.candidateId,
        "positionId": controls.candidatePosition.value,
        "recruiterId":currentUserObj.userId,
        "positionType":"Budget"
      };    
      return addData;
  }

  Save(){
    this.errorMes="";
    this.disabled=true;
    const controls = this.addUpdateCandidatePositionForm.controls;
   
    if (this.addUpdateCandidatePositionForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    //  this.activeModal.close();
    const addData=this.AddPosition(controls);
    console.log(addData)
    this.candidateService.saveCandidatePositionData(addData).subscribe(
      data => {
      //  console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          this.OpenAlert(data['message']);
        }else{
          this.activeModal.close();
          this.addUpdateCandidatePositionForm.reset();
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('SUCCESS');
        }
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );
  }

  OpenAlert(message){
    const initialNotice = message;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      position: {top: '20px'},
      data: { title: 'Alert', body: initialNotice }
    });
  }

  isDisabled() : boolean {
    return this.disabled;
   }

}
