import { Component, OnInit, ViewEncapsulation, Input, ɵConsole } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NgbModal,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { StdLookupAttribute } from 'src/app/views/pages/contact-setting/models/std-lookup-attribute.model';
import { AttributeType } from 'src/app/views/pages/contact-setting/models/attribute-type.model';
import { Contact } from 'src/app/views/pages/contact-setting/models/contact.model';
import { DateAttributes } from 'src/app/views/pages/contact-setting/models/date-attributes.model';
import { LooupValues } from 'src/app/views/pages/contact-setting/models/looup-values.model';
import { ContactListService } from 'src/app/views/pages/contact-setting/services/contact-list.service';
import { CustomService } from 'src/app/views/pages/contact-setting/services/custom.service';
import { ManageActivitiesService } from 'src/app/views/pages/manage-activities/services/manage-activities.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import { Company } from '../../company-configuration/_subs/models/company.model';
import { FieldService } from '../services/field.service';
import { ListType } from '../../contact-setting/models/list-type.model';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { EventEmitterService } from '../services/event-emitter.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';


@Component({
  selector: 'kt-add-field',
  templateUrl: './add-field.component.html',
  styleUrls: ['./add-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddFieldComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:String="";
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  lookupVal: LooupValues[] = [];

  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  attributeContactType: string;
  mandatoryContact:boolean;
  allowBrowseVal:boolean;
  uniqueVal:boolean;
  mandatoryContactFlag:number;
  allowBrowsingFlag:number;
  uniqueFlag:number;
  statusFlag:number | string;
 
  stdLookupList: StdLookupAttribute[];
  attributeType:AttributeType;
  characterType:ListType;
  primaryContact:ListType;
  stage:ListType;
  entity:ListType;
  numericValidationType:ListType;
  resultSet:any;
  displayDateValueDiv: boolean;
  displayMultiSelectDiv: boolean;
  displaySingleSelectDiv:boolean;
  isDisplayText:boolean;
  isDisplayImage:boolean;
  isDisplayNumeric:boolean;
  isDisplaySelection:boolean;
  displayFields: boolean;
  displayLinkedFields: boolean;
  allCompanyTypeList: Company[];
  dateAttributes: DateAttributes;
 // lookUpValues: LooupValues[];
  lookup:LooupValues;
  standardTypeValue: StdLookupAttribute;
  companyTypes: Company[];
  companyTyp: Company;
  companyTypesId: string;
  customAttributeId: number;
  customAttributeName: string;
  attributeName: string;
  instructions: string;
  selectedattributeType: number;

  editing:boolean;
  dateValueType;
  futureDateValue:number;
  previousDateValue:number;
  flag_Value: boolean;
  browseVal:boolean;
  unique_Value:boolean;
  disabled:boolean;
  duplicate:boolean;
  attributeTypelist:AttributeType[];
  standardTypelist:StdLookupAttribute[];
  characterTypeList:ListType[];
  numericValidationTypeList:ListType[];
  stageTypeList:ListType[];
  entityTypeList:ListType[];
  primaryContactTypeList:ListType[];
  selectionTypeList:AttributeType[];
  entityList:CustomAttibute[];
  entityfieldList:CustomAttibute[];
  isDisplayEmail:boolean;
  moduleType:string | number;
  singleSelectOption:boolean;
  multiSelectOption:boolean;
  fieldValue;
  linkedfieldList:any;
  isExist:boolean;
 
  public attributeFilterType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();
  public attributecharacterType: FormControl = new FormControl();
  public attributenumericValidationType: FormControl = new FormControl();
  public stageFilterType: FormControl = new FormControl();
  public contactFilterType: FormControl = new FormControl();
  public entityValueType: FormControl = new FormControl();
  public entityfieldType: FormControl = new FormControl();
  public selectionTypeValue: FormControl = new FormControl();

  public addFieldForm: FormGroup;

   /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public _field: FieldService,
    public contactService: ContactListService,
    private eventEmitterService: EventEmitterService,
    public customService: CustomService,
    private _activityService: ManageActivitiesService,
    public dialog: MatDialog) {
      this.unsubscribe = new Subject();
     }

  ngOnInit(): void {
    this.initaddCustomAttributeForm();
    this.getAttributeType();
    this.getcharacterTypeList();
    this.getnumericValidationTypeList();
    this.getstageTypeList();
    this.getentityTypeList();
    this.getprimaryContactTypeList();
    this.displayDateValueDiv=false;
    this.displayMultiSelectDiv=false;
    this.displaySingleSelectDiv=false;
    this.dateAttributes=new DateAttributes();
    this.dateAttributes.stdLookupAttribute= new StdLookupAttribute();
   // this.lookUpValues= new Array<LooupValues>();
    this.companyTypes= new Array<Company>();
    this.companyTypesId='';
    this.standardTypeValue= new StdLookupAttribute();
    this.disabled=false;
    this.duplicate=false;
    this.isDisplayText=false;
    this.isDisplayImage=false;
    this.isDisplayNumeric=false;
    this.displayLinkedFields=false;
    this.isDisplaySelection=false;
    this.dateAttributes=new DateAttributes();
    this.dateAttributes.stdLookupAttribute= new StdLookupAttribute();
  //  this.lookUpValues= new Array<LooupValues>();
    this.isDisplayEmail=false;
    if(this.moduleType >=100){
      this.displayFields=true;
      this.fieldValue='1';
      this.addFieldForm.controls.fieldValue.setValidators([Validators.required]);
      this.addFieldForm.controls.fieldValue.updateValueAndValidity();
    }else{
      this.displayFields=false;
      this.addFieldForm.controls.fieldValue.clearValidators();
      this.addFieldForm.controls.fieldValue.updateValueAndValidity();
    }

  }

  getcharacterTypeList(){
    this.characterTypeList= new Array<ListType>();
    this.characterType= new ListType();
    this.characterTypeList.push({ id: 1, value:"All"});
    this.characterTypeList.push({ id: 2, value:"Alpha-Numeric"});
    this.characterTypeList.push({ id: 3, value:"Alphabet"});
  }

  getprimaryContactTypeList(){
    this.primaryContactTypeList= new Array<ListType>();
    this.primaryContact= new ListType();
    this.primaryContactTypeList.push({ id: 1, value:"Yes"});
    this.primaryContactTypeList.push({ id: 2, value:"No"});
  }
  
  getstageTypeList(){
    this.stageTypeList= new Array<ListType>();
    this.stage= new ListType();
    this.stageTypeList.push({ id: 1, value:"In Progress"});
    this.stageTypeList.push({ id: 2, value:"Closed Won"});
    this.stageTypeList.push({ id: 3, value:"Closed Lost"});
  }

  getnumericValidationTypeList(){
    this.numericValidationTypeList= new Array<ListType>();
    this.numericValidationType= new ListType();
    this.numericValidationTypeList.push({ id: 1, value:"Digits"});
    this.numericValidationTypeList.push({ id: 2, value:"Value"});
   // this.numericValidationTypeList.push({ id: 0, value:"Select"});
  }

  getentityTypeList(){
    this.entityTypeList= new Array<ListType>();
    this.stage= new ListType();

   // this.entityTypeList.push({ id: 1, value:"Contact"});
    // this.entityTypeList.push({ id: 2, value:"Customer"});
    // this.entityTypeList.push({ id: 3, value:"Opportunity"});
    this.entityTypeList.push({ id: 4, value:"Product"});
    //this.entityTypeList.push({ id: 5, value:"Activity"});


    this._activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result=>{
        console.log( result['responseData']);
      result['responseData'].forEach(
        row => {
          if(row.moduleId==2){
            this.entityTypeList.push({ id: row.moduleId, value: row.singularName});
          } 
          if(row.moduleId==3 && row.opportunityEnabled){
            this.entityTypeList.push({ id: row.moduleId, value: row.singularName});
          } 
        }
      )      
      
      
    },
    err=>{
    }
  )


  }

  getselectionTypeList(){
    this.selectionTypeList= new Array<AttributeType>();
    this.selectionTypeList.push({ id: 1, typeName:"Single Select Dropdown"});
    this.selectionTypeList.push({ id: 2, typeName:"Multi Select Dropdown"});

  }



  getAttributeType(){
    this.customService.getAtrributeType().subscribe(
		(result)=>{
      this.attributeTypelist=result;
		},
    error => {
      this.errorMes=error.error.responseData;
    }
    )
  }

  getStandardLookUpList(){
    var attributeId=this.attributeType.id;
    var attributeName=this.attributeType.typeName;
   // console.log(this.attributeType.id+'----'+this.attributeType.typeName);
    this.customService.getstdLookUpList(attributeId,attributeName).subscribe(
		(result)=>{
    //  console.log(result);
        this.standardTypelist=result;
		},
    error => {
      this.errorMes=error.error.responseData;
    })
  }

  getCustomFields(moduleType){
      this._field.getFields(moduleType, this.selectedProject)
      .subscribe(
      (result)=>{
      this.entityList=result['responseData'];
      this.entityfieldList= new Array<CustomAttibute>();
  
        for(let ent of this.entityList){
            if(ent.fieldSpec == 0){

            }else{
               this.isExist=false;
              if(ent.status == 1)
                this.linkedfieldList.forEach(ele => {
                  if(ele.attributedId == ent.id)
                      this.isExist=true;
               });

               if(!this.isExist)
                  this.entityfieldList.push(ent); 
            }
         }
      },
      error => {
        console.log(error)
      })
    }


  initaddCustomAttributeForm(){
    this.addFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
        //attributeType: ['', Validators.compose([ Validators.required])],
        attributeType:[],
        instructions:[],
        dateValueType: [''],
        attributeMultiSelectName:[''],
        attributeSingleName:[],
        attributeCompanyType:[],
        standardTypeValue:[],
        lookup:[],
        futureDateValue:[],
        previousDateValue:[],
        mandatoryCheck:[],
        unique:[],
        minCharacters:[],
        maxCharacters:[],
        characterType:[],
        maxImagesAllowed:[],
        allowBrowsing:[],
        numericValidationType:[],
        numericMinValue:[],
        numericMaxValue:[],
        minDigits:[],
        maxDigits:[],
        fieldValue: [],
        entityValue:[],
        linkedAttributeId:[],
        selectionType:[]
     });
  }

  changeSelectionType(data){
    this.attributeType=data;
  }

  Save(){
    this.disabled=true;
    this.errorMes="";
    
    
    const controls = this.addFieldForm.controls;
      if (this.addFieldForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );

        //for (let el in this.addFieldForm.controls) {
         // if (this.addFieldForm.controls[el].errors) {
          //  console.log(el)
         // }
        //}
        this.disabled=false;
        return;
      }

      

      if(this.mandatoryContact == true){
        this.mandatoryContactFlag=1;
      }else{
        this.mandatoryContactFlag=0;
      }

      if(this.allowBrowseVal == true){
        this.allowBrowsingFlag=1;
      }else{
        this.allowBrowsingFlag=0;
      }

      
      if(this.uniqueVal == true){
        this.uniqueFlag=1;
      }else{
        this.uniqueFlag=0;
      }
 
      if(this.isDisplayType()){
        if(this.attributeType.id ==1){

        }else if(this.attributeType.id ==2){

        }else{
          this.lookupVal=[];
        }
      }

      if(this.isDisplaySelection){

        //console.log(this.attributeType);
      }

      const addAttributeData = {
        //attributeName: controls.attributeName.value,
        dateValueType: controls.dateValueType.value,
        companyTypes: controls.attributeCompanyType.value,
        singleValues: controls.attributeSingleName.value,
        ValuesMutli: controls.attributeMultiSelectName.value,
        futureDateValue: controls.futureDateValue.value,
        previousDateValue: controls.previousDateValue.value,
        instructions: controls.instructions.value==null? '': controls.instructions.value.trim(),
        characterType:controls.instructions.value,
        minCharacters:controls.minCharacters.value,
        maxCharacters:controls.maxCharacters.value,
        allowBrowsing:controls.allowBrowsing.value,
        maxImagesAllowed:controls.maxImagesAllowed.value,
        numericValidationType:controls.numericValidationType.value,
        minDigits:controls.minDigits.value,
        maxDigits:controls.maxDigits.value,
        minNumber:controls.numericMinValue.value,
        maxNumber:controls.numericMaxValue.value,
        linkedAttributeId:controls.linkedAttributeId.value,
        fieldValue:controls.fieldValue.value
      };
//console.log('fieldValue--------'+addAttributeData.fieldValue);
//console.log('linkedAttributeId----'+addAttributeData.linkedAttributeId);

      if(addAttributeData.fieldValue == 1){
        addAttributeData.linkedAttributeId = null;
      }
      if(addAttributeData.linkedAttributeId !=null && controls.entityValue.value!=4){
        for(let atr of this.entityfieldList){
            if(atr.id == addAttributeData.linkedAttributeId){
              this.attributeType=atr.attributeType;
              if(atr.attributeType.id == 3){
                this.dateAttributes.stdLookupAttribute = atr.dateAttributes.stdLookupAttribute;
              }
            }
        }
      }

      if(addAttributeData.numericValidationType == 1 || addAttributeData.numericValidationType == '1'){
        var min = addAttributeData.minDigits;
        var max = addAttributeData.maxDigits;
        
        if(min!="" && max!=""){
          if(min!=null && max!=null){
            if(Number(min) > Number(max)){
              var message='Minimum digits cannot be greater than Maximum digits';
              this.OpenAlert(message);
              this.disabled=false;
              return false;
            }
          }
        }
      }
      if(addAttributeData.numericValidationType == 2 || addAttributeData.numericValidationType == '2'){
        var min = addAttributeData.minNumber;
        var max = addAttributeData.maxNumber;
        if(min!="" && max!=""){
          if(min!=null && max!=null){
            if(Number(min) > Number(max)){
              var message='Minimum value cannot be greater than Maximum value';
              this.OpenAlert(message);
              this.disabled=false;
              return false;
            }
          }
        }
      }
     
  //   if(addAttributeData.fieldValue == 1 || addAttributeData.fieldValue =='1'){
     
      if(this.attributeType.id == 6 || this.attributeType.id == 8){
        var min = addAttributeData.minCharacters;
        var max = addAttributeData.maxCharacters;
        if(min!="" && max!=""){
          if(min!=null && max!=null){
            if(Number(min) > Number(max)){
              var message='Minimum characters cannot be greater than Maximum characters';
              this.OpenAlert(message);
              this.disabled=false;
              return false;
            }
          }
        }
      }
 //   }
      
      if(this.isDisplayType()){
        if(this.attributeType.id ==3){
          if(addAttributeData.dateValueType == 'Standard'){

              this.dateAttributes.stdLookupAttribute.id=this.standardTypeValue.id;
              this.dateAttributes.stdLookupAttribute.value=this.standardTypeValue.value;
              this.dateAttributes.previousDateAllowed=null;
              this.dateAttributes.futureDateAllowed=null;
            
          }else if(addAttributeData.dateValueType =='Custom'){
              this.dateAttributes.previousDateAllowed=Number(addAttributeData.previousDateValue);
              this.dateAttributes.futureDateAllowed=Number(addAttributeData.futureDateValue);
              this.dateAttributes.stdLookupAttribute=null;
          }else{
              this.dateAttributes=null;
          }
        }else{
          this.dateAttributes=null;
        }

        
    }
  

    if(this.characterType.id == undefined || this.characterType.id==null){
      this.characterType.id=1;
    }

     
      var displayOrder=99999;
      var fieldSpec=2;
    
  //  console.log('entity tye---'+controls.entityValue.value);
  this._field
  .addFields(this.attributeName.trim(), this.attributeType,this.mandatoryContactFlag,
    this.lookupVal,this.dateAttributes,this.moduleType,this.allowBrowsingFlag,addAttributeData.instructions,
    this.characterType.id,addAttributeData.maxCharacters,addAttributeData.maxDigits,addAttributeData.maxImagesAllowed,
    addAttributeData.minCharacters,addAttributeData.minDigits,this.numericValidationType.id,fieldSpec,
    displayOrder,addAttributeData.minNumber,addAttributeData.maxNumber,this.uniqueFlag,
    addAttributeData.linkedAttributeId, this.selectedProject)
    
    .pipe(
      tap(CustomAttibute => {
        console.log(CustomAttibute['responseData']);
      }),
      takeUntil(this.unsubscribe),
      finalize(() => {
        
      })
    )
    .subscribe(
      data =>{ console.log("sucesssfullycalled Custom Attribute API");
      this.loading = false;
        this.activeModal.close('SUCCESS');
        this.addFieldForm.reset();
        this.eventEmitterService.onModalCloseResult('SUCCESS');
    },
      error => {
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );  

     
  }

  changeCharacterType(data){
    this.characterType.id=data;
  }

  changeStd(data){
   this.standardTypeValue=data;
 //  console.log(this.standardTypeValue);
  }

  change(data){
    this.attributeType=data;
    if(this.attributeType.id ==3){
      this.displayDateValueDiv=true;
      this.getStandardLookUpList();
      this.addFieldForm.controls.dateValueType.setValidators([Validators.required]);
      this.addFieldForm.controls.dateValueType.updateValueAndValidity();
    }else{
      this.displayDateValueDiv=false;
      this.addFieldForm.controls.dateValueType.clearValidators();
      this.addFieldForm.controls.dateValueType.updateValueAndValidity();
      this.resetDateValue();
    }

    this.lookupVal=[];
    if(this.attributeType.id ==2){
      this.displayMultiSelectDiv=true;
      this.addFieldForm.controls.attributeMultiSelectName.setValidators([Validators.required]);
      this.addFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }else{
      this.displayMultiSelectDiv=false;
      this.addFieldForm.controls.attributeMultiSelectName.clearValidators();
      this.addFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }

    if(this.attributeType.id ==1){
      this.displaySingleSelectDiv=true;
      this.addFieldForm.controls.attributeSingleName.setValidators([Validators.required]);
      this.addFieldForm.controls.attributeSingleName.updateValueAndValidity();
    }else{
      this.displaySingleSelectDiv=false;
      this.addFieldForm.controls.attributeSingleName.clearValidators();
      this.addFieldForm.controls.attributeSingleName.updateValueAndValidity();
    }

    if(this.attributeType.id ==6){
      this.isDisplayText=true;
    }else{
      this.isDisplayText=false;
    }

    if(this.attributeType.id ==4){
      this.isDisplayImage=true;
    }else{
      this.isDisplayImage=false;
    }

    if(this.attributeType.id ==7){
      this.isDisplayNumeric=true;
    }else{
      this.isDisplayNumeric=false;
      this.addFieldForm.controls['numericValidationType'].reset();
    }

    if(this.attributeType.id ==6 || this.attributeType.id ==8){
      this.isDisplayText=true;
      this.addFieldForm.controls['characterType'].setValue(1);
    }else{
      this.isDisplayText=false;
    }

    if(this.attributeType.id ==4){
      this.isDisplayImage=true;
    }else{
      this.isDisplayImage=false;
    }

    if(this.attributeType.id ==7){
      this.isDisplayNumeric=true;
     }else{
      this.isDisplayNumeric=false;
    }

    if(this.attributeType.id ==9){
      this.isDisplayEmail=true;
    }else{
      this.isDisplayEmail=false;
    }

  }

  isDisplayStandard(): boolean{
    if(this.addFieldForm.controls.dateValueType.value =='Standard'){
      this.addFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.addFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    }else{
      this.addFieldForm.controls.standardTypeValue.clearValidators();
      this.addFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  radioChange($event: MatRadioChange){
    var radio= $event.value;
    if(radio == 1 || radio=='1'){
     // console.log('if******************');
      this.addFieldForm.controls.attributeType.setValidators([Validators.required]);
      this.addFieldForm.controls.attributeType.updateValueAndValidity();
    }else{
     // console.log('else******************');
      this.addFieldForm.controls.attributeType.clearValidators();
      this.addFieldForm.controls.attributeType.updateValueAndValidity();
    }
  }

  isDisplayType(): boolean{
    if(Number(this.moduleType) > 100){
    if(this.addFieldForm.controls.fieldValue.value =='1'){
        this.addFieldForm.controls.attributeType.setValidators([Validators.required]);
        this.addFieldForm.controls.attributeType.updateValueAndValidity();
      return true;
    }else{
        this.addFieldForm.controls.standardTypeValue.clearValidators();
        this.addFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }else{
    this.addFieldForm.controls.attributeType.setValidators([Validators.required]);
    this.addFieldForm.controls.attributeType.updateValueAndValidity();
    return true;
  }
  }

  isDisplayexistings(): boolean{
    if(Number(this.moduleType) > 100){
    if(this.addFieldForm.controls.fieldValue.value =='2'){
     // this.addFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
     // this.addFieldForm.controls.standardTypeValue.updateValueAndValidity();
        this.displayDateValueDiv=false;
        this.addFieldForm.controls.dateValueType.clearValidators();
        this.addFieldForm.controls.dateValueType.updateValueAndValidity();
        this.resetDateValue();
        this.displayMultiSelectDiv=false;
        this.addFieldForm.controls.attributeMultiSelectName.clearValidators();
        this.addFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
        this.displaySingleSelectDiv=false;
        this.addFieldForm.controls.attributeSingleName.clearValidators();
        this.addFieldForm.controls.attributeSingleName.updateValueAndValidity();
        this.isDisplayText=false;
        this.isDisplayImage=false;
        this.isDisplayNumeric=false;
        this.addFieldForm.controls['numericValidationType'].reset();
        this.addFieldForm.controls['attributeType'].reset();
        this.isDisplayEmail=false;
      return true;
    }else{
      this.addFieldForm.controls['entityValue'].reset();
      this.addFieldForm.controls['linkedAttributeId'].reset();
      this.isDisplaySelection=false;
      this.addFieldForm.controls['selectionType'].reset();
      this.addFieldForm.controls.selectionType.clearValidators();
      this.addFieldForm.controls.selectionType.updateValueAndValidity();
    }
  }else{
      return false;
    }
  }


  isDisplayCustom(): boolean{
    if(this.addFieldForm.controls.dateValueType.value =='Custom'){
      return true;
    }else{
      return false;
    }
  }

  changeValidation(data){
    this.numericValidationType.id=data;
  }

  changeEntity(data){

    this.getCustomFields(data);
    if(data == 4){
      this.getselectionTypeList();
      this.isDisplaySelection = true;
      this.addFieldForm.controls.selectionType.setValidators([Validators.required]);
      this.addFieldForm.controls.selectionType.updateValueAndValidity();
    }else{
      this.isDisplaySelection=false;
      this.addFieldForm.controls.selectionType.clearValidators();
      this.addFieldForm.controls.selectionType.updateValueAndValidity();
    }

  }

  isDisplayNumericValue(): boolean{
    if(this.addFieldForm.controls.numericValidationType.value =='2'){
      return true;
    }else{
      return false;
    }
  }

  isDisplayNumericDigit(): boolean{
    if(this.addFieldForm.controls.numericValidationType.value =='1'){
      return true;
    }else{
      return false;
    }
  }

  showOptions(val) {
    this.allowBrowseVal=val;
  }

  showOptionsUnique(val) {
    this.uniqueVal=val;
  }

  showOptionsEdit(val) {
    this.mandatoryContact=val;
  }

  resetDateValue(){
    this.addFieldForm.controls['dateValueType'].reset();
  }

  OpenAlert(message){
        const initialNotice = message;
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '600px',
          position: {top: '20px'},
          data: { title: 'Alert', body: initialNotice }
        });
    
  }

  Close(){
    this.activeModal.close();
    this.addFieldForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addFieldForm.controls[controlName];
      if (!control) {
        return false;
      }

      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.duplicate=false;

    
    if ((value || '').trim()) {
      for(let fr of this.lookupVal){
        var name= fr.lookupValue;
        if(value.trim().toLowerCase()==name.toLowerCase()){
          this.duplicate=true;
        }
      }
      if(this.duplicate==false){
      this.lookupVal.push({lookupValue: value.trim(),
      isActive: 1, stageId:null});
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(lookup: LooupValues): void {
    const index = this.lookupVal.indexOf(lookup);

    if (index >= 0) {
      this.lookupVal.splice(index, 1);
    } 
    if(index == 0){
      this.addFieldForm.controls['attributeSingleName'].reset();
      this.addFieldForm.controls['attributeMultiSelectName'].reset();
    }
  }
  isDisabled() : boolean {
    return this.disabled;
   }

   resetAll(){
    this.addFieldForm.controls['mandatoryCheck'].reset();
    this.addFieldForm.reset();
    this.lookupVal=[];
    this.addFieldForm.controls['attributeMultiSelectName'].reset();
    this.addFieldForm.controls['attributeSingleName'].reset();
    this.disabled=false;
    this.displayDateValueDiv=false;
    this.addFieldForm.controls.dateValueType.clearValidators();
    this.addFieldForm.controls.dateValueType.updateValueAndValidity();
    this.resetDateValue();
    this.displayMultiSelectDiv=false;
    this.addFieldForm.controls.attributeMultiSelectName.clearValidators();
    this.addFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    this.displaySingleSelectDiv=false;
    this.addFieldForm.controls.attributeSingleName.clearValidators();
    this.addFieldForm.controls.attributeSingleName.updateValueAndValidity();
    this.isDisplayStandard();
    this.isDisplayCustom();
   }

}
