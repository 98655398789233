import { Component, Input, OnInit } from '@angular/core';
import { OpportunityDashboardService } from '../../../../services/opportunity-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SubheaderService } from '../../../../../../../../app/core/_base/layout';
import { OpportunityInfoModel } from '../../../../models/opportunity-info.model';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { EditCompaniesDialogComponent } from '../../../../../shared/custom-edit/edit-companies-dialog/edit-companies-dialog.component';

@Component({
  selector: 'kt-widget5-opportunity',
  templateUrl: './widget5-opportunity.component.html',
  styleUrls: ['./widget5-opportunity.component.scss']
})
export class Widget5OpportunityComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('pluralName'));
  headingName = 'Opportunity';
  opportunityInfo: OpportunityInfoModel;
  opportunityName: string;
  winProbability: number | string;
  estimatedRevenue: number | string;
  closureDate: string;
  contacts: any;
  status: string | number;
  imageName: string;
  isError = false;
  errorMessage: string;

  public isCollapsed = false;

  constructor(
    private _ods: OpportunityDashboardService,
    private SpinnerService: NgxSpinnerService,
    private subheaderService: SubheaderService,
    private eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-opportunity');
    this.fetchOpportunityInfo(this.selectedProject, this.parentData);

    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.opportunityResult(data);
      });
    }

    if(this.tabHeading['opportunities'])
      this.headingName = this.tabHeading['opportunities'];

  }

  fetchOpportunityInfo(projectId: number, opportunityId: number){
    this._ods.getOpportunityInfo(projectId, opportunityId).subscribe(
      res=>{
        this.opportunityInfo = res['responseData'];
        this.opportunityName = this.opportunityInfo.name;
        this.winProbability = this.opportunityInfo.winProbability;
        this.estimatedRevenue = this.opportunityInfo.estimatedRevenue;
        this.closureDate = this.opportunityInfo.closureDate;
        this.contacts = this.opportunityInfo.contacts;
        this.status = this.opportunityInfo.status;
        this.imageName = this.opportunityInfo.imageName;
        this.subheaderService.setTitle(this.opportunityInfo.name);
        this.SpinnerService.hide('dashboard-opportunity');

      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-opportunity');
      }
    )
  }

  editOpportunity() {
    const _saveMessage = `Opportunity  has been modified.`;
   	const ref = this.modalService.open(EditCompaniesDialogComponent,
      {
        centered: false,
        size : 'lg',
        backdrop : 'static'
      }
    );

    ref.componentInstance.moduleType=3;
    ref.componentInstance.flag='opportunity';
    ref.componentInstance.editId = this.parentData;
    ref.componentInstance.activityFlag=false;

    ref.result.then((result) => {
      this.opportunityResult(result);
    });
    
  }

  opportunityResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-opportunity');
      this.fetchOpportunityInfo(this.selectedProject, this.parentData);
    }
  }

}
