import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelationService {

  constructor(private http:HttpClient) { }

  getFields(projectId:number){
    return this.http.get(
      environment.baseUrl +'/transactions/api/customType/getFields?projectId='+projectId+'&moduleType=2')
  }

  getFieldRelation(moduleType, id, projectId:number){
    return this.http.get(
      environment.baseUrl +'/setting/api/customType/getFieldRelation?projectId='+projectId+'&moduleType='+moduleType+'&attributeId='+id)
  }

  addRelation(data){
    console.log(data);
    return this.http.post(environment.baseUrl +'/setting/api/customType/addEditRelation',data)
  }

  deleteRelation(data){
    console.log(data);
    return this.http.post(environment.baseUrl +'/setting/api/customType/deleteRelation',data)
  }
}
