import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { opportunitiesModel } from '../models/opportunities.model';
import { Contact } from '../../contact-setting/models/contact.model';
import { opportunitiesStatusModel } from '../models/opportunity-status.model';
@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {

  constructor(private http:HttpClient) { }
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  getAllOpportunityDetails(companyId=null){
    return this.http.get<opportunitiesModel[]>(
      environment.baseUrl +'/transactions/api/opportunity/getOpportunityLookup?projectId='+this.selectedProject)
  }
  getAllOpportunityDetails2(companyId){
    return this.http.get<opportunitiesModel[]>(
      environment.baseUrl +'/transactions/api/opportunity/getOpportunityLookup?projectId='+this.selectedProject+'&companyId='+companyId)
  }

  getAllContactDeatils(projectId: number){
    return this.http.post<Contact[]>(
      environment.baseUrl +'/transactions/api/contact/getAllContactDetails',{"projectId": projectId})
  }

  addEditOpportunity(addDataValues){
    return this.http.post<opportunitiesModel>(
      environment.baseUrl +'/transactions/api/opportunity/addEditOpportunity',addDataValues)
  }

  getAllOpportunityType(projectId: number){
    return this.http.post<opportunitiesStatusModel[]>(
      environment.baseUrl +'/opportunity/getAllOpportunityType',{"projectId": projectId})
  }

  getOpportunity(opportunityId, projectId: number){
    return this.http.get<opportunitiesStatusModel[]>(
      environment.baseUrl +'/transactions/api/opportunity/getOpportunity?projectId='+projectId+'&opportunityId='+opportunityId)
  }

  

}
