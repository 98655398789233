<div class="card card-custom mb-4" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header pl-2 pr-5" [ngStyle]="{'border-bottom':0}">
        <div class="card-title text-primary font-weight-bold font-size-h6"> 
            <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="company" [ngStyle]="{'cursor':'pointer'}">
                <img *ngIf="isCollapsed" src="./assets/media/svg/icons/General/Angle-double-left.svg" class="pr-1">
                <img *ngIf="!isCollapsed" src="./assets/media/svg/icons/General/Angle-double-bottom.svg" class="pr-1">
            </a>
            {{headingName}} 
        </div>
        <div class="card-toolbar">
            <div class="example-tools justify-content-center">
                <a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" href="javascript:void(0)" (click)="addCompany()">
                    <i class="fas fa-plus"></i>
                </a>
                <!--<a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" href="javascript:void(0)">
                    <i class="fas fa-filter"></i>
                </a>-->
            </div>
        </div>
    </div>
    <!-- Body -->
    <div id="company" [ngbCollapse]="isCollapsed" class="card-body p-0 m-0 compay-body-scroll">
        <ngx-spinner size="default" type="ball-spin-clockwise" name='dashboard-company'>  
            <p style="color: white">Fetching Data... </p>  
        </ngx-spinner>
        <div class="font-size-h6 text-center" *ngIf="isError">{{errorMessage}}</div>
        <!-- Contacts Start -->
        <div class="card card-border rounded p-2 ml-2 mr-2 mb-2"  *ngFor="let company of contactCompanies">
            <div class="d-flex align-items-center p-2">
                <div class="d-flex flex-column flex-grow-1">
                    <a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{company.name}}</a>
                </div>
                <a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" 
                    href="javascript:void(0)"
                    routerLink="/company-dashboard/{{company.companyId}}" title="Company Dashboard">
                    <img src="./assets/media/svg/icons/General/Arrow-right.svg"> 
                </a>
            </div>
            <div class="d-flex align-items-center pl-2 pr-2 pb-2">
                <div class="d-flex flex-column flex-grow-1">
                    <span class="text-muted font-size-sm">
                        <img src="./assets/media/svg/icons/General/Opportunity_Icon.svg" class="pr-2"> 
                        <span *ngIf="company.openOpportunities == 1">{{company.openOpportunities}} Open Opportunity </span>
                        <span *ngIf="company.openOpportunities > 1">{{company.openOpportunities}} Open Opportunities </span>
                    </span>
                    <span class="text-muted font-size-sm pt-2">
                        <img src="./assets/media/svg/icons/General/money_2.svg" class="pr-2"> 
                        {{company.estimatedRevenue}}
                    </span>
                    <span class="text-muted font-size-sm pt-2">
                        <img src="./assets/media/svg/icons/General/people_outline_24px.svg" class="pr-2">
                        <span>
                            <span class="label label-light-primary mr-2 mb-2 p-2" *ngFor="let contact of company.contacts" ngbTooltip="{{contact.name}}">
                                {{contact.initials}}
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <!-- Contacts End -->
    </div>
</div>
