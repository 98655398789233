import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'kt-recruitment-management-candidate',
  templateUrl: './recruitment-management-candidate.component.html',
  styleUrls: ['./recruitment-management-candidate.component.scss']
})
export class RecruitmentManagementCandidateComponent implements OnInit {

  selectedTab = 0;
 // moduleType = 5;
  returnUrl: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl || '/';
    });

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
      this.selectedTab = params.tab;
      console.log('Tab : '+this.selectedTab);

    });
  }

}
