<div class="example-preview">       
    <div [ngClass]="{'mobile-view': viewMode === 'AppPreview'}">

        <form [formGroup]="addCompaniesForm" autocomplete="off" style="background-color: #FFF; border-radius: 10px;">
            <div *ngIf="viewMode === 'AppPreview' && moduleType > 100" class="modal-header" style="background-color:#3699FF;">
               <table>
                   <td>
                <tr>
                <h4 class="modal-title" style="color: cornsilk; font-size: 10px;">Add {{singularName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </tr>
           <!-- <tr>
                    <h6 style="color: cornsilk; font-size: 6px;">Company Name</h6>
                </tr> -->
            </td>
            </table>
            </div>
            <div *ngIf="viewMode === 'WebPreview' && moduleType > 100" class="modal-header">
                <table>
                    <td>
                 <tr>
                 <h4 class="modal-title" style="font-size: 10px;">Add {{singularName}}</h4>
                 <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                     <span aria-hidden="true">&times;</span>
                 </button>
             </tr>
            <!-- <tr>
                     <h6 style="color: cornsilk; font-size: 8px;">Company Name</h6>
                 </tr>  -->
             </td>
             </table>
             </div>
            <div *ngIf="viewMode === 'AppPreview'  && moduleType < 100" class="modal-header" style="background-color:#3699FF;">
                <h4 class="modal-title" style="color: cornsilk; font-size: 10px;padding: 8px;">Add New {{singularName}}</h4>

                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="viewMode === 'WebPreview' && moduleType < 100"  class="modal-header">
                <h4 class="modal-title" style="font-size: 10px;padding: 8px;">Add New {{singularName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div *ngIf="!preview" class="modal-header">
                <h4 class="modal-title">Add New {{singularName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
	<div *ngIf="preview">
            <div class="modal-body"  [ngClass]="{'preview-body-size': viewMode === 'AppPreview'}">
             
                <div id="div_{{customAttr.id}}" [ngClass]="{'hidden': customAttr.parentAttributeId !==0}"
                 class="form-group row" *ngFor="let customAttr of customAttributesList">

                    <div class="col-md-4" style="font-size: 10px;padding: 8px;">
                        <label class="col-form-label" style="font-size: 10px;padding: 8px;">{{customAttr.attributeName}}</label>
                    </div>
                                                                
                    <!-- first div (single select)-->  
                    
                    <div id="select_{{customAttr.id}}" class="col-md-8" *ngIf="customAttr.attributeType.id==1 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) ; else systemAttrbitues ">
                        <mat-form-field  appearance="standard" class="input-100" style="font-size: 10px;padding: 8px;">
        
                            <mat-select  [formControlName]="customAttr.combinedAttributeName" (selectionChange)="invokeChildMethod($event.value,customAttr)">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="singleDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let values of customAttr.lookupValues| search : singleDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                                
                            </mat-select>
                        
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     

                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">Please select one</mat-error>                      
            
                        </mat-form-field>                    
                    </div>                   

                    <!-- second div (multi select)-->
                        
                    <div id="select_{{customAttr.id}}" class="col-md-8"  *ngIf="customAttr.attributeType.id==2 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) && (customAttr.systemAttribute['id'] !==28); else systemAttrbituesM " >
                        <mat-form-field class="mat-form-field-fluid" style="font-size: 10px;padding: 8px;">
                            <mat-select [formControlName]="customAttr.combinedAttributeName" multiple (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr.id) : ''">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="multiDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let values of customAttr.lookupValues| search : multiDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                        
                    </div>

                    <!-- third div (Date) -->
                    <!--<div class="col-md-8"  *ngIf="customAttr.attributeType.id==3">
                        <mat-form-field class="example-full-width" style="font-size: 10px;padding: 8px;">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" ktAutoTrimSpaces>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div> -->
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3">
                        <mat-form-field class="example-full-width">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div>

                    <!-- fourth div (Image) -->
                        
                    <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==4">
                        <div class="mat-form-field" style="font-size: 10px;padding: 8px;">
                            <div class="mat-form-field-wrapper">
                                <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                    (change)="processFile($event,customAttr.combinedAttributeName,customAttr.mandatoryFlag,customAttr.maxImagesAllowed)">
                                <span id="image{{customAttr.combinedAttributeName}}">
                                </span>
                                <span *ngIf="showUploadImageIcon"> 
                                    <div class="artist-collection-photo">
                                        <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                        <div *ngIf="customAttr.maxImagesAllowed" class="img-index">{{imageUploadedCounter}}/{{customAttr.maxImagesAllowed}}</div>
                                    </div>
                                </span>
                            </div>
                        <!-- <img *ngFor="let url of images"  [src]="url" height="50" width="90" style="padding: 10px 10px 0px 10px;"> -->
                        </div>
                        <div class="mat-form-field-underline ng-star-inserted">
                            <span class="mat-form-field-ripple"></span>
                        </div>
                        <div class="mat-form-field-subscript-wrapper">
                            <mat-hint class="mat-hint_img" *ngIf="customAttr.mandatoryFlag" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                            <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" > {{customAttr.instructions}} (Optional) </mat-hint>
                            <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" >Optional </mat-hint>
                            <mat-error class="mat-hint_img" *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                            <mat-error class="mat-hint_img" *ngIf="uploadErr_+customAttr.mandatoryFlag">Please upload again</mat-error>
                            <mat-error *ngIf="imageNoUploaded" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.attributeName}} can not be blank </mat-error>
                        </div>
                                            
                    </div>
                  
    
                    <!-- fifth div (Radio)-->
                
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==5" style="font-size: 10px;padding: 8px;">
                        <mat-radio-group [formControlName]="customAttr.combinedAttributeName" fxLayout="column" fxLayoutGap=".25rem">
                            <mat-radio-button *ngFor="let values of customAttr.lookupValues" [value]="values.id">
                                {{values.lookupValue}}
                            </mat-radio-button>
        
                        </mat-radio-group>    
                        <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                        <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                        <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                                                    
                        <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                    </div>

                    <!-- sixth div (Text-single line)-->

                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==6" style="font-size: 10px;padding: 8px;">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>                                            
                    </div>
    
                    <!-- seventh div (Numeric) -->
                
                   <!-- <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxNumber}}" minlength="{{customAttr.minNumber}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName">
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">Only numeric values</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxNumber" align="end">{{message.value.length}} / {{customAttr.maxNumber}}</mat-hint>
                        </mat-form-field>  
                    </div> -->
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                        <div *ngIf="customAttr.numericValidationType==1">
                        <div *ngIf="customAttr.maxDigits!=null">
                        <mat-form-field class="mat-form-field-fluid" style="font-size: 10px;padding: 8px;">
                            <input maxlength="{{customAttr.maxDigits}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>    
                           
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                            <mat-hint  align="end">{{message.value.length}} / {{customAttr.maxDigits}}</mat-hint>
                        </mat-form-field> 
                        </div> 
                        <div *ngIf="customAttr.maxDigits==null">
                            <mat-form-field class="mat-form-field-fluid" style="font-size: 10px;padding: 8px;">
                                <input  #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>    
                               
                                <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message.value.length}} </mat-hint>
                            </mat-form-field> 
                            </div> 
                        </div>
                        <div *ngIf="customAttr.numericValidationType==2">
                            <div *ngIf="customAttr.maxNumber!=null">
                                <mat-form-field class="mat-form-field-fluid" style="font-size: 10px;padding: 8px;">
                                    <input maxlength="{{customAttr.maxNumber.toString().length}}"  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                    <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                    
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}} / {{customAttr.maxNumber.toString().length}}</mat-hint>
                                </mat-form-field>  
                            </div>
                            <div *ngIf="customAttr.maxNumber == null">
                                <mat-form-field class="mat-form-field-fluid" style="font-size: 10px;padding: 8px;">
                                    <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                    <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                    
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                                </mat-form-field>  
                            </div>

                        </div>
                        <div *ngIf="(customAttr.numericValidationType==null || customAttr.numericValidationType==0)">
                            <mat-form-field class="mat-form-field-fluid" style="font-size: 10px;padding: 8px;">
                                <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                
                            <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                            </mat-form-field>  
                        </div>
                           
                        
                    </div>
   
                    <!-- eigth div (Text -multi line)-->

                    <div class="col-md-8 mb-2"  *ngIf="customAttr.attributeType.id==8">
                        <mat-form-field appearance="standard" class="input-100" style="font-size: 10px;padding: 8px;">
                            <textarea  maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces></textarea>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>
                                                                    
                    </div>

                    <!-- nineth div (Email) -->
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==9">
                        <mat-form-field class="mat-form-field-fluid" style="font-size: 10px;padding: 8px;">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>                                            
                    </div>


<!--      =========================system Attributes block================================= -->

                    <ng-template #systemAttrbitues >
                        <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21 ">
                            <div class="row">
                                <div class="col-md-4" style="font-size: 10px;padding: 8px;">
                                    <mat-form-field class="mat-form-field-fluid input-100" style="font-size: 10px;padding: 8px;">
                                        <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                {{country.countryName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select country</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select country (Optional)</mat-hint>     
                        
                                        <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4" style="font-size: 10px;padding: 8px;">
                                    <mat-form-field class="mat-form-field-fluid input-100" style="font-size: 10px;padding: 8px;">
                                        <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                {{state.stateName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank</mat-error>
     
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select state</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select state (Optional)</mat-hint>     
                        
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4" style="font-size: 10px;padding: 8px;">
                                    <mat-form-field class="mat-form-field-fluid input-100" style="font-size: 10px;padding: 8px;">
                                        <mat-select formControlName="cityName">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                {{city.cityName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank</mat-error>
                 
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select city</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select city (Optional)</mat-hint>     
                        
                                    </mat-form-field>
                                </div>                                       
                            </div>                                                        
                        </div>
                      
                    </ng-template>

                    <ng-template #systemAttrbituesM >
                
                        <div class="col-md-12" *ngIf="viewMode === 'WebPreview' && moduleType > 100 && customAttr.systemAttribute.id==28" style="font-size: 10px;padding: 8px;">
                            <div >
                                <div class="form-group row" formArrayName="skuItems">
                                    <div class="row">
                                        <td>                                            
                                            <tr>
                                                <table class="style">
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>List Price</th>
                                                        <th>Selling Price</th>
                                                        <th>Discount %</th>
                                                        <th>Tax %</th>
                                                        <th>Total</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                        <td>
                                                            <mat-form-field class="inputSelect">
                                                                <mat-select formControlName="sku" required>
                                                                    <!-- <mat-option>
                                                                        <ngx-mat-select-search [formControl]="skuFilterType" ></ngx-mat-select-search>
                                                                    </mat-option> -->
                                                                    <mat-option *ngFor="let sku of skuList" [value]="sku.productId">
                                                                        {{sku.productName}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                
                                                             </mat-form-field>
                                                        </td>
                                                            <td>
                                                                <mat-form-field  class="inputbox"> 
                                                                    <input matInput formControlName="quantity" required ktStrictNumberOnlyDirective >  
                                                              
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="listPrice"  ktStrictNumberOnlyDirective>  
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="sellingPrice" required ktStrictNumberOnlyDirective>  
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="discount"  ktStrictNumberOnlyDirective>  
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="tax" required ktTwoDigitDecimaNumberDirective>  
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="total"  ktStrictNumberOnlyDirective>
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td style="width:90px; padding-left: 10px;"> 
                                                                <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                                    <i class="fas fa-plus" (click)="addTable()"></i>
                                                                </button>
                                            
                                                                <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="addCompaniesForm.controls.skuItems.controls.length > 1">
                                                                    <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                                </button>
                                                               
                                                            </td>
                                                            
                                                  </tr>
                                                
                                                </table>
                                             </tr>
                                             <tr style="height: 20px;"></tr>
                                            
                                           
                                           <!-- <tr>
                                                <td>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-9">
                                                                <label class="col-form-label form-padding-top-15"></label>
                                                            </div> 
                                                            <div class="col-md-3">
                                                                <label class="col-form-label form-padding-top-15">&nbsp;&nbsp;{{totalSum}}</label>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>  -->
                                        </td>
                                    </div>  
                                   
                                </div>                                   
                            </div>  
                            <!-- <mat-error *ngIf="isControlHasError('sku', 'required')">Please select product</mat-error> -->
                            <mat-error *ngIf="addProductError">Please select product</mat-error>
                          
                        </div>

                        <div class="col-md-12" *ngIf="viewMode === 'AppPreview' && moduleType > 100 && customAttr.systemAttribute.id==28" style="font-size: 10px;padding: 8px;">
                            <div >
                                <div class="form-group row" formArrayName="skuItems">
                                    <div class="row">
                                        <td>                                            
                                            <tr>
                                                <table class="style">
                                                    <tr>
                                                        <th  style=" background-color: #F3F6F9;">Product</th>
                                                        <th  style=" background-color: #F3F6F9;">Units</th>
                                                        <th  style=" background-color: #F3F6F9;">Subtotal</th>
                                                        <th  style=" background-color: #F3F6F9;">Actions</th>
                                                        
                                                    </tr>
                                                    <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                       
                                                            <td class="inputbox4" style=" width: 75px;">
                                                              
                                                            </td>
                                                            <td class="inputbox3" style=" width: 25px;">
                                                               
                                                            </td>
                                                            <td class="inputbox2" style="width: 50px;">
                                                              
                                                            </td>
            
                                                            <td class="inputbox6" style="width: 60px;">
                                                             
                                                            </td>
                                                           
                                                        
                                                            
                                                  </tr>
                                                </table>
                                            </tr>
                                                  <tr> 
                                                    <table class="style">
                                                        <tr>
                                                        <td class="inputbox5" style=" width: 103px;">
                                                                <span>Total</span>
                                                        </td>
                                                        
                                                        <td class="inputbox2" style=" width: 50px;">
                                                            <span>0.00</span>
                                                        </td>
                                                        <td style="width:60px; padding-left: 10px; background-color: #F3F6F9;"> 
                                                            <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                                <i class="fas fa-plus" ></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                  </table>

                                                  </tr>
                                                
                                                
                                             
                                             <tr style="height: 20px;"></tr>
                                            
                                           
                                           <!-- <tr>
                                                <td>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-9">
                                                                <label class="col-form-label form-padding-top-15"></label>
                                                            </div> 
                                                            <div class="col-md-3">
                                                                <label class="col-form-label form-padding-top-15">&nbsp;&nbsp;{{totalSum}}</label>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>  -->
                                        </td>
                                    </div>  
                                   
                                </div>                                   
                            </div>  
                            <!-- <mat-error *ngIf="isControlHasError('sku', 'required')">Please select product</mat-error> -->
                            <mat-error *ngIf="addProductError">Please select product</mat-error>
                          
                        </div>

                    </ng-template>
               
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
            
            </div>
	</div>
	<div *ngIf="!preview">
	<div class="modal-body"  [ngClass]="{'preview-body-size': viewMode === 'AppPreview'}">
             
                <div id="div_{{customAttr.id}}" [ngClass]="{'hidden': customAttr.parentAttributeId !==0}"
                 class="form-group row" *ngFor="let customAttr of customAttributesList">

                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25" >{{customAttr.attributeName}}</label>
                    </div>
                                                                
                    <!-- first div (single select)-->  
                    
                    <div id="select_{{customAttr.id}}" class="col-md-8" *ngIf="customAttr.attributeType.id==1 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) ; else systemAttrbitues ">
                        <mat-form-field  appearance="standard" class="input-100">
        
                            <mat-select  [formControlName]="customAttr.combinedAttributeName" (selectionChange)="invokeChildMethod($event.value,customAttr)">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="singleDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let values of customAttr.lookupValues| search : singleDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                                
                            </mat-select>
                        
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     

                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">Please select one</mat-error>                      
            
                        </mat-form-field>                    
                    </div>                   

                    <!-- second div (multi select)-->
                        
                    <div id="select_{{customAttr.id}}" class="col-md-8"  *ngIf="customAttr.attributeType.id==2 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) && (customAttr.systemAttribute['id'] !==28); else systemAttrbituesM " >
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select [formControlName]="customAttr.combinedAttributeName" multiple (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr.id) : ''">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="multiDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let values of customAttr.lookupValues| search : multiDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                        
                    </div>

                    <!-- third div (Date) -->
                 <!--   <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3">
                        <mat-form-field class="example-full-width">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" ktAutoTrimSpaces>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div> -->

                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMaxFlag && !DisplayDateMinFlag && !DisplayDateFlag">
                        <mat-form-field class="example-full-width">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div>
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMinFlag && !DisplayDateMaxFlag && !DisplayDateFlag">
                        <mat-form-field class="example-full-width">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate"  [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div>
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag ">
                        <mat-form-field class="example-full-width">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate" [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div>
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && !DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag">
                        <mat-form-field class="example-full-width">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div>

                    <!-- fourth div (Image) -->
                        
                    <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==4">
                        <div class="mat-form-field">
                            <div class="mat-form-field-wrapper">
                                <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                    (change)="processFile($event,customAttr.combinedAttributeName,customAttr.mandatoryFlag,customAttr.maxImagesAllowed)">
                                <span id="image{{customAttr.combinedAttributeName}}">
                                </span>
                                <span *ngIf="showUploadImageIcon"> 
                                    <div class="artist-collection-photo">
                                        <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                        <div *ngIf="customAttr.maxImagesAllowed" class="img-index">{{imageUploadedCounter}}/{{customAttr.maxImagesAllowed}}</div>
                                    </div>
                                </span>
                            </div>
                        <!-- <img *ngFor="let url of images"  [src]="url" height="50" width="90" style="padding: 10px 10px 0px 10px;"> -->
                        </div>
                        <div class="mat-form-field-underline ng-star-inserted">
                            <span class="mat-form-field-ripple"></span>
                        </div>
                        <div class="mat-form-field-subscript-wrapper">
                            <mat-hint class="mat-hint_img" *ngIf="customAttr.mandatoryFlag" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                            <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" > {{customAttr.instructions}} (Optional) </mat-hint>
                            <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" >Optional </mat-hint>
                            <mat-error class="mat-hint_img" *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                            <mat-error class="mat-hint_img" *ngIf="uploadErr_+customAttr.mandatoryFlag">Please upload again</mat-error>
                            <mat-error *ngIf="imageNoUploaded" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.attributeName}} can not be blank </mat-error>
                        </div>
                                            
                    </div>
                  
    
                    <!-- fifth div (Radio)-->
                
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==5">
                        <mat-radio-group [formControlName]="customAttr.combinedAttributeName" fxLayout="column" fxLayoutGap=".25rem">
                            <mat-radio-button *ngFor="let values of customAttr.lookupValues" [value]="values.id">
                                {{values.lookupValue}}
                            </mat-radio-button>
        
                        </mat-radio-group>    
                        <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                        <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                        <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                                                    
                        <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                    </div>

                    <!-- sixth div (Text-single line)-->

                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==6">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>                                            
                    </div>
    
                    <!-- seventh div (Numeric) -->
                
                   <!-- <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxNumber}}" minlength="{{customAttr.minNumber}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName">
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">Only numeric values</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxNumber" align="end">{{message.value.length}} / {{customAttr.maxNumber}}</mat-hint>
                        </mat-form-field>  
                    </div> -->
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                        <div *ngIf="customAttr.numericValidationType==1">
                        <div *ngIf="customAttr.maxDigits!=null">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxDigits}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>    
                           
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                            <mat-hint  align="end">{{message.value.length}} / {{customAttr.maxDigits}}</mat-hint>
                        </mat-form-field> 
                        </div> 
                        <div *ngIf="customAttr.maxDigits==null">
                            <mat-form-field class="mat-form-field-fluid">
                                <input  #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>    
                               
                                <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message.value.length}} </mat-hint>
                            </mat-form-field> 
                            </div> 
                        </div>
                        <div *ngIf="customAttr.numericValidationType==2">
                            <div *ngIf="customAttr.maxNumber!=null">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input maxlength="{{customAttr.maxNumber.toString().length}}"  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                    <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                    
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}} / {{customAttr.maxNumber.toString().length}}</mat-hint>
                                </mat-form-field>  
                            </div>
                            <div *ngIf="customAttr.maxNumber == null">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                    <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                    
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                                </mat-form-field>  
                            </div>

                        </div>
                        <div *ngIf="(customAttr.numericValidationType==null || customAttr.numericValidationType==0)">
                            <mat-form-field class="mat-form-field-fluid">
                                <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                
                            <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                            </mat-form-field>  
                        </div>
                           
                        
                    </div>
   
                    <!-- eigth div (Text -multi line)-->

                    <div class="col-md-8 mb-2"  *ngIf="customAttr.attributeType.id==8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <textarea  maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces></textarea>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>
                                                                    
                    </div>

                    <!-- nineth div (Email) -->
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==9">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>                                            
                    </div>


<!--      =========================system Attributes block================================= -->

                    <ng-template #systemAttrbitues >
                        <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21 ">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                {{country.countryName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select country</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select country (Optional)</mat-hint>     
                        
                                        <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                {{state.stateName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank</mat-error>
     
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select state</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select state (Optional)</mat-hint>     
                        
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="cityName">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                {{city.cityName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank</mat-error>
                 
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select city</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select city (Optional)</mat-hint>     
                        
                                    </mat-form-field>
                                </div>                                       
                            </div>                                                        
                        </div>
                      
                    </ng-template>

                    <ng-template #systemAttrbituesM >
                
                        <div class="col-md-12" *ngIf="customAttr.systemAttribute.id==28">
                            <div >
                                <div class="form-group row" formArrayName="skuItems">
                                    <div class="row">
                                        <td>                                            
                                            <tr>
                                                <table class="style">
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>List Price</th>
                                                        <th>Selling Price</th>
                                                        <th>Discount %</th>
                                                        <th>Tax %</th>
                                                        <th>Total</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                        <td>
                                                            <mat-form-field class="inputSelect">
                                                                <mat-select formControlName="sku" required>
                                                                    <!-- <mat-option>
                                                                        <ngx-mat-select-search [formControl]="skuFilterType" ></ngx-mat-select-search>
                                                                    </mat-option> -->
                                                                    <mat-option *ngFor="let sku of skuList" [value]="sku.productId">
                                                                        {{sku.productName}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                
                                                             </mat-form-field>
                                                        </td>
                                                            <td>
                                                                <mat-form-field  class="inputbox"> 
                                                                    <input matInput formControlName="quantity" required ktStrictNumberOnlyDirective >  
                                                              
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="listPrice"  ktStrictNumberOnlyDirective>  
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="sellingPrice" required ktStrictNumberOnlyDirective>  
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="discount"  ktStrictNumberOnlyDirective>  
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="tax" required ktTwoDigitDecimaNumberDirective>  
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="total"  ktStrictNumberOnlyDirective>
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td style="width:90px; padding-left: 10px;"> 
                                                                <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                                    <i class="fas fa-plus" (click)="addTable()"></i>
                                                                </button>
                                            
                                                                <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="addCompaniesForm.controls.skuItems.controls.length > 1">
                                                                    <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                                </button>
                                                               
                                                            </td>
                                                            
                                                  </tr>
                                                
                                                </table>
                                             </tr>
                                             <tr style="height: 20px;"></tr>
                                            
                                           
                                           <!-- <tr>
                                                <td>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-9">
                                                                <label class="col-form-label form-padding-top-15"></label>
                                                            </div> 
                                                            <div class="col-md-3">
                                                                <label class="col-form-label form-padding-top-15">&nbsp;&nbsp;{{totalSum}}</label>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>  -->
                                        </td>
                                    </div>  
                                   
                                </div>                                   
                            </div>  
                            <!-- <mat-error *ngIf="isControlHasError('sku', 'required')">Please select product</mat-error> -->
                            <mat-error *ngIf="addProductError">Please select product</mat-error>
                          
                        </div>
                    </ng-template>
               
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
            
            </div>
	</div>
            <div class="modal-footer" *ngIf="!preview">
                <button type="button" class="btn btn-secondary" (click)="Close('Close')">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="Save('Save')" *ngIf="!preview">Save</button>
                <button type="button" class="btn btn-primary" (click)="SaveAndAdd()" *ngIf="!preview"   >Save and Add New</button>
        
            </div>

            <div class="modal-footer" [ngClass]="{'preview-footer-size': viewMode === 'AppPreview'}" *ngIf="preview">
                <button type="button" class="btn btn-secondary" style="font-size: 10px;padding: 8px;"(click)="Close()">Cancel</button>
              <button type="button" class="btn btn-secondary" style="font-size: 10px;padding: 8px;" (click)="Save()" disabled>Save</button>
                <button type="button" class="btn btn-secondary" style="font-size: 10px;padding: 8px;" (click)="SaveAndAdd()"  disabled>Save&Add New</button>
        
            </div>

        </form> 
    </div>
</div> 
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="addCompany">  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner> 
    