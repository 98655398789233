
<div class="col-xl-12">
  <div class="col-xl-12" >
    <h3 mat-dialog-title class="pt-4" *ngIf="data.code != 3">Hello {{data.name}}!</h3>
      <div mat-dialog-content *ngIf="data.code==1">
          <p>We have sent an activation email to <mark>{{data.email}}</mark>. Kindly click on the activation link in the email to proceed.</p>          
          <p>Can’t find the email? Try checking your spam folder or <span class="text-primary" [mat-dialog-close]="data">click here</span> to resend.</p>
      </div>
      <div mat-dialog-content *ngIf="data.code==2">          
          <p>Your account is pending for activation. We have sent an activation email to <mark>{{data.email}}</mark>. Kindly click on the activation link in the email to proceed.</p>
          <p>Can’t find the email? Try checking your spam folder or <span class="text-primary" [mat-dialog-close]="data">click here</span> to resend.</p>
      </div>
      <div mat-dialog-content *ngIf="data.code==3">
          <p class="pt-6" *ngIf="data.code == 3">Hello!</p>
          <p>A password reset link will be sent to <mark>{{data.email}}</mark> if a 1Channel account with this ID exists.  Kindly check your email for further actions.</p>
      </div>
      <div *ngIf="data.resendFlag==1">
        <div  mat-dialog-actions class="float-left"><span style="background-color: green; color: beige; font-size: 18px;">Activation email sent again.</span></div>
        <div  mat-dialog-actions class="float-right"><button mat-button (click)="onClose()">Okay</button></div>
      </div>
      <div *ngIf="data.resendFlag==0">
        <div mat-dialog-actions class="float-right">
          <button mat-button class="text-primary" (click)="onClose()">Okay</button>
        </div>
      </div>
  </div>
</div>
      