import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
//import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
//import {MatStepperModule} from '@angular/material/stepper';
import { RecruitmentCandidateService } from '../../services/recruitment-candidate.service';
import { MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-candidate-direct',
  templateUrl: './candidate-direct.component.html',
  styleUrls: ['./candidate-direct.component.scss']
})


export class CandidateDirectComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public addDirectCandidateForm: FormGroup;
  disabled:boolean;
  userPwdCheck:boolean;
  errorMes:String="";
  isShown: boolean;
  isHide: boolean;
  cityList:[];
  public cityNameFilter: FormControl = new FormControl();
  positionDetailsData:[];

  positionCityId:any;
  positionCityName:any;
  budgetMaxSalaryLimit:any;
  budgetJobTypeArray:any;
  budgetIndustryTypeArray:any;


  //for step form
  title = 'newMat';
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  positionId

  candidateExpectedSalary
  candidateJobType
  candidateIndistryPreference
  candidatePreferedCityName
  candidateRecruiter
  candidatePinCode

  constructor(
    private _rmcs:RecruitmentCandidateService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    //private _as:AddressService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initFirstForm();  
    this.initSecondForm();
    this.setByDefaultValue();
    this.getAllCities();
    this.getBudgetDetails();
    this.disabled=false;
    this.userPwdCheck=false;
    this.isShown = false;
    this.isHide = true;

  }

  submit(){
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
  }

  initFirstForm(){
    this.firstFormGroup = this.fb.group({
      mobilePrefix: ['', Validators.compose([Validators.required ])],
      mobileNumber:['', Validators.compose([Validators.required, Validators.minLength(10) ])],
    });
  }

  initSecondForm(){
    this.secondFormGroup = this.fb.group({
      candidateId:[''],
      firstName: ['', Validators.compose([Validators.required ])],
      //lastName: ['', Validators.compose([Validators.required ])],
      lastName: [''],
      email:['',Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      cityName:[''],
      resume:[''],
      resumeFile:[]
    });
  }

  getAllCities(){
    this._rmcs.getAllCity(this.selectedProject).subscribe( 
      (result)=>{
        console.log('all city', result);
        this.cityList=result['embedded']; 

      },
      error => {
        console.log(error)
      }
    )
  }


  getCandidateData(controls){
    const mobileNoArray = this.firstFormGroup.value;
    var mobileNo = mobileNoArray.mobileNumber;

    var selectCityId = controls.cityName.value;
    if(selectCityId == ''){
      selectCityId = this.positionCityId;
    }

    var fullname = "";
    var candidateMiddleName = "";
    var firstNameTemp = controls.firstName.value.trim();
    var firstNameTempArray = firstNameTemp.split(/ (.*)/);
    var candidateFirstName = firstNameTempArray[0];

    if(firstNameTempArray[1] != undefined){
      candidateMiddleName = firstNameTempArray[1];
      //fullname = candidateFirstName+' '+candidateMiddleName+' '+controls.lastName.value.trim();
    }else{
      //fullname = candidateFirstName+' '+controls.lastName.value.trim();
    }
    
    var lastNameTemp = controls.lastName.value.trim();
    var tempLastSectionFirstName = lastNameTemp.substring(0, lastNameTemp.lastIndexOf(" ") + 1);
    var tempLastSectionLastName = lastNameTemp.substring(lastNameTemp.lastIndexOf(" ") + 1, lastNameTemp.length);

    if(tempLastSectionFirstName != ''){
      candidateMiddleName = candidateMiddleName+' '+tempLastSectionFirstName;
    }

    fullname = firstNameTemp+' '+lastNameTemp;

    
    if(this.candidateExpectedSalary == null){
      this.candidateExpectedSalary = this.budgetMaxSalaryLimit;
    }

    if(this.candidatePreferedCityName == null){
      this.candidatePreferedCityName = this.positionCityName;
    }

    if(this.candidateJobType == null){
      this.candidateJobType = this.budgetJobTypeArray;
    }else if(this.candidateJobType.length == 0){
      this.candidateJobType = this.budgetJobTypeArray;
    }

    if(this.candidateIndistryPreference == null){
      this.candidateIndistryPreference = this.budgetIndustryTypeArray;
    }else if(this.candidateIndistryPreference.length == 0){
      this.candidateIndistryPreference = this.budgetIndustryTypeArray;
    }

    if(this.candidatePinCode == null){
      this.candidatePinCode = 0;
    }

    if(this.candidateRecruiter == null){
      this.candidateRecruiter = '';
    }

    
    //if(controls.resume.value == ''){
      const editData = { 
        "candidateId": controls.candidateId.value,
        "fullName": fullname.trim(),
        "firstName": candidateFirstName.trim(),
        "middleName": candidateMiddleName.trim(),
        "lastName": tempLastSectionLastName.trim(), //controls.lastName.value.trim(),
        "email": controls.email.value,
        "mobileNumber": mobileNo,
        "cityId":selectCityId,
        "cityName": this.candidatePreferedCityName,
        "expectedSalary":this.candidateExpectedSalary,
        "preferredJobTypes": this.candidateJobType,
        "preferredIndustries": this.candidateIndistryPreference,
        "pincode": this.candidatePinCode,
        "source": this.candidateRecruiter,
      };    
      return editData;
    /*}else{
      //Form data
      const formData = new FormData();
        formData.set("candidateId",controls.candidateId.value);
        formData.set("fullName",fullname);
        formData.set("firstName",controls.firstName.value.trim());
        formData.set("middleName","");
        formData.set("lastName",controls.lastName.value.trim());
        formData.set("email",controls.email.value);
        formData.set("mobileNumber",mobileNo);
        formData.set("cityId",selectCityId);
        formData.set("cityName","");
        formData.set("expectedSalary",null);
        formData.set("jobType",null);
        formData.set("indistryPreference",null);
        formData.set("resume",controls.resume.value);
        formData.set("filePath",null);

       console.log(formData);
      return formData;
    }*/
  }
  

  saveCandidate(){
    this.isHide = false;

    const controls = this.secondFormGroup.controls;
    if (this.secondFormGroup.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    const candidateData=this.getCandidateData(controls);
    console.log('candidateData', candidateData)

    //return false 
    
    this._rmcs.addCandidate(candidateData).subscribe(
      data => {
        //console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          //this.OpenAlert(data['message']);
        }else{
         /* this.activeModal.close();
          this.secondFormGroup.reset();
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('ADD_CANDIDATE_SUCCESS');*/
          this.addResume(data['candidateId'])
        }
        this.addCandidateToPosition(data);
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );
  }

  addResume(candidateId: any){
    const controls = this.secondFormGroup.controls; 
    
    if(controls.resume.value != ''){
      //const formData = this.editUserForm.value;
      //console.log(data)
      const formData = new FormData();
        formData.set("file",controls.resumeFile.value);
        formData.set("id",candidateId);

        this._rmcs.addResume(formData, candidateId).subscribe(
          data => {
           // console.log(data);
           if(data['statusCode']==412){
            // this.errorMes=data['message'];
             this.disabled=false;
             //this.OpenAlert(data['message']);
           }else{
            this.activeModal.close();
            this.secondFormGroup.reset();
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult('ADD_CANDIDATE_SUCCESS');
            console.log('File upload done')
           }
    
          },
          error =>{
            this.errorMes=error.error.responseData;
            this.activeModal.close();
            this.secondFormGroup.reset();
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult('CANDIDATE_DATA_FILE_UPLOAD_FAILED');
          }
        );
    
      }
      else{
        this.activeModal.close();
        this.secondFormGroup.reset();
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ADD_CANDIDATE_SUCCESS');
      }
  }

  addCandidateToPosition(data){
    var usersObject = JSON.parse(localStorage.getItem('currentUser'));
    const addData = {    
      "candidateId": data.candidateId,
      "positionId": this.positionId,
      "recruiterId": usersObject.userId,
      "positionType": "Budget",
      "source": "Recruiter"
    };   

    this._rmcs.addCandidatePosition(addData).subscribe(
      data => {
      //  console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          //this.OpenAlert(data['message']);
        }else{
          this.activeModal.close();
          this.secondFormGroup.reset();
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('ADD_CANDIDATE_POSITION_SUCCESS');
        }
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );

  }

  onChange(event: Event) {
    let file = (event.target as HTMLInputElement).files[0];
    //console.log('file upload', file.name)
    this.secondFormGroup.controls['resume'].setValue(file.name);
    //this.secondFormGroup.controls['file'].setValue(file.name);
  }

  setByDefaultValue(){
    this.firstFormGroup.controls['mobilePrefix'].setValue('+91');
  }

  Close(){
    this.activeModal.close();
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  isControlHasErrorFirstForm(controlName: string, validationType: string): boolean {
    const control = this.firstFormGroup.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.secondFormGroup.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  goForward(){
    this.isShown = false;
    const controls = this.firstFormGroup.controls;
    if (this.firstFormGroup.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    const candidateMobileNo = this.firstFormGroup.value;
    this.SpinnerService.show('add-candidate-direct-loader');
    this._rmcs.checkCandidateData(candidateMobileNo).subscribe(
      data => {
        console.log('userData', data);
        var responseData = data['embedded'];
        //console.log('responseData', responseData);
        if(responseData.length > 0){
          this.secondFormGroup.controls['candidateId'].setValue(responseData[0].candidateId);
          this.secondFormGroup.controls['firstName'].setValue(responseData[0].firstName+' '+responseData[0].middleName);
          this.secondFormGroup.controls['lastName'].setValue(responseData[0].lastName);
          this.secondFormGroup.controls['email'].setValue(responseData[0].emailId);
          
          if(responseData[0].preferredCity != null){
            this.secondFormGroup.controls['cityName'].setValue(responseData[0].preferredCity.cityId);
          }else{
            this.secondFormGroup.controls['cityName'].setValue('');
          }

          if(responseData[0].preferredCity != null){
            this.candidatePreferedCityName = responseData[0].preferredCity.cityName;
          }else{
            this.candidatePreferedCityName = null;
          }

          var tempJobType = responseData[0].preferredJobTypes;
          var tempJobtypeArray = new Array(); 
          if(tempJobType){
            tempJobType.forEach(data =>
              tempJobtypeArray.push(data.id)
            );
          }
          this.candidateJobType = tempJobtypeArray;

          var tempIndustryType = responseData[0].preferredIndustries;
          var tempIndustryTypeArray = new Array(); 
          if(tempIndustryType){
            tempIndustryType.forEach(data =>
              tempIndustryTypeArray.push(data.id)
            );
          }
          this.candidateIndistryPreference = tempIndustryTypeArray;

          
          this.candidateExpectedSalary = responseData[0].expectedSalary;
          //this.candidateJobType = responseData[0].preferredJobTypes;
          //this.candidateIndistryPreference = responseData[0].preferredIndustries;
          this.isShown = true;
          this.candidateRecruiter = responseData[0].candidateSource.sourceName;
          this.candidatePinCode = responseData[0].pincode;
        }else{
          this.secondFormGroup.controls['candidateId'].setValue('');
          this.secondFormGroup.controls['firstName'].setValue('');
          this.secondFormGroup.controls['lastName'].setValue('');
          this.secondFormGroup.controls['email'].setValue('');
          this.secondFormGroup.controls['cityName'].setValue('');
          this.candidateExpectedSalary = null;
          this.candidateJobType = null;
          this.candidateIndistryPreference = null;
          this.candidatePreferedCityName = null;
          this.candidateRecruiter = null;
          this.candidatePinCode = null;
        }
        this.SpinnerService.hide('add-candidate-direct-loader');
      },
      error =>{
        this.secondFormGroup.controls['candidateId'].setValue('');
        this.secondFormGroup.controls['firstName'].setValue('');
        this.secondFormGroup.controls['lastName'].setValue('');
        this.secondFormGroup.controls['email'].setValue('');
        this.secondFormGroup.controls['cityName'].setValue('');
        this.candidateExpectedSalary = null;
        this.candidateJobType = null;
        this.candidateIndistryPreference = null;
        this.candidatePreferedCityName = null;
        this.candidateRecruiter = null;
        this.candidatePinCode = null;
        this.SpinnerService.hide('add-candidate-direct-loader');
      }
    );
  }


  getBudgetDetails(){ 
    this._rmcs.getPolicyList(this.positionId).subscribe(
      data => {
        console.log('maxSalaryLimit---', data['maxSalaryLimit']);
        this.positionCityId = data['cityId'].cityId;
        this.positionCityName = data['cityId'].cityName;
        this.budgetMaxSalaryLimit = data['maxSalaryLimit'];

        var tempJobType = data['budgetId'].budgetJobType;
        var tempJobtypeArray = new Array(); 
        if(tempJobType){
          tempJobType.forEach(data =>
            tempJobtypeArray.push(data.jobTypeId)
          );
        }
        this.budgetJobTypeArray = tempJobtypeArray;


        var tempIndustryType = data['budgetId'].budgetIndustryType;
        var tempIndustryTypeArray = new Array(); 
        if(tempIndustryType){
          tempIndustryType.forEach(data =>
            tempIndustryTypeArray.push(data.industryId)
          );
        }
        this.budgetIndustryTypeArray = tempIndustryTypeArray;

      },
      error =>{
        
      }
      
    );
    
  }


  onlyNumber(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.firstFormGroup.controls['mobileNumber'].setValue(onlyNumberValue);
  }



}


