
import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CandidateService } from '../../Services/candidate.service';
import { Candidates } from '../../Models/candidates.model';
import { AddCandidateComponent } from '../add-candidate/add-candidate.component';
import { CandidatePositionComponent } from '../candidate-position/candidate-position.component';
import {FilterCandidateComponent} from '../filter-candidate/filter-candidate.component';

//utilities
import { LayoutUtilsService ,MessageType} from '../../../../../core/_base/crud';
import { EditCandidateComponent } from '../edit-candidate/edit-candidate.component';
import { EditCellRendererComponent } from '../edit-cell-renderer/edit-cell-renderer.component';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, IGetRowsParams } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-candidates-list',
  templateUrl: './candidates-list.component.html',
  styleUrls: ['./candidates-list.component.scss']
})
export class CandidatesListComponent implements OnInit {

  @ViewChild('myButton') myButton : ElementRef;
  //@ViewChild('myGrid') myGrid : AgGridAngular; //SSP

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

 // private gridOptions: GridOptions //SSP

  variables
  dataSource: any;
  modalReference: any;
  errorText: boolean = false;
  candidateList: Candidates[];
  editCandidate: Candidates;

  resultCustom: any;
  viewContent:boolean;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  rowData: [];
  selectedValue: any;
  domLayout: any;
  searchValue: any;
  gridOptions: any;
  frameworkComponents: any;
  paginationPageSize: any;
  cacheBlockSize: any;
  rowSelection: any;
  rowGroupPanelShow: any;
  pivotPanelShow: any;
  currentRouteUrl: string;
  noRowsTemplate:any;

  rowModelType:any;
  serverSideStoreType: any;
  paginationNumberFormatter: any;

  rightSearchViewFlag = true;

  private subscriptions: Subscription[] = [];
  openFilterFlag:any;

  //SSp
  private cacheOverflowSize;
  private maxConcurrentDatasourceRequests;
  private infiniteInitialRowCount;
  agGridPerPageData: any


  constructor(private modalService: NgbModal,
    private candidateService:CandidateService,
    private layoutUtilsService: LayoutUtilsService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private subheaderService: SubheaderService,
    private SpinnerService: NgxSpinnerService,
    private http: HttpClient,
    public snackBar: MatSnackBar) {
    this.gridOptions={
      context : {
        componentParent: this
      }
    }

    
    this.noRowsTemplate = '<span class="ag-overlay-loading-center">Data is loading...</span>';

  }

  ngOnInit() {
    this.getCandidateJobType();
    this.getIndistry();

    this.gridOptions.suppressNoRowsOverlay = true;
    this.agGridPerPageData = 10;

    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('Candidates');
    }

    this.subheaderService.setRightSearchConfig(this.rightSearchViewFlag, false);

    //On click filter button
    this.subscriptions.push(this.subheaderService.rightSearchButton$.subscribe(rt => {
      //console.log('filter', rt);
      this.openFilterFlag = rt.viewFlag
      if(rt.filterClickFlag == true){
        this.FilterCandidate();
      }
      
    }));

    if (this.eventEmitterService.subsVar==undefined) {    //after add candidate on close modal get result
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.modalResult(data);
      });
    }

    
    
    

    this.columnDefs = [
      {
        headerName:'Name',
        field: 'fullName',
        menuTabs: ['filterMenuTab']
      },
      {
        headerName:'Mobile',
        field: 'mobileNumber',
        menuTabs: ['filterMenuTab']
      },
      {
        headerName:'Current City',
        field: 'cityName',
        menuTabs: ['filterMenuTab']
      },
      {
        headerName: 'Expected Salary',
        field: 'expectedSalary',
        maxWidth: 150,
        menuTabs: ['filterMenuTab']
      },
      {
        headerName:'Resume',
        field: 'href',
        menuTabs: ['filterMenuTab'],

        cellRenderer: function(params) {
          if(typeof(params.value) != 'undefined'){
            return '<a href="'+params.value+'">View</a>';
          }
          
        },
      },
      {
        headerName:'Last Update',
        field: 'lastUpdated',
        menuTabs: ['filterMenuTab'] 
      },
      {
        headerName: 'Action',
        field: '',
        maxWidth: 150,
        filter: false,
        sortable: false,
        menuTabs: [],
        cellRendererFramework: EditCellRendererComponent
      },
    ]

    this.defaultColDef = {
      sortable: true,
      resizable: false,
      editable: false,
      filter: true,
      flex: 1,
      minWidth: 150
    };
  
    this.domLayout = 'autoHeight';
    this.selectedValue='10';
    this.paginationPageSize = 10;
    this.refreshGrid();

  }

  getCandidateJobType(){
    var localStorageJobType = localStorage.getItem('jobType');
    
    if(localStorageJobType){
    }else{
      this.candidateService.getCandidateJobType(this.selectedProject).subscribe( 
        
        (result)=>{
          var tempVar = [];
              tempVar.push(result);

          var tempJobType = JSON.stringify(tempVar[0]);
          localStorage.setItem('jobType', tempJobType)
        },
        error => {
          console.log(error)
        }
      )
    }
  }

  getIndistry(){
    var localStorageIndistryType = localStorage.getItem('indistryType');

    if(localStorageIndistryType){
    }else{
      this.candidateService.getIndistry(this.selectedProject).subscribe( 
        
        (result)=>{
          var tempVar = [];
              tempVar.push(result);
 
          var tempJobType = JSON.stringify(tempVar[0]);
          localStorage.setItem('indistryType', tempJobType);   
        },
        error => {
          console.log(error)
        }
      )
    }

  }

  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getAllCandidateList(0);
  }

  getAllCandidateList(editFlag:number){
    this.SpinnerService.show('candidate-list');
    this.candidateService.getAllCandidatesList(this.selectedProject).subscribe(
      result=>{
        if (!result || !result['embedded'].length) {
          this.gridOptions.suppressNoRowsOverlay = false;
          this.noRowsTemplate = '<span>There are no records.</span>';
          this.gridOptions.api.showNoRowsOverlay();
        }
        
        result['embedded'].forEach(
            row => {
              //row.fieldName= row.fieldType.typeName;
              row.name= row.firstName +' '+ row.lastName;
              var formatedDate = this.dateAgo(row.lastUpdated);
              var yearAgo = formatedDate.years;
              var monthAgo =  formatedDate.months;
              var dayAgo = formatedDate.days;

              var lastUpdate = '';
              if(yearAgo != 0){
                var text = (yearAgo > 1) ? " years ago" : " year ago";
                lastUpdate = yearAgo+text;
              }else if(yearAgo == 0 && monthAgo != 0){
                var text = (monthAgo > 1) ? " months ago" : " month ago";
                lastUpdate = monthAgo+text;
              }else if(yearAgo == 0 && monthAgo == 0 && dayAgo != 0){
                var text = (dayAgo > 1) ? " days ago" : " day ago";
                lastUpdate = dayAgo+text;
              }else if(yearAgo == 0 && monthAgo == 0 && dayAgo == 0){
                lastUpdate = 'Today'
              }
              row.lastUpdated = lastUpdate;
              row.href = row.href;
  
            }
          )
         // params.api.setServerSideDatasource(datasource);
        this.resultCustom=result['embedded'];
        this.rowData =  this.resultCustom;
        this.SpinnerService.hide('candidate-list');
  
        /** Refresh Grid after add and update */
        if(editFlag == 1) {
          //this.gridApi.updateRowData({add: this.rowData});
          //this.gridApi.refreshCells();
       }
  
       let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
       setTimeout(() => {
         el.click(),
         this.viewContent = true;
       }, 300);

       
  
      },
      err=>{
        this.errorText=true;
      }
    )
  }


  populateCandidateList(editFlag:number){
    this.candidateService.getAllCandidatesList(this.selectedProject).subscribe(
      result=>{
        if (!result || !result['embedded'].length) {
          this.gridOptions.suppressNoRowsOverlay = false;
          this.noRowsTemplate = '<span>There are no records.</span>';
          this.gridOptions.api.showNoRowsOverlay();
        }
        
        result['embedded'].forEach(
            row => {
              //row.fieldName= row.fieldType.typeName;
              row.name= row.firstName +' '+ row.lastName;
              var formatedDate = this.dateAgo(row.lastUpdated);
              var yearAgo = formatedDate.years;
              var monthAgo =  formatedDate.months;
              var dayAgo = formatedDate.days;

              var lastUpdate = '';
              if(yearAgo != 0){
                var text = (yearAgo > 1) ? " years ago" : " year ago";
                lastUpdate = yearAgo+text;
              }else if(yearAgo == 0 && monthAgo != 0){
                var text = (monthAgo > 1) ? " months ago" : " month ago";
                lastUpdate = monthAgo+text;
              }else if(yearAgo == 0 && monthAgo == 0 && dayAgo != 0){
                var text = (dayAgo > 1) ? " days ago" : " day ago";
                lastUpdate = dayAgo+text;
              }else if(yearAgo == 0 && monthAgo == 0 && dayAgo == 0){
                lastUpdate = 'Today'
              }
              row.lastUpdated = lastUpdate;
              row.href = row.href;
  
            }
          )
        this.resultCustom=result['embedded'];
        this.rowData =  this.resultCustom;
  
        /** Refresh Grid after add and update */
        if(editFlag == 1) {
          //this.gridApi.updateRowData({add: this.rowData});
          //this.gridApi.refreshCells();
       }
  
       let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
       setTimeout(() => {
         el.click(),
         this.viewContent = true;
       }, 300);

       
  
      },
      err=>{
        this.errorText=true;
      }
    )
  }

  onPageSizeChanged(newPageSize) {
    console.log('newPageSize', Number(newPageSize))
    this.gridApi.paginationSetPageSize(Number(newPageSize));
  }

  onPaginationChanged(event){
    //var page = this.gridApi.paginationGetCurrentPage();
  }


  dateAgo(sentOn){
    let todayDate = new Date();
    let sentOnDate = new Date(sentOn);
    sentOnDate.setDate(sentOnDate.getDate());
    let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
    // To calculate the no. of days between two dates
    let d = Math.floor(differenceInTime / (1000 * 3600 * 24)); 

    let months = 0, years = 0, days = 0, weeks = 0;
    while(d){
      if(d >= 365){
        years++;
        d -= 365;
      }else if(d >= 30){
        months++;
        d -= 30;
      }else if(d >= 7){
        weeks++;
        d -= 7;
      }else{
        days++;
        d--;
      }
    };
    return {
      years, months, weeks, days
    };
        
  }

  

  
  //------Quick Search
  quickSearch(){
    this.gridApi.setQuickFilter(this.searchValue);
  }

  AddUser() {
    const _saveMessage = `User has been saved.`;
    const _messageType = MessageType.Create;
    this.modalReference = this.modalService.open(AddCandidateComponent,
      {  centered: false,
          size : 'lg',
          backdrop : 'static'
        }
    );
    this.modalReference.result.then((result) => {
      console.log('after add data', result)
      if(result == 'SUCCESS'){
        this.snackBar.open('User added.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.refreshGrid();
      }else if(result == 'ERROR'){
        this.snackBar.open('Unable to add. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    })
  }

  EditUser(editCandidateValues) {
    console.log(editCandidateValues);
    const _saveMessage = `User has been saved.`;
    const _messageType = MessageType.Create;
    this.editCandidate=editCandidateValues;
  
    const ref = this.modalService.open(EditCandidateComponent, 
      { centered: false,
        size : 'lg',
        backdrop : 'static'
      }
    );
    ref.componentInstance.editCandidate = editCandidateValues;
    /*this.modalReference.result.then((result) => {
      if(result == 'SUCCESS'){
        this.snackBar.open('User updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.refreshGrid();
      }else if(result == 'ERROR'){
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    })*/
  }

  AddPosition(editCandidateValues){
    this.editCandidate=editCandidateValues;
    this.candidateService.getCandidatePosition(editCandidateValues).subscribe(
      result=>{
        console.log('get data')
      },
      err=>{
        this.errorText=true;
      }
    )
    const ref = this.modalService.open(CandidatePositionComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }

    );
    console.log(editCandidateValues.name)
    ref.componentInstance.editCandidate = editCandidateValues;
    ref.componentInstance.candidateName = editCandidateValues.name;
    this.modalReference.result.then((result) => {
      console.log('saveddata', result);
      if(result == 'SUCCESS'){
        this.snackBar.open('User updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.refreshGrid();
      }else if(result == 'ERROR'){
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    })
    
  }

  FilterCandidate(){
    console.log('Search candidate')
    const ref = this.modalService.open(FilterCandidateComponent, 
      { 
        centered: false,
        backdrop : 'static' ,
        windowClass:'candidate-filter-modal'
      }
    );
    //ref.componentInstance.candidateName=editCandidateValues.name;
  }

  //modalResult(result: string) {
  modalResult(result: any) {
    console.log('modal-close---',result)
    if(result === 'SUCCESS'){
      this.snackBar.open('User updated.', '', {
        duration: 3000,
        panelClass: ['green-snackbar']
      });
      this.gridApi.setRowData([]);
      this.getAllCandidateList(1);
    }else if(result === 'USER_SAVE_SUCCESS'){
      this.snackBar.open('Successfully add user', '', {
        duration: 3000,
        panelClass: ['green-snackbar']
      });
     // this.gridApi.setRowData([]);
     // this.getAllCandidateList(0);
    }
    else if(result === 'ERROR'){
    }else if(result === 'CANDIDATE_DATA_UPDATE_ERROR'){
      this.snackBar.open('User updated failed.', '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    }else if(result === 'CANDIDATE_DATA_SAVE_ERROR'){
      this.snackBar.open('Failed to save data please try again.', '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    }else if(result === 'CANDIDATE_DATA_FILE_UPLOAD_FAILED'){
      this.snackBar.open('Candidate added successfully but file upload failed, please edit and upload file ', '', {
        duration: 5000,
        panelClass: ['red-snackbar']
      });
    } 
    else{ //for search
      var data = result['embedded'];

      result['embedded'].forEach(
        row => {
          //row.fieldName= row.fieldType.typeName;
          row.name= row.firstName +' '+ row.lastName;
          var formatedDate = this.dateAgo(row.lastUpdated);
          var yearAgo = formatedDate.years;
          var monthAgo =  formatedDate.months;
          var dayAgo = formatedDate.days;

          var lastUpdate = '';
          if(yearAgo != 0){
            var text = (yearAgo > 1) ? " years ago" : " year ago";
            lastUpdate = yearAgo+text;
          }else if(yearAgo == 0 && monthAgo != 0){
            var text = (monthAgo > 1) ? " months ago" : " month ago";
            lastUpdate = monthAgo+text;
          }else if(yearAgo == 0 && monthAgo == 0 && dayAgo != 0){
            var text = (dayAgo > 1) ? " days ago" : " day ago";
            lastUpdate = dayAgo+text;
          }else if(yearAgo == 0 && monthAgo == 0 && dayAgo == 0){
            lastUpdate = 'Today'
          }
          row.lastUpdated = lastUpdate;
          row.href = row.href;

        }
      )
      this.resultCustom=result['embedded'];
      this.rowData =  this.resultCustom;

      this.gridApi.setRowData([]);
      this.gridApi.updateRowData({add: this.rowData});
      this.gridApi.refreshCells();
    }
  }

  refreshGrid(){
    this.gridApi.setRowData([]);
    //this.getAllCandidateList(1);
  }

  getItemCssClassByStatus(status: number = 0): string {
    switch (status) {
      case 0:
        return 'danger';
      case 1:
        return 'success';
      case 2:
        return 'metal';
    }
    return '';
  }

  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Inactive';
      case 1:
        return 'Active';
      case 2:
        return 'Pending';
    }
    return '';
  }


  onGridReady_serverside(params) {
    //alert('onGridReady')
    /*this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getAllCandidateList(0);*/

    //SSP
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    var datasource = {
      rowCount: null,
      getRows: (params: IGetRowsParams) =>{
        
        var pageNo = params.startRow/this.agGridPerPageData;
            pageNo = pageNo+1
        
        this.candidateService.getAllCandidatesList_serverside(pageNo,this.agGridPerPageData)
        .subscribe( result=> {
          //setTimeout(function() {
          if (!result || !result['embedded'].length) {
            this.gridOptions.suppressNoRowsOverlay = false;
            this.noRowsTemplate = '<span>There are no records.</span>';
            this.gridOptions.api.showNoRowsOverlay();
          }

          result['embedded'].forEach(
            row => {
              //row.fieldName= row.fieldType.typeName;
              row.name= row.firstName +' '+ row.lastName;
              var formatedDate = this.dateAgo(row.lastUpdated);
              var yearAgo = formatedDate.years;
              var monthAgo =  formatedDate.months;
              var dayAgo = formatedDate.days;

              var lastUpdate = '';
              if(yearAgo != 0){
                var text = (yearAgo > 1) ? " years ago" : " year ago";
                lastUpdate = yearAgo+text;
              }else if(yearAgo == 0 && monthAgo != 0){
                var text = (monthAgo > 1) ? " months ago" : " month ago";
                lastUpdate = monthAgo+text;
              }else if(yearAgo == 0 && monthAgo == 0 && dayAgo != 0){
                var text = (dayAgo > 1) ? " days ago" : " day ago";
                lastUpdate = dayAgo+text;
              }else if(yearAgo == 0 && monthAgo == 0 && dayAgo == 0){
                lastUpdate = 'Today'
              }
              row.lastUpdated = lastUpdate;
              row.href = row.href;
  
            }
          )
          this.resultCustom=result['embedded'];
          //this.rowData =  this.resultCustom;
          
          params.successCallback(this.resultCustom, result['totalPages']);
          
        }
        )
        
      }
    }
    //params.api.setDatasource(datasource);
    this.gridApi.setDatasource(datasource);

  }

  

  onBtForEachNode() {
    this.gridApi.forEachNode(this.printNode);
  }

  printNode(node, index) {
    if (node.group) {
      console.log(index + ' -> group: ' + node.key);
    } else {
      console.log(
        index + ' -> data: ' + node.data.id + ', ' + node.data.fieldName
      );
    }
  }

  triggerClick() {} 


}
