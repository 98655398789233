import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html',
  styleUrls: ['./edit-cell-renderer.component.scss']
})
export class EditCellRendererComponent implements OnInit {

  data:any;
  params:any;

  constructor() { }

  agInit(params){
    this.params = params;
    this.data = params.value;
  }

  ngOnInit(): void {
  }

  public invokeParentMethod() {
    console.log('----',this.params.data)
    this.params.context.componentParent.EditUser(this.params.data);
    //this.params.context.EditUser(this.params.data);
  }

  public invokeAddPositionMethod() {
    this.params.context.componentParent.AddPosition(this.params.data);
  }

}
