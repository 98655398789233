import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html',
  styleUrls: ['./edit-cell-renderer.component.scss']
})
export class EditCellRendererComponent implements OnInit {

  data:any;
  params:any;
  displayOfferButton: boolean
  displayScheduleButton: boolean
  displayRejectButton: boolean

  constructor() { }

  agInit(params){
    this.displayScheduleButton = true   //999,1000,1001,1002,1003,1004,1005,1006,1007,1008
    this.displayOfferButton = false
    this.displayRejectButton = true;
    
    /*  999  Legacy System Transfer - Assigned                          
     1000  Assigned                                                   
     1001  Test Scheduled                                             
     1002  Awaiting Test Score                                        
     1003  Legacy System Transfer - Test Completed                    
     1004  Awaiting Interview Schedule                                
     1005  Interview Scheduled                                        
     1006  Awaiting Interview Result                                  
     1007  Legacy System Transfer - Interview Result Under Discussion
     1008  Legacy System Transfer - Interview Result Under Review     
     1009  Selected                                                   
     1010  Offered                                                    
     1013  Legacy System Transfer - Awaiting Joining                  
     1014  Joined                                                     
     1019  Legacy System Transfer - To Be Decided                     
     1020  Rejected                                                   
     1021  Not Joined                                                 
     1022  Declined        */ 
     
    if(params.data.statusId == 1009){   //Selected
      this.displayScheduleButton = false   
      this.displayOfferButton = true
      this.displayRejectButton = true;
    }

    if(params.data.statusId == 1010){   //Offered
      this.displayScheduleButton = false   
      this.displayOfferButton = true
      this.displayRejectButton = true;
    }

    if(params.data.statusId == 1013){   
      this.displayScheduleButton = false;
      this.displayOfferButton = true;
      this.displayRejectButton = true;
    }

    if(params.data.statusId == 1014 || params.data.statusId == 1019 || params.data.statusId == 1020 || params.data.statusId == 1021 || params.data.statusId == 1022){  
      this.displayScheduleButton = false   
      this.displayOfferButton = false;
      this.displayRejectButton = false;
    }

    if(params.data.openPositionGreaterOfferJoined == true){   //Offer Button Show
      if(params.data.positionType == 'Mandate'){  //Offer Button Hide
        this.displayOfferButton = false;
      }else{  //show
        if(params.data.directOfferAllowed == 1){  //Offer Button Show
          
          if(params.data.statusId == 1014 || params.data.statusId == 1019 || params.data.statusId == 1020 || params.data.statusId == 1021 || params.data.statusId == 1022){  
            
            this.displayOfferButton = false;
          }else{
            this.displayOfferButton = true;
          }

        }
      }
    }else{  //Offer Button Hide
      this.displayOfferButton = false;
    }
    
    this.params = params;
    this.data = params.value;


  }

  ngOnInit(): void {
  }

  public invokeScheduleMethod() {
    this.params.context.componentParent.scheduleInterview(this.params.data);
  }

  public invokeOfferMethod(){
    this.params.context.componentParent.candidateOffer(this.params.data);
  }

  public invokeRejectMethod(){
    this.params.context.componentParent.rejectCandidate(this.params.data);
  }

}



