import { Injectable } from '@angular/core';
//import { Candidates } from '../Models/candidates.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RoleList } from '../Models/role-list.model';

//import { HttpClient, HttpHeaders } from '@angular/common/http';

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
}

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private http:HttpClient) { }

  getAllCandidatesList_serverside(page, perPageDataSize){    //projectId: number
    return this.http.get(environment.baseUrl+'/candidate-services/api/v1/candidate/?page='+page+'&size='+perPageDataSize+'');
    //return this.http.get(environment.baseUrl+'/candidate-services/api/v1/candidate/?page='+start+'&size='+end+'');
  }

  getAllCandidatesList(projectId: number){    
    return this.http.get(environment.baseUrl+'/candidate-services/api/v1/candidate/?page=1&size=100');
  }

  getCandidateJobType(projectId: number){
    return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/jobtypes/');
  }

  getAllCity(projectId: number){
    return this.http.get(environment.baseUrl + '/candidate-services/api/v1/city/');
  }

  getIndistry(projectId: number){
    return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/industry/');
  }

  getActiveUserList(projectId: number){ 
    return this.http.get(environment.baseUrl + '/login/api/users?projectId='+projectId+'&activeStatus=1');
  }

  
  saveCandidateData(candidateData){
    return this.http.post<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate',candidateData)
  }

  saveCandidatePositionData(positionData){
    return this.http.post<any[]>(environment.baseUrl + '/recruiter-services/api/v1/positions/new',positionData)
  }

  
  getCandidatePositionList(projectId: number){
    return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/positions/?page=0&size=100&active=1');
  }

  getCandidatePosition(candidateData){
    var candidateId = candidateData.candidateId; 
    return this.http.get<any[]>(environment.baseUrl + '/legacy-services/api/v1/candidate/positions/'+candidateId)
    
  }

  updateCandidateData(candidateData, candidateId){
    //return this.http.put<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/attachment/'+candidateId,candidateData, HttpUploadOptions)  
    return this.http.put<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/'+candidateId,candidateData)
  }

  addResume(candidateData, candidateId){
    return this.http.post<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/attachment/'+candidateId,candidateData)
  }

  getCandidateDetail(candidateId, projectId: number){
    return this.http.get<any[]>(environment.baseUrl+'/candidate-services/api/v1/candidate/'+candidateId);
  }

  filterCandidate(searchData){
    return this.http.get<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/search?name='+searchData.name+'&city='+searchData.city+'&jobtypes='+searchData.jobType+'&phone='+searchData.mobile+'&email='+searchData.email+'&page=1&size=100');
    
  }
 
}
