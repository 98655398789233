import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kt-recruitment-management',
  templateUrl: './recruitment-management.component.html',
  styleUrls: ['./recruitment-management.component.scss']
})
export class RecruitmentManagementComponent implements OnInit {

  selectedTab = 0;
  moduleType = 5;
  returnUrl: any;

  /**
     * Component constructor
     *
     * @param router: Router
     * @param route
     */

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('recruiterList');
      // redirect back to the returnUrl before login
        this.route.queryParams.subscribe(params => {
        this.returnUrl = params.returnUrl || '/';
      });

      const routeSubscription =  this.activatedRoute.params.subscribe(params => {
        this.selectedTab = params.tab;
        console.log('Tab : '+this.selectedTab);

      });
  }

}
