// Angular
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
// Layout
import { OffcanvasOptions } from '../../../../core/_base/layout';
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../core/auth';
// profile image service
import {UserProfilePicService} from './user-profile-pic.service';


@Component({
  selector: 'kt-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss']
})
export class QuickUserPanelComponent implements OnInit {
  user$: Observable<User>;
  user: User;
// image upload 
  imagePrefixBase64 = "data:image/jpg;base64,";
  userProfilePic:string = "";
  serverProfilePic:string = "";
  blankProfilePic:string = "./assets/media/users/blank_user_img.png";
  @ViewChild('imageInput') fileInput: ElementRef<HTMLElement>;   
  imageNotUploaded: boolean = true;
  allowImageEdit: boolean = false;  

  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'offcanvas',
    placement: 'right',
    closeBy: 'kt_quick_user_close',
    toggleBy: 'kt_quick_user_toggle'
  };

  constructor(private store: Store<AppState>, 
  private profilePicService: UserProfilePicService, 
  private SpinnerService: NgxSpinnerService) {
    this.fetchUserProfilePicFromServer();
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
    const userString = localStorage.getItem('currentUser');
    this.user = (JSON.parse(userString) as User);
  }

  /**
   * Log out
   */
  logout() {
    this.store.dispatch(new Logout());
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
     this.SpinnerService.show('showImageProcessing');
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.userProfilePic = event.target.result;
      const uploadData = new FormData();
      uploadData.append('profileImage', file, file.name);
      this.profilePicService.uploadProfilePicture(uploadData).subscribe(
        serviceResponse=>{
          if(serviceResponse['statusCode'] == 200){
        		this.userProfilePic =  this.imagePrefixBase64 + serviceResponse['responseData'];  
            this.serverProfilePic = this.imagePrefixBase64 + serviceResponse['responseData'];
            this.imageNotUploaded = false;
        	}  
          this.SpinnerService.hide('showImageProcessing');
        },
        err=>{         
        	this.SpinnerService.hide('showImageProcessing');
          console.log("error in upload profile pic "+err);              
        }
      )      
    });
    reader.readAsDataURL(file);
    // uploading file on server
  //   const uploadData = new FormData();
  // uploadData.append('profileImage', file, file.name);
  //   this.uploadProfilePicOnServer(uploadData);    
  }

  fetchUserProfilePicFromServer(){
    this.SpinnerService.show('showImageProcessing');
    this.profilePicService.fetchProfilePicture().subscribe(
      serviceResponse=>{
        if(serviceResponse['statusCode'] == 200){
        		this.userProfilePic =  this.imagePrefixBase64 + serviceResponse['responseData']; 
            this.serverProfilePic = this.imagePrefixBase64 + serviceResponse['responseData']; 
            this.imageNotUploaded = false;
        	}else{
            this.userProfilePic =  this.blankProfilePic; 
            this.serverProfilePic =  this.blankProfilePic; 
          }    
        this.SpinnerService.hide('showImageProcessing');       
      },
      err=>{         
        this.SpinnerService.hide('showImageProcessing');
        console.log("error in fetch profile pic ",err);
      }
    )
  }

  uploadProfilePicOnServer(profilePic){
    this.profilePicService.uploadProfilePicture(profilePic).subscribe(
      serviceResponse=>{
        if(serviceResponse['statusCode'] == 200){
        	this.userProfilePic =  this.imagePrefixBase64 + serviceResponse['responseData']; 
          this.serverProfilePic = this.imagePrefixBase64 + serviceResponse['responseData'];
          this.imageNotUploaded = false; 
        }                  
      },
      err=>{
        console.log("error in upload profile pic ",err);         
      }
    )
  }

  selectProfilepic(event: any){    
    let el: HTMLElement = this.fileInput.nativeElement;
    el.click();    
  }

  removeProfilePic(event: any){    
   this.SpinnerService.show('showImageProcessing');
    this.profilePicService.removeProfilePicture().subscribe(
      serviceResponse=>{
        this.userProfilePic =  this.blankProfilePic; 
        this.serverProfilePic = this.blankProfilePic; 
        this.imageNotUploaded = true;
        console.log(serviceResponse);     
        this.SpinnerService.hide('showImageProcessing');     
      },
      err=>{
        this.SpinnerService.hide('showImageProcessing');
        console.log("error in delete profile pic ",err);
      }
    )
  }
  
  showImageEditOption(){
  	this.allowImageEdit = true;
    this.userProfilePic =  "./assets/media/users/camera_1.png";         
  }

	hideImageEditOption(){
		this.allowImageEdit = false;
    this.userProfilePic =  this.serverProfilePic;         
	}  

}
