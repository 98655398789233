import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
//import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RecruitmentCandidateService } from '../../services/recruitment-candidate.service';

import { MAT_DATE_FORMATS } from '@angular/material/core';

//import { MY_DATE_FORMATS } from './my-date-formats';


@Component({
  selector: 'kt-interview-schedule',
  templateUrl: './interview-schedule.component.html',
  styleUrls: ['./interview-schedule.component.scss']
})


export class InterviewScheduleComponent implements OnInit {


  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public interViewScheduleForm: FormGroup;
  disabled:boolean;
  userPwdCheck:boolean;
  errorMes:String="";
  isShown: boolean;
  isHide: boolean;
  interviewerLevelList:any;
  interviewerNameList:[];
  public interviewerLevelFilter: FormControl = new FormControl();
  public interviewerNameFilter: FormControl = new FormControl();
  interViewApplicationId: any;
  positionId:any;

  minDate: Date;  //Date Picker
  

  constructor(
    private _rmcs:RecruitmentCandidateService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    let todayDate = new Date();
        //todayDate.setDate(todayDate.getDate() - 3);
    this.minDate = todayDate;

    this.initInterViewScheduleForm(); 
    this.getAllInterviewerLevelList();
    //this.getInterviewerNameList();
    this.disabled=false;
    this.userPwdCheck=false;
  }

  initInterViewScheduleForm(){
    this.interViewScheduleForm = this.fb.group({
      interViewApplicationId:[''],
      interviewerLevel: ['', Validators.compose([Validators.required ])],
      interviewerName: ['', Validators.compose([Validators.required ])],
      interviewLocation: ['', Validators.compose([Validators.required ])],
      interviewDate: ['', Validators.compose([Validators.required ])],
      interviewTime: ['', Validators.compose([Validators.required ])],
    });
  }


  getAllInterviewerLevelList(){
    var interviewerArray = [];
    this._rmcs.getInterviewerList(this.positionId).subscribe(
      data => {
        console.log(data);
        
        data.forEach(function (value) {
          var interviewerId = value.interviewer.userId;
          var interviewerLvel = value.level;
          var interviewerLvelValue = 'Interviewer '+value.level;
          var interviewerName = value.interviewer.firstName+' '+value.interviewer.middleName+' '+value.interviewer.lastName;
          var tempArray = {"id":interviewerId, "name":interviewerName, "level":interviewerLvel, "levelValue":interviewerLvelValue };
          interviewerArray.push(tempArray);
        });
      },
      error =>{
        
      }
    );
    this.interviewerLevelList = interviewerArray;

  }

  changeInterviewerLevel(selectedVal: any){
    var interviewerId = '';
    this.interviewerLevelList.forEach(function (v) {
      console.log(v['name'])
      if(v['level'] == selectedVal){
        interviewerId = v['id']
      }
    })

    this.interViewScheduleForm.controls['interviewerName'].setValue(interviewerId);
  }

  interviewScheduleData(controls){
    var interViewDate = this.formatDate(controls.interviewDate.value);
    var interViewTime = this.timeConv24(controls.interviewTime.value);
    var interViewDateTime = interViewDate+' '+interViewTime;
    
    const addData = {    
      "applicationId": this.interViewApplicationId,   //controls.interViewApplicationId.value,
      "interviewerId": controls.interviewerName.value,
      "interviewerLevel": controls.interviewerLevel.value,
      "interviewerLocation":controls.interviewLocation.value.trim(),
      "interviewSchedule": interViewDateTime //"2021-07-20 10:00:00 AM" //controls.interviewDate.value
    };  
    console.log(addData);
    return addData;
  }

  saveInterViewSchedule(){
    const controls = this.interViewScheduleForm.controls;

    if (this.interViewScheduleForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    const addData=this.interviewScheduleData(controls);
    
    this._rmcs.saveInterviewSchedule(addData).subscribe(
      data => {
      //  console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          //this.OpenAlert(data['message']);
        }else{
          this.activeModal.close();
          this.interViewScheduleForm.reset();
          this.disabled=false;
          //this.eventEmitterService.onModalCloseResult(data);
          this.eventEmitterService.onModalCloseResult('INTERVIEW_SCHEDULED_SUCCESS');
          
        }
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  timeConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    var hour = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = hour + ts.substr(2, 3);
    ts = ts+':00'+ ampm
    return ts;
  };

  Close(){
    this.activeModal.close();
    this.interViewScheduleForm.reset();
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.interViewScheduleForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }


}
