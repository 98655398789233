<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<span *ngIf="errorText"><i>No record found</i></span>
<!-- start::FILTERS & GROUP ACTIONS -->
<div class="form mb-3">
    <!-- start::FILTERS -->
    <div class="form-filtration">
        <div class="row align-items-center">
            <div class="col-md-2 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue">
                    <mat-hint align="start">Search in all fields</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-7 kt-margin-bottom-10-mobile"></div>
            <div class="col-md-3 text-right kt-margin-bottom-10-mobile">
                <button type="button" mat-raised-button color="primary" (click)="AddUser()" >Add</button>
                <!-- <button type="button" class="ml-2" mat-raised-button color="primary" (click)="FilterCandidate()" >Search</button> -->
            </div>
            
        </div>
    </div>
    <!-- end::FILTERS -->
</div> 
<!-- end::FILTERS & GROUP ACTIONS --> 

<!-- AG GRID MODULE START -->
<ag-grid-angular
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [animateRows]="true"
    [domLayout]="domLayout"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [rowData]="rowData"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (paginationChanged)="onPaginationChanged($event)"
    
>
</ag-grid-angular>

<!--<ag-grid-angular 
    class="ag-theme-alpine"
    
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [animateRows]="true"
    [domLayout]="domLayout"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [maxBlocksInCache]="maxBlocksInCache"
    [gridOptions]="gridOptions"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
    [cacheOverflowSize]="cacheOverflowSize"

    (gridReady)="onGridReady($event)"
    
    
>
</ag-grid-angular>  -->


<!--<div class="row col-md-5" style="margin-top:-45px;">
    <div class="col-md-3 pl-0">
        <label class="col-form-label form-padding-top-15">Items per page:</label>
    </div>
    <div class="col-md-2">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select (selectionChange)="onPageSizeChanged($event.value)" [(value)]="selectedValue">
                <mat-option value="10">10</mat-option>
                <mat-option value="50">50</mat-option>
                <mat-option value="100">100</mat-option>
                <mat-option value="500">500</mat-option>
            </mat-select>
        </mat-form-field>
    </div>  
</div>-->

<ngx-spinner size="default" type="ball-spin-clockwise" name='candidate-list'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>