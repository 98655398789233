<img class="same-height-width ml-2" style="height:24px; width:24px" *ngIf="displayScheduleButton" src="./assets/media/svg/icons/General/Calendar.svg" (click)="invokeScheduleMethod()">

<img class="same-height-width ml-2" style="height:24px; width:24px" *ngIf="displayOfferButton" src="./assets/media/svg/icons/General/Send.svg" (click)="invokeOfferMethod()">

<img class="same-height-width ml-2" style="height:24px; width:24px" *ngIf="displayRejectButton" src="./assets/media/svg/icons/General/recruiter_delete.svg" (click)="invokeRejectMethod()">

<!-- <a href="javascript:;"
   routerLink="#"> 
    <img src="./assets/media/svg/icons/General/dashboard.svg" title="Dashboard">
</a> -->

<img class="same-height-width d-none ml-2" style="height:24px; width:24px" src="./assets/media/svg/icons/General/dashboard.svg" title="Dashboard"> 
