// Angular
import {Component, Input, OnInit} from '@angular/core';
import { ContactDashboardService } from '../../../../services/contact-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SubheaderService } from '../../../../../../../core/_base/layout';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { EditCompaniesDialogComponent } from '../../../../../shared/custom-edit/edit-companies-dialog/edit-companies-dialog.component';


@Component({
  selector: 'kt-widget3-contact',
  templateUrl: './widget3-contact.component.html',
  styleUrls: ['./widget3-contact.component.scss']
})
export class Widget3ContactComponent implements OnInit  {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('pluralName'));
  headingName = 'Contact';
  contactInfo: any;
  contactName: string;
  email: string;
  designation?: string;
  phone?: string;
  isError = false;
  errorMessage: string;

  public isCollapsed = false;

  constructor(
    private _cds: ContactDashboardService,
    private SpinnerService: NgxSpinnerService,
    private subheaderService: SubheaderService,
    private eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-contact');
    this.fetchContactInfo(this.selectedProject, this.parentData);

    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.contactResult(data);
      });
    }

    if(this.tabHeading['contacts'])
      this.headingName = this.tabHeading['contacts'];
      
  }

  fetchContactInfo(projectId: number, contactId: number){
    this._cds.getContactInfo(projectId, contactId).subscribe(
      res=>{
        this.contactInfo = res['responseData'];
        this.subheaderService.setTitle(this.contactInfo.name);
        this.contactName = this.contactInfo.name;
        this.email = this.contactInfo.email;
        this.designation = this.contactInfo.designation;
        this.phone = this.contactInfo.phone;
        this.SpinnerService.hide('dashboard-contact');
      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-contact');
      }
    )
  }

  editContact() {
    const _saveMessage = `Contact has been saved.`;
    const ref = this.modalService.open(EditCompaniesDialogComponent,
      {
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });

    ref.componentInstance.moduleType=1;
    ref.componentInstance.flag='contact';
    ref.componentInstance.editId=this.parentData;
    ref.componentInstance.activityFlag=false;

    ref.result.then((result) => {
      this.contactResult(result);
    });
  }

  contactResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-contact');
      this.fetchContactInfo(this.selectedProject, this.parentData);
    }
  }

}
