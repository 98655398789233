import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Contact } from '../models/contact.model';
import { environment} from 'src/environments/environment';

const API_ADD_CONTACT_URL=environment.baseUrl+'/contact/addContactType';

@Injectable({
  providedIn: 'root'
})

export class AddContactService {
  
  constructor(private http: HttpClient) { }

  addContact(contactTypeName: string, isActive:number,isPrimary: number, projectId:number): Observable<Contact> {
    return this.http.post<Contact>(API_ADD_CONTACT_URL, {contactTypeName,"projectId": projectId,isActive,isPrimary});
  }

  saveContactSettings(addSettingValues ){
    return this.http.post<any>(
      environment.baseUrl +'/setting/api/contact/addUpdateContactSetting',addSettingValues)
  }
  
  getContactSettings(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl +'/setting/api/contact/getContactSetting?projectId='+projectId);
  }
}
