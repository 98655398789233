import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { customAttributesModel } from '../../company-management/models/customAttributes.model';
import { environment} from '../../../../../environments/environment';
import { companiesModel } from '../../company-management/models/companies.model';
@Injectable({
  providedIn: 'root'
})
export class CustomAttributesService {

  constructor(private http:HttpClient) { } 

  add(addData,moduleType){
    switch (moduleType){
      case 1:{        
        return this.http.post(environment.baseUrl + '/transactions/api/contact/addEditContact',addData);
        break;
      }
      case 2:{
        return this.http.post(environment.baseUrl + '/transactions/api/company/addEditCompany',addData);
        break;
      }
      case 3:{
        return this.http.post(environment.baseUrl + '/transactions/api/opportunity/addEditOpportunity',addData);
        break;
      }
      case 4:{
        return this.http.post(environment.baseUrl + '/transactions/api/product/addEditProduct',addData);
        break;
      }
      default: { 
        return this.http.post(environment.baseUrl + '/transactions/api/activities/addEditActivity',addData);
        break;
     } 
    }

  }

  getFields(moduleType, projectId: number){
    return this.http.get<companiesModel>(environment.baseUrl + '/transactions/api/customType/getFields?projectId='+projectId+'&moduleType='+moduleType)
  }

  getFieldRelation(attributeId,attributeLookupId){
    return this.http.get<companiesModel>(environment.baseUrl + '/setting/api/customType/getChildAttributes?attributeId='+attributeId+'&attributeLookupId='+attributeLookupId)
  }

  getParticularCompany(id,moduleType, projectId: number){

    if(moduleType==1){
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/contact/getContact?projectId='+projectId+'&contactId='+id);
    }
    if(moduleType==2){
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/company/getCompany?projectId='+projectId+'&companyId='+id);
    
    }
    if(moduleType==3){
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/opportunity/getOpportunity?projectId='+projectId+'&opportunityId='+id);
      
    }
    if(moduleType==4){
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/product/getProduct?projectId='+projectId+'&productId='+id);
      
    }
    if(moduleType>100){
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/activities/getActivity?projectId='+projectId+'&activityId='+id);
      
    }


    // switch (moduleType){
    //   case 1:{        
    //     return this.http.get<companiesModel[]>(
    //       environment.baseUrl + '/transactions/api/contact/getContact?projectId='+projectId+'&contactId='+id);
    //     break;
    //   }
    //   case 2:{
    //     return this.http.get<companiesModel[]>(
    //       environment.baseUrl + '/transactions/api/company/getCompany?projectId='+projectId+'&companyId='+id);
    //     break;
    //   }
    //   case 3:{
    //     return this.http.get<companiesModel[]>(
    //       environment.baseUrl + '/transactions/api/opportunity/getOpportunity?projectId='+projectId+'&opportunityId='+id);
    //     break;
    //   }
    //   case 4:{
    //     return this.http.get<companiesModel[]>(
    //       environment.baseUrl + '/transactions/api/product/getProduct?projectId='+projectId+'&productId='+id);
    //     break;
    //   }      
    //   default: { 
    //     console.log("No such module exists!");
    //     break; 
    //  } 
    // }
   
  }

  getAllCustomAttributes(module, projectId: number){
    return this.http.post<customAttributesModel[]>(
      environment.baseUrl + '/setting/api/customType/getAllCustomAttributes',{
      "projectId": projectId,
      "moduleType": module
    })
  }

  getCompanyTypeCustomAttributes(companyTypeId, projectId: number){
    return this.http.post<customAttributesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyTypeCustomAttributes',{
      "companyTypeId": companyTypeId,
      "projectId": projectId
    })
  }

  getOpportunityTypeCustomAttributes(opportunityTypeId, projectId: number){
    return this.http.post<customAttributesModel[]>(
      environment.baseUrl + '/transactions/api/opportunity/getOpportunityTypeCustomAttributes',{
      "opportunityTypeId": opportunityTypeId,
      "projectId": projectId
    })
  }

  getContactTypeCustomAttributes(contactTypeId, projectId: number){
    return this.http.post<customAttributesModel[]>(
      environment.baseUrl + '/transactions/api/contact/getContactTypeCustomAttributes',{
      "contactTypeId": contactTypeId,
      "projectId": projectId
    })
  }
  
  getActivityLookup(moduleType, projectId: number, companyId, opportunityId){

    if(opportunityId!=null){
     
      return this.http.get<customAttributesModel[]>(
        environment.baseUrl + '/transactions/api/activities/getActivityLookup?projectId='+projectId+'&moduleType='+moduleType+'&companyId='+companyId+'&opportunityId='+opportunityId)
   
    }else{
     
    return this.http.get<customAttributesModel[]>(
      environment.baseUrl + '/transactions/api/activities/getActivityLookup?projectId='+projectId+'&moduleType='+moduleType+'&companyId='+companyId)
    }
    }

  getActivity(activityId, projectId: number){
    return this.http.get<customAttributesModel[]>(
      environment.baseUrl + '/transactions/api/activities/getActivity?projectId='+projectId+'&activityId='+activityId)
  }

  getOwner(projectId: number,moduleId:number){
    return this.http.get<customAttributesModel[]>(
     // environment.baseUrl + '/login/api/users/downHierarchy?projectId='+projectId);
     environment.baseUrl + '/login/api/users/fetchModuleOwnerOptions?projectId='+projectId+'&moduleId='+moduleId);
  }

  mapOpportunityContact(contactId, opportunityId, projectId){
    return this.http.get<customAttributesModel[]>(
      // environment.baseUrl + '/login/api/users/downHierarchy?projectId='+projectId);
      environment.baseUrl + '/reports/api/opportunity/dashboard/updateContactMapping?projectId='+projectId+'&opportunityId='+opportunityId+'&contactId='+contactId);
  }

}
