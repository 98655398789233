import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LocalizationService } from '../../services/localization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss']
})
export class LocalizationComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  localizationData: any;
  localizationForm: FormGroup;
  unsubscribe: Subject<any>;
  disabled:boolean;
  countryList:[];
  timeZoneList: [];
  languageList: [];
  errorMes = '';
  currentRouteUrl: string;


  public countryNameFilter: FormControl = new FormControl();
  public timeZoneFilter: FormControl = new FormControl();
  public languageFilter: FormControl = new FormControl();

  constructor(
    private fb: FormBuilder,
    private _ls:LocalizationService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private subheaderService: SubheaderService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('System');
    }

    this.disabled=false;
    //form builder 
    this.initLocalizationForm();
    //get countries
    this.getLocalization(this.selectedProject);

  }


  isDisabled() : boolean {
    return this.disabled;
  }

  initLocalizationForm(){
    this.localizationForm = new FormGroup({
      countryName: new FormControl('',Validators.compose([Validators.required])),
      timeZone: new FormControl('', Validators.compose([ Validators.required])),
      language: new FormControl('',Validators.compose([ Validators.required])),
      systemName: new FormControl('',Validators.compose([ Validators.required])),
   });
 }

 getLocalization(selectedProject:number){
  this.SpinnerService.show();
    this._ls.getLocalization(selectedProject).subscribe(
		(result)=>{ 
        this.localizationData = result['responseData']; 
        this.countryList = this.localizationData.countryList;
        this.timeZoneList = this.localizationData.timeZoneList;
        this.languageList = this.localizationData.languageList;

        /* Set Selected Value */
        this.localizationForm.controls['countryName'].setValue(this.localizationData.selectedCountryId);
        this.localizationForm.controls['timeZone'].setValue(this.localizationData.selectedTimeZoneId);
        this.localizationForm.controls['language'].setValue(this.localizationData.selectedLanguageId);
        this.localizationForm.controls['systemName'].setValue(this.localizationData.systemName);
       console.log(this.localizationData);
       this.SpinnerService.hide();
		},
		error => {
			console.log(error)
    })
  }

  Save(){
    this.disabled=true;
    const controls = this.localizationForm.controls;
    if (this.localizationForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }


   // const updateData = {
    //  "selectedCountryId": controls.countryName.value,
    //  "selectedTimeZoneId":controls.timeZone.value,
    //  "selectedLanguageId":controls.language.value,
    //  "projectId": this.selectedProject,
    //  "systemName":controls.systemName.value
  //};
  
    var selectedCountryId = controls.countryName.value;
    var selectedTimeZoneId = controls.timeZone.value;
    var selectedLanguageId = controls.language.value;
    var projectId = this.selectedProject;
    var systemName = controls.systemName.value.trim();

//  console.log(updateData);

    //this._ls.updateLocalization(updateData).subscribe(
      this._ls.updateLocalization(selectedCountryId,selectedTimeZoneId,selectedLanguageId,projectId,systemName).subscribe(
      data => {
        console.log(data);
      //  this.settingActivityForm.reset();
        this.disabled=false;
        this.snackBar.open('Settings updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.getLocalization(this.selectedProject);
      },
      error => {
        console.log('error occured');
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
        this.errorMes=error.error.responseData;
        this.disabled=false;
      }
    ); 
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.localizationForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ResetForm(){
    this.getLocalization(this.selectedProject);
  }

}
