<div class="example-preview">
    <div>
        <form [formGroup]="addUpdateCandidatePositionForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Add {{ candidateName }} to Position</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label">Select Opening</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="candidatePosition">  <!--formControlName="candidatePosition" -->
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="candidatePositionFilter" ></ngx-mat-select-search> <!--[formControl]="candidatePositionFilter"-->
                                </mat-option> 
                                <mat-option *ngFor="let position of positionList| search : candidatePositionFilter.value :'positionName'" [value]="position.id">
                                    {{position.positionName}}
                                </mat-option>
                            </mat-select>
                         </mat-form-field>
                    </div>  
                </div>
                


            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="Save()">Save</button>

            </div>
        </form> 
    </div>
</div>  

<ngx-spinner size="default" type="ball-spin-clockwise" name='candidate-position-loader'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>
