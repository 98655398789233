<!-- start::FILTERS & GROUP ACTIONS -->
<!-- <div class="form mb-3">
    <div class="form-filtration mb-6">
        <div class="row align-items-center">
            <div class="col-md-2 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue">
                    <mat-hint align="start">Search in all fields</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-7 kt-margin-bottom-10-mobile"></div>
            <div class="col-md-3 text-right kt-margin-bottom-10-mobile">
                <button type="button" mat-raised-button color="primary" (click)="addWhatsApp();">Add via Whatsapp</button>
                <button type="button" class="ml-2" mat-raised-button color="primary" (click)="directAdd();">Add Candidate</button>
            </div>

        </div>
    </div>
</div> -->
<!-- end::FILTERS & GROUP ACTIONS -->

<!-- AG GRID MODULE START -->
<!-- <ag-grid-angular
    class="ag-theme-alpine"
    [columnDefs]="inProcessColumnDefs"
    [defaultColDef]="inProcessDefaultColDef"
    [rowDragManaged]="true"
    [animateRows]="true"
    [domLayout]="domLayout"
    (rowDragEnd)="onBtForEachNode()"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    (gridReady)="onGridReady($event)"
>
</ag-grid-angular> -->


<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">
    <mat-tab label="In Process ({{inProcessCount}})" >
        <div class="example-large-box">
            <div class="form mb-3">
                <div class="form-filtration mb-6">
                    <div class="row align-items-center">
                        <div class="col-md-2 kt-margin-bottom-10-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue">
                                <mat-hint align="start">Search in all fields</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-7 kt-margin-bottom-10-mobile"></div>
                        <div class="col-md-3 text-right kt-margin-bottom-10-mobile">
                            <button type="button" mat-raised-button color="primary" (click)="addWhatsApp();" class="d-none" >Add via Whatsapp</button>
                            <button type="button" class="ml-2" mat-raised-button color="primary" (click)="directAdd();">Add Candidate</button>
                        </div>
            
                    </div>
                </div>
            </div>
            <ag-grid-angular
                class="ag-theme-alpine"
                [columnDefs]="inProcessColumnDefs"
                [defaultColDef]="inProcessDefaultColDef"
                [rowDragManaged]="true"
                [animateRows]="true"
                [domLayout]="domLayout"
                (rowDragEnd)="onBtForEachNode()"
                [gridOptions]="gridOptions"
                [rowData]="rowData"
                [pagination]="true"
                [paginationPageSize]="paginationPageSize"
                (gridReady)="inprocessOnGridReady($event)"
                >
            </ag-grid-angular> 
        </div>
    </mat-tab>
    <mat-tab label="Offered / Joined ({{offeredJoindCount}})">
        <div class="example-large-box">
            <ag-grid-angular
                class="ag-theme-alpine"
                [columnDefs]="joinedColumnDefs"
                [defaultColDef]="joinedDefaultColDef"
                [rowDragManaged]="true"
                [animateRows]="true"
                [domLayout]="domLayout"
                (rowDragEnd)="onBtForEachNode()"
                [gridOptions]="gridOptions"
                [rowData]="joinedRowData"
                [pagination]="true"
                [paginationPageSize]="paginationPageSize"
                (gridReady)="joinedOnGridReady($event)"
                >
            </ag-grid-angular>
        </div>
    </mat-tab>
    <mat-tab label="Rejected/Not Joined ({{rejectedCount}})">
        <div class="example-large-box">
            <ag-grid-angular
                class="ag-theme-alpine"
                [columnDefs]="rejectColumnDefs" 
                [defaultColDef]="rejectDefaultColDef" 
                [rowDragManaged]="true"
                [animateRows]="true"
                [domLayout]="rejectDomLayout"
                (rowDragEnd)="onBtForEachNode()"
                [gridOptions]="gridOptions"
                [rowData]="rejectRowData"
                [pagination]="true"
                [paginationPageSize]="paginationPageSize"
                (gridReady)="rejectOnGridReady($event)"
                >
            </ag-grid-angular>
        </div>
    </mat-tab>
  </mat-tab-group>

<ngx-spinner size="default" type="ball-spin-clockwise" name='recruitment-details-candidte-list'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>

