<div class="example-preview">
    <form  [formGroup]="localizationForm" autocomplete="off" >           
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-15">Country</label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid input-100">
                    <mat-select formControlName="countryName">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="countryNameFilter" ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let country of countryList| search : countryNameFilter.value :'name'" [value]="country.id">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Select country</mat-hint>
                    <mat-error *ngIf="isControlHasError('countryName', 'required')">Country is required</mat-error>
                </mat-form-field>
           </div> 
        </div>

        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-15">Time Zone</label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid input-100">
                    <mat-select formControlName="timeZone">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="timeZoneFilter" ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let zone of timeZoneList| search : timeZoneFilter.value :'name'" [value]="zone.id">
                            {{zone.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Select Time Zone</mat-hint>
                    <mat-error *ngIf="isControlHasError('timeZone', 'required')">Time Zone is required</mat-error>
                </mat-form-field>
           </div> 
        </div>

        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-15">Language</label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid input-100">
                    <mat-select formControlName="language">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="languageFilter" ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let lang of languageList| search : languageFilter.value :'name'" [value]="lang.id">
                            {{lang.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Select Language</mat-hint>
                    <mat-error *ngIf="isControlHasError('language', 'required')">Language is required</mat-error>
                </mat-form-field>
           </div> 
        </div>

        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">System Name</label>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="standard" class="input-100" >
                    <input matInput #systemNameInput placeholder="" formControlName="systemName" ktAutoTrimSpaces>
                    <mat-hint align="end">{{systemNameInput.value?.length || 0}}/50</mat-hint>    
                    <mat-error *ngIf="isControlHasError('systemName', 'required')">System Name is required</mat-error>
                    <mat-error *ngIf="isControlHasError('systemName', 'maxlength')"><strong>Maximum 100 characters</strong></mat-error>
                    <mat-icon *ngIf="isControlHasError('systemName', 'required') == false;" matSuffix [ngStyle]="{'color':'blue'}"></mat-icon>
                </mat-form-field>  
           </div> 
        </div>

        
      
        <div class="row" ktMargin [marginBottom]="'20px'"></div>

        <div class="modal-footer pr-0">
            <button type="button" class="btn btn-secondary" (click)="ResetForm()">Cancel</button>
            <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
        </div>
        
    </form> 
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
