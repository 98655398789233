import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { localization } from '../models/localization.model';
import { environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(private http:HttpClient) { }

  getLocalization(projectId){
    console.log(environment.baseUrl + '/setting/api/projects/'+projectId);
    return this.http.get<any[]>(environment.baseUrl + '/setting/api/projects/'+projectId);
  }

  updateLocalization(selectedCountryId : number,selectedTimeZoneId : number,
    selectedLanguageId : number,projectId: number,systemName: string){

   // return this.http.post<any[]>(environment.baseUrl+'/setting/api/projects/{'+this.selectedProject,localizationData);
   return this.http.post<any[]>(environment.baseUrl+'/setting/api/projects/'+projectId,{"selectedCountryId" : selectedCountryId,"selectedTimeZoneId":selectedTimeZoneId,"selectedLanguageId":selectedLanguageId,"projectId" : projectId,"systemName":systemName});
  }

  // Opportunity Setting Service
  saveOpportunitySettings(addSettingValues){
    return this.http.post<any>(environment.baseUrl +'/setting/api/opportunity/addUpdateOpportunitySetting',addSettingValues)
  }

  getOpportunitySettings(projectId:number){
    return this.http.post<any[]>(environment.baseUrl +'/setting/api/opportunity/getOpportunitySetting',{"projectId": projectId})
  }

  // Contact Setting Service
  saveContactSettings(addSettingValues ){
    return this.http.post<any>(
      environment.baseUrl +'/setting/api/contact/addUpdateContactSetting',addSettingValues)
  }
  
  getContactSettings(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl +'/setting/api/contact/getContactSetting?projectId='+projectId);
  }

  // Company Setting Service
  saveCompanySettings(addSettingValues ){
    return this.http.post<any>(
      environment.baseUrl +'/setting/api/company/saveCompanySettings',addSettingValues)
  }

  getCompanySettings(projectId:number){
    return this.http.post<any[]>(
      environment.baseUrl +'/setting/api/company/getCompanySettings',{"projectId": projectId})
  }

  getModulesDynamicName(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl +'/setting/api/projects/'+projectId+'/dynamicFieldNames')
  }

}
