import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyInfoModel } from '../models/company-info.model';
import { CompanyActivitiesModel } from '../models/company-activities.model';
import { CompanyContactsModel } from '../models/company-contacts.model';
import { CompanyOpportunitiesModel } from '../models/company-opportunities.model';
import { CompanyStatsModel } from '../models/company-stats.model';
import { environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyDashboardService {

  constructor(private http:HttpClient) { }


  getCompanyInfo(projectId: number, companyId: number){
    return this.http.get<CompanyInfoModel[]>(
      environment.baseUrl + '/reports/api/company/dashboard/getCompanyInfo?projectId='+projectId+'&companyId='+companyId
      
      );
  }

  getCompanyContacts(projectId: number, companyId: number){
    console.log('dashbord---1')
    return this.http.get<CompanyContactsModel>(
      
      environment.baseUrl + '/reports/api/company/dashboard/getContacts?projectId='+projectId+'&companyId='+companyId
      
      );
  }

  getCompanyOpportunities(projectId: number, companyId: number){
    return this.http.get<CompanyOpportunitiesModel[]>(
      environment.baseUrl + '/reports/api/company/dashboard/getOpportunities?projectId='+projectId+'&companyId='+companyId
    );
  }

  getCompanyActivities(projectId: number, companyId: number){
    return this.http.get<CompanyActivitiesModel>(
      environment.baseUrl + '/reports/api/company/dashboard/getActivities?projectId='+projectId+'&companyId='+companyId
    );
  }

  getCompanyStats(projectId: number, companyId: number){
    console.log('1----------------1');
    return this.http.get<CompanyStatsModel[]>(
      environment.baseUrl + '/reports/api/company/dashboard/getStats?projectId='+projectId+'&companyId='+companyId
      
      );
  }
}
