import { ConditionalExpr } from '@angular/compiler';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ktStrictNumberOnlyDirective]'
})
export class StrictNumberOnlyDirectiveDirective {

  private regex: RegExp = new RegExp('^[0-9]*$');
  private specialKeys: Array<String> = ['Backspace', 'ArrowLeft','ArrowRight'];
  private ctrlDown = false;

  constructor(private elementRef: ElementRef) { }

 @HostListener('keydown',['$event'])onkeyDown(event: KeyboardEvent){
    
    if(event.key == 'Control'){
      this.ctrlDown = true;
    }

    if(this.specialKeys.indexOf(event.key) !== -1){
      return;
    }

    const inputValue: string= this.elementRef.nativeElement.value.concat(event.key);
    //console.log(this.ctrlDown,inputValue)
    if(this.ctrlDown && event.keyCode == 86){
      return; 
    }
    else if(this.ctrlDown && event.keyCode == 67){
      return;
    }
    else if(inputValue && !String(inputValue).match(this.regex)){
      event.preventDefault();
    }
    return;
  }


  @HostListener('keyup',['$event'])onkeyup(event: KeyboardEvent){
    if(event.key == 'Control'){
      this.ctrlDown = false;
    }

  }

  @HostListener('paste',['$event']) onPaste(event){ 
    console.log('paste', event)
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    console.log(clipboardData)
    if(clipboardData){
      const regEx= new RegExp('^[0-9]*$');
      if(!regEx.test(clipboardData)){
        event.preventDefault();
      }
    }
    return;
  }
}
