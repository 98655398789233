import { AfterViewInit, Component, ElementRef, OnInit, ViewChild ,ViewEncapsulation} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Contact } from '../models/contact.model';
//material
import { MatTableDataSource} from '@angular/material/table';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AddContactService } from '../services/add-contact.service';

@Component({
  selector: 'kt-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddContactComponent implements OnInit {
  errorMes:String="";
  primaryContactVal=false;
  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  disabled:boolean;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  public addContactForm: FormGroup;

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    private add: AddContactService,
    public activeModal: NgbActiveModal) {
       this.unsubscribe = new Subject();
   }

  ngOnInit(): void {
    this.initaddContactForm();
    this.disabled=false;
  }

  ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
  }

  initaddContactForm(){
    this.addContactForm = this.fb.group({
      contactTypeName: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ])
      ],
      contactCheck:[]
     });
  }

  Save(){
    this.disabled=true;
    this.errorMes="";
    const controls = this.addContactForm.controls;
      if (this.addContactForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled=false;
        return;
      }
      const addRedcordData = {
        contactTypeName: controls.contactTypeName.value,
        primary_contact: this.primaryContactVal,
      };
  
    var primaryContact: string = addRedcordData.primary_contact.toString();
    var isPrimary=0;
    if(primaryContact =='true'){
      isPrimary = 1;
    }
    var isActive=1;
    
      this.add
        .addContact(addRedcordData.contactTypeName,isActive,isPrimary, this.selectedProject)
        .pipe(
          tap(contact => {
            this.loading = false;
            this.activeModal.close();
            this.addContactForm.reset();
          }),
          takeUntil(this.unsubscribe),
          finalize(() => {
            
          })
        )
        .subscribe(
          data => console.log("sucesssfulll called API"),
          error => {
            this.errorMes=error.error.responseData;
            this.disabled=false;
          }
          
        );
  }
  
Close(){
  this.activeModal.close();
  this.addContactForm.reset();
}

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addContactForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  
  showOptions(val) {
    this.primaryContactVal=val;
  }

  isDisabled() : boolean {
    return this.disabled;
   }

}
