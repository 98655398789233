<div class="example-preview">
    <div>
        <form [formGroup]="candidateFilterForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Candidate Search</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="col-form-label form-padding-top-15">City</label>
                    </div>
                    <div class="col-md-9">
                        <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                            <mat-select formControlName="cityName">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName">
                                    {{city.cityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> 

                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="col-form-label form-padding-top-25">Job Type</label>
                    </div>
                    <div class="col-md-9">
                        <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                            <mat-select formControlName="jobType" multiple>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="jobTypeFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let job of Jobtype| search : jobTypeFilter.value :'jobTypeName'" [value]="job.jobTypeName">
                                    {{job.jobTypeName}}
                                </mat-option>
                            </mat-select>
                         </mat-form-field>
                    </div>  
                </div>

                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="col-form-label form-padding-top-25">Name</label>
                    </div>
                    <div class="col-md-9">
                        <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                            <input matInput placeholder="Name" formControlName="searchCandidateName" ktAutoTrimSpaces>
                            <mat-label style="color: rgba(0,0,0,.54);">Name </mat-label>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="col-form-label form-padding-top-25">Mobile</label>
                    </div>
                    <div class="col-md-9">
                        <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                            <input matInput  formControlName="mobileNumber" (input)="onlyNumber($event.target.value)" maxlength="10" minlength="10">
                            <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                        </mat-form-field>          
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="col-form-label form-padding-top-25">Email</label>
                    </div>
                    <div class="col-md-9">
                        <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                            <input matInput formControlName="email">
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">email</mat-icon>
                            <mat-error *ngIf="isControlHasError('email', 'pattern')">Email address format is incorrect</mat-error> 
                        </mat-form-field>                    
                    </div>
                </div>

                <!-- <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div> -->

            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="SearchCandidate()">Apply</button>
            </div>
        </form> 
    </div>
</div>  

<ngx-spinner size="default" type="ball-spin-clockwise" name='candidate-filter-loader'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>
