import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html',
  styleUrls: ['./edit-cell-renderer.component.scss']
})
export class EditCellRendererComponent implements OnInit {

  data:any;
  params:any;
  openingId: number;

  constructor() { }

  agInit(params){
    //console.log('params-----', params)
    /*this.params = params;
    this.data = params.value;
    this.openingId = params.data.id;*/

    if(params.data == null){
      return null;
    }else{
      this.params = params;
      this.data = params.value;
      this.openingId = params.data.id;
    }

   
  }

  ngOnInit(): void {
  }

  public openPositionDetailsPage(){
    this.params.context.componentParent.openPositionDetails(this.params.data);
  }

  public updateRecruiterMethod() {
    this.params.context.componentParent.UpdateRecruiter(this.params.data);
  }

}
