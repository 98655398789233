<div class="example-preview">
    <div>
        <form [formGroup]="editFieldForm" novalidate autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Edit Field</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Name</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input1  maxlength="50" formControlName="attributeName" [(ngModel)]="attributeName" ktAutoTrimSpaces>
        
                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input1.value?.length || 0}}/50</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeName', 'required')">Field Name cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('attributeName', 'maxlength')"><strong>Maximum 50 characters</strong></mat-error>
                            <mat-icon  *ngIf="disableAttributeName" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                            <mat-icon *ngIf="disableAttributeName== false" matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Instructions</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input2  maxlength="200" formControlName="instructions" [(ngModel)]="instructions" ktAutoTrimSpaces>
                            <mat-hint>Enter the instructions to show for adding data in this field (optional)</mat-hint>
                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input2.value?.length || 0}}/200</mat-hint>
                             <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> 
                        </mat-form-field>                    
                    </div>
                </div>

                <div *ngIf="displayFields">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>New / Existing</label>
                        </div>
    
                            <div class="col-md-8">
                                <mat-radio-group formControlName="fieldValue" fxLayout="column" fxLayoutGap=".25rem" >
                                <mat-radio-button [value]="'1'">New Field</mat-radio-button>
                                <mat-radio-button [value]="'2'">Link from other entities</mat-radio-button>
                                </mat-radio-group>
                            </div>    
                    </div>
                </div>

                <div *ngIf="isDisplayexistings()">
                
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Entity</label>
                        </div>
                        <div class="col-md-8">
                            <!--<mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="entityValue" (selectionChange)="changeEntity($event.value)" >
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="entityValueType" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let entityVal of entityTypeList; let i = index;" [value]="entityVal.id">
                                        {{entityVal.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select the entity</mat-hint>
                            </mat-form-field> -->
                            <mat-form-field appearance="standard" class="input-100" >
                                <input matInput #input1  maxlength="50" formControlName="entityValue" [(ngModel)]="entityValue" ktAutoTrimSpaces>
                                <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select the entity</mat-hint>
                            </mat-form-field>  
                        </div>  
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Entity Field</label>
                        </div>
                        <div class="col-md-8">
                           <!-- <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="linkedAttributeId" >
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="entityfieldType" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let fieldEntity of entityfieldList; let i = index;" [value]="fieldEntity.id">
                                        {{fieldEntity.attributeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select field of the entity that can be selected</mat-hint>
                            </mat-form-field> -->
                            <mat-form-field appearance="standard" class="input-100" >
                                <input matInput #input12  maxlength="50" formControlName="linkedAttributeId" [(ngModel)]="linkedAttributeId">
                                <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select field of the entity that can be selected</mat-hint>
                            </mat-form-field>
                        </div>  
                    </div>
                </div>

                <div *ngIf="isDisplaySelection">
                
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Selection Type</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="selectionType" (selectionChange)="changeSelectionType($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="selectionTypeValue" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let attrType of selectionTypeList | search : selectionTypeValue.value :'typeName'" [value]="attrType.id">
                                        {{attrType.typeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select from the list</mat-hint>
                            </mat-form-field>
                        </div>  
                    </div>
                </div>    

                 

                <div  *ngIf="isDisplayexistings() == false" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Type</label>
                   </div>
                   <div class="col-md-8">
                      <!--  <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                            <mat-select formControlName="attributeType" (selectionChange)="change($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="attributeFilterType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let attributeType of attributeTypelist| search : attributeFilterType.value :'typeName'" [value]="attributeType.id">
                                    {{attributeType.typeName}}
                                </mat-option>
                            </mat-select>
                          <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field</mat-hint>  
                            <mat-error *ngIf="isControlHasError('attributeType', 'required')">Field Type is required</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>  -->

                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput formControlName="attributeType" [(ngModel)]="attributeTypeVal">
                            <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>   
                   </div>  
                </div>
                
                <div *ngIf="displaySingleSelectDiv">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <mat-chip-list #chipList aria-label="Enter Value" required
                                formControlName="attributeSingleName">
                                    <mat-chip *ngFor="let lookup of lookupVal" 
                                            [selectable]="selectable"
                                            [removable]="removable" 
                                            (removed)="remove(lookup)">
                                            {{lookup.lookupValue}}
                                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input
                                           formControlName="attributeSingleName"
                                           [matChipInputFor]="chipList"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="add($event)" ktAutoTrimSpaces>
                                  </mat-chip-list>
                                  <mat-icon  *ngIf="disableattributeSingleName == false" matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> 
                                  <mat-hint *ngIf="disableattributeSingleName == false" style="color: rgba(0,0,0,.54);">Enter comma separated values. Unique values only.</mat-hint>
                                  <mat-error *ngIf="isControlHasError('attributeSingleName', 'required')">Options cannot be blank</mat-error>
                                  <mat-icon  *ngIf="disableattributeSingleName" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="displaySingleSelectDivTable" formArrayName="OptionsItems">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options</label>
                        </div>
                        <div class="col-md-8">
                            <table class="style">
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                    
                                    <td>
                                        <mat-form-field appearance="standard" class="inputbox"> 
                                            <mat-label style="color: rgba(0,0,0,.54);">Value</mat-label>
                                            <input matInput #input4 formControlName="OptionVal"  maxlength="25">  
                                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input4.value?.length || 0}}/25</mat-hint>
                                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                                            <mat-error>Value can not be blank</mat-error> 
                                            <!--<mat-error *ngIf="item.controls.OptionVal.invalid">
                                                Unit name is required.        
                                            </mat-error>  --> 
                                        </mat-form-field>
                                    </td> 
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                    <td>
                                        <mat-form-field appearance="standard" class="mat-form-field-fluid inputSelect ">
                                            <mat-label style="color: rgba(0,0,0,.54);">Stage</mat-label>
                                            <mat-select formControlName="stageId">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="stageFilterType" ></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let StageType of stageTypeList| search : stageFilterType.value :'value'" [value]="StageType.id">
                                                    {{StageType.value}}
                                                </mat-option>
                                
                                            </mat-select>
                                            <mat-error>Select one</mat-error>
                                         </mat-form-field>
                                    </td><td>&nbsp;&nbsp;&nbsp;&nbsp; </td>

                                        <!--<td style="width:90px;"> 
                                            <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                                <i class="fas fa-plus" (click)="addTable()"></i>
                                            </button>
                                        </td><td>    
                                            <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3" *ngIf="editFieldForm.controls.OptionsItems.controls.length > 1">
                                                <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                            </button>
                                           
                                        </td> -->
                                        <td style="text-align: right;"> 
                                            <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                                <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                            </button>
                                            
                                        </td>
                                        
                                </tr>
                                <tr><td></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                    <td></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                    <td style="text-align: right;">    
                                        <!--<button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3" *ngIf="editFieldForm.controls.OptionsItems.controls.length > 1">
                                            <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                        </button>  -->
                                        <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                            <i class="fas fa-plus" (click)="addTable()"></i>
                                        </button>
                                    </td>
                                </tr> 
                            
                            </table>                   
                        </div>
                    </div>
                </div>

                <div *ngIf="displaySingleContactDivTable" formArrayName="OptionsItems">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options</label>
                        </div>
                        <div class="col-md-8">
                            <table class="style">
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                    
                                    <td>
                                        <mat-form-field appearance="standard" class="inputbox"> 
                                            <mat-label style="color: rgba(0,0,0,.54);">Value</mat-label>
                                            <input matInput #input4 formControlName="OptionVal"  maxlength="25"  ktAutoTrimSpaces>  
                                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input4.value?.length || 0}}/25</mat-hint>
                                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                                            <mat-error>Value can not be blank</mat-error>
                                        </mat-form-field>
                                    </td> 
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                    <td>
                                        <mat-form-field appearance="standard" class="mat-form-field-fluid inputSelect form-padding-top-10">
                                            <mat-label style="color: rgba(0,0,0,.54);">Primary Contact</mat-label>
                                            <mat-select formControlName="stageId" >
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="contactFilterType" ></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let contact of primaryContactTypeList| search : contactFilterType.value :'value'" [value]="contact.id">
                                                    {{contact.value}}
                                                </mat-option>
                                
                                            </mat-select>
                                            <mat-error>Select one</mat-error>
                                         </mat-form-field>
                                    </td><td>&nbsp;&nbsp;&nbsp;&nbsp; </td>

                                        <td style="text-align: right;"> 
                                            <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                                <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                            </button>
                                            
                                        </td>
                                        
                                </tr>
                            <tr><td></td>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                <td></td>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                <td style="text-align: right;">    
                                    <!--<button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3" *ngIf="editFieldForm.controls.OptionsItems.controls.length > 1">
                                        <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                    </button>  -->
                                    <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                        <i class="fas fa-plus" (click)="addTable()"></i>
                                    </button>
                                </td>
                            </tr> 
                            
                            </table>                   
                        </div>
                    </div>
                </div>
                    
                <div *ngIf="displayMultiSelectDiv">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <mat-chip-list #chipList aria-label="Enter Value" required
                                formControlName="attributeMultiSelectName">
                                    <mat-chip *ngFor="let lookup of lookupVal" [selectable]="selectable"
                                             [removable]="removable" (removed)="remove(lookup)">
                                            {{lookup.lookupValue}}
                                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input 
                                           formControlName="attributeMultiSelectName"
                                           [matChipInputFor]="chipList"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="add($event)" ktAutoTrimSpaces>
                                  </mat-chip-list>
                                  <mat-icon  matSuffix [ngStyle]="{'color':'gray'}">text_fields</mat-icon> 
                                  <mat-hint style="color: rgba(0,0,0,.54);">Enter comma separated values. Unique values only.</mat-hint>
                                  <mat-error *ngIf="isControlHasError('attributeMultiSelectName', 'required')">Options cannot be blank</mat-error>
                               
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="displayDateValueDiv">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Value Type</label>
                        </div>

                        <div class="col-md-8">
                            <mat-radio-group formControlName="dateValueType" fxLayout="column" fxLayoutGap=".25rem" [(ngModel)]="dateValueType">
                            <mat-radio-button [value]="'Standard'">Standard</mat-radio-button>
                            <mat-radio-button [value]="'Custom'">Custom</mat-radio-button>
                            <!--   <mat-hint>Select the value type for date field</mat-hint>  -->
                            <mat-error class="form-error" *ngIf="isControlHasError('dateValueType', 'required')">Select one</mat-error>
                                </mat-radio-group>
                        </div>    
                    </div>
                </div>
                <div *ngIf="isDisplayStandard()">
                
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Value</label>
                    </div>
                    <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="standardTypeValue" (selectionChange)="changeStd($event.value)" >
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="standardFilterType" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let standardTypeValue of standardTypelist; let i = index;" [value]="standardTypeValue.id">
                                        {{standardTypeValue.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select standard date field from the list</mat-hint>
                            </mat-form-field>
                    </div>  
                    </div>
                </div>
                <div *ngIf="isDisplayCustom()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Previous Dates Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="previousDateValue"  ktStrictNumberOnlyDirective [(ngModel)]="previousDateValue">
                                <mat-hint>Number of days, blank defaults to no limit (optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Future Dates Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="futureDateValue"  ktStrictNumberOnlyDirective [(ngModel)]="futureDateValue">
                                <mat-hint>Number of days, blank defaults to no limit (optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>
                <div style="height: 20px;"></div>
                <div>
                    <div class="form-group row mt-2">
                        <div class="col-md-4">
                            <label>Mandatory</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox [disabled]="disableMandatoryCheck" (change)='showOptionsEdit(checkbox.checked)'  [checked]="flag_Value==true" [(value)]=flag_Value>
                            </mat-checkbox>&nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" *ngIf="disableMandatoryCheck == false" class="form-error">Select to make this a mandatory field</mat-hint>
                        </div>
                    </div>
                </div>    

                <div *ngIf="isDisplayText">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Unique Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptionsUnique(checkbox.checked)' [checked]="unique_Value==true" [(value)]=unique_Value>
                            </mat-checkbox>  &nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select if this field should only allow unique values</mat-hint>
                       
                        </div>
                    </div> 

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Type of Characters</label>
                    </div>
                   <!-- <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="characterType" >
                                    
                                    <mat-option value=0>All</mat-option>
                                    <mat-option value=2>Alpha-numeric</mat-option>
                                    <mat-option value=3>Alphabets</mat-option>
                                    
                                </mat-select>
                            <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field </mat-hint>
                        </mat-form-field>
                    </div>  -->
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                            <mat-select formControlName="characterType" (selectionChange)="changeCharacterType($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="attributecharacterType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let characterType of characterTypeList| search : attributecharacterType.value :'value'" [value]="characterType.id">
                                    {{characterType.value}}
                                </mat-option>
                            </mat-select>
                            <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field</mat-hint>
                        </mat-form-field>
                   </div>  
                    </div>  

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Min Characters</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="minCharacters" ktStrictNumberOnlyDirective >
                                <mat-hint style="color: rgba(0,0,0,.54);">Enter minimum no of characters, blank defaults to no limit</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Characters</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="maxCharacters" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Enter maximum no of characters, blank defaults to no limit</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayImage">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Allow Browsing</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptions(checkbox.checked)' [checked]="browseVal==true" [(value)]=browseVal>
                            </mat-checkbox> <br>    
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select if user can choose image from gallery</mat-hint>
                        </div>
                    </div> 

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Image Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="maxImagesAllowed" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Number of maximum images to be taken, blank defaults to no limit</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayEmail">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Unique Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptionsUnique(checkbox.checked)' [checked]="unique_Value==true" [(value)]=unique_Value>
                            </mat-checkbox>  &nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select if this field should only allow unique values</mat-hint>
                       
                        </div>
                    </div>
                </div>    

                <div *ngIf="isDisplayNumeric">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Unique Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptionsUnique(checkbox.checked)' [checked]="unique_Value==true" [(value)]=unique_Value>
                            </mat-checkbox>  &nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select if this field should only allow unique values</mat-hint>
                       
                        </div>
                    </div> 
                    
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Validation Type</label>
                    </div>
                    <div class="col-md-8">
                          <!--  <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="numericValidationType" (selectionChange)="changeValidation($event.value)">
                                    <mat-option value="1">Select</mat-option>
                                    <mat-option value="2">Value</mat-option>
                                    <mat-option value="3">Digit</mat-option>                                    
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field (Optional)</mat-hint>
                            </mat-form-field>  -->

                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="numericValidationType" (selectionChange)="changeValidation($event.value)" >
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="attributenumericValidationType" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let numericValidationType of numericValidationTypeList| search : attributenumericValidationType.value :'value'" [value]="numericValidationType.id">
                                        {{numericValidationType.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field (optional)</mat-hint>
                            </mat-form-field>
                    </div>  
                    </div>  
                </div>

                <div *ngIf="isDisplayNumericValue()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Minimum Value</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="numericMinValue" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Enter minimum no of value, blank defaults to no limit (optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Maximum Value</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="numericMaxValue" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Enter maximum no of value, blank defaults to no limit (optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayNumericDigit()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Minimum Digits</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="minDigits" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Enter minimum no of digits, blank defaults to no limit (optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Maximum Digits</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="maxDigits" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Enter maximum no of digits, blank defaults to no limit (optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplaySelectionLock">
                
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Selection Type</label>
                        </div>
                        <div class="col-md-8">
                           <!-- <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="selectionType" (selectionChange)="changeSelectionType($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="selectionTypeValue" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let attrType of selectionTypeList | search : selectionTypeValue.value :'typeName'" [value]="attrType.id">
                                        {{attrType.typeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select from the list</mat-hint>
                            </mat-form-field> -->

                            <mat-form-field appearance="standard" class="input-100" >
                                <input matInput #input1  maxlength="50" formControlName="selectionType" [(ngModel)]="selectionType">
                                <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                            </mat-form-field> 
                        </div>  
                    </div>
                </div>

                <div *ngIf="disableSelect == false" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Status</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="mat-form-field-fluid input-100" >
                            <mat-select formControlName="status" (selectionChange)="OpenAlert($event.value)">
                                <mat-option value="1" >Active</mat-option>
                                <mat-option value="0" >Inactive</mat-option>
                            </mat-select>
                            <mat-hint style="color: rgba(0,0,0,.54);" *ngIf="disableSelect == false">Select to change the status</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="disableSelect" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Status</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput formControlName="status" [(ngModel)]="statusVal">
                            <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field> 
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"  (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="Save()">Save</button>
            </div>
        </form> 
    </div>
</div>  
