import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http:HttpClient) { }

  getProductLookup(projectId: number){
    return this.http.get(environment.baseUrl +'/transactions/api/product/getProductLookup?projectId='+projectId);
  }
}