import { Overlay } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { AddFieldComponent } from '../add-field/add-field.component';
import { EditCellRendererComponent } from '../edit-cell-renderer/edit-cell-renderer.component';
import { EditFieldComponent } from '../edit-field/edit-field.component';
import { PreviewDisplayOrderComponent } from '../preview-display-order/preview-display-order.component';
import { FieldService } from '../services/field.service';
import { RelationshipDialogComponent } from '../Relationship/relationship-dialog/relationship-dialog.component';
import { EventEmitterService } from '../services/event-emitter.service';
import {MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SubheaderService } from '../../../../../app/core/_base/layout';
import { ManageActivitiesService } from '../../manage-activities/services/manage-activities.service';

@Component({
  selector: 'kt-fields-list',
  templateUrl: './fields-list.component.html',
  styleUrls: ['./fields-list.component.scss']
})
export class FieldsListComponent implements OnInit {

  @ViewChild('myButton') myButton : ElementRef;

  @Input() public parentData;
  @Input() public backBtn;
  @Input() public singularData;


  resultCustom: any;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  searchValue: any;
  gridOptions: any;
  frameworkComponents: any;
  public rowData;
  dataNode: any;
  moduleType : number;
  noRowsTemplate:any;

  modalReference: any;
  errorText = false;
  editCustomVal: CustomAttibute;
  public groupDefaultExpanded;
  public getDataPath;
  public components;
  public getRowNodeId;
  public autoGroupColumnDef;
  sequenceOrder:any;
  disabled:boolean;
  reorderFlag = false;
  singularName : string;
  currentRouteUrl: string;
  dataSize:number;
  addFlag: boolean;
  transFlag:number;
  activitiesList:any;
  activityName:string;
  linkedfieldList:any;

  constructor(
    private _field: FieldService,
    private modalService: NgbModal,
    private layoutUtilsService: LayoutUtilsService,
    public overlay: Overlay,
    public snackBar: MatSnackBar,
    private eventEmitterService: EventEmitterService,
    private location: Location,
    private router: Router,
    private subheaderService: SubheaderService,
    public dialog: MatDialog)
  { }

  ngOnInit(){
    
    this.addFlag = false;
    this.transFlag = 0;
    this.moduleType=this.parentData;
    this.singularName=this.singularData;
    this.linkedfieldList=[];

    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      if(this.moduleType === 1)
        this.subheaderService.setTitle('Contact');
      else if(this.moduleType === 2)
        this.subheaderService.setTitle('Customers');
      else if(this.moduleType === 3)
        this.subheaderService.setTitle('Opportunity');
      else if(this.moduleType === 4)
        this.subheaderService.setTitle('Products');
      else if(this.moduleType === 5)
        this.subheaderService.setTitle('Manage Recruitment');
      else 
        this.subheaderService.setTitle(this.singularName);
    }

    
    console.log('mod------'+this.moduleType);
    console.log('singull------'+this.singularName);
    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.modalResult(data);
      });
    }
    this.Custom();
    this.getCustom1(0,this.parentData);
    this.disabled=false;
  }


  onBtForEachNode() {
    console.log('### api.forEachNode() ###');
    this.gridApi.forEachNode(this.printNode);
  }
  
  getCustom1(editFlag:number,moduleType){
    this._field.getFields(moduleType, this.selectedProject).subscribe(
      (result)=>{

         if (!result || !result['responseData'].length) {
        this.gridOptions.suppressNoRowsOverlay = false;
        this.noRowsTemplate = '<span>There are no records.</span>';
        this.gridOptions.api.showNoRowsOverlay();
      } 

        result['responseData'].forEach(
          row => {
            row.attributeTypeName= row.attributeType.typeName;
          
            if(row.mandatoryFlag==1){
              row.mandatory ='Yes';
            } else {
              row.mandatory='No';
            }
            if(row.status==1){
              row.active ='Active';
            } else {
              row.active='Inactive';
            }

            if(row.linkedAttributeId !='' && row.linkedAttributeId!=null ){
              this.linkedfieldList.push({attributedId: row.linkedAttributeId});
            }
          }
        )
      this.resultCustom=result['responseData'];
      this.rowData =  this.resultCustom;
      if(editFlag == 0)
          this.dataSize = this.rowData.length;
    
      if(editFlag == 1){
        console.log(this.addFlag);
        this.gridApi.updateRowData({add: this.rowData});
        this.gridApi.refreshCells();
        this.gridReload(this.rowData.length);
      }

      let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
        el.click()
        }, 300);

    },
    error => {
      console.log(error)
    })
  }

  gridReload(rowLength: number){
    if(rowLength == this.dataSize && this.addFlag){
      this.getCustom1(1,this.parentData);
    } else {
      this.dataSize = rowLength;
      this.addFlag = false;
    }
  }

  /**
 * Quick Search
 */
  quickSearch(){
    this.gridApi.setQuickFilter(this.searchValue);
  }

  /**
   * UI Change for Status
   * @param status
   */
  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Inactive';
      case 1:
        return 'Active';
      case 2:
        return 'Pending';
    }
    return '';
  }


  AddCustomField(){
    this.addFlag = true;
    const _saveMessage = `Custom Field has been saved.`;
    const _messageType = MessageType.Create;
    const ref = this.modalService.open(AddFieldComponent,
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.moduleType=this.parentData;
    ref.componentInstance.linkedfieldList=this.linkedfieldList;

    ref.result.then((result) => {
      if(result == 'SUCCESS'){
        this.addFlag = true;
        this.snackBar.open('Field added.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.refreshGrid();
      }else if(result == 'ERROR'){
        this.snackBar.open('Unable to add. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    })
    
  }

  EditCustomField(customValues) {
    this.addFlag = false;
    const _saveMessage = `Custom Field has been saved.`;
    const _messageType = MessageType.Create;
    
    this.editCustomVal=customValues;
    
    const ref = this.modalService.open(EditFieldComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.editCustomVal=customValues; 

    ref.result.then((result) => {
      if(result == 'SUCCESS'){
        this.addFlag = false;
        this.snackBar.open('Field updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.refreshGrid();
      }else if(result == 'ERROR'){
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    })

  }

  editRelationship(customValues) {
    const _saveMessage = `Relationship has been saved.`;
    const _messageType = MessageType.Create;
  
    this.editCustomVal=customValues;
    this.editCustomVal['moduleId']=this.parentData; 
    
    const ref = this.modalService.open(RelationshipDialogComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.editCustomVal=customValues; 
   
    ref.result.then((result) => {
      if(result === 'RELATIONSHIP'){
        this.snackBar.open('Field updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.refreshGrid();
        setTimeout(()=>{
          this.SaveDisplayOrder(0);
        },2000)
      }else if(result == 'ERROR'){
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
      
    });
    
  }

 
  refreshGrid(){
    console.log(this.addFlag);
    console.log(this.transFlag);

    this.gridApi.setRowData([]);
    this.getCustom1(1,this.parentData);  
  }

  modalResult(result: string) {
  /*  if(result === 'SUCCESS'){
      this.refreshGrid();
    }

    if(result === 'RELATIONSHIP'){
     
      this.refreshGrid();
      setTimeout(()=>{
        
        this.SaveDisplayOrder(0);

      },2000)
    } */
  }

  Custom(){
    this.columnDefs = [
      {
        headerName: 'Field Type',
        field: 'attributeTypeName',
        minWidth: 250,
      },
      {
        field: 'mandatory',
        minWidth: 250,
      },
      {
        headerName: 'Status',
        field: 'active',
        minWidth: 100,

        cellRenderer: function(params) {
          if(params.value == 'Active'){
            if(params.data.fieldSpec=='0'){
            return '<button class="no-click label label-lg btn btn-secondary label-inline">' + params.value + '</button>';
            }else{
              return '<button class="no-click label label-lg btn btn-success label-inline">' + params.value + '</button>';
          
            }
          }  
          else if(params.value == 'Inactive')
            return '<button class="no-click label label-lg btn btn-danger label-inline">' + params.value + '</button>';
          else if(params.value == 'Pending')
            return '<button class="no-click label label-lg btn btn-metal label-inline">' + params.value + '</button>';
          else
            return '';
        },
      },
      {
        headerName: 'Actions',
        field: '',
        maxWidth: 150,
        filter: false,
        sortable: false,
        cellRendererFramework: EditCellRendererComponent
      }
    ];
    this.defaultColDef = {
      sortable: false,
      resizable: false,
      editable: false,
      filter: false,
      flex: 1,
      minWidth: 150
    };
  
    this.groupDefaultExpanded = -1;
    this.getDataPath = function (data) {
    
      return data.path;
    };
    this.getRowNodeId = function (data) {
      return data.id;
    };
    this.domLayout = 'autoHeight';
    this.autoGroupColumnDef = {
      rowDrag: true,
      minWidth: 400,
    headerName: 'Field Name (in display order)',
    cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
          suppressCount: true
        } 
    };
    this.gridOptions={
      context : {
        componentParent: this
      }
    }

  }

  // SaveDisplayOrder(isDialog){
  //   console.log('display order api call');
  //   console.log(this.sequenceOrder);
  //   this._field.saveSequenceOrder(this.sequenceOrder).subscribe(
  //     data => {
      
  //       this.disabled=false;
  //       if(isDialog){
  //         this.snackBar.open('Field Order Updated Successfully', '', {
  //           duration: 2000,
  //         });
  //       }
        
  //       this.reorderFlag = false;
  //     },
  //     error => {
  //       this.disabled=false;
  //       console.log('error occured');
  //     }
  //   );
  // }

  SaveDisplayOrder(isDialog){
    let nodeData = [];
    this.gridApi.forEachNode(function(node) { 
      nodeData.push(node.data);
    });
 
   // this.gridApi.forEachNode(this.printNode);
 
    const orderData=[];
    var count=1;
 
    for(let items of nodeData){
      var id=items.id;
      var name=items.attributeName;
      orderData.push(
      {
        id:id,
        attributeName:name,
        displayOrder:count
      });
      count++;
    }
 
    this.sequenceOrder=orderData;
 
    console.log('display order api call');
    console.log(this.sequenceOrder);
    //  this.disabled=true;
    this._field.saveSequenceOrder(this.sequenceOrder).subscribe(
      data => {
      
        this.disabled=false;
        if(isDialog){
          this.snackBar.open('Display order updated.', '', {
            duration: 3000,
            panelClass: ['green-snackbar']
          });
        }
        
        this.reorderFlag = false;
      },
      error => {
        this.disabled=false;
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar'],
          verticalPosition:'bottom'
        });
        console.log('error occured');
      }
    );
  }


  isDisabled() : boolean {
    return this.disabled;
  }

  onRowDragEnd(event) {
    // this is the row the mouse is release
    var overNode = event.overNode;

    if (!overNode) {
      return;
    }

    // the data we want to move
    var movingData = event.node.data;

  //console.log('parent id-----'+event.node.parent.id);
  //console.log('overnide----'+ overNode.parent.id);

      if(event.node.parent.id == 'ROOT_NODE_ID' ||
        overNode.parent.id == event.node.parent.id) {
    
        var overData = overNode.data;

        var fromIndex = this.rowData.indexOf(movingData);
        var toIndex = this.rowData.indexOf(overData);

        var newStore = this.rowData.slice();
        this.moveInArray(newStore, fromIndex, toIndex);
        this.rowData = newStore;
        
        this.gridApi.setRowData(newStore);
        this.gridApi.clearFocusedCell();
      }

    //console.log('### api.forEachNode() ###');
    let nodeData = [];
    this.gridApi.forEachNode(function(node) { 
      nodeData.push(node.data);
    });

   // this.gridApi.forEachNode(this.printNode);

    const orderData=[];
    var count=1;

    for(let items of nodeData){
      var id=items.id;
      var name=items.attributeName;
      orderData.push(
      {
        id:id,
        attributeName:name,
        displayOrder:count
      });
      count++;
    }

    this.sequenceOrder=orderData;
    //console.log(this.sequenceOrder);
    this.reorderFlag = true;
  }

  printNode(node, index) {
      if (node.group) {
        console.log(index + ' -> group: ' + node.key);
      } else {
      console.log(
      index + ' -> data: ' + node.data.id + ', ' + node.data.displayOrder
      );
    }
  }


  moveInArray(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  showDisplayList(){
   
    const target = document.querySelector("#btn") as HTMLElement;
        const overlayRef = this.overlay.create({
          hasBackdrop: true,
          backdropClass: "cdk-overlay-transparent-backdrop",
          panelClass: "mat-elevation-z8",
          positionStrategy: this.overlay
            .position()
            .flexibleConnectedTo(target)
            .withPositions([
              {
                originX: "start",
                originY: "bottom",
                overlayX: "start",
                overlayY: "top"
              }
            ])
        });
        const component = new ComponentPortal(PreviewDisplayOrderComponent);
        const componentRef = overlayRef.attach(component);
        overlayRef.backdropClick().subscribe(() => overlayRef.detach());
    }

  showDisplayList2(){
    this.moduleType=this.parentData;
  // let dialogRef: FilePreviewOverlayRefComponent = this.previewDialog.open({}, this.previewDialog);
  const target = document.querySelector("#btn") as HTMLElement;
      const overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: "cdk-overlay-transparent-backdrop",
        panelClass: "mat-elevation-z8",
        disposeOnNavigation: true,
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(target)
          .withPositions([
            {
              originX: "start",
              originY: "bottom",
              overlayX: "start",
              overlayY: "top"
            }
          ])
      });
      const component = new ComponentPortal(PreviewDisplayOrderComponent);
      const componentRef = overlayRef.attach(component);
      componentRef.instance.moduleType=this.parentData;
      
     // overlayRef.detach();
      overlayRef.backdropClick().subscribe(() => overlayRef.detach());
      setTimeout(() => {
        overlayRef.detach();
      }, 2000);
  }


  showList(){
    this.modalReference = this.modalService.open(PreviewDisplayOrderComponent,
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
  }


  goBack() {
    if(this.reorderFlag){
      this.openDialog()
    } else {
      this.location.back();
    }
  }

  openDialog() {
    const message = `You have not saved the changes for the reorder or field. Do you want to save?.`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: { title: 'Confirm Action', body: message }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.SaveDisplayOrder(1);
        this.location.back();
      } else {
        this.location.back();
      }
    });
    
  }


  triggerClick() {}


}

var valueFormatter = function (params) {
  return params.value ? params.value + ' MB' : '';
};
function moveToPath(newParentPath, node, allUpdatedNodes) {
  var oldPath = node.data.path;
  var fileName = oldPath[oldPath.length - 1];
  var newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
}
function isSelectionParentOfTarget(selectedNode, targetNode) {
  var children = selectedNode.childrenAfterGroup;
  for (var i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) return true;
    isSelectionParentOfTarget(children[i], targetNode);
  }
  return false;
}
function arePathsEqual(path1, path2) {
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
}


