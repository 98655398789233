import { Component, OnInit } from '@angular/core';
import { CandidateService } from '../../Services/candidate.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { Jobtype } from '../../Models/job-type-list.model';
//import { Candidateposition } from '../../Models/candidate-position-list.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-filter-candidate',
  templateUrl: './filter-candidate.component.html',
  styleUrls: ['./filter-candidate.component.scss']
})
export class FilterCandidateComponent implements OnInit {

  modalReference: any;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public candidateFilterForm: FormGroup;
  searchCandidateName = '';
  Jobtype: Jobtype[];
  public cityNameFilter: FormControl = new FormControl();
  public jobTypeFilter: FormControl =new FormControl();
  disabled:boolean;
  errorMes:String="";
  cityList:[];

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public candidateService: CandidateService,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog){ }

  ngOnInit(): void {
    this.inCandidateFilterForm();
    this.getCandidateJobType();
    this.getAllCities();
  }

  inCandidateFilterForm(){
    this.candidateFilterForm = this.fb.group({
      searchCandidateName:[''],
      cityName:[''],
      jobType:[''],
      mobileNumber:[''],
      email:['',Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
     });
  }

  getCandidateJobType(){
    var localStorageJobType = localStorage.getItem('jobType');
    this.Jobtype = JSON.parse(localStorageJobType);
  }

  getAllCities(){
    this.candidateService.getAllCity(this.selectedProject).subscribe( 
      (result)=>{
        console.log('all city', result);
        this.cityList=result['embedded']; 

      },
      error => {
        console.log(error)
      }
    )
  }

  SearchData(controls){
    const searchData = {  
        "city": controls.cityName.value,
        "jobType": controls.jobType.value,
        "name": controls.searchCandidateName.value,
        "mobile": controls.mobileNumber.value,
        "email": controls.email.value,
      };    

      return searchData;
  }

  SearchCandidate(){
    const controls = this.candidateFilterForm.controls;
    if (this.candidateFilterForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    this.SpinnerService.show('candidate-filter-loader');
    const searchData=this.SearchData(controls);
      this.candidateService.filterCandidate(searchData).subscribe(
        data => {
          //console.log('searchdata',data);
          var searchResultData = data['embedded'];
          if(data['statusCode']==412){
            this.disabled=false;
            this.OpenAlert(data['message']);
          }else{
            this.activeModal.close();
            this.candidateFilterForm.reset();
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult(data);
          }
          this.SpinnerService.hide('candidate-filter-loader');
        },
        error =>{
          this.errorMes=error.error.responseData;
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('ERROR');
          this.SpinnerService.hide('candidate-filter-loader');
        }
      );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.candidateFilterForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  OpenAlert(message){
    const initialNotice = message;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      position: {top: '20px'},
      data: { title: 'Alert', body: initialNotice }
    });
  }

  Close(){
    this.activeModal.close();
    this.candidateFilterForm.reset();
  }

  isDisabled() : boolean {
    return this.disabled;
   }

   onlyNumber(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.candidateFilterForm.controls['mobileNumber'].setValue(onlyNumberValue);
  }

}
