<div class="card card-custom" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header pl-4 pr-5">
        <div class="card-title text-primary font-weight-bold font-size-h6"> Activities </div>
        <div class="card-toolbar">
            <div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline" title="Add Activity">
                <a ngbDropdownToggle 
                    class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary"
                    data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false">
                    <i class="fas fa-plus"></i>
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                    <!--<kt-add-activity [activityModule]="moduleString" [contactId]="parentData"></kt-add-activity>-->
                    <div class="act-add-list">
                        <div class="modal-header">
                            <h4 class="modal-title">Add Activity</h4>
                        </div>
                        <div class="modal-body">
                            <ng-container>
                                <div class="menu-submenu menu-submenu-classic menu-submenu-left" >
                                    <span class="menu-arrow menu-arrow-adjust"></span>
                            
                                    <ul  class="menu-subnav" *ngFor="let activity of rowData">
                                        <li class="menu-item" >
                                            <a class="menu-link pl-0" (click)="AddActivity(activity.activityId)" >
                                                <span  class="menu-text"> {{ activity.activityName}} </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>  
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <!-- Body -->
    <div class="card-body p-5 activity-scroll">
        <div class="font-size-h6 text-center" *ngIf="isError">{{errorMessage}}</div>
        <div class="timeline timeline-3">
            <div class="timeline-items">
                <!--begin::Item-->
                <div class="timeline-item" *ngFor="let activity of activityList">
                    <!--begin::Icon-->
                <div class="timeline-media {{activity.borderColor}} {{activity.bgLightClass}}" >
                        <span class="svg-icon svg-icon-primary {{activity.textClass}}">
                            {{activity.activityDoneByInitials}}
                        </span>
                    </div>
                    <!--end::Icon-->
        
                    <!--begin::Info-->
                    <div class="timeline-info h-100">
                        <table class="table table-borderless table-vertical-center">
                            <tbody>
                                <tr>
                                    <td class="p-0">
                                        <div class="font-weight-bold font-size-lg {{activity.textClass}}"> {{activity.name}} </div>
                                        <div class="timeline-label text-muted font-size-sm">{{activity.created}}</div>
                                    </td>
                                    <td class="text-right p-0" width="15%">
                                        <span class="label label-lg {{activity.lableLight}} label-inline">
                                            <i class="fas {{activity.iconClass}} mr-2"></i>
                                             {{activity.winProbability}}%
                                        </span>
                                    </td>
                                    <td class="text-right p-0" width="20%">
                                        <span class="label label-lg {{activity.statusLable}} label-inline">{{activity.status}}</span>
                                    </td>
                                    <td class="text-right p-0" width="6%">
                                        <div *ngIf="(activity.activityTypeName === 'Estimate' || activityTypeName === 'Order')">
                                            <!--<a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" href="javascript:void(0)">
                                                <i class="fas fa-download"></i>
                                            </a>-->
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="p-0">
                                        <div *ngIf="(activity.activityTypeName === 'Estimate' || activityTypeName === 'Order')">
                                            <img src="./assets/media/svg/icons/General/money_2.svg" class="pr-2"> 
                                                {{activity.estimatedRevenue}}
                                        </div>
                                        <div class="font-weight-normal text-dark-50" *ngIf="(activity.description != null)">
                                            {{activity.description}}
                                        </div>
                                        <div class="d-flex flex-column flex-grow-1">
                                            <span class="text-muted font-size-sm pt-2">
                                                <!--<img src="./assets/media/svg/icons/General/people_outline_24px.svg" class="pl-3 pr-4">-->
                                                <span class="label label-light-primary mr-2" *ngFor="let contact of activity.contacts" ngbTooltip="{{contact.name}}">
                                                    {{contact.initials}}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="symbol-list d-flex flex-wrap mt-2" *ngIf="activity.displayImage">
                                            <div class="symbol mr-2" *ngFor="let image of activity.images">
                                                <img alt="Pic" src="{{image}}"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--end::Info-->
                </div>
                <!--end::Item-->
            </div>
        </div>
        <!--end: Items-->
    </div>
</div>
<ngx-spinner size="default" type="ball-spin-clockwise" name="dashboard-activity">  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
