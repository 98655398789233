import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageActivitiesService } from '../../services/manage-activities.service';
import {MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../../shared/alert-dialog/alert-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:String="";
  unsubscribe: Subject<any>;
  disabled:boolean;
  addActivityForm: FormGroup;
  _onDestroy = new Subject<void>();
  allowCompanyName: string;
  allowOpportunityName: string;

  allowCompany: boolean;
  allowForCompany: boolean;
  allowOpportunity: boolean;
  allowForOpportunity: boolean;
  captureLiveLocation: boolean;
  geofence: boolean;
  opportunityEnabled: false;

  geofenceDistance: number | string;

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public dialog: MatDialog,
    public activityService: ManageActivitiesService,

    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    private router: Router  ) {
    this.unsubscribe = new Subject();
    }

  ngOnInit(): void {

    this.getdynamicFieldNames();

    this.disabled=false;
    this.geofence=false;
    this.SpinnerService.show('addActivity');

    this.initializeForm();
    var allowForCompanyVal=0;
    var allowForOpportunityVal =0;
    this.allowForCompany = allowForCompanyVal == 1 ? true : false;
    this.allowForOpportunity = allowForOpportunityVal == 1 ? true : false;
    this.captureLiveLocation = false;
  }

  getdynamicFieldNames(){
    this.activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result=>{
      result['responseData'].forEach(
        row => {

          if(row.moduleId==2){
            this.allowCompanyName =row.pluralName;
          } 
          if(row.moduleId==3){
            this.allowOpportunityName =row.pluralName;
            this.opportunityEnabled = row.opportunityEnabled;
          } 
        }
      )
    },
    err=>{
    }
  )
  }

  initializeForm() {
    this.addActivityForm = this.fb.group({
      activityName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
      allowForCompany: [],
      allowForOpportunity: [],
      captureLiveLocation: [],
      geofence:[],
      geofenceDistance:[]
     });

     this.SpinnerService.hide('addActivity');
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  Save(){
    this.disabled=true;
    const controls = this.addActivityForm.controls;
    if (this.addActivityForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    const addActivityData = this.assignActivityData(controls);

    this.SpinnerService.show('addActivity');

    this.activityService.saveActivity(addActivityData).subscribe(
      data => {
        const res = data['responseData'];
        console.log(res.activityId);
        this.activeModal.close();
        this.addActivityForm.reset();
        this.disabled=false;
        this.SpinnerService.hide('addActivity');
        this.eventEmitterService.onModalCloseResult('SUCCESS');
        var val='/activity-list/'+res.activityId;
        this.router.navigate([val]);
      },
      error => {
        console.log('error occured');
        this.SpinnerService.hide('addActivity');
        this.errorMes=error.error.responseData;
        this.disabled=false;
      }

    );
  }

  assignActivityData(controls) {

      const finalData = {
        'activityName': controls.activityName.value.trim(),
        'allowForCompanies': controls.allowForCompany.value == true ? 1 :0,
        'allowForOpportunities': this.opportunityEnabled ? (controls.allowForOpportunity.value == true ? 1 :0) : 0,
        'allowGeofence': controls.geofence.value == true ? 1 :0,
        'geofenceDistance': controls.geofenceDistance.value,
        'projectId': this.selectedProject,
        'captureLiveLocation': controls.captureLiveLocation.value == true ? 1 :0
      }

      return finalData;
  }

  Close(){
    this.activeModal.close();
    this.addActivityForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addActivityForm.controls[controlName];
      if (!control) {
        return false;
      }

      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  openDialog() {
    const controls = this.addActivityForm.controls;
    const checkbox = controls.allowForOpportunity.value;

    if(checkbox) {
      const initialNotice = `Please make sure that field ‘Select Opportunity’ is Active in field details.`;
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '600px',
        data: { title: 'Alert', body: initialNotice }
      });
    }
    this.enableOrDisableGeofence();
  }

  addGeofenceField(value){
    this.geofenceDistance="";
    if(value){
      this.addActivityForm.controls.geofenceDistance.setValidators([Validators.required]);
      this.addActivityForm.controls.geofenceDistance.updateValueAndValidity();
     // this.addCompanySettingsForm.addControl('tolerance', new FormControl('',Validators.compose([Validators.required])) );
    } else {
      this.addActivityForm.controls.geofenceDistance.clearValidators();
      this.addActivityForm.controls.geofenceDistance.updateValueAndValidity();
    }
  }

  enableOrDisableGeofence(){     
    const controls = this.addActivityForm.controls;
    if(controls.allowForCompany.value || controls.allowForOpportunity.value){
      controls.geofence.enable();    
    }else{
      controls.geofence.disable();             
      controls.geofence.reset();
      this.geofence = false;
      this.addGeofenceField(this.geofence);
    }     
  }

}
