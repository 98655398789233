import { Injectable } from '@angular/core';
import { StdLookupAttribute } from '../../contact-setting/models/std-lookup-attribute.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomAttributeList } from '../../contact-setting/models/custom-attribute-list.model';

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
}

@Injectable({
  providedIn: 'root'
})
export class RecruitmentCandidateService {

  constructor(private http: HttpClient) { }

  getMyOpeningsList(projectId: number){
    //return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/recruiter/dashboard?team=self');

    //for test--
    return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/jobtypes/');
  }

  getAllCity(projectId: number){
    return this.http.get(environment.baseUrl + '/candidate-services/api/v1/city/');
  }

  getSalaryMap(projectId: number){
    return this.http.get(environment.baseUrl + '/legacy-services/api/v1/budget/salarymap');
  }


  //Interview Schedule
  saveInterviewSchedule(data){
    return this.http.put<any[]>(environment.baseUrl + '/recruiter-services/api/v1/positions/schedule/interview/',data)
  }

  addCandidate(data){
    var candidateId = data.candidateId;
    if(candidateId == ''){  //save new data
      return this.http.post<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/',data)
    }else{  //update user data
      return this.http.put<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/'+candidateId,data)
    }
  }

  addResume(candidateData, candidateId){
    return this.http.post<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/attachment/'+candidateId,candidateData)
  }

  updateCandidateEmial(candidateData, candidateId){
    return this.http.put<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/'+candidateId,candidateData)
  }

  checkCandidateData(data){
    var phoneNo = data.mobileNumber;
    return this.http.get<any[]>(environment.baseUrl + '/candidate-services/api/v1/candidate/search?name=&city=&jobtypes=&phone='+phoneNo+'&page=1&size=10')
  }

  addCandidatePosition(data){
    return this.http.post<any[]>(environment.baseUrl + '/recruiter-services/api/v1/positions/new',data)
  }

  saveOfferData(data){
    var applicationId = data['applicationId'];
    return this.http.post<any[]>(environment.baseUrl + '/recruiter-services/api/v1/positions/make/offer/'+applicationId,data)
   
  }

  //Reject Candidate
  saveCandidateReject(data){
    return this.http.post<any[]>(environment.baseUrl + '/recruiter-services/api/v1/positions/reject/application',data)
    
  }


  //Inprocess Candidate List
  getInprocessCandidateList(openngId:any, candidateStatus: any, positionType: string){
    if(candidateStatus == ''){
      return this.http.get<any[]>(environment.baseUrl + '/recruiter-services/api/v1/positions/candidates?positionId='+openngId+'&isBudget='+positionType+'&page=0&size=100')
    }else{ 
      return this.http.get<any[]>(environment.baseUrl + '/recruiter-services/api/v1/positions/candidates?positionId='+openngId+'&isBudget='+positionType+'&page=0&size=100&status='+candidateStatus+'')
    }
    
    ///api/v1/positions/candidates?positionId=22511&isBudget=Budget&page=0&size=10&status=1020'
   
  }

  getInterviewerList(positionId:any){
   return this.http.get<any[]>(environment.baseUrl + '/legacy-services/api/v1/position/'+positionId+'/interviewers')
   
  }

  getPolicyList(positionId: any){
    return this.http.get<any[]>(environment.baseUrl + '/legacy-services/api/v1/position/'+positionId)  
    //return this.http.get<any[]>(environment.baseUrl + '/legacy-services/api/v1/position/mandate/321')  
  }

  checkAadhaarNo(adharNumber: any){
    return this.http.get<any[]>(environment.baseUrl + '/legacy-services/api/v1/recruiter/check/duplicity/'+adharNumber) 
    
  }

  getOfferDetails(adharNumber: any, applicationId: any){
    return this.http.get<any[]>(environment.baseUrl + '/legacy-services/api/v1/offer/?applicationId='+applicationId+'&aadharNumber='+adharNumber);
   
  }


}
