import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidatePoolComponent } from './candidate-pool.component';
import { CandidatesListComponent } from './_sub/candidates-list/candidates-list.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NgxMatSelectSearchModule } from '../mat-select-search/public_api';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { AddCandidateComponent } from './_sub/add-candidate/add-candidate.component';
import { EditCellRendererComponent } from './_sub/edit-cell-renderer/edit-cell-renderer.component';
import { EditCandidateComponent } from './_sub/edit-candidate/edit-candidate.component';
import { CandidatePositionComponent } from './_sub/candidate-position/candidate-position.component';
import { AlertDialogComponent } from './_sub/alert-dialog/alert-dialog.component';
import { FilterCandidateComponent } from './_sub/filter-candidate/filter-candidate.component';
//import { AlertDialogComponent } from './_subs/alert-dialog/alert-dialog.component';

const routes:Routes=[{
  path:'',component:CandidatePoolComponent
}]

@NgModule({
 // declarations: [CandidatePoolComponent, UsersListComponent, AddUserComponent, EditUserComponent, EditCellRendererComponent, AlertDialogComponent],
 declarations: [CandidatePoolComponent, CandidatesListComponent, AddCandidateComponent, EditCellRendererComponent, EditCandidateComponent, CandidatePositionComponent, AlertDialogComponent, FilterCandidateComponent], 
 imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    NgxMatSelectSearchModule,
    NgbModule,
    AgGridModule.withComponents([EditCellRendererComponent])
  ],
  entryComponents:[AddCandidateComponent, EditCandidateComponent, CandidatePositionComponent, FilterCandidateComponent]
})
export class CandidatePoolModule { }
