import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { MatDialog } from '@angular/material/dialog';
import { RecruitmentService } from '../../services/recruitment.service';

@Component({
  selector: 'kt-update-recruiter',
  templateUrl: './update-recruiter.component.html',
  styleUrls: ['./update-recruiter.component.scss']
})
export class UpdateRecruiterComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public updateRecruiterForm: FormGroup;
  disabled:boolean;
  recruiterList:any;
  positionId:any;
  errorMes:String="";
  public recruiterFilter: FormControl = new FormControl();

  constructor(
    private recruitmentService:RecruitmentService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initUpdateRecruiterFrom(); 
    this.getRecruiterList();
    this.disabled=false;
  }

  initUpdateRecruiterFrom(){
    this.updateRecruiterForm = this.fb.group({
      recruiterName: ['', Validators.compose([Validators.required ])],
    });
  }

  getRecruiterList(){
    var localStorageRecruiterList = localStorage.getItem('recruiterList');
    this.recruiterList = JSON.parse(localStorageRecruiterList);
  }

  saveRecruiter(){
    console.log(this.positionId)
    
    const controls = this.updateRecruiterForm.controls;
    var recruiterId = controls.recruiterName.value;

    if (this.updateRecruiterForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    //const addData=this.updateRecruiterForm(controls);
    
    this.recruitmentService.saveRecruiterData(recruiterId, this.positionId).subscribe(
      data => {
      //  console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          //this.OpenAlert(data['message']);
        }else{
          this.activeModal.close();
          this.updateRecruiterForm.reset();
          this.disabled=false;
          //this.eventEmitterService.onModalCloseResult(data);
          this.eventEmitterService.onModalCloseResult('INTERVIEW_SCHEDULED_SUCCESS');
          
        }
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );
  }

  Close(){
    this.activeModal.close();
    this.updateRecruiterForm.reset();
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.updateRecruiterForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

}
