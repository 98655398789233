  
<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<div class="row align-items-center">
    <!-- <div class="col-md-1"></div> -->
    <div class="col calculate-section mr-2 total-pos-bg">
        <div class="value-text">{{totalPosition}}</div>
        <div class="label-text">Total Positions</div>
    </div>
    <div class="col calculate-section mr-2 open-pos-bg">
        <div class="value-text">{{totalOpenPosition}}</div>
        <div class="label-text">Open Positions</div>
     </div>
     <div class="col calculate-section mr-2 avg-age-bg">
        <div class="value-text">{{avgAgeValue}}</div>
        <div class="label-text">Average Age</div>
     </div>
     <div class="col calculate-section mr-2 avg-tat-bg">
        <div class="value-text">{{avgTatValue}}</div>
        <div class="label-text">Average TAT</div>
     </div>
     <div class="col calculate-section mr-2 cdts-bg">
        <div class="value-text">{{cdtsValue}}</div>
        <div class="label-text">Candidates</div>
     </div>
     <!-- <div class="col-md-1"></div> -->
    
</div>


<!-- start::FILTERS & GROUP ACTIONS -->
<div class="form mb-3">
    <!-- start::FILTERS -->
    <div class="form-filtration mb-6">
        <div class="row align-items-center">
            <div class="col-md-2 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue">
                    <mat-hint align="start">Search in all fields</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-7 kt-margin-bottom-10-mobile"></div>
            <!-- <div class="col-md-3 text-right kt-margin-bottom-10-mobile">
                <button type="button" mat-raised-button color="primary"
                    (click)="addWhatsApp();">Add</button>
            </div> -->
        </div>
    </div>
    <!-- end::FILTERS -->
</div>
<!-- end::FILTERS & GROUP ACTIONS -->

<!-- AG GRID MODULE START -->
<ag-grid-angular
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowDragManaged]="true"
    [animateRows]="true"
    [domLayout]="domLayout"
    (rowDragEnd)="onBtForEachNode()"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    (gridReady)="onGridReady($event)"    
>
</ag-grid-angular>

<!--<ag-grid-angular 
    #agGrid
    class="ag-theme-alpine"
    id="myGrid"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [animateRows]="true"
    [domLayout]="domLayout"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [maxBlocksInCache]="maxBlocksInCache"
    [gridOptions]="gridOptions"
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
    [cacheOverflowSize]="cacheOverflowSize"
    [rowModelType]="rowModelType"
    [serverSideStoreType]="serverSideStoreType"

    (gridReady)="onGridReady($event)"
    (paginationChanged)="onPaginationChanged($event)"

>
</ag-grid-angular>-->

<ngx-spinner size="default" type="ball-spin-clockwise" name='recruitment-list'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>