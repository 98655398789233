import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html'
})
export class EditCellRendererComponent implements OnInit {

  params:any;
  displayRelation:boolean;
  activityId: number;

  constructor() { }

  agInit(params){
    this.displayRelation=false;
    this.params = params;
    this.activityId = params.data.activityId;

    if(params.data.defaultActivity == 0){
      this.displayRelation=true;
    }

  }

  ngOnInit(): void {
  }

  public invokeDeleteActivity() {
    this.params.context.componentParent.deleteActivity(this.params.data);
  }


}
