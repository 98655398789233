// Angular
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactDashboardService } from '../../../../services/contact-dashboard.service';
import { AddActivityComponent } from '../../../../../shared/add-activity/add-activity.component';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { ManageActivitiesService } from '../../../../../manage-activities/services/manage-activities.service';
import { AddNewCompanyComponent } from '../../../../../shared/custom-add/add-new-company/add-new-company.component';


interface FilePreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: FilePreviewDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel'
}

@Component({
  selector: 'kt-widget2-recent-activities',
  templateUrl: './widget2-recent-activities.component.html',
  styleUrls: ['./widget2-recent-activities.component.scss'],
  providers: [DatePipe]
})
export class Widget2RecentActivitiesComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  activityList: any;
  noDataFound: false;
  modalReference: any;
  moduleString: string;
  isError = false;
  errorMessage: string;
  actList: any;
  contactId: number;
  rowData: any;
  estimateModuleId: string | number;
  
  constructor(
    private modalService: NgbModal,
    private _cds: ContactDashboardService,
    private SpinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
    private _mas: ManageActivitiesService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.moduleString = 'contact';
    this.SpinnerService.show('dashboard-activity');
    this.fetchContactActivities(this.selectedProject, this.parentData);
    this.getAllActivitiesList();

    /* if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:number) => {
        this.activityResult(data);
      });
    } */
  }

  fetchContactActivities(projectId: number, contactId: number){
    this._cds.getContactActivities(projectId, contactId).subscribe(
      res=>{
        this.actList = res['responseData'];
        var winProbPrev: number;
        var probability: string;
        var textClass: string;
        var borderColor: string;
        var bgLightClass: string;
        var lableLight: string;
        var iconClass: string;
        var displayImage: boolean;
        var statusLable: string;
        for(let res of this.actList.reverse()) {
          /** Date Format */
          if(res.activityDate == null){
            res.created = "";
          } else {
            let dateCreated = new Date(res.activityDate);
            res.created = this.datePipe.transform(dateCreated, 'dd-MMM-yyyy HH:mm:ss');
          }

          /** For set percentage */
          if(res.winProbability > winProbPrev) {
            probability = 'Increased';
            lableLight = 'label-light-success';
            iconClass = 'fa-arrow-up text-success'
          } else if(res.winProbability < winProbPrev){
            probability = 'Decreased';
            lableLight = 'label-light-danger';
            iconClass = 'fa-arrow-down text-danger'
          } else {
            probability = 'No-Change';
            lableLight = 'label-light-primary';
            iconClass = 'fa-arrows-alt-h text-primary'
          }

          /** For set row color */
          if(res.status === 'Won'){
            textClass = 'text-success';
            bgLightClass = 'bg-light-success';
            borderColor = 'timeline-media-success';
            statusLable = 'label-light-success';
          } else if(res.status === 'Lost'){
            textClass = 'text-danger';
            bgLightClass = 'bg-light-danger';
            borderColor = 'timeline-media-danger';
            statusLable = 'label-light-danger';
          } else if(res.activityTypeName === 'Estimate'){
            textClass = 'text-warning';
            bgLightClass = 'bg-light-warning';
            borderColor = 'timeline-media-warning';
            statusLable = 'label-light-primary';
          } else if(res.activityTypeName === 'Order'){
            textClass = 'text-success';
            bgLightClass = 'bg-light-success';
            borderColor = 'timeline-media-success';
            statusLable = 'label-light-primary';
          } else {
            textClass = 'text-primary';
            bgLightClass = 'bg-light-primary';
            borderColor = 'timeline-media-primary';
            statusLable = 'label-light-primary';
          }


          /** For set display image */
          if(res.images.length > 0) {
            displayImage = true;
          } else {
            displayImage = false;
          }

          res.probability = probability;
          res.lableLight = lableLight;
          res.iconClass = iconClass;
          res.textClass = textClass;
          res.bgLightClass = bgLightClass;
          res.borderColor = borderColor;
          res.displayImage = displayImage;
          res.statusLable = statusLable;
          winProbPrev = res.winProbability;
        }
        this.actList.reverse();
        this.activityList = this.actList;
        this.SpinnerService.hide('dashboard-activity');
      },
      err=>{
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        console.log(err);
        this.SpinnerService.hide('dashboard-activity');
      }
    )
  }
/*
  AddActivity(){
    console.log('contact Id'+this.parentData);
    const ref= this.modalReference = this.modalService.open(AddActivityComponent,
      {  centered: false,
          size : 'xxs'
      }
    );
    ref.componentInstance.opportunityId=this.parentData;
    ref.componentInstance.flag='contact';
    ref.componentInstance.modalFlag='contact';
  }
*/

  AddActivity(activityId:number) {   
    const _saveMessage = `Activity has been saved.`;
    const ref=this.modalReference = this.modalService.open(AddNewCompanyComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      });
  
    ref.componentInstance.moduleType = activityId;
    ref.componentInstance.flag = this.moduleString;
    ref.componentInstance.activityFlag = true;
    ref.componentInstance.contactId = this.parentData;
    ref.componentInstance.estimateModuleId=this.estimateModuleId;
  
    ref.result.then((result) => {
      this.activityResult(result);
    });
  
  }

  activityResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-activity');
      this.fetchContactActivities(this.selectedProject, this.parentData);
    }
    
  }

  getAllActivitiesList(){
    this._mas.getAllActivities(this.selectedProject).subscribe(
      data=>{
        this.rowData =data['responseData'].filter(ele=>{
          return ele.active == 1;
        })
        data['responseData'].forEach(res=>{  
         // console.log(res);        
         if(res.activityName == 'Estimate') {
          // console.log(res.activityId);
           this.estimateModuleId=res.activityId;
         }        
       })
      }
    )
    
  }
  
}
