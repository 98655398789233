// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth';
import { UserRoleModule } from './views/pages/user-role/user-role.module';



const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
  {path: 'error', loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule)},
  
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [

      //--- DASHBOARD
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module')
          .then(m => m.DashboardModule),
      },
      {
        path: 'company-dashboard/:companyId',
        loadChildren: () => import('./views/pages/company-dashboard/company-dashboard.module')
          .then(m => m.CompanyDashboardModule),
      },
      {
        path: 'opportunity-dashboard/:opportunityId',
        loadChildren: () => import('./views/pages/opportunity-dashboard/opportunity-dashboard.module')
          .then(m => m.OpportunityDashboardModule),
      },
      {
        path: 'contact-dashboard/:contactId',
        loadChildren: () => import('./views/pages/contact-dashboard/contact-dashboard.module')
          .then(m => m.ContactDashboardModule),
      },
      //--- DATA MANAGEMENT
      {
        path: 'customers',
        loadChildren: () => import('./views/pages/company-management/company-management.module')
          .then(m => m.CompanyManagementModule),
      },
      {
        path: 'contacts',
        loadChildren: () => import('./views/pages/contact-management/contact-management.module')
          .then(m => m.ContactManagementModule),
      },
      {
        path: 'opportunities',
        loadChildren: () => import('./views/pages/opportunities/opportunities.module')
          .then(m => m.OpportunitiesModule),
      },
      {
        path: 'products',
        loadChildren: () => import('./views/pages/product-management/product-management.module').then(m => m.ProductManagementModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./views/pages/users-management/users-management.module')
          .then(m => m.UsersManagementModule),
      },
      {
        path: 'users/set-assist',
        loadChildren: () => import('./views/pages/users-management/users-management.module')
          .then(m => m.UsersManagementModule),
      },
      //---CANDIDATE-POOL
      {
        path: 'candidates',
        loadChildren: () => import('./views/pages/candidate-pool/candidate-pool.module')
          .then(m => m.CandidatePoolModule),
      },


      //--- SETTINGS -- CONFIGURATION SETTINGS
      {
        path: 'settings-assistant',
        loadChildren: () => import('./views/pages/configuration-assistant/configuration-assistant.module')
          .then(m => m.ConfigurationAssistantModule),
      },
      {
        path: 'user-role',
        loadChildren: ()=>import('./views/pages/user-role/user-role.module')
          .then(m=>m.UserRoleModule)
      },
      {
        path: 'user-role/set-assist',
        loadChildren: ()=>import('./views/pages/user-role/user-role.module')
          .then(m=>m.UserRoleModule)
      },
      {
        path: 'customer-settings',
        loadChildren: () => import('./views/pages/company-configuration/company-configuration.module')
          .then(m => m.CompanyConfigurationModule),
      },
      {
        path: 'customer-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/company-configuration/company-configuration.module')
          .then(m => m.CompanyConfigurationModule),
      },
      {
        path: 'contact-settings',
        loadChildren: () => import('./views/pages/contact-setting/contact-setting.module')
          .then(m => m.ContactSettingModule),
      },
      {
        path: 'contact-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/contact-setting/contact-setting.module')
          .then(m => m.ContactSettingModule),
      },
      {
        path: 'opportunity-settings',
        loadChildren: () => import('./views/pages/opportunity-configuration/opportunity-configuration.module')
          .then(m => m.OpportunityConfigurationModule),
      },
      {
        path: 'opportunity-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/opportunity-configuration/opportunity-configuration.module')
          .then(m => m.OpportunityConfigurationModule),
      },
      {
        path: 'applications', //recruitment-management
        loadChildren: () => import('./views/pages/recruitment-management/recruitment-management.module')
          .then(m => m.RecruitmentManagementModule),
      },
      {
        //path: 'recruitement-candidate-details/:openingId', 
        path: 'recruitement-candidate-details', 
        loadChildren: () => import('./views/pages/recruitment-management-candidate/recruitment-management-candidate.module')
          .then(m => m.RecruitmentManagementCandidateModule),
      },
      {
        path: 'product-settings',
        loadChildren: () => import('./views/pages/product-settings/product-settings.module')
          .then(m => m.ProductSettingsModule),
      },
      {
        path: 'product-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/product-settings/product-settings.module')
          .then(m => m.ProductSettingsModule),
      },
      {  path: 'user-roles',
        loadChildren: ()=>import('./views/pages/user-role/user-role.module')
          .then(m=>m.UserRoleModule)
      },
      {  path: 'product-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/product-settings/product-settings.module')
          .then(m => m.ProductSettingsModule),
      },
      {
        path: 'activities',
        loadChildren: () => import('./views/pages/manage-activities/manage-activities.module')
          .then(m => m.ManageActivitiesModule),
      },
      {
        path: 'activity-list/:moduleType',
        loadChildren: () => import('./views/pages/config-tab-settings/config-tab-settings.module')
          .then(m => m.ConfigTabSettingsModule),
      },
      {
        path: 'general-settings',
        loadChildren: () => import('./views/pages/general-settings/general-settings.module')
          .then(m => m.GeneralSettingsModule),
      },
      {
        path: 'general-settings/set-assist',
        loadChildren: () => import('./views/pages/general-settings/general-settings.module')
          .then(m => m.GeneralSettingsModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./views/pages/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'user-activity',
        loadChildren: () => import('./views/pages/user-activity/user-activity.module')
          .then(m => m.UserActivityModule),
      },
      {
        path: 'activity-report',
        loadChildren: () => import('./views/pages/activity-report/activity-report.module').then(m => m.ActivityReportModule),
      },
      {
        path: 'report-builder',
        loadChildren: () => import('./views/pages/report-builder/report-builder.module')
          .then(m => m.ReportBuilderModule),
      },  
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
    ],
  },
  {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
