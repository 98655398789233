import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecruitmentManagementComponent } from './recruitment-management.component';
//import { ProductFieldsListComponent } from './_subs/product-fields-list/product-fields-list.component';
//import { AddProductFieldComponent } from './_subs/add-product-field/add-product-field.component';
//import { EditProductFieldComponent } from './_subs/edit-product-field/edit-product-field.component';
//import { SettingsComponent } from './_subs/settings/settings.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NgxMatSelectSearchModule } from '../mat-select-search/public_api';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AgGridModule } from 'ag-grid-angular';
import { RecruitmentListComponent } from './_sub/recruitment-list/recruitment-list.component';
import { EditCellRendererComponent } from './_sub/edit-cell-renderer/edit-cell-renderer.component';
import { TeamsRecruitmentListComponent } from './_sub/teams-recruitment-list/teams-recruitment-list.component';
import { UpdateRecruiterComponent } from './_sub/update-recruiter/update-recruiter.component';


const routes:Routes=[{
  path:'',component:RecruitmentManagementComponent
}]


@NgModule({
  declarations: [
    RecruitmentManagementComponent,
    RecruitmentListComponent,
    EditCellRendererComponent,
    TeamsRecruitmentListComponent,
    UpdateRecruiterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    NgxMatSelectSearchModule,
    NgbModule,
    AgGridModule.withComponents([EditCellRendererComponent])
  ],
  exports:[],
  entryComponents:[
    UpdateRecruiterComponent
  ]
})
export class RecruitmentManagementModule { }
