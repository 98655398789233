<div class="d-flex flex-column-fluid flex-center mt-10 mt-lg-20">
	<!--begin::Signup-->
	<div class="login-form login-signup" style="display: block;">
		<div class="mt-5 mt-lg-10 mb-5 mb-lg-10" *ngIf="authFor == 'Reset-Password'">
			<p>Hello {{name}}!</p>
            <p>Please create your new password and login to your account.</p>
		</div>

		<div class="mt-5 mt-lg-10 mb-5 mb-lg-10" *ngIf="authFor == 'Create-Password'">
			<p>Hello <b>{{name}}</b>!</p>
            <p>It’s great to have you here! Please create your password and login to activate your account.</p>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="form" [formGroup]="registerForm" autocomplete="off">
			<div class="form-group" [ngStyle]="{'padding-bottom': '0px'}">
				<mat-form-field>
					<mat-label>Username</mat-label>
					<input matInput type="text" placeholder="Username" 
						formControlName="username" [(ngModel)]="userEmail" readonly />
					<mat-error *ngIf="isControlHasError('username','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" [ngStyle]="{'padding-bottom': '0px'}">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
						formControlName="password" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>Password should be 8 characters or more</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('password').errors && registerForm.get('password').errors.password">
						<strong>Passsword can't be same as username.</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" [ngStyle]="{'padding-bottom': '0px'}">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"
						formControlName="confirmPassword" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>Password should be 8 characters or more</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passwords do not match.</strong>
					</mat-error>
				</mat-form-field>
			</div>
			
			<!--begin::Action-->
			<div class="form-group text-right">
				<a href="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</a>

					<!--<button *ngIf="authFor == 'Reset-Password'" (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">Reset</button>-->

					<button (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>
			</div>
			<!--end::Action-->
		</form>
	</div>
	<!--end::Signup-->
</div>