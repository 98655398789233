// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse ,HttpErrorResponse } from '@angular/common/http';
// RxJS
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from '../../../auth/_services/auth.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private authenticationService: AuthService) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:no-debugger
    // modify request

    let currentUser = this.authenticationService.currentUserValue;
   

    if (currentUser && currentUser.token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + currentUser.token) });
    }

    if (!request.headers.has('Content-Type') && !request.url.search('uploadFrontImage') && !request.url.search('uploadFieldImage')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    if (!request.headers.has('Content-Type') && !request.url.search('attachment') && !request.url.search('attachment')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    if (!request.headers.has('Content-Type') && !request.url.search('/candidate-services/api/v1/candidate/') && !request.url.search('/candidate-services/api/v1/candidate/')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }


    

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    //console.log('request---------',request);
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(error);
        })
      )
  }
}