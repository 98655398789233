import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder,Validators, Form , FormControl} from '@angular/forms';
//utilities
import { LayoutUtilsService ,MessageType} from '../../../../../core/_base/crud';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'kt-customer-setting',
  templateUrl: './customer-setting.component.html',
  styleUrls: ['./customer-setting.component.scss']
})
export class CustomerSettingComponent implements OnInit {

  selectedProject:number;
  addCompanySettingsForm:FormGroup;
  enforceLiveLocCapture: boolean;
  sortBy;
  resultSet:any;
  disabled:boolean;
  singularName: string;
  pruralName:string;
  prevsingularName: string;
  prevpruralName: string;
  chooseCompanyOwnerFlag;
  id:number;
  errorMes:String="";
  currentRouteUrl: string;
  @Output() updateCustomerTabLabel = new EventEmitter<string>();

  constructor(private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private compantSettingService: LocalizationService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private subheaderService: SubheaderService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('Customers');
    }

    this.selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
    //this.SpinnerService.show();
    this.addCompanySettingsForm = this.fb.group({
      enforceLiveLocCapture: [''],
      singularName: ['', Validators.compose([ Validators.required])],
      pruralName:['', Validators.compose([ Validators.required])],
      sortBy:[''],
      chooseCompanyOwnerFlag:['', Validators.compose([ Validators.required])]
     });
     this.SpinnerService.show();
     this.getSettingsValues(this.selectedProject);
     this.disabled=false;
  }

  getSettingsValues(selectedProject: number){
    this.SpinnerService.show();
    this.compantSettingService.getCompanySettings(selectedProject).subscribe(
      res=>{
       // console.log(res['responseData']);
        this.resultSet=res['responseData'];
        this.enforceLiveLocCapture=this.resultSet.enforceLocationCapture == 1 ? true : false;
        this.sortBy=this.resultSet.sortBy.toString();
        this.singularName=this.resultSet.singularName;
        this.updateCustomerTabLabel.emit(this.resultSet.singularName);
        this.pruralName=this.resultSet.pruralName;
        this.prevsingularName=this.resultSet.singularName;
        this.prevpruralName=this.resultSet.pruralName;
        this.chooseCompanyOwnerFlag=this.resultSet.chooseCompanyOwnerFlag.toString();
        this.id=this.resultSet.id;
        this.SpinnerService.hide();
      },
      err=>{
        console.log(err);
      }
    )
  }


  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addCompanySettingsForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }


  Save(){
    this.disabled=true;
    const controls = this.addCompanySettingsForm.controls;
    if (this.addCompanySettingsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
       
    const addSettingsData = {
        "projectId": this.selectedProject,  
        "enforceLocationCapture": controls.enforceLiveLocCapture.value ==true ? 1 :0,
        "singularName":controls.singularName.value.trim(),
        "pruralName":controls.pruralName.value.trim(),
        "id": this.id,
        "sortBy": controls.sortBy.value,
        "chooseCompanyOwnerFlag":controls.chooseCompanyOwnerFlag.value,
        "companyOwnerFlag": 0,
        "geoFencing": 0,
        "tolerance": 0
    };
    this.disabled=false;
   // console.log(addSettingsData);
    
    this.compantSettingService.saveCompanySettings(addSettingsData).subscribe(
      data => {
      if(!(this.prevsingularName == this.addCompanySettingsForm.controls.singularName.value) ||
          !(this.prevpruralName == this.addCompanySettingsForm.controls.pruralName.value)){
            this.snackBar.open('Renamed value will appear on your next login', '', {
              duration: 2000,
            });
          }else{
            this.snackBar.open('Settings updated.', '', {
              duration: 3000,
              panelClass: ['green-snackbar']
            });
          }
          
        this.getSettingsValues(this.selectedProject);
        
      },
      error =>{
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
       // this.errorMes=error.error.responseData;
      }
      
    );
  }


  Cancel(){
    this.disabled=true;
    this.SpinnerService.show();
    this.getSettingsValues(this.selectedProject);
    this.disabled=false;
  }
  
  isDisabled() : boolean {
    return this.disabled;
   }

}
