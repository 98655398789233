import { Component, Input, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
//import { AddProductFieldComponent } from '../add-product-field/add-product-field.component';
import { RecruitmentCandidateService } from '../../services/recruitment-candidate.service';
import { CandidateWhatsappComponent } from '../candidate-whatsapp/candidate-whatsapp.component';
import { CandidateDirectComponent } from '../candidate-direct/candidate-direct.component';
import { InterviewScheduleComponent } from '../interview-schedule/interview-schedule.component';
import { CandidateOfferComponent } from '../candidate-offer/candidate-offer.component';
import { EditCellRendererComponent } from '../edit-cell-renderer/edit-cell-renderer.component';
import { CandidateRejectComponent } from '../candidate-reject/candidate-reject.component';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubheaderService } from '../../../../../../app/core/_base/layout';

import 'ag-grid-enterprise';
import { from } from 'rxjs';

import { RecruitmentDataService } from 'src/app/views/pages/shared/services/recruitment-data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-inprocess-candidate',  
  templateUrl: './inprocess-candidate.component.html',
  styleUrls: ['./inprocess-candidate.component.scss']
})
export class InprocessCandidateComponent implements OnInit {
 
  @ViewChild('myButton') myButton : ElementRef;
  viewContent:boolean;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  resultCustom: any;

  //inprocess AG grid
  inProcessGridApi: any;
  inProcessGridColumnApi: any;
  inProcessColumnDefs: any;
  inProcessDefaultColDef: any;
  rowData: [];
  domLayout: any;
  searchValue: any;
  gridOptions: any;
  frameworkComponents: any;
  paginationPageSize:any;

  //joned AG grid
  joinedResultCustom: any;
  joinedRowData: [];
  joinedColumnDefs: any;
  joinedDefaultColDef: any;
  joinDomLayout: any;
  joinedGridApi: any;
  joinedGridColumnApi: any;
  isShownOffered:boolean;

  //Reject AG grid
  rejectResultCustom: any;
  rejectRowData: [];
  rejectColumnDefs: any;
  rejectDefaultColDef: any;
  rejectDomLayout: any;
  rejectGridApi: any;
  rejectGridColumnApi: any;

  modalReference: any;
  errorText = false;
  //editValue: ProductField;

  currentRouteUrl: string;
  rightSearchViewFlag = true;
  
  positionId: number;
  openingName:any;
  candidateList: any;
  pageUrlParamsObj
  candidateDataObj
  interviewersObject
  claimPolicyList
  mediclaimPolicyList
  fixedCtcPercentage

  candidateShareObj: any
  inProcessCount = 0
  offeredJoindCount = 0
  rejectedCount = 0

  
  constructor(
    private _rmcs:RecruitmentCandidateService,
    private modalService: NgbModal,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private subheaderService: SubheaderService,
    private eventEmitterService: EventEmitterService,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private SpinnerService: NgxSpinnerService,
    private recruitmentDataService:RecruitmentDataService,
  ) {
    this.gridOptions={
      context : {
        componentParent: this
      }
    }
   }

  ngOnInit(): void {
    this.candidateShareObj = this.getShareData();   //get share data
    if(this.candidateShareObj == undefined){
      this.router.navigate(['/applications'])
    }
    console.log('share data',this.candidateShareObj)
    this.setSharedData();

    this.recruitmentDataService.fromCandidateDetailsPage = true;

    // this.currentRouteUrl = this.router.url;
    // if(this.currentRouteUrl.search('set-assist') > 0){
    //   this.subheaderService.setTitle('Candidates');
    // }

    //this.subheaderService.setTitle('Recruitment Management');
    //this.subheaderService.setRightSearchConfig(this.rightSearchViewFlag)
    
    /*const routeSubscription =  this.activatedRoute.params.subscribe(params => {
      this.openingId = params.openingId;
    });*/
    this.isShownOffered = false;


    //in process AG grid
    this.inProcessColumnDefs = [
      {
        headerName: 'Candidate',
        field: 'candidateName',
        minWidth: 150,
        //rowDrag: true,
      },
      {
        headerName: 'City',
        field: 'city'
      },
      {
        headerName: 'Phone',
        field: 'phone'
      },
      {
        headerName: 'Source',
        field: 'source'
      },
      /*{
        headerName: 'Test 1',
        field: 'test1'
      },
      {
        headerName: 'Test 2',
        field: 'test2'
      },*/
     /* {
        headerName: 'Interview 1',
        field: 'interview1'
      },
      {
        headerName: 'Interview 2',
        field: 'interview2'
      },*/
      {
        headerName: 'Status',
        field: 'status'
      },
      {
        headerName: 'Action',
        field: '',
        maxWidth: 150,
        filter: false,
        sortable: false,
        cellRendererFramework: EditCellRendererComponent
      }
    ]


    this.inProcessDefaultColDef = {
      sortable: false,
      resizable: false,
      editable: false,
      filter: false,
      flex: 1,
     // minWidth: 150
    };

    this.domLayout = 'autoHeight';
    this.getInprocessCandidateList();


    //Joined AG grid
    this.joinedColumnDefs = [
      {
        headerName: 'Candidate',
        field: 'candidateName',
        minWidth: 150,
        //rowDrag: true,
      },
      {
        headerName: 'City',
        field: 'city'
      },
      {
        headerName: 'Phone',
        field: 'phone'
      },
      {
        headerName: 'Source',
        field: 'source'
      },
      /*{
        headerName: 'Test 1',
        field: 'test1'
      },
      {
        headerName: 'Test 2',
        field: 'test2'
      },*/
     /* {
        headerName: 'Interview 1',
        field: 'interview1'
      },
      {
        headerName: 'Interview 2',
        field: 'interview2'
      },*/
      {
        headerName: 'Status',
        field: 'status'
      },
      {
        headerName: 'Action',
        field: '',
        maxWidth: 150,
        filter: false,
        sortable: false,
        cellRendererFramework: EditCellRendererComponent
      }
    ]

    this.joinedDefaultColDef = {
      sortable: false,
      resizable: false,
      editable: false,
      filter: false,
      flex: 1,
     // minWidth: 150
    };
    this.joinDomLayout = 'autoHeight';
    

    //Reject AG grid
    this.rejectColumnDefs = [
      {
        headerName: 'Candidate',
        field: 'candidateName',
        minWidth: 150,
        //rowDrag: true,
      },
      {
        headerName: 'City',
        field: 'city'
      },
      {
        headerName: 'Phone',
        field: 'phone'
      },
      {
        headerName: 'Source',
        field: 'source'
      },
      /*{
        headerName: 'Test 1',
        field: 'test1'
      },
      {
        headerName: 'Test 2',
        field: 'test2'
      },*/
     /* {
        headerName: 'Interview 1',
        field: 'interview1'
      },
      {
        headerName: 'Interview 2',
        field: 'interview2'
      },*/
      {
        headerName: 'Status',
        field: 'status'
      },
      {
        headerName: 'Action',
        field: '',
        maxWidth: 150,
        filter: false,
        sortable: false,
        cellRendererFramework: EditCellRendererComponent
      }
    ]

    this.rejectDefaultColDef = {
      sortable: false,
      resizable: false,
      editable: false,
      filter: false,
      flex: 1,
     // minWidth: 150
    };
    this.rejectDomLayout = 'autoHeight';
    this.paginationPageSize = 10;

    var insertIndex = 4; 
    for (let i = 0; i < this.candidateShareObj.interviewRounds; i++) {
      var indexNo = i+1;
      var headerNametemp = 'Interview '+indexNo;
      var tempField = 'interview'+indexNo;
      var cellStyle = {"line-height":"normal"}
      var tempInterViewerObj = {"headerName": headerNametemp, 
              "field":tempField, 
              wrapText: true, 
              autoHeight: true, 
              minWidth: 165, 
              cellStyle: cellStyle,
              cellRenderer: params => { 
                if(params.value.indexOf('on') < 0){
                  return '<label style="line-height: 40px">' + params.value + '</label>';
                }else{
                  return '<label>' + params.value + '</label>';
                }
                
              } 
      };
      this.inProcessColumnDefs.splice(insertIndex,0,tempInterViewerObj);
      this.joinedColumnDefs.splice(insertIndex,0,tempInterViewerObj);
      this.rejectColumnDefs.splice(insertIndex,0,tempInterViewerObj);
      
      insertIndex++
    }

    if (this.eventEmitterService.subsVar==undefined) {    //after add candidate on close modal get result
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.modalResult(data);
      });
    }
  }

  getShareData(): any{
    console.log('get share data')
    return this.recruitmentDataService.shareData; 
  }

  setSharedData(){
    this.subheaderService.setTitle(this.candidateShareObj.opening+' - '+this.candidateShareObj.positionId+', '+this.candidateShareObj.openingCity+' - Candidates');
    this.openingName = this.candidateShareObj.opening;
    this.positionId = this.candidateShareObj.positionId;
    this.inProcessCount = this.candidateShareObj.totalInprocessCount;
    this.offeredJoindCount = this.candidateShareObj.totalOfferedJoined;
    this.rejectedCount = this.candidateShareObj.rej+this.candidateShareObj.notJoined;

    console.log('souvik test---', this.positionId);
  }
  
  
  getInprocessCandidateList = () => {
    this.SpinnerService.show('recruitment-details-candidte-list');

    var candidateStatus = '999,1000,1001,1002,1003,1004,1005,1006,1007,1008,1009';
    this.rowData=[];
    this._rmcs.getInprocessCandidateList(this.positionId, candidateStatus, this.candidateShareObj.positionType)
    .subscribe(
      (result)=>{
        var candidateData = result['content'];
        //console.log('candidateListData--', candidateData)

        candidateData.forEach(
          row => {
            console.log('candidate---', row)
            var candidateFirstName = row.candidate.firstName;
            var candidateMiddleName = row.candidate.middleName;
            var candidateLastName = row.candidate.lastName;
            row.candidateName = candidateFirstName+' '+candidateMiddleName+' '+candidateLastName;

            row.firstName = row.candidate.firstName;
            row.middleName = row.candidate.middleName;
            row.lastName = row.candidate.lastName;
            row.expectedSalary = row.candidate.expectedSalary;
            //row.preferredJobTypes = row.candidate.preferredJobTypes;
            //row.preferredIndustries = row.candidate.preferredIndustries;

            var tempJobType = row.candidate.preferredJobTypes;
            var tempJobtypeArray = new Array(); 
            if(tempJobType){
              tempJobType.forEach(data =>
                tempJobtypeArray.push(data.id)
              );
            }
            row.preferredJobTypes = tempJobtypeArray;

            var tempIndustryType = row.candidate.preferredIndustries;
            var tempIndustryTypeArray = new Array(); 
            if(tempIndustryType){
              tempIndustryType.forEach(data =>
                tempIndustryTypeArray.push(data.id)
              );
            }
            row.preferredIndustries = tempIndustryTypeArray;
            

            if(row.candidate.currentCity == null){
              row.city = '';
            }else{
              row.city = row.candidate.currentCity.cityName;
            }

            row.phone = row.candidate.mobileNumber;
            row.test1 = 'null';
            row.test2 = 'null';
            //row.interview1 = 'Pending';
            //row.interview2 = 'Pending';
            row.status = row.currentStatus.applicationStatus;
            row.statusId = row.currentStatus.statusId;
            row.applicationId = row.applicationId;
            row.positionType = this.candidateShareObj.positionType;
            row.directOfferAllowed = this.candidateShareObj.directOfferAllowed

            if(row.applicationSource.sourceName == null){
              row.source = '';
            }else{
              row.source = row.applicationSource.sourceName;
            }

            if(this.candidateShareObj.openingPosition > this.candidateShareObj.offered+this.candidateShareObj.joined){
              row.openPositionGreaterOfferJoined = true
            }else{
              row.openPositionGreaterOfferJoined = false
            }

            row.interview1 = 'Pending';
            row.interview2 = 'Pending';
            row.interview3 = 'Pending';
            row.interview4 = 'Pending';
            row.interview5 = 'Pending';
            row.interview6 = 'Pending';
            row.interview7 = 'Pending';
            row.interview8 = 'Pending';

            var thisVariable = this;
            row.applicationActivities.forEach(function(v){
              if(v.interviewLevel != null){
                if(v.interviewLevel == 1){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview1 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule);
                    row.interview1 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 2){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview2 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview2 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 3){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview3 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview3 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 4){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview4 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview4 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 5){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview5 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview5 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 6){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview6 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview6 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 7){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview7 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview7 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 8){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview8 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview8 = v.activityName+' on '+interViewDateTime;
                  }
                }
              }
            });

          }
        )
        this.rowData =  candidateData;

        this.SpinnerService.hide('recruitment-details-candidte-list');
        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click(),
          this.viewContent = true;
        }, 300); 
    },
    error => {
      console.log(error)
      this.SpinnerService.hide('recruitment-details-candidte-list');
    })
    

  }

  getJoinedOpeningsList(){
    this.SpinnerService.show('recruitment-details-candidte-list');
    var candidateStatus = '1010,1013,1014,1019';
    this.rowData=[];
    this._rmcs.getInprocessCandidateList(this.positionId, candidateStatus, this.candidateShareObj.positionType)
    .subscribe(
      (result)=>{
        var candidateData = result['content'];
       // console.log('candidateListData--', candidateData)

        candidateData.forEach(
          row => {
            //console.log('candidate---', row)
            var candidateFirstName = row.candidate.firstName;
            var candidateMiddleName = row.candidate.middleName;
            var candidateLastName = row.candidate.lastName;
            row.candidateName = candidateFirstName+' '+candidateMiddleName+' '+candidateLastName;
            
            row.firstName = row.candidate.firstName;
            row.middleName = row.candidate.middleName;
            row.lastName = row.candidate.lastName;
            row.expectedSalary = row.candidate.expectedSalary;
            //row.preferredJobTypes = row.candidate.preferredJobTypes;
            //row.preferredIndustries = row.candidate.preferredIndustries;

            var tempJobType = row.candidate.preferredJobTypes;
            var tempJobtypeArray = new Array(); 
            if(tempJobType){
              tempJobType.forEach(data =>
                tempJobtypeArray.push(data.id)
              );
            }
            row.preferredJobTypes = tempJobtypeArray;

            var tempIndustryType = row.candidate.preferredIndustries;
            var tempIndustryTypeArray = new Array(); 
            if(tempIndustryType){
              tempIndustryType.forEach(data =>
                tempIndustryTypeArray.push(data.id)
              );
            }
            row.preferredIndustries = tempIndustryTypeArray;


            if(row.candidate.currentCity == null){
              row.city = '';
            }else{
              row.city = row.candidate.currentCity.cityName;
            }

            row.phone = row.candidate.mobileNumber;
            row.test1 = 'null';
            row.test2 = 'null';
            //row.interview1 = 'Pending';
            //row.interview2 = 'Pending';
            row.status = row.currentStatus.applicationStatus;
            row.statusId = row.currentStatus.statusId;
            row.applicationId = row.applicationId;
            row.positionType = this.candidateShareObj.positionType;
            row.directOfferAllowed = this.candidateShareObj.directOfferAllowed;

            if(row.applicationSource.sourceName == null){
              row.source = '';
            }else{
              row.source = row.applicationSource.sourceName;
            }

            row.openPositionGreaterOfferJoined = true

            row.interview1 = 'Pending';
            row.interview2 = 'Pending';
            row.interview3 = 'Pending';
            row.interview4 = 'Pending';
            row.interview5 = 'Pending';
            row.interview6 = 'Pending';
            row.interview7 = 'Pending';
            row.interview8 = 'Pending';

            var thisVariable = this;
            row.applicationActivities.forEach(function(v){
              if(v.interviewLevel != null){
                if(v.interviewLevel == 1){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview1 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview1 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 2){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview2 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview2 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 3){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview3 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview3 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 4){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview4 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview4 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 5){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview5 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview5 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 6){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview6 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview6 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 7){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview7 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview7 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 8){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview8 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview8 = v.activityName+' on '+interViewDateTime;
                  }
                }
              }
            });

          }
        )
        this.joinedRowData =  candidateData;
        this.SpinnerService.hide('recruitment-details-candidte-list');
        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click(),
          this.viewContent = true;
        }, 300); 
    },
    error => {
      console.log(error)
      this.SpinnerService.hide('recruitment-details-candidte-list');
    })

  }

  getRejectOpeningsList(){
    this.SpinnerService.show('recruitment-details-candidte-list');
    var candidateStatus = '1020,1021,1022';   //reject
    this.rowData=[];
    this._rmcs.getInprocessCandidateList(this.positionId, candidateStatus, this.candidateShareObj.positionType)
    .subscribe(
      (result)=>{
        var candidateData = result['content'];
       // console.log('candidateListData--', candidateData)

        candidateData.forEach(
          row => {
          //  console.log('candidate---', row)
            var candidateFirstName = row.candidate.firstName;
            var candidateMiddleName = row.candidate.middleName;
            var candidateLastName = row.candidate.lastName;
            row.candidateName = candidateFirstName+' '+candidateMiddleName+' '+candidateLastName;
            
            row.firstName = row.candidate.firstName;
            row.middleName = row.candidate.middleName;
            row.lastName = row.candidate.lastName;
            row.expectedSalary = row.candidate.expectedSalary;
            //row.preferredJobTypes = row.candidate.preferredJobTypes;
            //row.preferredIndustries = row.candidate.preferredIndustries;

            var tempJobType = row.candidate.preferredJobTypes;
            var tempJobtypeArray = new Array(); 
            if(tempJobType){
              tempJobType.forEach(data =>
                tempJobtypeArray.push(data.id)
              );
            }
            row.preferredJobTypes = tempJobtypeArray;

            var tempIndustryType = row.candidate.preferredIndustries;
            var tempIndustryTypeArray = new Array(); 
            if(tempIndustryType){
              tempIndustryType.forEach(data =>
                tempIndustryTypeArray.push(data.id)
              );
            }
            row.preferredIndustries = tempIndustryTypeArray;

            if(row.candidate.currentCity == null){
              row.city = '';
            }else{
              row.city = row.candidate.currentCity.cityName;
            }

            row.phone = row.candidate.mobileNumber;
            row.test1 = 'null';
            row.test2 = 'null';
            //row.interview1 = 'Pending';
            //row.interview2 = 'Pending';
            row.status = row.currentStatus.applicationStatus;
            row.statusId = row.currentStatus.statusId;
            row.applicationId = row.applicationId;
            row.positionType = this.candidateShareObj.positionType;
            row.directOfferAllowed = this.candidateShareObj.directOfferAllowed;

            if(row.applicationSource.sourceName == null){
              row.source = '';
            }else{
              row.source = row.applicationSource.sourceName;
            }

            row.openPositionGreaterOfferJoined = true

            row.interview1 = 'Pending';
            row.interview2 = 'Pending';
            row.interview3 = 'Pending';
            row.interview4 = 'Pending';
            row.interview5 = 'Pending';
            row.interview6 = 'Pending';
            row.interview7 = 'Pending';
            row.interview8 = 'Pending';

            var thisVariable = this;
            row.applicationActivities.forEach(function(v){
              if(v.interviewLevel != null){
                if(v.interviewLevel == 1){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview1 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview1 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 2){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview2 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview2 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 3){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview3 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview3 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 4){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview4 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview4 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 5){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview5 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview5 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 6){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview6 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview6 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 7){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview7 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview7 = v.activityName+' on '+interViewDateTime;
                  }
                }else if(v.interviewLevel == 8){
                  if(v.interviewResult == "" || v.interviewResult != null){
                    row.interview8 = thisVariable.getStatus(v.interviewResult);
                  }else{
                    var interViewDateTime = thisVariable.converetTimeStamp(v.interviewSchedule)
                    row.interview8 = v.activityName+' on '+interViewDateTime;
                  }
                }
              }
            });

          }
        )

        //console.log('candidateData===', candidateData)
        this.rejectRowData = candidateData;
        this.SpinnerService.hide('recruitment-details-candidte-list');
        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click(),
          this.viewContent = true;
        }, 300); 
    },
    error => {
      console.log(error)
      this.SpinnerService.hide('recruitment-details-candidte-list');
    })
  }


  onTabChanged($event){
    let clickedIndex = $event.index; 
    if(clickedIndex == 0){
      this.getInprocessCandidateList();
      //this.inProcessGridApi.setQuickFilter('in-process');
    }else if(clickedIndex == 1){
      this.getJoinedOpeningsList();
    }else if(clickedIndex == 2){
      this.getRejectOpeningsList();
    }
  }

  quickSearch(){
    this.inProcessGridApi.setQuickFilter(this.searchValue);
  }

  
  addWhatsApp(){
    const ref = this.modalService.open(CandidateWhatsappComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }

    );
   // ref.componentInstance.candidateName=editCandidateValues.name;
  }

  directAdd(){
    const ref = this.modalService.open(CandidateDirectComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.positionId=this.positionId;
  }

  scheduleInterview(data){ 
    console.log('test data', data)
    const ref = this.modalService.open(InterviewScheduleComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.interViewApplicationId = data.applicationId;
    ref.componentInstance.positionId = data.positionId;
    
  }

  rejectCandidate(data){
    console.log('reject test data', data)
    const ref = this.modalService.open(CandidateRejectComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.applicationId= data.applicationId;
    ref.componentInstance.rejectCandidateName = data.candidateName;
    ref.componentInstance.positionName = this.openingName;
    
  }

  candidateOffer(candidateData){
    console.log('open candidate offer -----',candidateData)
    const ref = this.modalService.open(CandidateOfferComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.candidateInfoObj = candidateData;
    ref.componentInstance.candidateId=candidateData.candidate.id;
    ref.componentInstance.positionId=candidateData.positionId;
    ref.componentInstance.applicationId=candidateData.applicationId;
    
    ref.componentInstance.candidateName=candidateData.candidateName;
    ref.componentInstance.candidatePhone=candidateData.phone;
    ref.componentInstance.candidateAddress=candidateData.city+', '+candidateData.candidate.currentCity.state.stateName;
    ref.componentInstance.emailValue = candidateData.candidate.emailId;
    ref.componentInstance.candidateStateId=candidateData.candidate.currentCity.state.stateId;
    ref.componentInstance.candidateDob=candidateData.candidate.dateOfBirth;
    ref.componentInstance.candidateGender=candidateData.candidate.sex;
    
  }

  onBtForEachNode() {
    console.log('### api.forEachNode() ###');
    this.inProcessGridApi.forEachNode(this.printNode);
  }

  printNode(node, index) {
    if (node.group) {
      console.log(index + ' -> group: ' + node.key);
    } else {
      console.log(
        index + ' -> data: ' + node.data.id + ', ' + node.data.fieldName
      );
    }
  }

  inprocessOnGridReady(params) {
    this.inProcessGridApi = params.api;
    this.inProcessGridColumnApi = params.columnApi;

  }

  joinedOnGridReady(params) {
    this.joinedGridApi = params.api;
    this.joinedGridColumnApi = params.columnApi;
  }

  rejectOnGridReady(params) {
    this.rejectGridApi = params.api;
    this.rejectGridColumnApi = params.columnApi;
  }

  
  modalResult(result: any) {
    //console.log('modal-close---',result)
    if(result === 'SUCCESS'){
      this.getInprocessCandidateList();
    }else if(result === 'ADD_CANDIDATE_SUCCESS'){
     // this.getInprocessCandidateList();
    }else if(result === 'ADD_CANDIDATE_POSITION_SUCCESS'){
      this.getInprocessCandidateList();
    }else if(result === 'REJECT_CANDIDATE_SUCCESS'){
      this.getInprocessCandidateList();
    }else if(result === 'INTERVIEW_SCHEDULED_SUCCESS'){
      this.getInprocessCandidateList();
    }else if(result === 'CANDIDATE_DATA_FILE_UPLOAD_FAILED'){
      this.getInprocessCandidateList();
      this.snackBar.open('Candidate added successfully but file upload failed, please edit and upload file ', '', {
        duration: 5000,
        panelClass: ['red-snackbar']
      });
    }
    else if(result === 'ERROR'){
    }
    else{ //for search
      this.updateItems(result.applicationId,result.currentStatus.applicationStatus, result.currentStatus.statusId )
    }
  }

  updateItems(applicationId:any, applicationStatus: any, applicationStatusId: any) {
    var itemsToUpdate = [];
    this.inProcessGridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
      var data = rowNode.data;
      if(data.applicationId == applicationId){
        data.status = applicationStatus;
        data.statusId = applicationStatusId;
      }
      itemsToUpdate.push(data);
    });
    var res = this.inProcessGridApi.applyTransaction({ update: itemsToUpdate });
    
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  converetTimeStamp(ts: any){
    //var ts_ms = ts * 1000;    // convert unix timestamp to milliseconds
    var ts_ms = ts;
    var date_ob = new Date(ts_ms);    // initialize new Date object
    var year = date_ob.getFullYear();   //// year as 4 digits (YYYY)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);   // month as 2 digits (MM)
    var date = ("0" + date_ob.getDate()).slice(-2);   // date as 2 digits (DD)
    var hours = ("0" + date_ob.getHours()).slice(-2);   // hours as 2 digits (hh)
    var minutes = ("0" + date_ob.getMinutes()).slice(-2);   // minutes as 2 digits (mm)
    var seconds = ("0" + date_ob.getSeconds()).slice(-2); // seconds as 2 digits (ss)

    var time = hours+':'+minutes;
    var interviewTime = this.timeConv24(time);
    
    //console.log("Date as YYYY-MM-DD Format: " + year + "-" + month + "-" + date);   // date as YYYY-MM-DD format
    //console.log("Date as YYYY-MM-DD hh:mm:ss Format: " + year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds);  // date & time as YYYY-MM-DD hh:mm:ss format
   // console.log("Time as hh:mm Format: " + hours + ":" + minutes);    // time as hh:mm format: 

    var dateTime = year + "-" + month + "-" + date + " at " + interviewTime;
    return dateTime;
  }

  timeConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    var hour = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " am" : " pm";
    ts = hour + ts.substr(2, 3);
    ts = ts+ ampm
    return ts;
  };

  getStatus(status_id){
    var statusText = '';
    var statusArray = [
      {status_id:999, status:"Legacy System Transfer - Assigned"},                          
      { status_id:1000, status:"Assigned"},                                                
      { status_id:1001, status:"Test Scheduled"},                                            
      { status_id:1002, status:"Awaiting Test Score"},                                        
      { status_id:1003, status:"Legacy System Transfer - Test Completed"},                    
      { status_id:1004, status:"Awaiting Interview Schedule"},                  
      { status_id:1005, status:"Interview Scheduled"},                               
      { status_id:1006, status:"Awaiting Interview Result"},                                  
      { status_id:1007, status:"Legacy System Transfer - Interview Result Under Discussion"},
      { status_id:1008, status:"Legacy System Transfer - Interview Result Under Review"},
      { status_id:1009, status:"Selected"},                                                   
      { status_id:1010, status:"Offered"},                                                  
      { status_id:1013, status:"Legacy System Transfer - Awaiting Joining"},                  
      { status_id:1014, status:"Joined"},                                                    
      { status_id:1019, status:"Legacy System Transfer - To Be Decided"},                     
      { status_id:1020, status:"Rejected"},                                                   
      { status_id:1021, status:"Not Joined"},                                                 
      { status_id:1022, status:"Declined"},
    ]
    statusArray.forEach(function(v){
      if(v.status_id == status_id){
        statusText = v.status;
      }
    }) 
    return statusText;
  }
  

  triggerClick() {}

  

}
