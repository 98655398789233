<div class="example-preview">
    <div>
        <form [formGroup]="updateRecruiterForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Update Recruiter</h4>
            </div>
            <div class="modal-body">
                
                <div class="form-group row" >
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Recruiter</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="recruiterName" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="recruiterFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let recruiterData of recruiterList| search : recruiterFilter.value :'name'" [value]="recruiterData.id">
                                    {{recruiterData.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isControlHasError('recruiterName', 'required')">Interviewer level cannot be blank</mat-error>
                            
                        </mat-form-field>
                    </div>
                </div> 

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="saveRecruiter()" >Save</button>
            </div>
        </form> 
    </div>
</div>