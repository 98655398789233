import { Component, OnInit, ViewEncapsulation, Input, ɵConsole } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NgbModal,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { StdLookupAttribute } from 'src/app/views/pages/contact-setting/models/std-lookup-attribute.model';
import { AttributeType } from 'src/app/views/pages/contact-setting/models/attribute-type.model';
import { Contact } from 'src/app/views/pages/contact-setting/models/contact.model';
import { DateAttributes } from 'src/app/views/pages/contact-setting/models/date-attributes.model';
import { LooupValues } from 'src/app/views/pages/contact-setting/models/looup-values.model';
import { CompanyService } from '../services/company.service';
import { ContactListService } from 'src/app/views/pages/contact-setting/services/contact-list.service';
import { CustomService } from 'src/app/views/pages/contact-setting/services/custom.service';
import { Company } from '../models/company.model';
import { CustomAttibute } from '../../../contact-setting/models/custom-attibute.model';
import {MatChipInputEvent} from '@angular/material/chips';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import { SelectedCustomTypes } from '../../../contact-setting/models/selected-custom-types.model';

@Component({
  selector: 'kt-edit-custom-field',
  templateUrl: './edit-custom-field.component.html',
  styleUrls: ['./edit-custom-field.component.scss']
})

export class EditCustomFieldComponent implements OnInit {
  errorMes:String="";
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  lookupVal: LooupValues[] = [];
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  mandatoryContact:boolean;
  mandatoryContactFlag:number;
 
  stdLookupList: StdLookupAttribute[];
  attributeType:AttributeType;
  resultSet:any;
  displayDateValueDiv: boolean;
  displayMultiSelectDiv: boolean;
  displaySingleSelectDiv:boolean;
  allCompanyTypeList: Company[];
  dateAttributes: DateAttributes;
 // lookUpValues: LooupValues[];
  lookup:LooupValues;
  standardTypeValue: StdLookupAttribute;
  companyTypes: Company[];
  companyTyp: Company;
  companyTypesId: string;
  customAttributeId: number;
  customAttributeName: string;
  attributeName: String;
  selectedattributeType: number;

  editCustomVal: CustomAttibute;
  editing:boolean;
  dateValueType;
  futureDateValue:number | string;
  previousDateValue:number | string;
  flag_Value: boolean;
  selectedCompanyCustomList: SelectedCustomTypes[];
  allCompanyTypList: Company[];
  disabled:boolean;
  duplicate:boolean;
  attributeTypelist:AttributeType[];
  standardTypelist:StdLookupAttribute[];
  isDisplayText:boolean;
  isDisplayImage:boolean;
  isDisplayNumeric:boolean;

  public attributeFilterType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();

  public editCustomFieldForm: FormGroup;

  protected _onDestroy = new Subject<void>();

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public companyService: CompanyService,
    public contactService: ContactListService,
    public customService: CustomService) {
      this.unsubscribe = new Subject();
     }

  ngOnInit(): void {
  
    this.initeditCustomFieldForm();
    this.editCustomFieldForm.reset();
    this.getAttributeType();
    this.editCustomFieldForm.reset();
    this.displayDateValueDiv=false;
    this.displayMultiSelectDiv=false;
    this.displaySingleSelectDiv=false;
    this.dateAttributes=new DateAttributes();
    this.dateAttributes.stdLookupAttribute= new StdLookupAttribute();
   // this.lookUpValues= new Array<LooupValues>();
    this.companyTypes= new Array<Company>();
    this.companyTypesId='';
    this.standardTypeValue= new StdLookupAttribute();
    this.disabled=false;
    this.duplicate=false;
    this.isDisplayText=false;
    this.isDisplayImage=false;
    this.isDisplayNumeric=false;

     
     this.attributeName=this.editCustomVal.attributeName;
     this.editCustomFieldForm.controls['attributeType'].setValue(this.editCustomVal.attributeType.id);
     
     //this.editCustomFieldForm.controls['attributeCompanyType'].setValue([1,2]);
    
     this.attributeType=this.editCustomVal.attributeType;
     if(null!= this.editCustomVal.dateAttributes){
     if(null!= this.editCustomVal.dateAttributes.stdLookupAttribute){
        this.dateValueType='Standard';
        this.editCustomFieldForm.controls['standardTypeValue'].setValue(this.editCustomVal.dateAttributes.stdLookupAttribute.id);
    
     }else{
        this.dateValueType='Custom';
        this.previousDateValue=this.editCustomVal.dateAttributes.previousDateAllowed;
        this.futureDateValue=this.editCustomVal.dateAttributes.futureDateAllowed;
     }
    }
     this.change(this.editCustomVal.attributeType.id);

     var flag=this.editCustomVal.mandatoryFlag;
      if(flag==1){
       this.flag_Value=true;
     }else{
      this.flag_Value=false;
      }
     
  }
  
  getAttributeType(){
    this.customService.getAtrributeType().subscribe(
		(result)=>{
        this.attributeTypelist=result;
		},
		error => {
			console.log(error)
    })
  }

  getStandardLookUpList(){
    var attributeId=this.attributeType.id;
    var attributeName=this.attributeType.typeName;
    this.customService.getstdLookUpList(attributeId,attributeName).subscribe(
		(result)=>{
        this.standardTypelist=result;
		},
		error => {
			console.log(error)
    })
  }

  initeditCustomFieldForm(){
    this.editCustomFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
     // attributeType: ['', Validators.compose([ Validators.required])],
      attributeType:[{value: '', disabled: true}],
      fieldInstruction:[],
      dateValueType: [''],
      attributeMultiSelectName:[''],
      attributeSingleName:[],
      attributeCompanyType:[],
      standardTypeValue:[],
      lookup:[],
      futureDateValue:[],
      previousDateValue:[],
      mandatoryCheck:[],
      unique:[],
      minCharater:[],
      maxCharater:[],
      charactersType:[],
      maxImageAllowed:[],
      allowBrowsing:[],
      numericValidationType:[],
      numericMinValue:[],
      numericMaxValue:[],
      digitMinValue:[],
      digitMaxValue:[]
     });
  }


  Save(){
    this.disabled=true;
    if(this.attributeType.id ==2){
      if(this.lookupVal != null && this.lookupVal.length!=0){
          this.editCustomFieldForm.controls['attributeMultiSelectName'].clearValidators();
          this.editCustomFieldForm.controls['attributeMultiSelectName'].updateValueAndValidity();
      }
    }

    if(this.attributeType.id ==1){
      if(this.lookupVal != null && this.lookupVal.length!=0){
          this.editCustomFieldForm.controls['attributeSingleName'].clearValidators();
          this.editCustomFieldForm.controls['attributeSingleName'].updateValueAndValidity();
      }
    }
    const controls = this.editCustomFieldForm.controls;
      if (this.editCustomFieldForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled=false;
        return;
      }
      const addAttributeData = {
        attributeName: controls.attributeName.value,
        dateValueType: controls.dateValueType.value,
        companyTypes: controls.attributeCompanyType.value,
        singleValues: controls.attributeSingleName.value,
        ValuesMutli: controls.attributeMultiSelectName.value,
        futureDateValue: controls.futureDateValue.value,
        previousDateValue: controls.previousDateValue.value
      };

      if(this.mandatoryContact == true){
        this.mandatoryContactFlag=1;
      }else{
        this.mandatoryContactFlag=0;
      }
 
      if(this.attributeType.id ==1){
    
      }else if(this.attributeType.id ==2){
      }else{
        this.lookupVal=[];
      }
      
      if(this.attributeType.id ==3){
        if(addAttributeData.dateValueType == 'Standard'){
            this.dateAttributes.stdLookupAttribute.id=this.standardTypeValue.id;
            this.dateAttributes.stdLookupAttribute.value=this.standardTypeValue.value;
            this.dateAttributes.previousDateAllowed=null;
            this.dateAttributes.futureDateAllowed=null;
            if(null!=this.editCustomVal.dateAttributes){
              this.dateAttributes.id=this.editCustomVal.dateAttributes.id;
            }
        }else if(addAttributeData.dateValueType =='Custom'){
            this.dateAttributes.previousDateAllowed=Number(addAttributeData.previousDateValue);
            this.dateAttributes.futureDateAllowed=Number(addAttributeData.futureDateValue);
            this.dateAttributes.stdLookupAttribute=null;
            if(null!=this.editCustomVal.dateAttributes){
              this.dateAttributes.id=this.editCustomVal.dateAttributes.id;
            }
        }else{
            this.dateAttributes=null;       
        }
      }


      this.companyService
      .editCustomFields(this.editCustomVal.id,addAttributeData.attributeName, this.attributeType,this.mandatoryContactFlag,
        this.lookupVal,this.dateAttributes, this.selectedProject)
        .pipe(
          tap(CustomAttibute => {
            //this.customAttributeId= CustomAttibute['responseData'].attributeId;
            console.log(CustomAttibute['responseData']);   
          }),
          takeUntil(this.unsubscribe),
          finalize(() => {
            this.loading = false;
            this.activeModal.close();
            this.editCustomFieldForm.reset();
          })
        )
        .subscribe(
          data => console.log("sucesssfullycalled Custom Attribute API"),
          error => {
            this.errorMes=error.error.responseData;
          }
          
        );  
  }

  changeStd(data){
   this.standardTypeValue.id=data;
  }

  changeMulti(data){
    this.companyTypesId=data.toString();
  }

  change(data){
    this.attributeType.id=data;
    if(this.attributeType.id ==3){
      this.displayDateValueDiv=true;
      this.getStandardLookUpList();
      this.editCustomFieldForm.controls.dateValueType.setValidators([Validators.required]);
      this.editCustomFieldForm.controls.dateValueType.updateValueAndValidity();
    }else{
      this.displayDateValueDiv=false;
      this.editCustomFieldForm.controls.dateValueType.clearValidators();
      this.editCustomFieldForm.controls.dateValueType.updateValueAndValidity();
      this.resetDateValue();
    }
    this.lookupVal=[];
    if(this.attributeType.id ==2){
      this.displayMultiSelectDiv=true;
      this.lookupVal=this.editCustomVal.lookupValues;
      
      this.editCustomFieldForm.controls.attributeMultiSelectName.setValidators([Validators.required]);
      this.editCustomFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }else{
      this.displayMultiSelectDiv=false;
     // this.lookupVal=[];
      this.editCustomFieldForm.controls.attributeMultiSelectName.clearValidators();
      this.editCustomFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }

    if(this.attributeType.id ==1){
      this.displaySingleSelectDiv=true;
      this.lookupVal=this.editCustomVal.lookupValues;
      this.editCustomFieldForm.controls.attributeSingleName.setValidators([Validators.required]);
      this.editCustomFieldForm.controls.attributeSingleName.updateValueAndValidity();
    }else{
      this.displaySingleSelectDiv=false;
     // this.lookupVal=[];
      this.editCustomFieldForm.controls.attributeSingleName.clearValidators();
      this.editCustomFieldForm.controls.attributeSingleName.updateValueAndValidity();
    }
    if(this.attributeType.id ==6){
      this.isDisplayText=true;
    }else{
      this.isDisplayText=false;
    }

    if(this.attributeType.id ==4){
      this.isDisplayImage=true;
    }else{
      this.isDisplayImage=false;
    }

    if(this.attributeType.id ==7){
      this.isDisplayNumeric=true;
    }else{
      this.isDisplayNumeric=false;
      this.editCustomFieldForm.controls['numericValidationType'].reset();
    }

  }
  

  isDisplayStandard(): boolean{
    if(this.editCustomFieldForm.controls.dateValueType.value =='Standard'){
      this.editCustomFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.editCustomFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    }else{
      this.editCustomFieldForm.controls.standardTypeValue.clearValidators();
      this.editCustomFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  isDisplayCustom(): boolean{
    if(this.editCustomFieldForm.controls.dateValueType.value =='Custom'){
      return true;
    }else{
      return false;
    }
  }

  changeValidation(id){
    console.log(id);
  }

  isDisplayNumericValue(): boolean{
    if(this.editCustomFieldForm.controls.numericValidationType.value =='1'){
      return true;
    }else{
      return false;
    }
  }

  isDisplayNumericDigit(): boolean{
    if(this.editCustomFieldForm.controls.numericValidationType.value =='2'){
      return true;
    }else{
      return false;
    }
  }

  showOptions(val) {
    this.mandatoryContact=val;
  }

  showOptionsEdit(val) {
    this.mandatoryContact=val;
    //this.editContactVal.primary=val;
  }
  resetDateValue(){
    this.editCustomFieldForm.controls['dateValueType'].reset();
  }

  Close(){
    this.activeModal.close();
    this.editCustomFieldForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editCustomFieldForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.duplicate=false;

    if ((value || '').trim()) {
      for(let fr of this.lookupVal){
        var name= fr.lookupValue;
        if(value.trim()==name){
          this.duplicate=true;
        }
      }
      if(this.duplicate==false){
      this.lookupVal.push({lookupValue: value.trim(),
      isActive: 1, stageId:null});
      }
    }
  
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(lookup: LooupValues): void {
    const index = this.lookupVal.indexOf(lookup);

    if (index >= 0) {
      this.lookupVal.splice(index, 1);
    }
  }

  isDisabled() : boolean {
    return this.disabled;
   }

}

