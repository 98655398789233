import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup,FormControl, FormBuilder,Validators, Form } from '@angular/forms';
import { LocalizationService } from '../../services/localization.service';
//utilities
import { LayoutUtilsService ,MessageType} from '../../../../../core/_base/crud';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-opportunity-setting',
  templateUrl: './opportunity-setting.component.html',
  styleUrls: ['./opportunity-setting.component.scss']
})
export class OpportunitySettingComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:String="";
  addSettingsForm:FormGroup;
  singularName: string;
  enforceLiveLocCapture: boolean;
  enableOpportunityFeature: boolean;
  pruralName:string;
  disabled:boolean;
  currentRouteUrl: string;
  prevsingularName: string;
  prevpruralName: string;
  @Output() updateOpportunityTabLabel = new EventEmitter<string>();

  constructor(private fb: FormBuilder,
     private _ss:LocalizationService,
     private layoutUtilsService: LayoutUtilsService,
     private SpinnerService: NgxSpinnerService,
     private router: Router,
    private subheaderService: SubheaderService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('Opportunity');
    }
    this.addSettingsForm = this.fb.group({
      enforceLiveLocCapture: [''],
      enableOpportunityFeature: [''],
      singularName: ['', Validators.compose([ Validators.required])],
      pruralName:['', Validators.compose([ Validators.required])],
     });

     this.disabled=false;
    this.getSettingsValues();    
  }

  getSettingsValues(){
    this.SpinnerService.show();
    this._ss.getOpportunitySettings(this.selectedProject).subscribe(
      resp=>{
        console.log(resp);
        var res=resp['responseData'];
        this.enforceLiveLocCapture=res.enforceLiveLocCapture ==1 ? true :false;
        this.enableOpportunityFeature = res.enableOpportunityFeature ==1 ? true :false;
        this.singularName=res.singularName;
        this.updateOpportunityTabLabel.emit(res.singularName);
        this.pruralName=res.pruralName;
        this.prevsingularName=res.singularName;
        this.prevpruralName=res.pruralName;
        this.toggleOpportunityFeature(this.enableOpportunityFeature);
        this.SpinnerService.hide();
        //this.addSettingsForm.controls['singularName'].setValue(res.singularName);
        //this.addSettingsForm.controls['pruralName'].setValue(res.pruralName);
      },
      err=>{
        console.log(err);
      }
    )
  }
   
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addSettingsForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  Save(){
    this.disabled=true;
    const controls = this.addSettingsForm.controls;
    if (this.addSettingsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
       
    const addSettingsData = {
        "projectId": this.selectedProject,  
        "enforceLiveLocCapture": controls.enforceLiveLocCapture.value ==true ? 1 :0,
        "enableOpportunityFeature": controls.enableOpportunityFeature.value ==true ? 1 :0,        
        "singularName":controls.singularName.value.trim(),
        "pruralName":controls.pruralName.value.trim()
    };
    this.disabled=false;
    
    this._ss.saveOpportunitySettings(addSettingsData).subscribe(
      data => {
        if(!(this.prevsingularName == this.addSettingsForm.controls.singularName.value) ||
        !(this.prevpruralName == this.addSettingsForm.controls.pruralName.value)){
          this.snackBar.open('Renamed value will appear on your next login', '', {
            duration: 2000,
          });
        }else{
          this.snackBar.open('Settings updated.', '', {
            duration: 3000,
            panelClass: ['green-snackbar']
          });
        }
        this.getSettingsValues();

      },
      error =>{
        this.errorMes=error.error.responseData;
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
      
    );
  }

  Cancel(){
    this.disabled=true;
    this.getSettingsValues();
    this.disabled=false;
  }

  isDisabled() : boolean {
    return this.disabled;
   }

   toggleOpportunityFeature(isChecked: any){     
     if(isChecked){
      this.addSettingsForm.controls.singularName.enable();
      this.addSettingsForm.controls.pruralName.enable();
      this.addSettingsForm.controls.enforceLiveLocCapture.enable();
     }else{
      this.addSettingsForm.controls.singularName.disable();
      this.addSettingsForm.controls.pruralName.disable();
      this.addSettingsForm.controls.enforceLiveLocCapture.disable();
     }     
   }

}
