import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmitterService } from '../shared/services/event-emitter.service';

@Component({
  selector: 'kt-contact-dashboard',
  templateUrl: './contact-dashboard.component.html',
  styleUrls: ['./contact-dashboard.component.scss']
})
export class ContactDashboardComponent implements OnInit {

  contactId: number;
  returnUrl: any;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  constructor(
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {

    this.eventEmitterService.onModalCloseResult(true);

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
		  this.returnUrl = params.returnUrl || '/';
	  });

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
        this.contactId = params.contactId;
        console.log('companyId : '+this.contactId);
    });

  }

}
