<div class="example-preview">
    <div>
        <!-- <div class="modal-header">
            <h4 class="modal-title">Add Candidate</h4>
            <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> -->
        <div class="modal-body">
            <mat-horizontal-stepper [linear]="isLinear" >
                <mat-step [stepControl]="firstFormGroup">
                  <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Add Number</ng-template>
                    
                    <!-- <mat-form-field>
                      <mat-label>Name</mat-label>
                      <input matInput placeholder="Name" formControlName="name" required>
                    </mat-form-field> -->
                    <div class="form-group row">
                      <div class="col-md-4">
                          <label class="col-form-label form-padding-top-25">Mobile Number</label>
                      </div>
                     
                      <div class="col-md-8">
                          <div class="row">
                              <div class="col-md-2">
                                  <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                                      <mat-select formControlName="mobilePrefix" >
                                          <mat-option value="+91">+91</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="isControlHasError('mobilePrefix', 'required')">Code cannot be blank</mat-error> 
                                   </mat-form-field>
                             </div>
                             <div class="col-md-10">
                              <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                  <input matInput formControlName="mobileNumber" (input)="onlyNumber($event.target.value)" maxlength="10" minlength="10">   <!--ktStrictNumberOnlyDirective-->
                                  <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                  <mat-error *ngIf="isControlHasErrorFirstForm('mobileNumber', 'required')">Mobile Number cannot be blank</mat-error> 
                                  <mat-error *ngIf="isControlHasErrorFirstForm('mobileNumber', 'minLength')">Enter 10 digit number</mat-error> 
                              </mat-form-field> 
                              </div>
                          </div>             
                      </div> 
                      </div>
                    
                    <div class="text-right mt-10">
                      <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                      <button type="button" class="btn btn-primary ml-2" matStepperNext (click)="goForward()">Next</button> <!--matStepperNext-->
                    </div>
                  </form>
                </mat-step>
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>More Details</ng-template>
                      <div class="form-group row">
                        <mat-form-field [hidden]="true">
                            <input matInput formControlName="candidateId">
                        </mat-form-field>
                          <div class="col-md-4">
                              <label class="col-form-label form-padding-top-25">Name</label>
                          </div>
                          <div class="col-md-4">
                              <mat-form-field appearance="standard" class="input-100" >
                                  <input matInput placeholder="First Name" formControlName="firstName" ktAutoTrimSpaces>
                                  <mat-label style="color: rgba(0,0,0,.54);">First Name</mat-label>
                                  <mat-error *ngIf="isControlHasError('firstName', 'required')">First Name cannot be blank</mat-error>
                                  <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                              </mat-form-field>                    
                          </div>
                          <div class="col-md-4">
                              <mat-form-field appearance="standard" class="input-100" >
                                  <input matInput placeholder="Last Name" formControlName="lastName" ktAutoTrimSpaces>
                                  <mat-label style="color: rgba(0,0,0,.54);">Last Name</mat-label>
                                  <!-- <mat-error *ngIf="isControlHasError('lastName', 'required')">Last Name cannot be blank</mat-error> -->
                                  <!-- <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> -->
                              </mat-form-field>                    
                          </div>
                      </div>
                      <div class="form-group row">
                          <div class="col-md-4">
                              <label class="col-form-label form-padding-top-25">Email</label>
                          </div>
                          <div class="col-md-8">
                              <mat-form-field appearance="standard" class="input-100" >
                                  <input matInput formControlName="email">
                                  <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">email</mat-icon>
                                  <!-- <mat-error *ngIf="isControlHasError('email', 'required')">Email address cannot be blank</mat-error>     -->
                              <!-- <mat-error *ngIf="isControlHasError('email', 'email')">Email address format1 is incorrect</mat-error>  -->
                                  <mat-error *ngIf="isControlHasError('email', 'pattern')">Email address format is incorrect</mat-error> 
                                  <mat-hint>Optional</mat-hint>
                                </mat-form-field>                    
                          </div>
                      </div>
                      <div class="form-group row">
                          <div class="col-md-4">
                              <label class="col-form-label form-padding-top-25">Current City</label>
                          </div>
                          <div class="col-md-8">
                              <mat-form-field class="mat-form-field-fluid input-100">
                                  <mat-select formControlName="cityName">
                                      <mat-option>
                                          <ngx-mat-select-search [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                      </mat-option>
                                      <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityId">
                                          {{city.cityName}}
                                      </mat-option>
                                  </mat-select>
                                  <mat-hint>Optional</mat-hint>
                              </mat-form-field>
                          </div>
                      </div>
                      <div class="form-group row">
                          <div class="col-md-4">
                              <label class="col-form-label form-padding-top-25">Resume</label>
                          </div>
                          <div class="col-md-8">
                                  <!-- <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                      <input matInput  formControlName="resume" ktStrictNumberOnlyDirective>
                                      <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint> 
                                  </mat-form-field>    -->
                                  
                                  <mat-form-field appearance="standard" class="resume-section input-100 form-padding-top-5" > 
                                      <input matInput formControlName="resume">
                                      <input type="file" formControlName="resumeFile" (change)="onChange($event)" style="opacity: 0; filter:alpha(opacity=0);  position: relative; top: -20px; width:100%"/>
                                      <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">folder</mat-icon>
                                      <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint> 
                                  </mat-form-field> 
                          </div>
                      </div>

                      <div [className]="isShown ? '' : 'd-none'">
                        <div class="form-group row" >
                            <div class="col-md-12">
                                <label class="col-form-label form-padding-top-25" style="color:red"><span><img class="same-height-width" style="height: 24px; width: 24px" src="./assets/media/svg/icons/General/Information.svg"></span> Candidate information will be overwritten with the updated data</label>
                            </div>
                        </div>
                      </div>
                      <div class="form-group row">
                          <div class="col-md-12">
                              <span style="color:red">{{errorMes}}</span>
                          </div>
                          
                      </div>
              
                    <!-- <div>
                      <button mat-button matStepperPrevious>Back</button>
                      <button mat-button matStepperNext>Next</button>
                    </div> -->
              
                    <div class="text-right mt-10">
                        <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                      <button type="button" class="btn btn-secondary ml-2" matStepperPrevious>Back</button>
                      <button type="button" class="btn btn-primary ml-2" (click)="saveCandidate()">Save</button>
                    </div>
                  </form>
                </mat-step>
                <!-- <mat-step>
                  <ng-template matStepLabel>Done</ng-template>
                  <p>You are now done.</p>
                
                  <mat-list>
                   <mat-list-item> <strong>Name:</strong> {{ this.firstFormGroup.value.name }}</mat-list-item>
                   <mat-list-item> <strong>Description:</strong> {{ this.firstFormGroup.value.description }}</mat-list-item>
              
               
                   <mat-list-item> <strong>Amount:</strong> {{ this.secondFormGroup.value.amount }}</mat-list-item>
                   <mat-list-item> <strong>Stock:</strong> {{ this.secondFormGroup.value.stock }}</mat-list-item>
                  </mat-list>
                
                  <div>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button (click)="stepper.reset()">Reset</button>
                    <button mat-button (click)="submit()">Submit</button>
                  </div>
                </mat-step> -->
              </mat-horizontal-stepper>
        </div>
    </div>
</div>

<ngx-spinner size="default" type="ball-spin-clockwise" name='add-candidate-direct-loader'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>


