import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators, Form } from '@angular/forms';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../models/company.model';
import { CompanyService } from '../../services/company.service';


@Component({
  selector: 'kt-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
  errorMes:String="";
  editCompanyForm:FormGroup;
  labelPosition: string = "after";
  selectedValue : string;
  editCompVal:Company;
  companyTypeName:string;
  statusValue;
  disabled:boolean;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  loading = false;

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private edit:CompanyService) { }

  ngOnInit(): void {
    console.log(this.editCompVal);

    this.editCompanyForm = this.fb.group({
      companyTypeName: ['', Validators.compose([
          Validators.required,
          Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ])
      ],
      //statusValue: ['', Validators.compose([Validators.required ])]
      statusValue:[]
     });

    this.editCompanyForm.reset();
    this.selectedValue=this.editCompVal.isActive.toString();
    this.companyTypeName=this.editCompVal.companyTypeName;
    console.log('name----'+this.companyTypeName);
    this.disabled=false;

  }

  Edit(){
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    this.disabled=true;
    const controls = this.editCompanyForm.controls;
    console.log('name---'+controls.companyTypeName.value);
    if (this.editCompanyForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    console.log('ggggggggggggggggggggggg');   
    const editRedcordData = {
        'companyTypeName':controls.companyTypeName.value,
        "projectId": this.selectedProject,  
        "isActive": Number(this.selectedValue),
        "id": this.editCompVal.id
    };
    
    
    console.log(editRedcordData);

    this.edit.editCompanyType(editRedcordData).subscribe(

      data => {
        console.log(data);
        this.loading = false;
        this.activeModal.close();
        this.editCompanyForm.reset();
      },
      error => {
        this.errorMes=error.error.responseData;
      }
      
    );
  }

  Close(){
    this.activeModal.close();
    this.editCompanyForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
      const control = this.editCompanyForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isDisabled() : boolean {
    return this.disabled;
} 

}
