import { Component, OnInit, ViewEncapsulation, Input, ɵConsole } from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormArray} from '@angular/forms';
import {NgbModal,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { StdLookupAttribute } from 'src/app/views/pages/contact-setting/models/std-lookup-attribute.model';
import { AttributeType } from 'src/app/views/pages/contact-setting/models/attribute-type.model';
import { Contact } from 'src/app/views/pages/contact-setting/models/contact.model';
import { DateAttributes } from 'src/app/views/pages/contact-setting/models/date-attributes.model';
import { LooupValues } from 'src/app/views/pages/contact-setting/models/looup-values.model';
import { ContactListService } from 'src/app/views/pages/contact-setting/services/contact-list.service';
import { CustomService } from 'src/app/views/pages/contact-setting/services/custom.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import { Company } from '../../company-configuration/_subs/models/company.model';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { FieldService } from '../services/field.service';
import { ListType } from '../../contact-setting/models/list-type.model';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from '../services/event-emitter.service';

@Component({
  selector: 'kt-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:String="";
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  lookupVal: LooupValues[];
  lookupValPrev:LooupValues[];
  modalCloseResult = false;
  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  mandatoryContact:boolean;
  allowBrowseVal:boolean;
  uniqueVal:boolean;
  mandatoryContactFlag:number;
  allowBrowsingFlag:number;
  uniqueFlag:number;
  statusFlag:number | string;
 
  stdLookupList: StdLookupAttribute[];
  attributeType:AttributeType;
  characterType:ListType;
  primaryContact:ListType;
  stage:ListType;
  numericValidationType:ListType;
  resultSet:any;
  displayDateValueDiv: boolean;
  displayMultiSelectDiv: boolean;
  displaySingleSelectDiv:boolean;
  displaySingleSelectDivTable:boolean;
  displaySingleContactDivTable:boolean;
  allCompanyTypeList: Company[];
  dateAttributes: DateAttributes;
 // lookUpValues: LooupValues[];
  //lookup:LooupValues;
  standardTypeValue: StdLookupAttribute;
  companyTypes: Company[];
  companyTyp: Company;
  companyTypesId: string;
  customAttributeId: number;
  customAttributeName: string;
  attributeName: string;
  instructions: string;
  selectedattributeType: number;
  attributeTypeVal : string;
  statusVal: string;

  editCustomVal: CustomAttibute;
  editing:boolean;
  dateValueType;
  futureDateValue:number | string;
  previousDateValue:number | string;
  flag_Value: boolean;
  browseVal:boolean;
  unique_Value:boolean;
  //selectedCompanyCustomList: SelectedCustomTypes[];
  allCompanyTypList: Company[];
  disabled:boolean;
  duplicate:boolean;
  attributeTypelist:AttributeType[];
  standardTypelist:StdLookupAttribute[];
  characterTypeList:ListType[];
  numericValidationTypeList:ListType[];
  stageTypeList:ListType[];
  entityTypeList:ListType[];
  entityfieldList:CustomAttibute[];
  primaryContactTypeList:ListType[];
  isDisplayText:boolean;
  isDisplayImage:boolean;
  isDisplayNumeric:boolean;
  isDisplayEmail:boolean;
  moduleType:string | number;
  singleSelectOption:boolean;
  multiSelectOption:boolean;
  disableMandatoryCheck:boolean;
  disableAttributeName:boolean;
  disableattributeSingleName:boolean;
  displayFields: boolean;
  displayLinkedFields: boolean;
  entityValue: string;
  linkedAttributeId: string;
  isDisplaySelection:boolean;
  isDisplaySelectionLock:boolean;
  selectionTypeList:AttributeType[];
  duplicateOptions: boolean;
  selectionType:string;


  public attributeFilterType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();
  public attributecharacterType: FormControl = new FormControl();
  public attributenumericValidationType: FormControl = new FormControl();
  public stageFilterType: FormControl = new FormControl();
  public contactFilterType: FormControl = new FormControl();
  public entityValueType: FormControl = new FormControl();
  public entityfieldType: FormControl = new FormControl();
  public selectionTypeValue: FormControl = new FormControl();

  public editFieldForm: FormGroup;
  disableSelect: boolean;

  protected _onDestroy = new Subject<void>();
  myFormValueChanges$;
  myFormValue$;
  //disableSelect = new FormControl(false);

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public _field: FieldService,
    public contactService: ContactListService,
    public customService: CustomService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog) {
      this.unsubscribe = new Subject();
     }

  ngOnInit(): void {
  
    console.log(this.editCustomVal);
   if((this.editCustomVal.moduleType=='3' && this.editCustomVal.systemAttribute !=null && this.editCustomVal.systemAttribute.id ==8) || 
        (this.editCustomVal.moduleType=='1' && this.editCustomVal.systemAttribute !=null && this.editCustomVal.systemAttribute.id ==10)){
          this.initeditCustomFieldOptionsForm();
     }else{
          this.initeditCustomFieldForm();
     }
     this.lookupValPrev=this.editCustomVal.lookupValues;
    this.editFieldForm.reset();
    this.getAttributeType();
    this.getcharacterTypeList();
    this.getnumericValidationTypeList();
    this.getstageTypeList();
    this.getprimaryContactTypeList();
    this.getentityTypeList();
    this.editFieldForm.reset();
    this.displayDateValueDiv=false;
    this.displayMultiSelectDiv=false;
    this.displaySingleSelectDiv=false;
    this.displaySingleSelectDivTable=false;
    this.displaySingleContactDivTable=false;
    this.dateAttributes=new DateAttributes();
    this.dateAttributes.stdLookupAttribute= new StdLookupAttribute();
   // this.lookUpValues= new Array<LooupValues>();
    this.companyTypes= new Array<Company>();
    this.companyTypesId='';
    this.standardTypeValue= new StdLookupAttribute();
    this.disabled=false;
    this.duplicate=false;
    this.isDisplayText=false;
    this.isDisplayImage=false;
    this.isDisplayNumeric=false;
    this.isDisplayEmail=false;
    this.moduleType=this.editCustomVal.moduleType;
    this.disableSelect=false;
    this.disableMandatoryCheck=false;
    this.singleSelectOption=true;
    this.multiSelectOption=true;
    this.displaySingleOption();
    this.displayMultipleOption();
    this.mandatoryCheckVal();
    this.disableAttributeName=false;
    this.disableattributeSingleName=false;
    this.isDisplaySelection=false;
    this.isDisplaySelectionLock=false;
    //console.log(this.editCustomVal);
    if(Number(this.moduleType) > 100){
      this.displayFields=true;
     // && (Number(this.editCustomVal.moduleType) == 105 || Number(this.editCustomVal.moduleType) == 294) &&
      if(this.editCustomVal.linkedAttributeId == null && this.editCustomVal.systemAttribute !=null &&
        this.editCustomVal.systemAttribute.id == 40){
            this.editFieldForm.controls['fieldValue'].setValue('2');
            this.entityValue='Estimate';
            this.linkedAttributeId='Estimate';
           // if(Number(this.editCustomVal.moduleType) == 105){
            this.getselectionTypeList();
            this.isDisplaySelectionLock=true;
            
            this.selectionType=this.editCustomVal.attributeType.typeName;
            console.log(this.selectionType);
            this.editFieldForm.controls['selectionType'].disable();
          //  }
           // this.editFieldForm.controls['selectionType'].setValue(this.editCustomVal.attributeType.typeName);

            //.editFieldForm.controls['linkedAttributeId'].setValue('Estimate');
      }
      else if(this.editCustomVal.linkedAttributeId == null || this.editCustomVal.linkedAttributeId==0){
          this.editFieldForm.controls['fieldValue'].setValue('1');
         
         // this.change(this.editCustomVal.attributeType.id);
      }
      else{
      
          this.editFieldForm.controls['fieldValue'].setValue('2');
          for(let ent of this.entityTypeList){
            if(ent.id == this.editCustomVal.linkedAttributeModuleId ){
                this.entityValue=ent.value;
            }
          }
         // this.editFieldForm.controls['entityValue'].setValue(this.editCustomVal.linkedAttributeModuleId);
          this.getCustomFields2(this.editCustomVal.linkedAttributeModuleId);
          
        //  console.log('valll-----'+this.linkedAttributeId)
          this.editFieldForm.controls['linkedAttributeId'].setValue(this.editCustomVal.linkedAttributeId);
      
          if((this.editCustomVal.linkedAttributeModuleId == 4) || ((this.editCustomVal.systemAttribute!= null && this.editCustomVal.systemAttribute.id == 27 && this.editCustomVal.systemAttribute.name == 'Contacts'))){
            this.getselectionTypeList();
            this.isDisplaySelection=true;
            this.editFieldForm.controls['selectionType'].setValue(this.editCustomVal.attributeType.id);

          }
      }
    }
     
     this.attributeName=this.editCustomVal.attributeName;
     this.instructions=this.editCustomVal.instructions;
    
     this.editFieldForm.controls['attributeType'].setValue(this.editCustomVal.attributeType.id);
     this.attributeTypeVal=this.editCustomVal.attributeType.typeName;
     
     //this.editFieldForm.controls['instructions'].setValue(this.editCustomVal.instruction);
  
     this.attributeType=this.editCustomVal.attributeType;
     if(null!= this.editCustomVal.dateAttributes){
     if(null!= this.editCustomVal.dateAttributes.stdLookupAttribute){
        this.dateValueType='Standard';
        this.editFieldForm.controls['standardTypeValue'].setValue(this.editCustomVal.dateAttributes.stdLookupAttribute.id);
    
     }else{
        this.dateValueType='Custom';
        this.previousDateValue=this.editCustomVal.dateAttributes.previousDateAllowed;
        this.futureDateValue=this.editCustomVal.dateAttributes.futureDateAllowed;
     }
    }
    
    this.characterType.id=this.editCustomVal.characterType;
    this.numericValidationType.id=this.editCustomVal.numericValidationType;
    this.statusFlag=this.editCustomVal.status;
    if(this.statusFlag == 1){
      this.statusVal='Active'
    }else if(this.statusFlag == 0){
      this.statusVal='Inactive'
    }
    
    this.change(this.editCustomVal.attributeType.id);

     var flag=this.editCustomVal.mandatoryFlag;
      if(flag==1){
       this.flag_Value=true;
     }else{
      this.flag_Value=false;
      }

      var flag2=this.editCustomVal.allowBrowsing;
      if(flag2!=null && flag2==1){
       this.browseVal=true;
      }else{
        this.browseVal=false;
      }

      var uniqueFlag=this.editCustomVal.hasUniqueValues;
      if(uniqueFlag!=null && uniqueFlag==1){
       this.unique_Value=true;
      }else{
        this.unique_Value=false;
      }

      this.editFieldForm.controls['status'].setValue(this.editCustomVal.status.toString());

      if(this.editCustomVal.fieldSpec ==0){
        this.disableSelect=true;
        this.editFieldForm.controls['status'].disable();
      }else{
        this.statusFlag=this.editCustomVal.status;
      }

      if((this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==16 && this.moduleType=='2') ||
          (this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==15 && this.moduleType=='1') ||
          (this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==21 && this.moduleType=='3')){
        this.disableAttributeName=true;
        this.editFieldForm.controls.attributeName.disable();
        this.editFieldForm.controls.attributeName.updateValueAndValidity();
      }

       // initialize stream on units
    //this.myFormValueChanges$ = this.editFieldForm.controls['optionsItems'].valueChanges;
    // subscribe to the stream so listen to changes on units
   // this.myFormValueChanges$.subscribe(skuItems => this.updateTotalUnitPrice(skuItems));
    this.disabled=false;
     
  }

  mandatoryCheckVal(){
    if((this.editCustomVal.moduleType=='2' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='1' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='3' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='4' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='101' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='102' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='104' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='105' && this.editCustomVal.fieldSpec==0)){
      this.disableMandatoryCheck=true;
    }

    if((Number(this.editCustomVal.moduleType) > 100 && this.editCustomVal.fieldSpec==0)){
      this.disableMandatoryCheck=true;
    }

    if((this.editCustomVal.moduleType=='3' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==6) ||
        (this.editCustomVal.moduleType=='1' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==12) ||
        (this.editCustomVal.moduleType=='1' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==13) ||
        (this.editCustomVal.moduleType=='4' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==37) ||
        (this.editCustomVal.moduleType=='293' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==28) ||
        (this.editCustomVal.moduleType=='294' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==28)){
              this.disableMandatoryCheck=false;
    }

  }
  
  getcharacterTypeList(){
    this.characterTypeList= new Array<ListType>();
    this.characterType= new ListType();
    this.characterTypeList.push({ id: 1, value:"All"});
    this.characterTypeList.push({ id: 2, value:"Alpha-Numeric"});
    this.characterTypeList.push({ id: 3, value:"Alphabet"});
  }

  getprimaryContactTypeList(){
    this.primaryContactTypeList= new Array<ListType>();
    this.primaryContact= new ListType();
    this.primaryContactTypeList.push({ id: 1, value:"Yes"});
    this.primaryContactTypeList.push({ id: 2, value:"No"});
  }
  
  getstageTypeList(){
    this.stageTypeList= new Array<ListType>();
    this.stage= new ListType();
    this.stageTypeList.push({ id: 1, value:"In Progress"});
    this.stageTypeList.push({ id: 2, value:"Closed Won"});
    this.stageTypeList.push({ id: 3, value:"Closed Lost"});
  }

  getnumericValidationTypeList(){
    this.numericValidationTypeList= new Array<ListType>();
    this.numericValidationType= new ListType();
    this.numericValidationTypeList.push({ id: 1, value:"Digits"});
    this.numericValidationTypeList.push({ id: 2, value:"Value"});
  //  this.numericValidationTypeList.push({ id: 0, value:"Select"});
  }

  getselectionTypeList(){
    this.selectionTypeList= new Array<AttributeType>();
    this.selectionTypeList.push({ id: 1, typeName:"Single Select Dropdown"});
    this.selectionTypeList.push({ id: 2, typeName:"Multi Select Dropdown"});

  }

  getAttributeType(){
    this.customService.getAtrributeType().subscribe(
		(result)=>{
        this.attributeTypelist=result;
		},
		error => {
			console.log(error)
    })
  }

  getStandardLookUpList(){
    var attributeId=this.attributeType.id;
    var attributeName=this.attributeType.typeName;
    this.customService.getstdLookUpList(attributeId,attributeName).subscribe(
		(result)=>{
        this.standardTypelist=result;
		},
		error => {
			console.log(error)
    })
  }

  getentityTypeList(){
    this.entityTypeList= new Array<ListType>();
    this.stage= new ListType();
    this.entityTypeList.push({ id: 1, value:"Contact"});
    this.entityTypeList.push({ id: 2, value:"Customer"});
    this.entityTypeList.push({ id: 3, value:"Opportunity"});
    this.entityTypeList.push({ id: 4, value:"Product"});
    //this.entityTypeList.push({ id: 5, value:"Activity"});
  }

  getCustomFields(moduleType){
    this._field.getFields(moduleType, this.selectedProject)
    .subscribe(
    (result)=>{
    this.entityfieldList=result['responseData'];
 //   console.log(this.entityfieldList);
    },
    error => {
      console.log(error)
    })
  }

  getCustomFields2(moduleType){
    this._field.getFields(moduleType, this.selectedProject)
    .subscribe(
    (result)=>{
      result['responseData'].forEach(
        row => {
          if(row.id==this.editCustomVal.linkedAttributeId){
            this.linkedAttributeId=row.attributeName;
          } 
        }
      )
    this.entityfieldList=result['responseData'];
  //  console.log(this.entityfieldList);
    },
    error => {
      console.log(error)
    })
  }

  initeditCustomFieldForm(){
    this.editFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
     // attributeType: ['', Validators.compose([ Validators.required])],
      attributeType:[{value: '', disabled: true}],
      instructions:[],
      dateValueType: [''],
      attributeMultiSelectName:[''],
      attributeSingleName:[],
      attributeCompanyType:[],
      standardTypeValue:[],
      lookup:[],
      futureDateValue:[],
      previousDateValue:[],
      mandatoryCheck:[],
      unique:[],
      minCharacters:[],
      maxCharacters:[],
      characterType:[],
      maxImagesAllowed:[],
      allowBrowsing:[],
      numericValidationType:[],
      numericMinValue:[],
      numericMaxValue:[],
      minDigits:[],
      maxDigits:[],
      status:[],
      fieldValue:[{value: '', disabled: true}],
      entityValue:[{value: '', disabled: true}],
      linkedAttributeId:[{value: '', disabled: true}],
      selectionType:[]
     });
  }

  initeditCustomFieldOptionsForm(){
    this.editFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
    //  attributeType: ['', Validators.compose([ Validators.required])],
      attributeType:[{value: '', disabled: true}],
      instructions:[],
      dateValueType: [''],
      attributeMultiSelectName:[''],
      attributeSingleName:[],
      attributeCompanyType:[],
      standardTypeValue:[],
      lookup:[],
      futureDateValue:[],
      previousDateValue:[],
      mandatoryCheck:[],
      unique:[],
      minCharacters:[],
      maxCharacters:[],
      characterType:[],
      maxImagesAllowed:[],
      allowBrowsing:[],
      numericValidationType:[],
      numericMinValue:[],
      numericMaxValue:[],
      minDigits:[],
      maxDigits:[],
      status:[],
      OptionsItems: this.fb.array([ this.getOptions()])
     });
  }

  private getOptions() {
    return this.fb.group({
     // OptionVal: ['', Validators.compose([ Validators.required])],
     // stageId: ['', Validators.compose([ Validators.required])],
     OptionVal:[],
     stageId:[]
    });
  }

  addTable() {
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
    control.push(this.getOptions());
  }

  deleteRow(i: number) {
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
    
    if((this.editFieldForm.get('OptionsItems') as FormArray).controls.length > 1){
    control.removeAt(i);
    }
  }

  addArray(){
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
    const itemsArray=[];
     this.editCustomVal.lookupValues.forEach(data=>{
      itemsArray.push(
          {
              "OptionVal":data.lookupValue,
              "stageId": data.stageId,
          }
        );
  
    })
    for (let i = 1; i < itemsArray.length; i++) {
      control.push(this.getOptions());
      }
   //   console.log('item arrr------------------');
    //  console.log(itemsArray);
      this.editFieldForm.patchValue({OptionsItems: itemsArray});
   // console.log(skuDetailData);
  }


  Save(){
   
    this.disabled=true;
    if(this.displaySingleSelectDivTable || this.displaySingleContactDivTable){

      const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
      var length=this.editFieldForm.controls.OptionsItems.value.length;
  
      for(var i=0;i<length;i++){
        control.at(+i).get('OptionVal').setValidators([Validators.required]);
        control.at(+i).get('OptionVal').updateValueAndValidity();

        control.at(+i).get('stageId').setValidators([Validators.required]);
        control.at(+i).get('stageId').updateValueAndValidity();
      }

     for(var i=0;i<length;i++){
      if(control.at(+i).get('OptionVal').invalid){
        control.at(+i).get('OptionVal').markAllAsTouched();
        this.disabled=false;
        return;
      }

      if(control.at(+i).get('stageId').invalid){
        control.at(+i).get('stageId').markAllAsTouched();
        this.disabled=false;
        return;
      }
     
    }

    }
    if(this.attributeType.id ==2){
      if(this.lookupVal != null && this.lookupVal.length!=0){
          this.editFieldForm.controls['attributeMultiSelectName'].clearValidators();
          this.editFieldForm.controls['attributeMultiSelectName'].updateValueAndValidity();
      }
    }

    if(this.attributeType.id ==1){
      if(this.lookupVal != null && this.lookupVal.length!=0){
          this.editFieldForm.controls['attributeSingleName'].clearValidators();
          this.editFieldForm.controls['attributeSingleName'].updateValueAndValidity();
      }
    }
    const controls = this.editFieldForm.controls;
      if (this.editFieldForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        
        this.disabled=false;
        return;
      }

      const addAttributeData = {
        //attributeName: controls.attributeName.value,
        dateValueType: controls.dateValueType.value,
        companyTypes: controls.attributeCompanyType.value,
        singleValues: controls.attributeSingleName.value,
        ValuesMutli: controls.attributeMultiSelectName.value,
        futureDateValue: controls.futureDateValue.value,
        previousDateValue: controls.previousDateValue.value,
        instructions: controls.instructions.value==null? '': controls.instructions.value.trim(),
        characterType:controls.characterType.value,
        minCharacters:controls.minCharacters.value,
        maxCharacters:controls.maxCharacters.value,
        allowBrowsing:controls.allowBrowsing.value,
        maxImagesAllowed:controls.maxImagesAllowed.value,
        numericValidationType:controls.numericValidationType.value,
        minDigits:controls.minDigits.value,
        maxDigits:controls.maxDigits.value,
        minNumber:controls.numericMinValue.value,
        maxNumber:controls.numericMaxValue.value,
        linkedAttributeId:this.editCustomVal.linkedAttributeId
        //linkedAttributeId:controls.linkedAttributeId.value
        
      };
      
      if(addAttributeData.numericValidationType == 1){
        var min = addAttributeData.minDigits;
        var max = addAttributeData.maxDigits;
        if(min!="" && max!=""){
          if(min!=null && max!=null){
            if(Number(min) > Number(max)){
              var message='Minimum digits cannot be greater than Maximum digits';
              this.OpenAlertValidation(message);
              this.disabled=false;
              return false;
            }
          }
        }
      }
      if(addAttributeData.numericValidationType == 2){
        var min = addAttributeData.minNumber;
        var max = addAttributeData.maxNumber;
        if(min!="" && max!=""){
          if(min!=null && max!=null){
            if(Number(min) > Number(max)){
              var message='Minimum value cannot be greater than Maximum value';
              this.OpenAlertValidation(message);
              this.disabled=false;
              return false;
            }
          }
        }
      }
     
      if(this.attributeType.id == 6 || this.attributeType.id == 8){
        var min = addAttributeData.minCharacters;
        var max = addAttributeData.maxCharacters;
        if(min!="" && max!=""){
          if(min!=null && max!=null){
            if(Number(min) > Number(max)){
              var message='Minimum characters cannot be greater than Maximum characters';
              this.OpenAlertValidation(message);
              this.disabled=false;
              return false;
            }
          }
      }
      }

      if(this.disableMandatoryCheck){
        this.mandatoryContactFlag=this.editCustomVal.mandatoryFlag;
      }else{
        if(this.mandatoryContact == undefined || this.mandatoryContact==null){
            this.mandatoryContactFlag=this.editCustomVal.mandatoryFlag;
        }else{
          if(this.mandatoryContact == true){
            this.mandatoryContactFlag=1;
          }else{
            this.mandatoryContactFlag=0;
          }
        }
      }

      if(this.allowBrowseVal == undefined || this.allowBrowseVal==null){
          this.allowBrowsingFlag=this.editCustomVal.allowBrowsing;
      }else{
        if(this.allowBrowseVal == true){
          this.allowBrowsingFlag=1;
        }else{
          this.allowBrowsingFlag=0;
        }
      } 

      if(this.uniqueVal == undefined || this.uniqueVal==null){
        this.uniqueFlag=this.editCustomVal.hasUniqueValues;
      }else{
        if(this.uniqueVal == true){
          this.uniqueFlag=1;
        }else{
          this.uniqueFlag=0;
        }
      } 
 
      if(this.attributeType.id ==1){
    
      }else if(this.attributeType.id ==2){
      }else{
        this.lookupVal=[];
      }
      
      if(this.attributeType.id ==3){
        if(addAttributeData.dateValueType == 'Standard'){
            this.dateAttributes.stdLookupAttribute.id=this.standardTypeValue.id;
            this.dateAttributes.stdLookupAttribute.value=this.standardTypeValue.value;
            this.dateAttributes.previousDateAllowed=null;
            this.dateAttributes.futureDateAllowed=null;
            if(null!=this.editCustomVal.dateAttributes){
              this.dateAttributes.id=this.editCustomVal.dateAttributes.id;
            }
        }else if(addAttributeData.dateValueType =='Custom'){
            this.dateAttributes.previousDateAllowed=addAttributeData.previousDateValue;
            this.dateAttributes.futureDateAllowed=addAttributeData.futureDateValue;
            this.dateAttributes.stdLookupAttribute=null;
            if(null!=this.editCustomVal.dateAttributes){
              this.dateAttributes.id=this.editCustomVal.dateAttributes.id;
            }
        }else{
            this.dateAttributes=null;       
        }
      }

    if(this.disableSelect){
      this.statusFlag=this.editCustomVal.status;
    }else{
      this.statusFlag= controls.status.value ==true ? 1 :0
    }

    if(this.disableAttributeName){
      this.attributeName=this.editCustomVal.attributeName;
    }

   // if(this.disableattributeSingleName){
    //      this.lookupVal=this.editCustomVal.lookupValues;
   // }
    if(this.displaySingleSelectDivTable || this.displaySingleContactDivTable){
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
    var length=this.editFieldForm.controls.OptionsItems.value.length;
    var allVal='';
    this.duplicateOptions=false;


    for(var i=0;i<length;i++){
      var optValue=control.at(+i).get('OptionVal').value.trim();
     
      //console.log('alll----'+allVal);
      //console.log('sub---'+optValue);
      //console.log(allVal.includes(optValue.toLowerCase()));
      var condition=allVal.includes(optValue.toLowerCase());
    
      allVal=allVal+','+optValue.toLowerCase();
      if(condition == false){
        this.lookupVal.push({lookupValue:optValue,
          isActive: 1, stageId:control.at(+i).get('stageId').value});
      }else{
        this.duplicateOptions=true;
      }
      
    }
    if(this.duplicateOptions){
      var message='Enter unique values for the options';
      this.OpenAlertValidation(message);
      this.lookupVal=[];
      this.disabled=false;
      return;
    }
  }
 // console.log('char id-----'+ this.characterType.id);
 // console.log(this.lookupVal);
  this._field
  .editFields(this.editCustomVal.id,this.attributeName.trim(), this.attributeType,this.mandatoryContactFlag,
    this.lookupVal,this.dateAttributes,this.moduleType,this.allowBrowsingFlag,addAttributeData.instructions,
    this.characterType.id,addAttributeData.maxCharacters,addAttributeData.maxDigits,addAttributeData.maxImagesAllowed,
    addAttributeData.minCharacters,addAttributeData.minDigits,this.numericValidationType.id,this.editCustomVal.fieldSpec,
    this.editCustomVal.displayOrder,addAttributeData.minNumber,addAttributeData.maxNumber,this.statusFlag,this.uniqueFlag,
    addAttributeData.linkedAttributeId, this.selectedProject)
    
    .pipe(
      tap(CustomAttibute => {
        console.log(CustomAttibute['responseData']);
      }),
      takeUntil(this.unsubscribe),
      finalize(() => {
        
      })
    )
    .subscribe(
      data =>{ console.log("sucesssfullycalled Custom Attribute API");
      this.loading = false;
        this.activeModal.close('SUCCESS');
        this.editFieldForm.reset();
        this.eventEmitterService.onModalCloseResult('SUCCESS');
    },
      error => {
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );    
  
     
  }

  changeStd(data){
   this.standardTypeValue.id=data;
  }

  changeMulti(data){
    this.companyTypesId=data.toString();
  }

  displaySingleOption(){
    if(null!=this.editCustomVal.moduleType && null!=this.editCustomVal.systemAttribute){
    if((this.editCustomVal.moduleType=='2'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==16) ||
        (this.editCustomVal.moduleType=='1'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==11) ||
        (this.editCustomVal.moduleType=='1'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==15) ||
        (this.editCustomVal.moduleType=='3'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==5) ||
        (this.editCustomVal.moduleType=='3'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==21) ||
        (this.editCustomVal.moduleType=='105'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==40) ||
        (this.editCustomVal.moduleType=='2'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==34) ||
        (this.editCustomVal.moduleType=='1'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==35)){
      this.singleSelectOption=false;
    }
  }
  }

  displayMultipleOption(){
    
    if(null!=this.editCustomVal.moduleType && null!=this.editCustomVal.systemAttribute){
    if((this.editCustomVal.moduleType=='1'&& this.editCustomVal.systemAttribute.id==11)||
        (this.editCustomVal.moduleType=='3'&& this.editCustomVal.systemAttribute.id==6) ||
        (this.editCustomVal.moduleType=='2'&& this.editCustomVal.systemAttribute.id==34)){
      this.multiSelectOption=false;
    }
  }
  }

  changeEntity(data){
    this.getCustomFields(data);
    if(data == 4){
      this.getselectionTypeList();
      this.isDisplaySelection = true;
      this.editFieldForm.controls.selectionType.setValidators([Validators.required]);
      this.editFieldForm.controls.selectionType.updateValueAndValidity();
    }else{
      this.isDisplaySelection=false;
      this.editFieldForm.controls.selectionType.clearValidators();
      this.editFieldForm.controls.selectionType.updateValueAndValidity();
    }
  }

  changeSelectionType(data){
    this.attributeType.id=data;
  }


  change(data){
   
    this.attributeType.id=data;
    if(this.editCustomVal.linkedAttributeId == null || this.editCustomVal.linkedAttributeId==0){
    
    if(this.attributeType.id ==3){
      this.displayDateValueDiv=true;
      this.getStandardLookUpList();
      this.editFieldForm.controls.dateValueType.setValidators([Validators.required]);
      this.editFieldForm.controls.dateValueType.updateValueAndValidity();
    }else{
      this.displayDateValueDiv=false;
      this.editFieldForm.controls.dateValueType.clearValidators();
      this.editFieldForm.controls.dateValueType.updateValueAndValidity();
      this.resetDateValue();
    }
    this.lookupVal=[];
    if(this.attributeType.id ==2 && this.multiSelectOption==true){
      this.displayMultiSelectDiv=true;
      this.lookupVal=this.editCustomVal.lookupValues;
      
      this.editFieldForm.controls.attributeMultiSelectName.setValidators([Validators.required]);
      this.editFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }else{
      this.displayMultiSelectDiv=false;
     // this.lookupVal=[];
      this.editFieldForm.controls.attributeMultiSelectName.clearValidators();
      this.editFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }

   
    if(this.attributeType.id ==1 && this.singleSelectOption==true){
      if((this.moduleType=='3' && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id ==8)){
        this.displaySingleSelectDivTable=true;
        this.addArray();
      }
     // else if( (this.moduleType=='1' && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id ==10)){
     //   this.displaySingleContactDivTable=true;
     //   this.addArray();
     // }
     else if(this.editCustomVal.linkedAttributeId == null && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id == 40){
        this.displaySingleSelectDiv=false;
     }
      else{
      this.displaySingleSelectDiv=true;
      this.lookupVal=this.editCustomVal.lookupValues;
      this.editFieldForm.controls.attributeSingleName.setValidators([Validators.required]);
        if((this.moduleType=='2' && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id ==17) || 
            (this.editCustomVal.moduleType=='3'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==21) ||
            (this.editCustomVal.moduleType=='1'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==14) ||
            (this.editCustomVal.moduleType=='4'&& this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id==39) ||
            (this.editCustomVal.moduleType=='4'&& this.editCustomVal.id==94)){
           this.disableattributeSingleName=true;
           this.editFieldForm.controls.attributeSingleName.disable();
        }
      this.editFieldForm.controls.attributeSingleName.updateValueAndValidity();
      }
    }else{
      this.displaySingleSelectDiv=false;
     // this.lookupVal=[];
      this.editFieldForm.controls.attributeSingleName.clearValidators();
      this.editFieldForm.controls.attributeSingleName.updateValueAndValidity();
    }
    if(this.attributeType.id ==6 || this.attributeType.id ==8){
      this.isDisplayText=true;
      this.editFieldForm.controls['characterType'].setValue(this.editCustomVal.characterType);
      this.editFieldForm.controls['minCharacters'].setValue(this.editCustomVal.minCharacters);
      this.editFieldForm.controls['maxCharacters'].setValue(this.editCustomVal.maxCharacters);
    }else{
      this.isDisplayText=false;
      this.editFieldForm.controls['characterType'].reset();
    }

    

    if(this.attributeType.id ==4){
      
      this.isDisplayImage=true;
      var flag2=this.editCustomVal.allowBrowsing;
      if(flag2!=null && flag2==1){
       this.browseVal=true;
     }else{
      this.browseVal=false;
      }
      this.editFieldForm.controls['maxImagesAllowed'].setValue(this.editCustomVal.maxImagesAllowed);
    }else{
    
      this.isDisplayImage=false;
    }

    if(this.attributeType.id ==7){
      this.isDisplayNumeric=true;
      this.editFieldForm.controls['numericValidationType'].setValue(this.editCustomVal.numericValidationType);
      
      if(this.editCustomVal.numericValidationType == 2){
        this.editFieldForm.controls['numericMinValue'].setValue(this.editCustomVal.minNumber);
        this.editFieldForm.controls['numericMaxValue'].setValue(this.editCustomVal.maxNumber);
      }
      
      if(this.editCustomVal.numericValidationType == 1){
      this.editFieldForm.controls['minDigits'].setValue(this.editCustomVal.minDigits);
      this.editFieldForm.controls['maxDigits'].setValue(this.editCustomVal.maxDigits);
      }
    }else{
      this.isDisplayNumeric=false;
      this.editFieldForm.controls['numericValidationType'].reset();
    }

    if(this.attributeType.id ==9){
      this.isDisplayEmail=true;
    }else{
      this.isDisplayEmail=false;
    }
  }else{
    if(this.attributeType.id ==4){
      
      this.isDisplayImage=true;
      var flag2=this.editCustomVal.allowBrowsing;
      if(flag2!=null && flag2==1){
       this.browseVal=true;
     }else{
      this.browseVal=false;
      }
      this.editFieldForm.controls['maxImagesAllowed'].setValue(this.editCustomVal.maxImagesAllowed);
    }else{
     
      this.isDisplayImage=false;
    }
  }
  }
  

  isDisplayStandard(): boolean{
    if(this.editFieldForm.controls.dateValueType.value =='Standard'){
      this.editFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.editFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    }else{
      this.editFieldForm.controls.standardTypeValue.clearValidators();
      this.editFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  isDisplayCustom(): boolean{
    if(this.editFieldForm.controls.dateValueType.value =='Custom'){
      return true;
    }else{
      return false;
    }
  }

  changeValidation(data){
    this.numericValidationType.id=data;
  }

  changeCharacterType(data){
    this.characterType.id=data;
  }

  isDisplayNumericValue(): boolean{
    if(this.editFieldForm.controls.numericValidationType.value =='2'){
      
      return true;
    }else{
      return false;
    }
  }

  isDisplayNumericDigit(): boolean{
    if(this.editFieldForm.controls.numericValidationType.value =='1'){
     
   
      return true;
    }else{
      return false;
    }
  }

  isDisplayexistings(): boolean{
    if(Number(this.moduleType) > 100){
    if(this.editFieldForm.controls.fieldValue.value =='2'){
     // this.addFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
     // this.addFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    }else{
      return false;
    }
  }else{
      return false;
    }
  }

  showOptions(val) {
    this.allowBrowseVal=val;
  }

  showOptionsUnique(val) {
    this.uniqueVal=val;
  }

  showOptionsEdit(val) {
    this.mandatoryContact=val;
    //this.editContactVal.primary=val;
  }
  resetDateValue(){
    this.editFieldForm.controls['dateValueType'].reset();
  }

  Close(){
    this.editCustomVal.lookupValues=this.lookupValPrev;
    this.activeModal.close();
    this.editFieldForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editFieldForm.controls[controlName];
      if (!control) {
        return false;
      }

      if(controlName == "attributeSingleName" || controlName == "attributeMultiSelectName") {
        if(this.lookupVal.length > 0 )
            control.setErrors(null);
        else
          control.setErrors({ required: true });
      }
      
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isControlHasErrorItems(controlName: string, validationType: string): boolean {
    //const control = this.editFieldForm.controls[controlName];
    const control= this.editFieldForm.controls['OptionsItems'].get(controlName);
      if (!control) {
        return false;
      }


    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
    //  console.log('result------'+result);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.duplicate=false;

    if ((value || '').trim()) {
      for(let fr of this.lookupVal){
        var name= fr.lookupValue;
        if(value.trim().toLowerCase()==name.toLowerCase()){
          this.duplicate=true;
        }
      }
      if(this.duplicate==false){
       // this.lookupVal.push({lookupValue: value.trim(),isActive: 1, stageId:null});
        this.lookupVal=this.lookupVal.concat({lookupValue: value.trim(),
        isActive: 1, stageId:null});
      }
    }
  
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(lookup): void {
   // remove(lookup: LooupValues): void {
    //  const index = this.lookupVal.indexOf(lookup);
    //if (index >= 0) {
      //this.lookupVal.splice(index, 1);
    //}
    this.lookupVal = this.lookupVal.filter(item => item.lookupValue != lookup.lookupValue);
  
  }

  isDisabled() : boolean {
    return this.disabled;
   }

   getControls() {
    return (this.editFieldForm.get('OptionsItems') as FormArray).controls;
  }

  OpenAlert(data){
    if(data == 0 || data =='Inactive') {
      if(this.editCustomVal.hasChildAttribute == true){
        const initialNotice = `Making this field inactive will hide all the dependent fields from add form. Delete relationships of dependent fields to make them visible in add form.`;
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '600px',
          position: {top: '20px'},
          data: { title: 'Alert', body: initialNotice }
        });
      }
    }
  }

  OpenAlertValidation(message){
       const initialNotice = message;
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '600px',
          position: {top: '20px'},
          data: { title: 'Alert', body: initialNotice }
        });
     
  }

}
