import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
//import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
//import {MatStepperModule} from '@angular/material/stepper';
import { RecruitmentCandidateService } from '../../services/recruitment-candidate.service';
import { ControllersService } from 'ag-grid-community';
import { MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-candidate-offer',
  templateUrl: './candidate-offer.component.html',
  styleUrls: ['./candidate-offer.component.scss']
})
export class CandidateOfferComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public addDirectCandidateForm: FormGroup;
  disabled:boolean;
  userPwdCheck:boolean;
  isShown:boolean;
  isHide:boolean;
  errorMes:String="";
  cityList:[];
  gradeList:[];
  bandList:[];
  claimPolicyList:[];
  mediclaimPolicyList:[];
  pfContributionList:[];
  genderList:any;
  candidateStateId:any;
  candidateId: any;
  positionId: any;
  applicationId:any;
  fixedCtcPercentage:any
  variableCtcPercentage:any
  budgetStateId:any
  gratuity:any
  isGratuityShow: boolean
  aadhaarNo:any;

  savedTotalAnnualCtc: any;
  savedFixedAnnualCtc: any;
  savedVariableAnnualIncentive: any;
  savedPfOption: any;

  public cityNameFilter: FormControl = new FormControl();
  //public gradeNameFilter: FormControl = new FormControl();
  //public bandNameFilter: FormControl = new FormControl();
  public claimPolicyFilter: FormControl = new FormControl();
  public mediclaimPolicyFilter: FormControl = new FormControl();
  public pfContributionFilter: FormControl = new FormControl();
  public genderFilter: FormControl = new FormControl();
  
  //Input fields value
  emailValue:String = ""; 
  candidateName:String = "";
  candidatePhone:String="";
  candidateAddress:String="";
  candidateInfoObj:any;
  candidateDob:any = "";
  candidateGender:any = "";

  //for step form
  title = 'newMat';
  isLinear = true;
  firstFormGroup: FormGroup;   //offerDetailsFromGroup
  secondFormGroup: FormGroup;  //salaryStructureFormGroup

  minDate: Date;  //Date Picker
  maxJoiningDate: Date;

  minDobDate: Date;  //Date Picker
  maxDobDate: Date;

  //Salary
  minSalaryMap:any
  salaryMap:any;
  candidateMaxCtc: any
  candidateMinCtc: any
  lockFixedVariable: boolean;

  customErrorMessageFlag:boolean
  customErrorMsg: any
  appointmentType:any
  contractEndDate:any;
  checkAadharStatuaDat:any;
  hasSalaryErrors: boolean;

  constructor(
    private _rmcs:RecruitmentCandidateService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    //private _as:AddressService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }


  ngOnInit(): void {
    this.genderList=[
      {"name":"Male", "value":"male"},
      {"name":"Female", "value":"female"}
    ]

    this.customErrorMessageFlag = false;
    let minJoiningDate = new Date();
        minJoiningDate.setDate(minJoiningDate.getDate() - 3);

    let tempMaxJoiningDate = new Date();    
        tempMaxJoiningDate.setDate(tempMaxJoiningDate.getDate() + 180);
    
    this.minDate = minJoiningDate;
    this.maxJoiningDate = tempMaxJoiningDate;


    let minDobDateTemp = new Date();
        minDobDateTemp.setDate(minJoiningDate.getDate() - 3);

    let maxDobDateTemp = new Date();    
        maxDobDateTemp.setFullYear(maxDobDateTemp.getFullYear()-18, maxDobDateTemp.getMonth(), maxDobDateTemp.getDay());

    this.minDobDate = new Date(1989, 2, 1);  //Date Picker
    this.maxDobDate = maxDobDateTemp

    this.getAllCities();
    this.initOfferDetailsForm();  
    this.initSalaryStructureForm();
    this.setByDefaultValue();
    //this.getGradeList();
    //this.getBandList();
    this.getPolicyList();
    this.setPfcontrubution();
    this.disabled=false;
    this.userPwdCheck=false;
    this.isShown = false;
    this.isHide = true;
    this.getSalaryMap(); 
    this.hasSalaryErrors=false;
  }
  
  getPolicyList(){ 
    this._rmcs.getPolicyList(this.positionId).subscribe(
      data => {
        this.claimPolicyList = data['budgetId'].claimPolicyList;
        this.mediclaimPolicyList = data['budgetId'].medicalPolicyList;
        this.variableCtcPercentage = data['budgetId'].variableCtcPercentage;
        this.candidateMaxCtc = data['budgetId'].jobDescription.maximumCtc;
        this.candidateMinCtc = data['budgetId'].jobDescription.minimumCtc;
        this.budgetStateId = data['cityId'].state.stateId;
        this.contractEndDate = data['budgetId'].contractEndDate;
        this.gratuity = data['budgetId'].gratuity;

        if(this.gratuity == 1){
          this.isGratuityShow = true;
        }else{
          this.isGratuityShow = false;
        }

        var lockFixedVariableData = data['budgetId'].lockFixedVariable;  
        if(lockFixedVariableData == 1){
          this.lockFixedVariable = true;
        }else{
          this.lockFixedVariable = false
        }
        if(data['budgetId'].appointmentType == "Permanent"){
          this.appointmentType = 1;
        }else{
          this.appointmentType = 0;
        }


      },
      error =>{
        
      }
    );

    
  }

  getCandidateEmailUpdateData(){
    const firstFormControls = this.firstFormGroup.controls;

    var dob = this.formatDate(firstFormControls.dateOfBirth.value);
    
    const data = { 
      "fullName": this.candidateInfoObj.candidateName,
      "firstName": this.candidateInfoObj.firstName,
      "middleName": this.candidateInfoObj.middleName,
      "lastName": this.candidateInfoObj.lastName,
      "mobileNumber": this.candidatePhone,
      "cityId":this.candidateInfoObj.candidate.currentCity.cityId,
      "cityName": this.candidateInfoObj.candidate.currentCity.cityName,
      "dateOfBirth":dob,
      "email": firstFormControls.email.value,
      "expectedSalary":this.candidateInfoObj.expectedSalary,
      "preferredJobTypes": this.candidateInfoObj.preferredJobTypes,   //jobType
      "preferredIndustries": this.candidateInfoObj.preferredIndustries,  //indistryPreference
      "candidateId": this.candidateId,
      "sex":firstFormControls.gender.value,
      "source":this.candidateInfoObj.applicationSource.sourceName,
      "pinCode": this.candidateInfoObj.candidate.pincode
    };    
    return data;
  }

  candidateOfferData(){
    const firstFormControls = this.firstFormGroup.controls;
    const secondFromControls = this.secondFormGroup.controls;

    var joiningDate = this.formatDate(firstFormControls.joiningDate.value);
        joiningDate = joiningDate+' 00:00:00 AM';

    console.log(this.salaryMap['basicMonthlyId'],this.salaryMap['bonusMonthlyId'],this.salaryMap['hraMonthlyId'],this.salaryMap['specialMonthlyId'],this.salaryMap['fixedIncentiveMonthlyId'],this.salaryMap['pfCmpMonthlyId'],this.salaryMap['gocMonthlyId'],this.salaryMap['gratuityMonthlyId'],this.salaryMap['esiCmpMonthlyId'])
    var totalMonthlyFixedCtc = this.salaryMap['basicMonthlyId']+this.salaryMap['bonusMonthlyId']+this.salaryMap['hraMonthlyId']+this.salaryMap['specialMonthlyId']+this.salaryMap['fixedIncentiveMonthlyId']+this.salaryMap['pfCmpMonthlyId']+this.salaryMap['gocMonthlyId']+this.salaryMap['gratuityMonthlyId']+this.salaryMap['esiCmpMonthlyId'] ;
    var totalAnnualFixedCtc = Math.round(totalMonthlyFixedCtc*12);
    console.log('totalAnnualFixedCtc',totalAnnualFixedCtc)

    console.log(this.salaryMap)  //gratuityAnnualId
    //var = parseInt(this.salaryMap['gratuityMonthlyId'])
    var totalAnnualCtc = Math.round(totalAnnualFixedCtc+secondFromControls.variableAnualCtc.value);

    const data = {  
      "candidateId":this.candidateId,
      "positionId":this.positionId,
      "applicationId":this.applicationId,
      //"fixedAnnualCtc":parseInt(secondFromControls.fixedAnualCtc.value), 
      "fixedAnnualCtc":totalAnnualFixedCtc,  
      "gradeId":0,
      "insurancePolicyId":firstFormControls.mediclaimPolicyName.value,
     // "totalAnnualCtc":parseInt(secondFromControls.totalAnualCtc.value), 
      "totalAnnualCtc":totalAnnualCtc,
      "uAppointmentType":this.appointmentType, 
      "uContractEndDate":this.contractEndDate+' 00:00:00 AM', //"2023-01-01 00:00:00 AM"
      "variableAnnualIncentive":parseInt(secondFromControls.variableAnualCtc.value),
      "pfcheck":secondFromControls.pfContribution.value,
      "claimPolicyId":firstFormControls.claimPolicyName.value,  
      "bandId":0,
      "aadharNumber":firstFormControls.adharNumber.value,
      "isLic":"y",
      "newJoinDate":joiningDate,
      "basicPay":parseInt(secondFromControls.monthlyBasicPay.value),
      "houseRent":parseInt(secondFromControls.monthlyHRA.value),
      "conveyance":0,
      "medical":parseInt(this.salaryMap['hisMonthlyId']),  
      "moblie":0,
      "internet":0,
      "bonus":parseInt(secondFromControls.monthlyBonus.value),
      "specialAllowance":parseInt(secondFromControls.monthlySpecialAllowance.value),
      "providentFundEmployer":parseInt(secondFromControls.monthlyPFcontEmployer.value),
      "esiEmployer":parseInt(this.salaryMap['esiCmpMonthlyId']),//
      "goc":parseInt(this.salaryMap['gocMonthlyId']),
      "grossSalary":parseInt(secondFromControls.monthlyGrossPay.value),  
      "pfDeduction":parseInt(secondFromControls.monthlyPFcontEmployee.value),
      "esiDeduction":parseInt(this.salaryMap['esiEmpMonthlyId']),//
      "hisDeduction":parseInt(this.salaryMap['hisMonthlyId']),
      "gratuity":parseInt(this.salaryMap['gratuityMonthlyId']),//
      "fixedIncentive":parseInt(this.salaryMap['fixedIncentiveMonthlyId'])
    };    

    console.log(data);
    return data;
  }

  goToNextForm(){
    this.errorMes="";
    this.disabled=true;
    const controls = this.firstFormGroup.controls;
    this.secondFormGroup.reset();
    
    this.secondFormGroup.controls['totalAnualCtc'].setValue(this.savedTotalAnnualCtc);
    this.secondFormGroup.controls['fixedAnualCtc'].setValue(this.savedFixedAnnualCtc);
    this.secondFormGroup.controls['variableAnualCtc'].setValue(this.savedVariableAnnualIncentive);
    this.secondFormGroup.controls['pfContribution'].setValue(this.savedPfOption);

    if (this.firstFormGroup.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    


    //Update User email
    const emailUpdateData =this.getCandidateEmailUpdateData();
    console.log(emailUpdateData);
    
    var candidateId = this.candidateId;
    this._rmcs.updateCandidateEmial(emailUpdateData, candidateId).subscribe(
      data => {
       if(data['statusCode']==412){
        // this.errorMes=data['message'];
         this.disabled=false;
         //this.OpenAlert(data['message']);
       }else{
        console.log('Update email done')
       }

      },
      error =>{
        console.log('Update email failed')
      }
    );

  }

  saveOffer(){
    
    this.errorMes="";
    this.disabled=true;
    const controls = this.secondFormGroup.controls;

    if (this.secondFormGroup.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    const data =this.candidateOfferData();
    
    console.log('candidateOfferData',data);
    
    var tempData1 = this.salaryMap['basicMonthlyId']+this.salaryMap['bonusMonthlyId']+this.salaryMap['hraMonthlyId']+this.salaryMap['specialMonthlyId']+this.salaryMap['fixedIncentiveMonthlyId']+this.salaryMap['pfCmpMonthlyId']+this.salaryMap['gocMonthlyId']+this.salaryMap['gratuityMonthlyId']+this.salaryMap['esiCmpMonthlyId'] ;
        tempData1 = tempData1+(data.variableAnnualIncentive/12);
    
    if(((data.fixedAnnualCtc+data.variableAnnualIncentive-4) <= tempData1*12) && ((data.fixedAnnualCtc+data.variableAnnualIncentive+4) >= tempData1*12)){  
     
     this.SpinnerService.show('send-offer-spinner');
     
       this._rmcs.saveOfferData(data).subscribe(
        data => {
        //  console.log(data);
          if(data['statusCode']==412){
            this.disabled=false;
            //this.OpenAlert(data['message']);
          }else{
            this.activeModal.close();
            this.firstFormGroup.reset();
            this.secondFormGroup.reset();
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult('SUCCESS');
            this.SpinnerService.hide('send-offer-spinner');
          }
        },
        error =>{
          this.errorMes=error.error.responseData;
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('ERROR');
          this.SpinnerService.hide('send-offer-spinner');
        }
      ); 
    }else {
      alert('You can make an offer at '+tempData1*12);
      return false;
    } 

    //var totalAnnualCtc = data.variableAnnualIncentive+tempData1;

    
    
  }

  initOfferDetailsForm(){  //Offer Details Form
    this.firstFormGroup = this.fb.group({
      email:['',Validators.compose([Validators.required , Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ])],
      adharNumber:['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12), this.checkAadhaarNo ])  ],
      joiningDate:['', Validators.compose([Validators.required ])],
      dateOfBirth:['', Validators.compose([Validators.required ])],
      gender:['', Validators.compose([Validators.required ])],
      //gradeName:['', Validators.compose([Validators.required ])],
      //bandName:['', Validators.compose([Validators.required ])],
      claimPolicyName:['', Validators.compose([Validators.required ])],
      mediclaimPolicyName:['', Validators.compose([Validators.required ])],
    });
  }

  checkAadhaarNo = (AC: FormControl) => {
    let dataForm = AC.parent;
    if(!dataForm) return null;
  
    var aadhaarNo = dataForm.get('adharNumber');
    let aadhaarNoTemp = aadhaarNo.value;
    var checkStatus = 0;
    
    if(aadhaarNoTemp != null){
      if(aadhaarNoTemp.length == 12){
        this._rmcs.checkAadhaarNo(aadhaarNoTemp).subscribe( 
          (result)=>{
            console.log('adhar status true', result['responseData'])
            if(result['responseData'] == "Aadhar number not present"){
              checkStatus = 0;  //Go to next page
            }else{
              checkStatus = 1;
            }
            this.setPreviousOfferData();
            
          },
          error => {
            console.log(error)
            checkStatus = 1;
          }
        )
      

        setTimeout(function(){
          if(checkStatus == 1){
            dataForm.controls["adharNumber"].setErrors( {checkAadhaarNo: true} );
            return {checkAadhaarNo: true};
          }else{
            dataForm.controls["adharNumber"].setErrors(false);
            //return {checkAadhaarNo: false};
          }
          return null;
        },2000)
      }
    }
    
  }


  initSalaryStructureForm(){
    this.secondFormGroup = this.fb.group({
      //salaryRange:['', Validators.compose([Validators.required ])],
      totalAnualCtc:['', Validators.compose([Validators.required ])],
      fixedAnualCtc:['', Validators.compose([Validators.required ])],
      variableAnualCtc:['', Validators.compose([Validators.required ])],
      pfContribution:['', Validators.compose([Validators.required ])],
      monthlyBasicPay:['', Validators.compose([Validators.required ])],
      annuallyBasicPay:['', Validators.compose([Validators.required ])],
      monthlyBonus:['', Validators.compose([Validators.required ])],
      annuallyBonus:['', Validators.compose([Validators.required ])],
      monthlyHRA:['', Validators.compose([Validators.required ])],
      annuallyHRA:['', Validators.compose([Validators.required ])],
      monthlySpecialAllowance:['', Validators.compose([Validators.required ])],
      annuallySpecialAllowance:['', Validators.compose([Validators.required ])],
      monthlyGrossPay:['', Validators.compose([Validators.required ])],
      annuallyGrossPay:['', Validators.compose([Validators.required ])],
      monthlyPFcontEmployer:['', Validators.compose([Validators.required ])],
      annuallyPFcontEmployer:['', Validators.compose([Validators.required ])],
      monthlyPFcontEmployee:['', Validators.compose([Validators.required ])],
      annuallyPFcontEmployee:['', Validators.compose([Validators.required ])],
      monthlyGoc:['', Validators.compose([Validators.required ])],
      annuallyGoc:['', Validators.compose([Validators.required ])],
      monthlyEmployerEsi:['', Validators.compose([Validators.required ])],
      annuallyEmployerEsi:['', Validators.compose([Validators.required ])],
      monthlyEmployeeEsi:['', Validators.compose([Validators.required ])],
      annuallyEmployeeEsi:['', Validators.compose([Validators.required ])],
      monthlyGratuity:['', Validators.compose([Validators.required ])],
      annuallyGratuity:['', Validators.compose([Validators.required ])],
      monthlyInsuranceDecuction:['', Validators.compose([Validators.required ])],
      annuallyInsuranceDecuction:['', Validators.compose([Validators.required ])],
      monthlyNetTakeHome:['', Validators.compose([Validators.required ])],
      annuallyNetTakeHome:['', Validators.compose([Validators.required ])],
    });
  }

  getAllCities(){
    this._rmcs.getAllCity(this.selectedProject).subscribe( 
      (result)=>{
        this.cityList=result['embedded']; 

      },
      error => {
        console.log(error)
      }
    )
  }


  setPfcontrubution(){
    var dummyData = '{"embedded":[{"id":"min","name":"Minimum PF"},{"id":"yes","name":"Maximum PF"}]}';
    //var dummyData = '{"embedded":[{"id":"min","name":"Minimum PF"},{"id":"yes","name":"Maximum PF"}, {"id":"no","name":"No PF"}]}';
    var dummyJsonData = JSON.parse(dummyData);
    this.pfContributionList=dummyJsonData['embedded']; 
  }

  setPreviousOfferData(){
    const firstFormControls = this.firstFormGroup.controls;
    var aadhaarNo = firstFormControls.adharNumber.value;
    this._rmcs.getOfferDetails(aadhaarNo, this.applicationId).subscribe( 
      (result)=>{
        //console.log('adhar status true', result[0].totalAnnualCtc)
        if(result.length > 0){
         // this.firstFormGroup.controls['adharNumber'].setValue(result[0].aadharNumber);
          this.firstFormGroup.controls['claimPolicyName'].setValue(result[0].claimPolicyId);
          this.firstFormGroup.controls['mediclaimPolicyName'].setValue(result[0].insurancePolicyId);
          var joiningDate = this.converetTimeStamp(result[0].joiningDate);
          this.firstFormGroup.controls['joiningDate'].setValue(joiningDate);
          
          this.savedTotalAnnualCtc = result[0].totalAnnualCtc;
          this.savedFixedAnnualCtc = result[0].fixedAnnualCtc;
          this.savedVariableAnnualIncentive = result[0].variableAnnualIncentive;
          this.savedPfOption = result[0].pfcheck;

          console.log('sex', this.candidateGender)
          if(this.candidateGender == 1){
            this.firstFormGroup.controls['gender'].setValue('male');
          }else if(this.candidateGender == 0){
            this.firstFormGroup.controls['gender'].setValue('female');
          }
          
          var candidateDob = this.converetTimeStamp(this.candidateDob);
          this.firstFormGroup.controls['dateOfBirth'].setValue(candidateDob);
          
        }
        
      },
      error => {
        console.log(error)
        
      }
    )
  }
  
  setByDefaultValue(){
    
    this.firstFormGroup.controls['email'].setValue(this.emailValue);
  }

  Close(){
    this.activeModal.close();
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  firstFormGroupIsControlHasError(controlName: string, validationType: string): boolean {
    const control = this.firstFormGroup.controls[controlName];

      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      
      return result;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.secondFormGroup.controls[controlName];
    
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      
      return result;
  }

  getSalaryMap(){
    this._rmcs.getSalaryMap(this.selectedProject).subscribe( 
      (result)=>{
        this.minSalaryMap=result; 

      },
      error => {
        console.log(error)
      }
    )
  }

  calculateVariable(){
    var controls = this.secondFormGroup.controls;
    var totalAnnualCtc = parseInt(controls.totalAnualCtc.value);
    console.log('calculateVariable', totalAnnualCtc)

    if(this.lockFixedVariable == true){
      var fixedCtcPercentage = 100-this.variableCtcPercentage;
      if(isNaN(totalAnnualCtc) == false){
        var fixedAnnualCtc = Math.round(this.percentage(totalAnnualCtc,fixedCtcPercentage));
        var variableCtc = Math.round(totalAnnualCtc-fixedAnnualCtc);

        this.secondFormGroup.controls['fixedAnualCtc'].setValue(fixedAnnualCtc);
        this.secondFormGroup.controls['variableAnualCtc'].setValue(variableCtc);
      }else{
        this.secondFormGroup.controls['fixedAnualCtc'].setValue(0);
        this.secondFormGroup.controls['variableAnualCtc'].setValue(0);
      }
    }else{
      if(isNaN(totalAnnualCtc) == false){
        var fixedAnnualCtc = parseInt(controls.fixedAnualCtc.value);
        var variableCtc = Math.round(totalAnnualCtc-fixedAnnualCtc);
        
        if(isNaN(variableCtc) == true){
          variableCtc = 0;
        }
        this.secondFormGroup.controls['variableAnualCtc'].setValue(variableCtc);
      }else{
        this.secondFormGroup.controls['variableAnualCtc'].setValue(0);
      }
    }
  }

  calculateSalary(){
    this.errorMes="";
    const controls = this.secondFormGroup.controls;
    const firstFormControls = this.firstFormGroup.controls;

    var totalAnnualCtc = controls.totalAnualCtc.value;
    var fixedAnualCtc = controls.fixedAnualCtc.value;
        fixedAnualCtc = fixedAnualCtc/12;

    var pfCheck = controls.pfContribution.value;
    var candidateStateId = this.candidateStateId
    var cityStateMap = '';
    var minSalaryMap = '';

    var variableAnualCtc = totalAnnualCtc-fixedAnualCtc;
    if(totalAnnualCtc == ''){
      alert('Please enter total annual CTC');
      return false;
    }

    if(fixedAnualCtc == ''){
      alert('Please enter Fixed annual CTC'); 
      return false;
    }

    if(totalAnnualCtc > this.candidateMaxCtc){
      alert('Please enter value between '+this.candidateMinCtc+' to '+ this.candidateMaxCtc ); 
      return false;
    }

    if(totalAnnualCtc < this.candidateMinCtc){
      alert('Please enter value between '+this.candidateMinCtc+' to '+ this.candidateMaxCtc ); 
      return false;
    }

    var deductionAmount = 0;
    var selectedMedicalPolicyId = firstFormControls.mediclaimPolicyName.value;
    this.mediclaimPolicyList.forEach(function(v){
      if(v['policyId'] == selectedMedicalPolicyId) {
        deductionAmount = v['deductionAmount'];
      }
    })

    
    if(pfCheck == '' || pfCheck == null){
      this.secondFormGroup.controls.pfContribution.setValidators([Validators.required]);
      this.secondFormGroup.controls.pfContribution.updateValueAndValidity();
      alert('Please select PF Contribution');
      return false;
    }else{
      this.secondFormGroup.controls.pfContribution.clearValidators();
      this.secondFormGroup.controls.pfContribution.updateValueAndValidity();
    }

    //this.salaryMap = this.calculateSalaryStructure(fixedAnualCtc,this.budgetStateId,221, pfCheck, 0, 0, cityStateMap, minSalaryMap) ;
    this.salaryMap = this.calculateSalaryStructure(fixedAnualCtc,this.budgetStateId,deductionAmount, pfCheck, 0, this.gratuity, cityStateMap, minSalaryMap) ;
    
    var salaryMap = this.salaryMap
    //this.secondFormGroup.controls['variableAnualCtc'].setValue(variableAnualCtc);
    if(salaryMap){
      this.secondFormGroup.controls['monthlyBasicPay'].setValue(salaryMap['basicMonthlyId']);
      this.secondFormGroup.controls['annuallyBasicPay'].setValue(salaryMap['basicAnnualId']);
      this.secondFormGroup.controls['monthlyBonus'].setValue(salaryMap['bonusMonthlyId']);
      this.secondFormGroup.controls['annuallyBonus'].setValue(salaryMap['bonusAnnualId']);
      this.secondFormGroup.controls['monthlyHRA'].setValue(salaryMap['hraMonthlyId']);
      this.secondFormGroup.controls['annuallyHRA'].setValue(salaryMap['hraAnnualId']);
      this.secondFormGroup.controls['monthlySpecialAllowance'].setValue(salaryMap['specialMonthlyId']);
      this.secondFormGroup.controls['annuallySpecialAllowance'].setValue(salaryMap['specialAnnualId']);
      this.secondFormGroup.controls['monthlyGrossPay'].setValue(salaryMap['grossMonthlyId']);
      this.secondFormGroup.controls['annuallyGrossPay'].setValue(salaryMap['grossAnnualId']);
      this.secondFormGroup.controls['monthlyPFcontEmployer'].setValue(salaryMap['pfCmpMonthlyId']);
      this.secondFormGroup.controls['annuallyPFcontEmployer'].setValue(salaryMap['pfCmpAnnualId']);
      this.secondFormGroup.controls['monthlyPFcontEmployee'].setValue(salaryMap['pfEmpMonthlyId']);
      this.secondFormGroup.controls['annuallyPFcontEmployee'].setValue(salaryMap['pfEmpAnnualId']);


      this.secondFormGroup.controls['monthlyGoc'].setValue(salaryMap['gocMonthlyId']);
      this.secondFormGroup.controls['annuallyGoc'].setValue(salaryMap['gocAnnualId']);
      this.secondFormGroup.controls['monthlyEmployerEsi'].setValue(salaryMap['esiCmpMonthlyId']);
      this.secondFormGroup.controls['annuallyEmployerEsi'].setValue(salaryMap['esiCmpAnnualId']);
      this.secondFormGroup.controls['monthlyEmployeeEsi'].setValue(salaryMap['esiEmpMonthlyId']);
      this.secondFormGroup.controls['annuallyEmployeeEsi'].setValue(salaryMap['esiEmpAnnualId']);
      this.secondFormGroup.controls['monthlyGratuity'].setValue(salaryMap['gratuityMonthlyId']);
      this.secondFormGroup.controls['annuallyGratuity'].setValue(salaryMap['gratuityAnnualId']);




      this.secondFormGroup.controls['monthlyInsuranceDecuction'].setValue(salaryMap['hisMonthlyId']);
      this.secondFormGroup.controls['annuallyInsuranceDecuction'].setValue(salaryMap['hisAnnualId']);
      this.secondFormGroup.controls['monthlyNetTakeHome'].setValue(salaryMap['netMonthlyId']);
      this.secondFormGroup.controls['annuallyNetTakeHome'].setValue(salaryMap['netAnnualId']);
      
    }
    console.log('salaryMap', salaryMap)
    
  }

    
  calculateSalaryStructure(fCtc,stateId,lic1, pfCheck, javaCall, gratuityFlag, cityStateMap, minSalaryMap)
  {
    console.log('budgetStateId',stateId)
    var basic = Math.round(fCtc*0.5);
    var bonus = 0;
    var esiEmployee = 0;
    var hra=0;
    var fixedIncentive=0;
    var pfs= pfCheck;
    var minBasic=0;  
    var checkPoint=0;
    //var stateId=0;
    var stateName="";  
    
    if(javaCall === 1){
      //stateId = cityStateMap[cId];
      minBasic = minSalaryMap[stateId];
    }
    else
      {
        //stateId=getStateId(cId);
        //stateName=getStateName(cId);
        //minBasic=this.getMinimumBasic(stateId);

        minBasic=this.getMinimumBasic(stateId);
      }
      
      
    if(minBasic==0)
    {
      return false;
    }
    
    if(basic < minBasic) {
      basic = minBasic;
    }
    
    hra = Math.round((basic)/2);
    if(basic<15400 && fCtc>=17226 && fCtc<=37500 && pfs=="no")
    {
      basic=15400;
      bonus=Math.round(basic*0.0833);
      hra = 0;
    }
    else
    {
          
      //	bonus = Math.round(basic*0.20);
    }
    bonus = Math.round(basic*0.0833);
    
    /*if(bonus < 584){
      bonus = 584;		
    }*/
    var gratuity = 0;
    if(gratuityFlag!=0 && gratuityFlag!=undefined){
      gratuity = Math.round(basic*0.0481);
    }
    var esiCompany = 0;
    var sumStructure = basic + bonus + hra + fixedIncentive;
    var iterationCount=0;
    var finalSalaryMap={};
    
    this.calculateAndDisplay(gratuityFlag, fCtc, basic, bonus, hra, sumStructure, esiCompany, fixedIncentive, esiEmployee, iterationCount, lic1, pfCheck, finalSalaryMap,javaCall, cityStateMap, minSalaryMap, gratuity, minBasic);
    if(fCtc<minBasic)
						{
							this.hasSalaryErrors=true;
						}
            else{
              this.hasSalaryErrors=false;
            }
    return finalSalaryMap;
    
  }

  calculateAndDisplay(gratuityFlag, fCtc, basic, bonus, hra, sumStructure, esiCompany, fixedIncentive, esiEmployee,iterationCount,lic1, pfCheck,finalSalaryMap, javaCall, cityStateMap, minSalaryMap, gratuity, minBasic)
{
	
	var pfEmployee = 0;
	var pfCompany = 0;
	var goc = 0;
	iterationCount++;
	
	var his = 0;	
		his = lic1;
	if(lic1 == ""){
		his = 0;
	}
	bonus = Math.round(basic*0.0833);
	if(hra==0 && iterationCount==0){
		hra = Math.round((basic)/2);
	}
	if(gratuityFlag!=0){
		gratuity = Math.round(basic*0.0481);
	}
	var pf= pfCheck;
	if(pf=="yes") {
		pfEmployee = Math.round(basic*12/100);
		pfCompany = Math.round(basic*12/100);
		goc = Math.round(basic*1/100);
	} 
	else if(pf=="min"){
		pfEmployee = 1800;
		pfCompany = 1800;
		goc = 150;
	}
	else if(pf=="no"){
		pfEmployee = 0;
		pfCompany = 0;
		goc = 0;
	}
	var grossSalary = Math.round(basic+bonus+hra+fixedIncentive);
	var grossSalaryWithEsi = Math.round(grossSalary*0.0325) + grossSalary;
	
	
	if(grossSalary <= 21000) {
		esiCompany = Math.round(grossSalary*0.0325);
		esiEmployee = Math.round(grossSalary*0.0075);
	}
	else
		{
			esiCompany=0;
			esiEmployee=0;
		}
	
	sumStructure = Math.round(basic + bonus + hra + fixedIncentive + gratuity + pfCompany + esiCompany+goc);
	var tCtc = Math.round(basic+bonus+hra+fixedIncentive+gratuity+pfCompany+esiCompany+goc);
	
	
		if(tCtc<fCtc)
		{
			
			if(tCtc==fCtc || iterationCount>=20) //|| tCtc==fCtc-1)
				{
				if(basic < 15000) {
					var minOrNo = "no";
					if(pf=="min") {
						minOrNo = "min"
					} 
					if(javaCall==0 && (pf == "min" || pf=="no"))
					{
					alert( "This candidate is not eligible for '" + minOrNo + " PF deduction' because his/her monthly " +
										   "basic salary is less than Rs. 15,000. Please select 'Maximum PF'.");
					return false;
					}
					if(javaCall==1 && (pf == "min" || pf=="no"))
						{
							finalSalaryMap["Error"]="This candidate is not eligible for '" + minOrNo + " PF deduction' because his/her monthly " +
							   "basic salary is less than Rs. 15,000. Please select 'Maximum PF'."
							return finalSalaryMap;
						
						}
					}
					var netTakeHome = Math.round(basic + bonus + hra + fixedIncentive  - pfEmployee - esiEmployee - his);
					
          finalSalaryMap["basicMonthlyId"]=Math.round(basic);  //monthlyBasicPay
					finalSalaryMap["basicAnnualId"]= Math.round(basic*12);  //annuallyBasicPay
					finalSalaryMap["bonusMonthlyId"]=Math.round(bonus); //monthlyBonus
					finalSalaryMap["bonusAnnualId"]=Math.round(bonus*12); //annuallyBonus
					finalSalaryMap["hraMonthlyId"]=Math.round(hra);   //monthlyHRA
					finalSalaryMap["hraAnnualId"]=Math.round(hra*12); //annuallyHRA
					finalSalaryMap["gratuityMonthlyId"]=Math.round(gratuity);
					finalSalaryMap["gratuityAnnualId"]=Math.round(gratuity*12);
					if(basic<=15000)
					{
						finalSalaryMap["fixedIncentiveMonthlyId"]=Math.round(fixedIncentive);
						finalSalaryMap["fixedIncentiveAnnualId"]=Math.round(fixedIncentive*12);
				
						finalSalaryMap["specialMonthlyId"]=0;
						finalSalaryMap["specialAnnualId"]=0*12;
				
					}
					else
					{
						finalSalaryMap["fixedIncentiveMonthlyId"]=0;
						finalSalaryMap["fixedIncentiveAnnualId"]=0*12;
					
						finalSalaryMap["specialMonthlyId"]=Math.round(fixedIncentive);
						finalSalaryMap["specialAnnualId"]=Math.round(fixedIncentive*12);
					
					}
					finalSalaryMap["grossMonthlyId"]=Math.round(basic + bonus + hra + fixedIncentive);
					finalSalaryMap["grossAnnualId"]=Math.round((basic + bonus + hra + fixedIncentive)*12);
					finalSalaryMap["pfCmpMonthlyId"]=Math.round(pfCompany);
					finalSalaryMap["pfCmpAnnualId"]=Math.round(pfCompany*12);
					finalSalaryMap["gocMonthlyId"]=Math.round(goc);
					finalSalaryMap["gocAnnualId"]=Math.round(goc*12);
					finalSalaryMap["esiCmpMonthlyId"]=Math.round(esiCompany);
					finalSalaryMap["esiCmpAnnualId"]=Math.round(esiCompany*12);
					finalSalaryMap["pfEmpMonthlyId"]=Math.round(pfEmployee);
					finalSalaryMap["pfEmpAnnualId"]=Math.round(pfEmployee*12);
					finalSalaryMap["esiEmpMonthlyId"]=Math.round(esiEmployee);
					finalSalaryMap["esiEmpAnnualId"]=Math.round(esiEmployee*12);
					finalSalaryMap["hisMonthlyId"]=Math.round(his);
					finalSalaryMap["hisAnnualId"]=Math.round(his*12);
					finalSalaryMap["netMonthlyId"]=Math.round(netTakeHome);
					finalSalaryMap["netAnnualId"]=Math.round(netTakeHome*12);	
					if(basic<=15000)
					{finalSalaryMap["fixedIncentiveHiddenId"]=Math.round(fixedIncentive);
					finalSalaryMap["specialAllowanceHiddenId"]=0;
					}
					else
						{
						finalSalaryMap["fixedIncentiveHiddenId"]=0;
						finalSalaryMap["specialAllowanceHiddenId"]=Math.round(fixedIncentive);
						}
					finalSalaryMap["grossHiddenId"]=Math.round(basic + bonus + hra + fixedIncentive);
					finalSalaryMap["pfCompanyHiddenId"]=Math.round(pfCompany);
					finalSalaryMap["gocHiddenId"]=Math.round(goc);
					finalSalaryMap["esiCompanyHiddenId"]=Math.round(esiCompany);
					finalSalaryMap["pfEmployeeHiddenId"]=Math.round(pfEmployee);
					finalSalaryMap["esiEmployeeHiddenId"]=Math.round(esiEmployee);
					finalSalaryMap["hisHiddenId"]=Math.round(his);

          if(fCtc<minBasic)
						{
							alert('The CTC entered by you is below minimum wages and cannot be offered.');
              this.hasSalaryErrors=true;
              return false;
						}
				}
			else
			{
       
				var binSearchMedian = (fCtc-tCtc)/2; //parseInt((fCtc-tCtc)/2,10);
				if(basic<=15000){
					basic+=binSearchMedian;
				}
				else{
					fixedIncentive+=binSearchMedian;
				}
				return this.calculateAndDisplay(gratuityFlag, fCtc, basic, bonus, hra, sumStructure, esiCompany, fixedIncentive,esiEmployee,iterationCount,lic1,pfCheck,finalSalaryMap, javaCall, cityStateMap, minSalaryMap,gratuity, minBasic);
			}
		}
		if(tCtc>fCtc)
		{
      
			if(tCtc==fCtc || iterationCount>=20)// || tCtc==fCtc-1)
				{
					if(basic < 15000) {
					var minOrNo = "no";
					if(pf=="min") {
						minOrNo = "min"
					} 
					if(javaCall==0 && (pf == "min" || pf=="no"))
					{
					alert( "This candidate is not eligible for '" + minOrNo + " PF deduction' because his/her monthly " +
										   "basic salary is less than Rs. 15,000. Please select 'Maximum PF'.");
					return false;
					}
					if(javaCall==1 && (pf == "min" || pf=="no"))
						{
							finalSalaryMap["Error"]="This candidate is not eligible for '" + minOrNo + " PF deduction' because his/her monthly " +
							   "basic salary is less than Rs. 15,000. Please select 'Maximum PF'."
							return finalSalaryMap;
						
						}
					}
					var netTakeHome = Math.round(basic + bonus + hra + fixedIncentive - pfEmployee - esiEmployee - his);
					
          finalSalaryMap["basicMonthlyId"]=Math.round(basic);
					finalSalaryMap["basicAnnualId"]= Math.round(basic*12);
					finalSalaryMap["bonusMonthlyId"]=Math.round(bonus);
					finalSalaryMap["bonusAnnualId"]=Math.round(bonus*12);
					finalSalaryMap["hraMonthlyId"]=Math.round(hra);
					finalSalaryMap["hraAnnualId"]=Math.round(hra*12);
					finalSalaryMap["gratuityMonthlyId"]=Math.round(gratuity);
					finalSalaryMap["gratuityAnnualId"]=Math.round(gratuity*12);
					if(basic<=15000)
						{
					finalSalaryMap["fixedIncentiveMonthlyId"]=Math.round(fixedIncentive);
					finalSalaryMap["fixedIncentiveAnnualId"]=Math.round(fixedIncentive*12);
					
					finalSalaryMap["specialMonthlyId"]=0;
					finalSalaryMap["specialAnnualId"]=0*12;
					
						}
					else
						{
						finalSalaryMap["fixedIncentiveMonthlyId"]=0;
						finalSalaryMap["fixedIncentiveAnnualId"]=0*12;
						
						finalSalaryMap["specialMonthlyId"]=Math.round(fixedIncentive);
						finalSalaryMap["specialAnnualId"]=Math.round(fixedIncentive*12);
						
						}
					finalSalaryMap["grossMonthlyId"]=Math.round(basic + bonus + hra + fixedIncentive );
					finalSalaryMap["grossAnnualId"]=Math.round((basic + bonus + hra + fixedIncentive )*12);
					finalSalaryMap["pfCmpMonthlyId"]=Math.round(pfCompany);
					finalSalaryMap["pfCmpAnnualId"]=Math.round(pfCompany*12);
					finalSalaryMap["gocMonthlyId"]=Math.round(goc);
					finalSalaryMap["gocAnnualId"]=Math.round(goc*12);
					finalSalaryMap["esiCmpMonthlyId"]=Math.round(esiCompany);
					finalSalaryMap["esiCmpAnnualId"]=Math.round(esiCompany*12);
					finalSalaryMap["pfEmpMonthlyId"]=Math.round(pfEmployee);
					finalSalaryMap["pfEmpAnnualId"]=Math.round(pfEmployee*12);
					finalSalaryMap["esiEmpMonthlyId"]=Math.round(esiEmployee);
					finalSalaryMap["esiEmpAnnualId"]=Math.round(esiEmployee*12);
					finalSalaryMap["hisMonthlyId"]=Math.round(his);
					finalSalaryMap["hisAnnualId"]=Math.round(his*12);
					finalSalaryMap["netMonthlyId"]=Math.round(netTakeHome);
					finalSalaryMap["netAnnualId"]=Math.round(netTakeHome*12);	
					if(basic<=15000)
					{finalSalaryMap["fixedIncentiveHiddenId"]=Math.round(fixedIncentive);
					finalSalaryMap["specialAllowanceHiddenId"]=0;
					}
					else
						{
						finalSalaryMap["fixedIncentiveHiddenId"]=0;
						finalSalaryMap["specialAllowanceHiddenId"]=Math.round(fixedIncentive);
						}
					finalSalaryMap["grossHiddenId"]=Math.round(basic + bonus + hra + fixedIncentive);
					finalSalaryMap["pfCompanyHiddenId"]=Math.round(pfCompany);
					finalSalaryMap["gocHiddenId"]=Math.round(goc);
					finalSalaryMap["esiCompanyHiddenId"]=Math.round(esiCompany);
					finalSalaryMap["pfEmployeeHiddenId"]=Math.round(pfEmployee);
					finalSalaryMap["esiEmployeeHiddenId"]=Math.round(esiEmployee);
					finalSalaryMap["hisHiddenId"]=Math.round(his);
          if(fCtc<minBasic)
						{
							alert('The CTC entered by you is below minimum wages and cannot be offered.');
              this.hasSalaryErrors=true;
              return false;
						
						}
				}
			else
			{
				var binSearchMedian = Math.round((tCtc-fCtc)/2); //parseInt(Math.round((tCtc-fCtc)/2),10);
				var prevHra = hra;
				basic-=binSearchMedian;
				var minBonus = basic*0.0833;
				if(basic < minBasic) {
						basic = minBasic;
						hra-=binSearchMedian;
					}
				if(basic<(fCtc/2))
					{
						basic=fCtc/2;
						hra-=binSearchMedian;
					}
					
				if(hra<0)
					{
					bonus-=binSearchMedian;
					hra=0;	
					}
				
				if(bonus < minBonus)
					bonus = minBonus;
				
				if(bonus<584)
					bonus=584;
				return this.calculateAndDisplay(gratuityFlag, fCtc, basic, bonus, hra, sumStructure, esiCompany, fixedIncentive,esiEmployee,iterationCount, lic1,pfCheck,finalSalaryMap, javaCall, cityStateMap, minSalaryMap,gratuity,minBasic);
			}
		}
		if(tCtc==fCtc)// || tCtc==fCtc-1)
		{
     
			if(basic < 15000) {
					var minOrNo = "no";
					if(pf=="min") {
						minOrNo = "min"
					} 
					if(javaCall==0 && (pf == "min" || pf=="no"))
					{
					alert( "This candidate is not eligible for '" + minOrNo + " PF deduction' because his/her monthly " +
										   "basic salary is less than Rs. 15,000. Please select 'Maximum PF'.");
					return false;
					}
					if(javaCall==1 && (pf == "min" || pf=="no"))
						{
							finalSalaryMap["Error"]="This candidate is not eligible for '" + minOrNo + " PF deduction' because his/her monthly " +
							   "basic salary is less than Rs. 15,000. Please select 'Maximum PF'."
							return finalSalaryMap;
						
						}
					}
			var netTakeHome = Math.round(basic + bonus + hra + fixedIncentive - pfEmployee - esiEmployee - his);
			
			finalSalaryMap["basicMonthlyId"]=Math.round(basic);
			finalSalaryMap["basicAnnualId"]= Math.round(basic*12);
			finalSalaryMap["bonusMonthlyId"]=Math.round(bonus);
			finalSalaryMap["bonusAnnualId"]=Math.round(bonus*12);
			finalSalaryMap["hraMonthlyId"]=Math.round(hra);
			finalSalaryMap["hraAnnualId"]=Math.round(hra*12);
			finalSalaryMap["gratuityMonthlyId"]=Math.round(gratuity);
			finalSalaryMap["gratuityAnnualId"]=Math.round(gratuity*12);
			if(basic<=15000)
				{
			finalSalaryMap["fixedIncentiveMonthlyId"]=Math.round(fixedIncentive);
			finalSalaryMap["fixedIncentiveAnnualId"]=Math.round(fixedIncentive*12);
			
			finalSalaryMap["specialMonthlyId"]=0;
			finalSalaryMap["specialAnnualId"]=0*12;
				}
			else
				{
				finalSalaryMap["fixedIncentiveMonthlyId"]=0;
				finalSalaryMap["fixedIncentiveAnnualId"]=0*12;
				
				finalSalaryMap["specialMonthlyId"]=Math.round(fixedIncentive);
				finalSalaryMap["specialAnnualId"]=Math.round(fixedIncentive*12);
				}
			finalSalaryMap["grossMonthlyId"]=Math.round(basic + bonus + hra + fixedIncentive );
			finalSalaryMap["grossAnnualId"]=Math.round((basic + bonus + hra + fixedIncentive)*12);
			finalSalaryMap["pfCmpMonthlyId"]=Math.round(pfCompany);
			finalSalaryMap["pfCmpAnnualId"]=Math.round(pfCompany*12);
			finalSalaryMap["gocMonthlyId"]=Math.round(goc);
			finalSalaryMap["gocAnnualId"]=Math.round(goc*12);
			finalSalaryMap["esiCmpMonthlyId"]=Math.round(esiCompany);
			finalSalaryMap["esiCmpAnnualId"]=Math.round(esiCompany*12);
			finalSalaryMap["pfEmpMonthlyId"]=Math.round(pfEmployee);
			finalSalaryMap["pfEmpAnnualId"]=Math.round(pfEmployee*12);
			finalSalaryMap["esiEmpMonthlyId"]=Math.round(esiEmployee);
			finalSalaryMap["esiEmpAnnualId"]=Math.round(esiEmployee*12);
			finalSalaryMap["hisMonthlyId"]=Math.round(his);
			finalSalaryMap["hisAnnualId"]=Math.round(his*12);
			finalSalaryMap["netMonthlyId"]=Math.round(netTakeHome);
			finalSalaryMap["netAnnualId"]=Math.round(netTakeHome*12);	
			if(basic<=15000)
			{finalSalaryMap["fixedIncentiveHiddenId"]=Math.round(fixedIncentive);
			finalSalaryMap["specialAllowanceHiddenId"]=0;
			}
			else
				{
				finalSalaryMap["fixedIncentiveHiddenId"]=0;
				finalSalaryMap["specialAllowanceHiddenId"]=Math.round(fixedIncentive);
				}
			finalSalaryMap["grossHiddenId"]=Math.round(basic + bonus + hra + fixedIncentive);
			finalSalaryMap["pfCompanyHiddenId"]=Math.round(pfCompany);
			finalSalaryMap["gocHiddenId"]=Math.round(goc);
			finalSalaryMap["esiCompanyHiddenId"]=Math.round(esiCompany);
			finalSalaryMap["pfEmployeeHiddenId"]=Math.round(pfEmployee);
			finalSalaryMap["esiEmployeeHiddenId"]=Math.round(esiEmployee);
			finalSalaryMap["hisHiddenId"]=Math.round(his);
      if(fCtc<minBasic)
						{
							alert('The CTC entered by you is below minimum wages and cannot be offered.');
              this.hasSalaryErrors=true;
              return false;
						}
		}
}

  getMinimumBasic(statteId: number){
    var minsalary = 0;
    this.minSalaryMap.forEach(function (value) {
      if(value.state_id == statteId){
        minsalary = value.salary;
      }
  });
    return minsalary;
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  percentage(value, per){
    return (value/100)*per;
  }

  converetTimeStamp(ts: any){
    //var ts_ms = ts * 1000;    // convert unix timestamp to milliseconds
    var ts_ms = ts;
    var date_ob = new Date(ts_ms);    // initialize new Date object
    var year = date_ob.getFullYear();   //// year as 4 digits (YYYY)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);   // month as 2 digits (MM)
    var date = ("0" + date_ob.getDate()).slice(-2);   // date as 2 digits (DD)
    var hours = ("0" + date_ob.getHours()).slice(-2);   // hours as 2 digits (hh)
    var minutes = ("0" + date_ob.getMinutes()).slice(-2);   // minutes as 2 digits (mm)
    var seconds = ("0" + date_ob.getSeconds()).slice(-2); // seconds as 2 digits (ss)

    var time = hours+':'+minutes;
    //var interviewTime = this.timeConv24(time);
    
    //console.log("Date as YYYY-MM-DD Format: " + year + "-" + month + "-" + date);   // date as YYYY-MM-DD format
    //console.log("Date as YYYY-MM-DD hh:mm:ss Format: " + year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds);  // date & time as YYYY-MM-DD hh:mm:ss format
   // console.log("Time as hh:mm Format: " + hours + ":" + minutes);    // time as hh:mm format: 

    var dateTime = year + "-" + month + "-" + date;
    return dateTime;
  }

  onlyNumber(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.firstFormGroup.controls['adharNumber'].setValue(onlyNumberValue);
  }

  onlyNumberTotalCtc(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.secondFormGroup.controls['totalAnualCtc'].setValue(onlyNumberValue);
  }

  onlyNumberFixedCtc(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.secondFormGroup.controls['fixedAnualCtc'].setValue(onlyNumberValue);
  }

}
