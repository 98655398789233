<!-- <kt-portlet> 
    <kt-portlet-body>
        <mat-tab-group [(selectedIndex)]="selectedTab" >           
            <mat-tab label="My Openings">
                <ng-template matTabContent>
                   <kt-recruitment-list></kt-recruitment-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="Teams Opening" >
                <ng-template matTabContent>
                    <kt-teams-recruitment-list></kt-teams-recruitment-list>
                </ng-template>
            </mat-tab> 
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet> -->

<kt-portlet> 
    <kt-portlet-body>
        <kt-recruitment-list></kt-recruitment-list>
    </kt-portlet-body>
</kt-portlet>
  

