import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { CustomAttibute } from '../../../contact-setting/models/custom-attibute.model';
import { entriesIn } from 'lodash';
import { AttributeType } from '../../../contact-setting/models/attribute-type.model';
import { LooupValues } from '../../../contact-setting/models/looup-values.model';
import { DateAttributes } from '../../../contact-setting/models/date-attributes.model';
import { Company } from '../models/company.model';
import { SelectedCustomTypes } from '../../../contact-setting/models/selected-custom-types.model';
import { CompanyList } from '../models/company-list.model';
import { CustomAttributeList } from '../../../contact-setting/models/custom-attribute-list.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  API_COMPANY_TYPE_LIST=environment.baseUrl+'/company/getAllCompanyType';
  API_ADD_COMPANY_TYPE=environment.baseUrl+'/company/addCompanyType';
  API_EDIT_COMPANY_TYPE=environment.baseUrl+'/company/editCompanyType';
  API_CUSTOM_LIST_URL=environment.baseUrl+'/setting/api/customType/getAllCustomAttributes';
  API_ATTRIBUTE_TYPE_URL=environment.baseUrl+'/setting/api/customType/getAllAttributeTypes';
  //API_ALL_COMPANY_TYPES=environment.baseUrl+'/company/getAllCompanyType';
  API_ADD_CUSTOM_COMPANY=environment.baseUrl+'/customType/addUpdateCompanyTypes';
  API_ADD_CUSTOM_ATTRIBUTE_URL=environment.baseUrl+'/setting/api/customType/addCustomAttribute';
  API_EDIT_CUSTOM_FIELD_URL=environment.baseUrl+'/setting/api/customType/editCustomAttribute';
  APP_COMPANY_CUSTOM=environment.baseUrl+'/customType/addUpdateCompanyTypes';
  SELECTED_COMPANY_CUSTOM_TYPES=environment.baseUrl+'/customType/getSelectedCompanyTypes';

  constructor(private http: HttpClient) { }

  getCompnayTypeList(projectId: number){
    return this.http.post<Company[]>(this.API_COMPANY_TYPE_LIST, { "projectId": projectId});
    } 

   addCompanyType(companyTypeName:string, isActive:number, projectId: number): Observable<Company>{
    return this.http.post<Company>(this.API_ADD_COMPANY_TYPE, {companyTypeName,"projectId": projectId,isActive});
   } 

   editCompanyType(editDataValues){
    return this.http.post<Company>(this.API_EDIT_COMPANY_TYPE,editDataValues)
  }

  getCustom(projectId: number)
  {
    return this.http.post<CustomAttributeList>(this.API_CUSTOM_LIST_URL, { "projectId": projectId, "moduleType" : "2"});
  }

  getFields(projectId: number){
    return this.http.get<any[]>(
      environment.baseUrl+'/transactions/api/customType/getFields?projectId='+projectId+'&moduleType=2');
  }


    getAtrributeType(){
      return this.http.get<AttributeType>(this.API_ATTRIBUTE_TYPE_URL,{});
    } 

    getCompanyTypes(projectId: number)
    {
      return this.http.post<CompanyList>(this.API_COMPANY_TYPE_LIST, { "projectId": projectId});
    } 

  addCustomAttributeToCompany(attributeId: number, attributeName:string, companyTypes: Company[], projectId: number){
    return this.http.post<Company>(this.APP_COMPANY_CUSTOM,{attributeId,attributeName,companyTypes,"projectId": projectId});
  }

  addCustomFields(attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
    lookupValues: LooupValues[],dateAttributes:DateAttributes, projectId: number ){
     
     return this.http.post<CustomAttibute>(this.API_ADD_CUSTOM_ATTRIBUTE_URL,{attributeName,"projectId": projectId,
         "moduleType":"2",mandatoryFlag,"status": 1,attributeType,lookupValues,dateAttributes});
 }

 editCustomFields(id:number,attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
  lookupValues: LooupValues[],dateAttributes:DateAttributes, projectId: number ){
    
   return this.http.post<CustomAttibute>(this.API_EDIT_CUSTOM_FIELD_URL,{id,attributeName,"projectId": projectId,
       "moduleType":"2",mandatoryFlag,"status": 1,attributeType,lookupValues,dateAttributes});
}

getSelectedCompanyCustomAttribute(companyCustomData){
  return this.http.post<SelectedCustomTypes[]>(this.SELECTED_COMPANY_CUSTOM_TYPES,companyCustomData);
}
 
}
