<div class="card card-custom mb-4" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header pl-2 pr-6" [ngStyle]="{'border-bottom':0}">
        <div class="card-title text-primary font-weight-bold font-size-h6"> 
            <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="contact" [ngStyle]="{'cursor':'pointer'}">
                <img *ngIf="isCollapsed" src="./assets/media/svg/icons/General/Angle-double-left.svg" class="pr-1">
                <img *ngIf="!isCollapsed" src="./assets/media/svg/icons/General/Angle-double-bottom.svg" class="pr-1">
            </a>
            {{headingName}}  
        </div>
        <div class="card-toolbar">
            <div class="example-tools justify-content-center">
                <a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" href="javascript:void(0)" (click)="addContact()">
                    <i class="fas fa-plus"></i>
                </a>
                <!--<a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" href="javascript:void(0)">
                    <i class="fas fa-filter"></i>
                </a>-->
            </div>
        </div>
    </div>
    <!-- Body -->
    <div id="contact" [ngbCollapse]="isCollapsed" class="card-body p-0 m-0 card-body-scroll">
        <ngx-spinner size="default" type="ball-spin-clockwise" name="dashboard-contact">  
            <p style="color: white">Fetching Data... </p>  
        </ngx-spinner>
        <div class="font-size-h6 text-center" *ngIf="isError">{{errorMessage}}</div>
        <!-- Contacts Start -->
        <div class="card card-border rounded p-2 ml-2 mr-2 mb-2"  *ngFor="let contact of contactList">
            <div class="d-flex align-items-center p-2">
                <div class="d-flex flex-column flex-grow-1">
                    <a href="javascript:void(0)" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{contact.name}}</a>
                </div>
                <a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" 
                    href="javascript:void(0)"
                    routerLink="/contact-dashboard/{{contact.contactId}}" title="Contact Dashboard">
                    <img src="./assets/media/svg/icons/General/Arrow-right.svg"> 
                </a>
            </div>
            <div class="d-flex align-items-center pl-2 pr-2 pb-2">
                <div class="d-flex flex-column flex-grow-1">
                    <span class="text-muted font-size-sm">
                        <img src="./assets/media/svg/icons/General/business-cards_2.svg" class="pr-2"> 
                        {{contact.designation}}
                    </span>
                    <span class="text-muted font-size-sm pt-2">
                        <i class="fas fa-phone-alt icon-nm text-warning pr-2"></i> {{contact.phone}}
                    </span>
                </div>
            </div>
        </div>
        <!-- Contacts End -->
    </div>
</div>
