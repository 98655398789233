import { Component, OnInit } from '@angular/core';
import { CandidateService } from '../../Services/candidate.service';
import { Candidates } from '../../Models/candidates.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from '../../Models/role.model';
import { Jobtype } from '../../Models/job-type-list.model';
import { Indistrytype } from '../../Models/indistry.model';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
//import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-edit-candidate',
  templateUrl: './edit-candidate.component.html',
  styleUrls: ['./edit-candidate.component.scss']
})
export class EditCandidateComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  editCandidate:Candidates;
  editCandidateVal:Candidates;
  public editUserForm: FormGroup;
  RoleList: Role[];
  Jobtype: Jobtype[];
  Indistrytype: Indistrytype[];
  cityList:[];
  activeUserList: Candidates[];
  public userRoleFilterType: FormControl = new FormControl();
  public reportingHeadIdFilterType: FormControl = new FormControl();
  errorMes:String="";
  disabled:boolean;
  userPwdCheck:boolean;
  password:String;
  public countryNameFilter: FormControl = new FormControl();
  public stateNameFilter: FormControl = new FormControl();
  public cityNameFilter: FormControl = new FormControl();
  public jobTypeFilter: FormControl =new FormControl();
  public indistryPreference: FormControl =new FormControl();
  public indistryPreferenceFilter: FormControl =new FormControl();

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public candidateService: CandidateService,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog){ }

  ngOnInit(): void {
    //console.log(this.editCandidate);
    this.getCandidateJobType();
    this.getIndistry();
    this.getAllCities();
    this.disabled=false;
    this.userPwdCheck=false;
   // this.editCandidateVal=this.editUser;
   // console.log(this.editCandidateVal); 
    this.initaddNewUserForm();
    this.getCandidateDetails();
  }

  initaddNewUserForm(){
    this.editUserForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required ])],
      lastName: [''],
      email:['',Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      mobilePrefix: ['', Validators.compose([Validators.required ])],
      mobileNumber:['', Validators.compose([Validators.required ])],
      jobType:[],
      indistryPreference:[],
      cityName:[''],
      expectedSalary:[''],
      resume:[''],
      file:['']
     });
  }

  onChange(event: Event) {

    //const file:File = (event.target as HTMLInputElement).files[0];
    //const formData = new FormData();
         //formData.append("thumbnail", file);

    //console.log('change file', formData);

    let file = (event.target as HTMLInputElement).files[0];
    console.log('file upload', file)
    this.editUserForm.controls['resume'].setValue(file.name);
    //this.editUserForm.controls['file'].setValue(file.name);
  }

  getCandidateDetails(){
    this.SpinnerService.show('candidate-edit-loader');
    //console.log('this.editUser', this.editCandidate)
    this.candidateService.getCandidateDetail(this.editCandidate.candidateId, this.selectedProject).subscribe(
      data=>{
        var tempdataArray = [];
        tempdataArray.push(data);
        this.editCandidateVal = tempdataArray[0];
        console.log('edit can data---',this.editCandidateVal['candidateSource'].sourceName);
   
        
       if(this.editCandidateVal.middleName != null){
        var firstName = this.editCandidateVal.firstName+' '+this.editCandidateVal.middleName;
        this.editUserForm.controls['firstName'].setValue(firstName);
       }else{
        this.editUserForm.controls['firstName'].setValue(this.editCandidateVal.firstName);
       }

       this.editUserForm.controls['lastName'].setValue(this.editCandidateVal.lastName);
       
       if(this.editCandidateVal.mobileNumber != null ){
         this.editUserForm.controls['mobileNumber'].setValue(this.editCandidateVal.mobileNumber);
         this.editUserForm.controls['mobilePrefix'].setValue('+91');
       }

       this.editUserForm.controls['cityName'].setValue(this.editCandidateVal.cityId);
       this.editUserForm.controls['expectedSalary'].setValue(this.editCandidateVal.expectedSalary);
       this.editUserForm.controls['email'].setValue(tempdataArray[0].emailId);

       var tempJobType = tempdataArray[0].preferredJobTypes;
        var tempJobtypeArray = new Array(); 
        if(tempJobType){
          tempJobType.forEach(data =>
            tempJobtypeArray.push(data.id)
          );
        }
        this.editUserForm.controls['jobType'].setValue(tempJobtypeArray);
       
       var tempIndustryType = tempdataArray[0].preferredIndustries;
        var tempIndustryTypeArray = new Array(); 
        if(tempIndustryType){
          tempIndustryType.forEach(data =>
            tempIndustryTypeArray.push(data.id)
          );
        }
        this.editUserForm.controls['indistryPreference'].setValue(tempIndustryTypeArray);

       /*this.editUserForm.controls['resume'].setValue(this.editCandidateVal.expectedSalary);
       */
       this.SpinnerService.hide('candidate-edit-loader');
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.SpinnerService.hide('candidate-edit-loader');
      }
    )
    
  }

  getCandidateJobType(){
    var localStorageJobType = localStorage.getItem('jobType');
    this.Jobtype = JSON.parse(localStorageJobType);
  }

  getIndistry(){
    var localStorageIndistryType = localStorage.getItem('indistryType');
    this.Indistrytype = JSON.parse(localStorageIndistryType);
  }

  getAllCities(){
    this.candidateService.getAllCity(this.selectedProject).subscribe( 
      (result)=>{
        console.log('all city', result);
        this.cityList=result['embedded']; 

      },
      error => {
        console.log(error)
      }
    )
  }

  EditUser(controls){
    var selectCityId = controls.cityName.value;
    if(selectCityId == ''){
      selectCityId = null;
    }

    var fullname = "";
    var candidateMiddleName = "";
    var firstNameTemp = controls.firstName.value.trim();
    var firstNameTempArray = firstNameTemp.split(/ (.*)/);
    var candidateFirstName = firstNameTempArray[0];

    if(firstNameTempArray[1] != undefined){
      candidateMiddleName = firstNameTempArray[1];
      //fullname = candidateFirstName+' '+candidateMiddleName+' '+controls.lastName.value.trim();
    }else{
      //fullname = candidateFirstName+' '+controls.lastName.value.trim();
    }

    var lastNameTemp = controls.lastName.value;
    var tempLastSectionFirstName = '';
    var tempLastSectionLastName = '';

    if(controls.lastName.value != null){
       lastNameTemp = controls.lastName.value.trim();
       tempLastSectionFirstName = lastNameTemp.substring(0, lastNameTemp.lastIndexOf(" ") + 1);
       tempLastSectionLastName = lastNameTemp.substring(lastNameTemp.lastIndexOf(" ") + 1, lastNameTemp.length);
    }
    //var lastNameTemp = controls.lastName.value.trim();
    //var tempLastSectionFirstName = lastNameTemp.substring(0, lastNameTemp.lastIndexOf(" ") + 1);
    //var tempLastSectionLastName = lastNameTemp.substring(lastNameTemp.lastIndexOf(" ") + 1, lastNameTemp.length);

    if(tempLastSectionFirstName != ''){
      candidateMiddleName = candidateMiddleName+' '+tempLastSectionFirstName;
    }

    fullname = firstNameTemp+' '+lastNameTemp;

    var pincode = 0
    if(this.editCandidateVal['pincode'] == undefined){
      pincode = 0
    }else if(this.editCandidateVal['pincode'] == null){
      pincode = 0
    }else{
      pincode = this.editCandidateVal['pincode'];
    }

    var source = ''
    if(this.editCandidateVal['candidateSource'].sourceName == undefined){
      source = 'Recruiter'
    }else if(this.editCandidateVal['candidateSource'].sourceName == null){
      source = 'Recruiter'
    }else{
      source = this.editCandidateVal['candidateSource'].sourceName;
    }

    

   // if(controls.resume.value == ''){
      const editData = { 
        "fullName": fullname.trim(),
        "firstName": candidateFirstName.trim(),
        "middleName": candidateMiddleName.trim(),
        "lastName": tempLastSectionLastName.trim(), //controls.lastName.value.trim(),
        "email": controls.email.value,
        "mobileNumber": controls.mobileNumber.value,
        "cityId":selectCityId,
        "cityName": "",
        "expectedSalary":controls.expectedSalary.value,
        "preferredJobTypes": controls.jobType.value,
        "preferredIndustries": controls.indistryPreference.value,
        "candidateId": this.editCandidate.candidateId,
        "pincode": pincode,
        "source": source,
      };    
      return editData;
    //}else{
      //Form data
    /*  const formData = new FormData();
        formData.set("fullName",fullname);
        formData.set("firstName",controls.firstName.value.trim());
        formData.set("middleName","");
        formData.set("lastName",controls.lastName.value.trim());
        formData.set("email",controls.email.value);
        formData.set("mobileNumber",controls.mobileNumber.value);
        formData.set("cityId",selectCityId);
        formData.set("cityName","");
        formData.set("expectedSalary",controls.expectedSalary.value);
        formData.set("jobType",controls.jobType.value);
        formData.set("indistryPreference",controls.indistryPreference.value);
        formData.set("resume",controls.resume.value);
        formData.set("filePath",null);

       console.log(formData);
      return formData;*/
   // }
  }

  Save(){
    this.disabled=true;
    const controls = this.editUserForm.controls;

    if(controls.mobileNumber.value == "" && controls.mobilePrefix.value ==null){
      this.editUserForm.controls.mobileNumber.clearValidators();
      this.editUserForm.controls.mobileNumber.updateValueAndValidity();

      this.editUserForm.controls.mobilePrefix.clearValidators();
      this.editUserForm.controls.mobilePrefix.updateValueAndValidity();
    }else{
      if(controls.mobileNumber.value!=null || controls.mobilePrefix.value !=null){

        this.editUserForm.controls.mobileNumber.setValidators([Validators.required]);
        this.editUserForm.controls.mobileNumber.updateValueAndValidity();

        this.editUserForm.controls.mobilePrefix.setValidators([Validators.required]);
        this.editUserForm.controls.mobilePrefix.updateValueAndValidity();

      }else{
      
        this.editUserForm.controls.mobileNumber.clearValidators();
        this.editUserForm.controls.mobileNumber.updateValueAndValidity();

        this.editUserForm.controls.mobilePrefix.clearValidators();
        this.editUserForm.controls.mobilePrefix.updateValueAndValidity();
      }
    }

    if (this.editUserForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    const candidateData=this.EditUser(controls);
    //const formData = this.editUserForm.value;
    console.log(candidateData)
    //return false

    var candidateId = this.editCandidate.candidateId;
    this.candidateService.updateCandidateData(candidateData, candidateId).subscribe(
      data => {
       // console.log(data);
       if(data['statusCode']==412){
        // this.errorMes=data['message'];
         this.disabled=false;
         //this.OpenAlert(data['message']);
       }else{
        this.addResume(candidateId);
       }

      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('CANDIDATE_DATA_UPDATE_ERROR');
      }
    );
   
  }

  
  addResume(candidateId: any){
    const controls = this.editUserForm.controls;   
    if(controls.resume.value != ''){

      //const formData = this.editUserForm.value;
      //console.log(data)
      const formData = new FormData();
        formData.set("file",controls.file.value);
        formData.set("id",candidateId);

        this.candidateService.addResume(formData, candidateId).subscribe(
          data => {
           // console.log(data);
           if(data['statusCode']==412){
            // this.errorMes=data['message'];
             this.disabled=false;
             //this.OpenAlert(data['message']);
           }else{
            this.activeModal.close();
            this.editUserForm.reset();
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult('SUCCESS');
            console.log('File upload done')
           }
    
          },
          error =>{
            this.errorMes=error.error.responseData;
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult('CANDIDATE_DATA_UPDATE_ERROR');
          }
        );
    
      }else{
        this.activeModal.close();
        this.editUserForm.reset();
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('SUCCESS');
      }
  }

  onSubmit(){
    this.disabled=true;
    const controls = this.editUserForm.controls;

    if (this.editUserForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    //console.log('submit', this.userModel)

    console.log (this.editUserForm.getRawValue());
  }


  // OpenAlert(message){

  //   const initialNotice = message;
  //   const dialogRef = this.dialog.open(AlertDialogComponent, {
  //     width: '600px',
  //     position: {top: '20px'},
  //     data: { title: 'Alert', body: initialNotice }
  //   });
  // }

  Close(){
    this.activeModal.close();
    this.editUserForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editUserForm.controls[controlName];
      if (!control) {
        return false;
      }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  onlyNumber(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.editUserForm.controls['mobileNumber'].setValue(onlyNumberValue);
  }

  onlyNumberExpectedSal(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.editUserForm.controls['expectedSalary'].setValue(onlyNumberValue);
  }

}
