import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizationService } from '../../services/localization.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubheaderService } from 'src/app/core/_base/layout';

@Component({
  selector: 'kt-contact-setting',
  templateUrl: './contact-setting.component.html',
  styleUrls: ['./contact-setting.component.scss']
})
export class ContactSettingComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  contactSettingsForm:FormGroup;
  resultSet:any;
  disabled:boolean;
  singularName: string;
  pruralName:string;
  chooseContactOwnerFlag;
  id:number;
  errorMes:String="";
  currentRouteUrl: string;
  prevsingularName: string;
  prevpruralName: string;
  @Output() updateContactTabLabel = new EventEmitter<string>();

  constructor(private fb: FormBuilder,
     private contactService: LocalizationService,
     private SpinnerService: NgxSpinnerService,
     private router: Router,
    private subheaderService: SubheaderService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('Contact');
    }
    this.contactSettingsForm = this.fb.group({
      singularName: ['', Validators.compose([ Validators.required])],
      pruralName:['', Validators.compose([ Validators.required])],
      chooseContactOwnerFlag:[]     
     });
     this.getSettingsValues();
     this.disabled=false;
  }

  getSettingsValues(){
    this.SpinnerService.show();
    this.contactService.getContactSettings(this.selectedProject).subscribe(
      res=>{
        console.log(res['responseData']);
        this.resultSet=res['responseData'];
        this.singularName=this.resultSet.singularName;
        this.updateContactTabLabel.emit(this.resultSet.singularName);
        this.pruralName=this.resultSet.pruralName;
        this.chooseContactOwnerFlag=this.resultSet.ownershipFlag.toString();
        this.id=this.resultSet.id;
        this.prevsingularName=this.resultSet.singularName;
        this.prevpruralName=this.resultSet.pruralName;
        this.SpinnerService.hide();
      },
      err=>{
        console.log(err);
      }
    )
  }


  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.contactSettingsForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }


  Save(){
    this.disabled=true;
    const controls = this.contactSettingsForm.controls;
    if (this.contactSettingsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
       
    const addSettingsData = {
        "projectId": this.selectedProject,  
        "singularName":controls.singularName.value.trim(),
        "pruralName":controls.pruralName.value.trim(),
        "id": this.id,
        "ownershipFlag":controls.chooseContactOwnerFlag.value
    };
    
   // console.log(addSettingsData);
    
    this.contactService.saveContactSettings(addSettingsData).subscribe(
      data => {
        this.disabled=false;
        if(!(this.prevsingularName == this.contactSettingsForm.controls.singularName.value) ||
          !(this.prevpruralName == this.contactSettingsForm.controls.pruralName.value)){
            this.snackBar.open('Renamed value will appear on your next login', '', {
              duration: 2000,
            });
          }else{
            this.snackBar.open('Settings updated.', '', {
              duration: 3000,
              panelClass: ['green-snackbar']
            });
          }
        this.getSettingsValues();

      },
      error =>{
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
       // this.errorMes=error.error.responseData;
      }
      
    );
  }

  Cancel(){
    this.disabled=true;
    this.getSettingsValues();
    this.disabled=false;
  }
  
  isDisabled() : boolean {
    return this.disabled;
   }


}
