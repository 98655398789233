import { Injectable } from '@angular/core';
import { StdLookupAttribute } from '../../contact-setting/models/std-lookup-attribute.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomAttributeList } from '../../contact-setting/models/custom-attribute-list.model';
//import { FieldType } from '../models/field-type.model';



@Injectable({
  providedIn: 'root'
})
export class RecruitmentService {
  shareData: any;
  constructor(private http: HttpClient) { }


  getstdLookUpList(id: number, typename: string){
    return this.http.post<StdLookupAttribute[]>(
      environment.baseUrl+'/setting/api/customType/getStdLookupForAttributeTypes',{id,typename});
  }

  getMyOpeningsList(projectId: number, teamFlag: any, activeInactiveFlag: any){
    if(teamFlag == 0){  //Self
      return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/positions/?page=0&size=500&active='+activeInactiveFlag+'');
    }else{  //team
      return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/positions/team/?page=0&size=1500&active='+activeInactiveFlag+'');
    }

  }

  getTeamsOpeningsList(projectId: number){
    return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/recruiter/dashboard?team=team');
  }

  // getFieldTypeList(){
  //   return this.http.get<FieldType[]>(
  //     environment.baseUrl+'/setting/api/customType/getAllAttributeTypes');
  // }

  save(addData){
    return this.http.post<any[]>(
      environment.baseUrl+'/transactions/api/product/addCustomField',addData);
  }

  edit(editData){
    return this.http.post<any[]>(
      environment.baseUrl+'/transactions/api/product/editCustomField',editData);
  }

  getProduct(id, projectId: number){
    return this.http.post<any[]>(
      environment.baseUrl + '/transactions/api/product/getCustomField',{"projectId": projectId,"id": id});
  }


  getMyOpeningsList_server_side(pageno:any,dataSize:any, teamFlag: any, activeInactiveFlag: any){
    
    if(teamFlag == 0){  //Self
      return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/positions/?page='+pageno+'&size='+dataSize+'&active='+activeInactiveFlag+'');
    }else{  //team
      return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/positions/team/?page='+pageno+'&size='+dataSize+'&active='+activeInactiveFlag+'');
    }

  }

  getRecruiterList(){
    return this.http.get(environment.baseUrl + '/legacy-services/api/v1/recruiter/all/');
  }

  saveRecruiterData(recruiterId: any,positionId:any){
    return this.http.put<any[]>(environment.baseUrl+'/legacy-services/api/v1/recruiter/'+recruiterId+'?positionId='+positionId+'','');
    //return this.http.get(environment.baseUrl+'/legacy-services/api/v1/recruiter/'+recruiterId+'?positionId='+positionId+'');

    
  }
}
