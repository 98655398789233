import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ManageActivitiesModel } from '../models/manage-activities.model';
import { environment} from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ManageActivitiesService {

  constructor(private http:HttpClient) { }

  getAllActivities(projectId: number){
    return this.http.get<ManageActivitiesModel[]>(
      environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId='+projectId);
  }

  saveActivity(activityData){
    return this.http.post<any[]>(
      environment.baseUrl+'/setting/api/activities/manageActivities/settings',activityData);
  }

  updateSequence(sequenceData){
    return this.http.post<any[]>(
      environment.baseUrl+'/transactions/api/activities/manageActivities/updateSequence',sequenceData);
  }

  getSelectedActivity(activityId, projectId: number) {
    return this.http.get<ManageActivitiesModel[]>(
      environment.baseUrl + '/setting/api/activities/manageActivities/settings/'+ activityId +'?projectId='+projectId);
  }

  deleteActivities(activityId, projectId: number){
    return this.http.delete<any[]>(
      environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId='+projectId+'&activityId='+ activityId);
  }

  getdynamicFieldNames(projectId: number){
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/projects/'+projectId+'/dynamicFieldNames');
  
  }

}
