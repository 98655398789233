import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarginDirective } from './margin.directive';
import { DynamicInputFieldDirective } from './dynamic-input-field.directive'; 
import { AddCustomAttributeComponent } from './add-custom-attribute/add-custom-attribute.component';
import { CustomAttributesListComponent } from './custom-attributes-list/custom-attributes-list.component';

//partials
import { PartialsModule } from '../../partials/partials.module';
//tabs
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//material
import { InlineSVGModule } from 'ng-inline-svg';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatListModule} from '@angular/material/list';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StrictNumberOnlyDirectiveDirective } from './strict-number-only-directive.directive';
import { NgxMatSelectSearchModule } from '../mat-select-search/public_api';
import { EditCustomAttributeComponent } from './edit-custom-attribute/edit-custom-attribute.component';
import {MatChipsModule} from '@angular/material/chips';
import { SearchPipe } from './pipes/search.pipe';
import { FieldsListComponent } from './fields-list/fields-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { EditCellRendererComponent } from './edit-cell-renderer/edit-cell-renderer.component';
import { AddFieldComponent } from './add-field/add-field.component';
import { EditFieldComponent } from './edit-field/edit-field.component';
import { RelationshipDialogComponent} from './Relationship/relationship-dialog/relationship-dialog.component';
import { PreviewDisplayOrderComponent } from './preview-display-order/preview-display-order.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { EditRelationDialogComponent } from './Relationship/_subs/edit-relation-dialog/edit-relation-dialog.component';
import { DeleteRelationDialogComponent } from './Relationship/_subs/delete-relation-dialog/delete-relation-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AddNewCompanyComponent} from './custom-add/add-new-company/add-new-company.component';
import { EventEmitterService } from './services/event-emitter.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EditCellRendererCompanyComponent } from './custom-edit/edit-cell-renderer-company/edit-cell-renderer-company.component';
import { EditCompaniesDialogComponent } from './custom-edit/edit-companies-dialog/edit-companies-dialog.component';
import { AddActivityComponent } from './add-activity/add-activity.component';
import { TwoDigitDecimaNumberDirectiveDirective } from './two-digit-decima-number-directive.directive';
import { AutoTrimSpacesDirective } from './auto-trim-spaces.directive';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';
import { AgmCoreModule } from '@agm/core';
import { EditActivityReportComponent } from './custom-edit/edit-activity-report/edit-activity-report.component';
@NgModule({
  	declarations: [
	 	  MarginDirective,
			DynamicInputFieldDirective,
			CustomAttributesListComponent,
			AddCustomAttributeComponent,
			StrictNumberOnlyDirectiveDirective,
			EditCustomAttributeComponent,
			SearchPipe,
			FieldsListComponent,
			EditCellRendererComponent,
			AddFieldComponent,
			EditFieldComponent,
			RelationshipDialogComponent,
			PreviewDisplayOrderComponent,
			EditRelationDialogComponent,
			DeleteRelationDialogComponent,
			AlertDialogComponent,
			AddNewCompanyComponent,
			ConfirmDialogComponent,
			EditCellRendererCompanyComponent,
			EditCompaniesDialogComponent,
			AddActivityComponent,
			TwoDigitDecimaNumberDirectiveDirective,
			AutoTrimSpacesDirective,
			LocationDialogComponent,
			EditActivityReportComponent

		],
  	imports: [
    	CommonModule,
		InlineSVGModule,
    	MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
    	MatDialogModule,
		FormsModule,
    	ReactiveFormsModule,
    	PartialsModule,
		NgbModule,
		NgxMatSelectSearchModule,
		MatChipsModule,
		MatTreeModule,
		MatListModule,
		OverlayModule,
		AgGridModule.withComponents([EditCellRendererComponent]),
		AgGridModule.withComponents([EditCellRendererCompanyComponent]),
		NgxSpinnerModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBWlY9IfA0AyRIz4DKL9W2wFFkxtXlKanw'
		  })  
  	],
  	exports:[
    	MarginDirective,
    	DynamicInputFieldDirective,
    	AddCustomAttributeComponent,
		CustomAttributesListComponent,
		StrictNumberOnlyDirectiveDirective,
		InlineSVGModule,
    	MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
    	MatDialogModule,
		FormsModule,
    	ReactiveFormsModule,
    	PartialsModule,
		NgbModule,
		MatChipsModule,
		NgxMatSelectSearchModule,
		SearchPipe,
		MatTreeModule,
		AgGridModule,		
		MatListModule,  
		FieldsListComponent,
		AddFieldComponent,
		EditFieldComponent,
		RelationshipDialogComponent,
		PreviewDisplayOrderComponent,	  
		AddNewCompanyComponent,		
		EditCellRendererCompanyComponent,
		EditCompaniesDialogComponent,
		NgxSpinnerModule,
		AddActivityComponent,
		AutoTrimSpacesDirective,
		LocationDialogComponent
	],
	providers: [
	  EventEmitterService
	],
	entryComponents:[
		 AddCustomAttributeComponent,
		 EditCustomAttributeComponent,
		 AddFieldComponent,
		 EditFieldComponent,
		 RelationshipDialogComponent,
		 PreviewDisplayOrderComponent,
		 EditRelationDialogComponent,
		 DeleteRelationDialogComponent,
		 AddNewCompanyComponent,
		 AlertDialogComponent,
		 ConfirmDialogComponent,
		 EditCompaniesDialogComponent,
		 AddActivityComponent,
		 LocationDialogComponent,
		 EditActivityReportComponent
	]

})
export class SharedModule { }
