import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizationService } from './services/localization.service';

@Component({
  selector: 'kt-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  selectedTab = 0;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  contactTabLabel: string = "Contact";
  customerTabLabel: string = "Customer";
  opportunityTabLabel : string = "Opportunity";
  dataMap = new Map();
  
  constructor(
    private _settingService:LocalizationService,
    private _spinnerService: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.fetchModulesNameFromServer();
  }

  fetchModulesNameFromServer(){
    this._spinnerService.show();
    this._settingService.getModulesDynamicName(this.selectedProject).subscribe(
      resp=>{
        console.log(resp);
        var responseData = resp['responseData'];    
        if(responseData){
          responseData.forEach(item => {
            //this.dataMap.set(item.moduleId, item);
            //localStorage.setItem("dynamicName_module_id"+item.moduleId, item);
            switch (item.moduleId) {
              case 1:
                this.contactTabLabel = item["singularName"];
              break;
              case 2:
                this.customerTabLabel = item["singularName"];
              break;
              case 3:
                this.opportunityTabLabel = item["singularName"];
              break;            
            }
          });
        }              
        this._spinnerService.hide();              
      },
      err=>{
        console.log(err);
      }
    )    
  }

  updateContactTabLabel(updatedContactTabText){    
    this.contactTabLabel = updatedContactTabText;
  }

  updateCustomerTabLabel(updatedCustomerTabText){    
    this.customerTabLabel = updatedCustomerTabText;
  }

  updateOpportunityTabLabel(updatedOpportunityTabText){    
    this.opportunityTabLabel = updatedOpportunityTabText;
  }

}
