// Angular
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';


export interface UserOption {
  value: string;
  viewValue: string;
  icon: any;
}

@Component({
  selector: 'kt-subheader1',
  templateUrl: './subheader1.component.html',
  styleUrls: ['./subheader1.component.scss']
})

export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fixed = true;
  @Input() clear = false;
  @Input() width = 'fluid';
  @Input() subheaderClasses = '';
  @Input() subheaderContainerClasses = '';
  @Input() displayDesc = false;
  @Input() displayDaterangepicker = true;

  today: number = Date.now();
  title = '';
  desc = '';
  singularName='';
  pruralName='';
  rightTabView=false;
  teamFlag=false;
  activeFlag='active';
  rightSearchButton=false;
  selected2:any;
  selected3:any;
  rightTeamActiveTabView = false;



  breadcrumbs: Breadcrumb[] = [];
  
  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(
    public subheaderService: SubheaderService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.selected2 = {value: 'self', icon: 'person', viewValue: 'Self'};
    this.selected3 = {value: 'active', icon: 'settings', viewValue: 'Active'};
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
      // breadcrumbs title sometimes can be undefined
      if (bt) {
        Promise.resolve(null).then(() => {
          this.title = bt.title;
          this.desc = bt.desc;
          this.singularName=bt.singularName;
          this.pruralName=bt.pruralName;
        });
      }
    }));

    this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.breadcrumbs = bc;
      });
    }));

    this.subscriptions.push(this.subheaderService.rightTab$.subscribe(rt => {
      Promise.resolve(null).then(() => {
        this.rightTabView = rt.viewFlag;
        console.log('self team dd ', this.teamFlag);
        this.teamFlag = rt.teamFlag;
        if(this.teamFlag)
          this.selected2 = {value: 'team', icon: 'group', viewValue: 'Team'};
      });
    }));
    //console.log("View : "+ this.rightTabView +", Team Flag : "+ this.teamFlag);

    this.subscriptions.push(this.subheaderService.rightTeamActiveTab$.subscribe(rt => {
      Promise.resolve(null).then(() => {
        this.rightTeamActiveTabView = rt.viewFlag;
        this.activeFlag = rt.activeFlag;
        //console.log('activ inactive dp', this.activeFlag);
       /* if(this.activeFlag){
          this.selected3 = {value: 'active', icon: 'group', viewValue: 'Active'};
        }*/
          
      });
    }));

    this.subscriptions.push(this.subheaderService.rightSearchButton$.subscribe(rt => {
      Promise.resolve(null).then(() => {
        this.rightSearchButton = rt.viewFlag;
      });
    }));

    

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  /**
   * User Select Dropdown
   */
  
  userOptions: UserOption[] = [
    {value: 'self', icon: 'person', viewValue: 'Self'},
    {value: 'team', icon: 'group', viewValue: 'Team'}
  ];

  activeOptions: UserOption[] = [
    {value: 'active', icon: 'person', viewValue: 'Active'},
    {value: 'inactive', icon: 'group', viewValue: 'Inactive'},
    {value: 'all', icon: 'group', viewValue: 'All'}
  ];
  
  compareFn(f1: UserOption, f2: UserOption): boolean {
    return f1 && f2? f1.value === f2.value : f1 === f2;
  }

  changeUser(selectedVal: any){
    console.log(selectedVal);
    if(selectedVal.value == 'team'){
      this.subheaderService.setRightTabConfig(true, true, true);
      //this.subheaderService.setRightTabConfig(true, selectedVal.value);
    }
    else{
      this.subheaderService.setRightTabConfig(true, false, true);
     // this.subheaderService.setRightTabConfig(true, selectedVal.value);
    }
  }

  changeOpeningStatus(selectedVal: any){
    console.log('ative-inactive-value----',selectedVal.value);
    this.subheaderService.setRightTeamActiveConfig(true,selectedVal.value,true);
    
  }

  FilterCandidate(selectedVal: any){
    console.log('click----', selectedVal)
    this.subheaderService.setRightSearchConfig(true,true);
  }

  

  

}
