<!--begin::Body-->
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-40 height-100" style="align-items:baseline;">
	<!--begin::Signin-->
	<div class="login-form login-signin">
		<div class="text-center mb-5 mb-lg-10">
			<h3 class="font-size-h1">Reset Password</h3>
			<p class="text-muted font-weight-semi-bold">Please enter your email address below to reset your password</p>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="form" [formGroup]="forgotPasswordForm" autocomplete="off">
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder=""
						formControlName="email" autocomplete="off" ktAutoTrimSpaces/>
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minLength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxLength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="fform-group d-flex justify-content-end flex-wrap flex-end">
				<a href="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-secondary px-9 py-4 my-3 mx-4">Cancel</a>
				<button (click)="submit()" id="kt_login_forgot_submit"
					class="btn btn-primary px-9 py-4 my-3"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>

				

			</div> 
			<!--end::Action-->
			
		</form>
	</div>
</div>