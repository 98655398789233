<div class="example-preview">
    <form  [formGroup]="settingActivityForm" autocomplete="off" >           
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">Activity Name</label>
            </div>

            <div class="col-md-8">
                <mat-form-field appearance="standard" class="input-100" >
                    <input matInput #inputActivity  maxlength="50" formControlName="activityName" [(ngModel)]="activityName" ktAutoTrimSpaces>
                
                    <mat-hint style="color: rgba(0,0,0,.54);" align="end">{{inputActivity.value?.length || 0}}/50</mat-hint>
                    <mat-error *ngIf="isControlHasError('activityName', 'required')">Name cannot be blank/Maximum 50 characters</mat-error>
                        <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                </mat-form-field> 
            </div>    
        </div>
        <div style="height: 10px;"></div>


        <!-- Associate activity -->
        <div class="form-group row mt-6">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-5"> Associate this Activity with </label>
           </div>
           <div class="col-md-8">

                <mat-checkbox [checked]="true" [class.mat-checkbox-disabled]="false"  disabled>
                    Activities
                </mat-checkbox>

                &nbsp;&nbsp;&nbsp;&nbsp;

                <mat-checkbox #checkbox1  formControlName="allowForCompany" [(ngModel)]="allowCompany" (change)="enableOrDisableGeofence()" [checked]="false" >
                    {{allowCompanyName}}
                </mat-checkbox>

                &nbsp;&nbsp;&nbsp;&nbsp;
              <span *ngIf="opportunityEnabled" >
                <mat-checkbox #checkbox2 formControlName="allowForOpportunity" [(ngModel)]="allowOpportunity"  (change)="openDialog()" [checked]="false" >
                    {{allowOpportunityName}}
                </mat-checkbox>  
              </span> 

                <br>
                <mat-hint class="form-hint" align="start"  style="color: rgba(0,0,0,.54)">Tick the options from where this activity can be performed</mat-hint> 
           </div>  
        </div> 

        <div style="height: 10px;"></div>
        <!-- Live location capture -->
        <div class="form-group row mt-6">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-5">Enforce Live Location Capture while Adding from App</label>
           </div>
           <div class="col-md-8">
                <mat-checkbox  formControlName="captureLiveLocation" [(ngModel)]="captureLiveLocation" checked>
                    Yes
                </mat-checkbox>
                <br>
                <mat-hint class="form-hint" align="start"  style="color: rgba(0,0,0,.54)">Tick this if you want to enable real time location during addition</mat-hint> 
           </div>  
        </div> 

        <div style="height: 20px;"></div>
        
        <div class="form-group row">
            <div class="col-md-4">
                <label col-form-label form-padding-top-35>Geofence</label>
            </div>
            <div class="col-md-8">
                <mat-checkbox #checkbox3  formControlName="geofence" [(ngModel)]="geofence" (change)="addGeofenceField(geofence)"> Yes
                </mat-checkbox>
                <br>
                <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select to activate geofence</mat-hint>
            </div>    
        </div>

        <div *ngIf="geofence==true" class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">Geofence Distance (in mtr)</label>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="standard" class="input-100" >
                    <input matInput #inputGeoDist  maxlength="5" formControlName="geofenceDistance" ktStrictNumberOnlyDirective [(ngModel)]="geofenceDistance" >
                    <mat-hint style="color: rgba(0,0,0,.54);" align="end">{{inputGeoDist.value?.length || 0}}/5</mat-hint>
                    <mat-error *ngIf="isControlHasError('geofenceDistance', 'required')">Geofence Distance cannot be blank.</mat-error>
                        <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                </mat-form-field>                    
            </div>
        </div>  
    
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-15">Status</label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid" >
                    <mat-select formControlName="status" >
                        <mat-option value="1">Active</mat-option>
                        <mat-option value="0">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        
        <div class="row" ktMargin [marginBottom]="'20px'"></div>

        <div class="modal-footer pr-0">
            <button type="button" class="btn btn-secondary" (click)="ResetForm()">Cancel</button> 
            <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
        </div>

    </form> 
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="editActivity">  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner> 
