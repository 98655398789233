<div class="example-preview">
    <div>
        <form [formGroup]="addCustomFieldForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Add Custom Field</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Name</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input  maxlength="50" formControlName="attributeName">
                        
                            <mat-hint align="end">{{input.value?.length || 0}}/50</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeName', 'required')">Field Name cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('attributeName', 'maxlength')"><strong>Maximum 50 characters</strong></mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon> 
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Instruction</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input  maxlength="50" formControlName="fieldInstruction">
                            <mat-hint>Enter the instruction while adding this field(Optional)</mat-hint>
                            <mat-hint align="end">{{input.value?.length || 0}}/100</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeName', 'required')">Field Name cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon> 
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label">Field Type</label>
                   </div>
                   <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">

                            <mat-select formControlName="attributeType" (selectionChange)="change($event.value)">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="attributeFilterType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let attributeType of attributeTypelist| search : attributeFilterType.value :'typeName'" [value]="attributeType">
                                    {{attributeType.typeName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Select what type of data will be entered in this field</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeType', 'required')">Field Type is required</mat-error>
                        </mat-form-field>
                   </div>  
                </div>  
                
                <div *ngIf="displaySingleSelectDiv">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <mat-chip-list #chipList aria-label="Enter Value" required
                                formControlName="attributeSingleName">
                                    <mat-chip *ngFor="let lookup of lookupVal" [selectable]="selectable"
                                             [removable]="removable" (removed)="remove(lookup)">
                                            {{lookup.lookupValue}}
                                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="New value..."
                                           formControlName="attributeSingleName"
                                           [matChipInputFor]="chipList"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="add($event)">
                                  </mat-chip-list>
                                  <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon>
                                  <mat-hint>Type comma seperated text to add values, duplicate values not allowed</mat-hint>
                                  <mat-error *ngIf="isControlHasError('attributeSingleName', 'required')">Value is required</mat-error>
                                
                 </mat-form-field>                    
                        </div>
                    </div>
                    </div>
                    
                    <div *ngIf="displayMultiSelectDiv">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <mat-chip-list #chipList aria-label="Enter Value" required
                                formControlName="attributeMultiSelectName">
                                    <mat-chip *ngFor="let lookup of lookupVal" [selectable]="selectable"
                                             [removable]="removable" (removed)="remove(lookup)">
                                            {{lookup.lookupValue}}
                                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="New value..."
                                           formControlName="attributeMultiSelectName"
                                           [matChipInputFor]="chipList"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="add($event)">
                                  </mat-chip-list>
                                  <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon> 
                                  <mat-hint>Type comma seperated text to add values, duplicate values not allowed</mat-hint>
                                  <mat-error *ngIf="isControlHasError('attributeMultiSelectName', 'required')">Value is required</mat-error>
                               
                            </mat-form-field>                    
                        </div>
                    </div>
                    </div>
                <div *ngIf="displayDateValueDiv">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Value Type</label>
                    </div>

                    <div class="col-md-8">
                        <mat-radio-group formControlName="dateValueType" fxLayout="column" fxLayoutGap=".25rem">
                        <mat-radio-button [value]="'Standard'">Standard</mat-radio-button>
                        <mat-radio-button [value]="'Custom'">Custom</mat-radio-button>
                         <!--   <mat-hint>Select the value type for date field</mat-hint>  -->
                         <mat-error class="form-error" *ngIf="isControlHasError('dateValueType', 'required')">Select one</mat-error>
                            </mat-radio-group>
                     </div>    
                </div>
                </div>
            <div *ngIf="isDisplayStandard()">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-15">Value</label>
                   </div>
                   <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select formControlName="standardTypeValue" (selectionChange)="changeStd($event.value)">
                                <mat-option *ngFor="let standardTypeValue of stdLookupList; let i = index;" [value]="standardTypeValue">
                                    {{standardTypeValue.value}}
                                </mat-option> 
                             </mat-select>

                            <mat-hint>Select standard date field from the list</mat-hint>
                        </mat-form-field>
                   </div>  
                </div>  
            </div>
            <div *ngIf="isDisplayCustom()">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Previous Dates Allowed</label>
                    </div>

                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input  matInput formControlName="previousDateValue" ktStrictNumberOnlyDirective >
                            <mat-hint>Number of days, blank defaults to no limit (Optional)</mat-hint>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Future Dates Allowed</label>
                    </div>

                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                             <input  matInput formControlName="futureDateValue" ktStrictNumberOnlyDirective>
                            <mat-hint>Number of days, blank defaults to no limit (Optional)</mat-hint>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>
            </div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Mandatory</label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox #checkbox (change)='showOptions(checkbox.checked)' formControlName="mandatoryCheck">
                        </mat-checkbox>
                    </div>
                </div> 

                <div *ngIf="isDisplayText">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>Unique Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptions(checkbox.checked)' formControlName="unique">
                                Select if this field should only allow unique values
                            </mat-checkbox>
                        </div>
                    </div> 

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label">Type of Characters</label>
                       </div>
                       <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="charactersType">
                              
                                    <mat-option value="1">All</mat-option>
                                    <mat-option value="2">Alpha-numeric</mat-option>
                                    <mat-option value="3">Alphabets</mat-option>
                                    
                                </mat-select>
                                <mat-hint>Select what type of data will be entered in this field </mat-hint>
                           </mat-form-field>
                       </div>  
                    </div>  

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Min Characters</label>
                        </div>
    
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput formControlName="minCharater" ktStrictNumberOnlyDirective >
                                <mat-hint>Enter minimum number of characters, blank default to no limit</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Characters</label>
                        </div>
    
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                 <input  matInput formControlName="maxCharater" ktStrictNumberOnlyDirective>
                                <mat-hint>Enter maximum number of characters, blank default to no limit</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayImage">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>Allow Browsing</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptions(checkbox.checked)' formControlName="allowBrowsing">
                                Select if user can choose image from gallery
                            </mat-checkbox>
                        </div>
                    </div> 

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Image Allowed</label>
                        </div>
    
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                 <input  matInput formControlName="maxImageAllowed" ktStrictNumberOnlyDirective>
                                <mat-hint>Number of maximum images to be taken, blank defaults to no limit</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayNumeric">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label">Validation Type</label>
                       </div>
                       <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="numericValidationType" (selectionChange)="changeValidation($event.value)">
                                    <mat-option value="1">Value</mat-option>
                                    <mat-option value="2">Digit</mat-option>                                    
                                </mat-select>
                                <mat-hint>Select what type of data will be entered in this field (Optional)</mat-hint>
                           </mat-form-field>
                       </div>  
                    </div>  
                </div>

                <div *ngIf="isDisplayNumericValue()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Minimum Value</label>
                        </div>
    
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                 <input  matInput formControlName="numericMinValue" ktStrictNumberOnlyDirective>
                                <mat-hint>Enter minimum no of value, blank defaults to no limit(Optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Maximum Value</label>
                        </div>
    
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                 <input  matInput formControlName="numericMaxValue" ktStrictNumberOnlyDirective>
                                <mat-hint>Enter maximum no of value, blank defaults to no limit(Optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayNumericDigit()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Minimum Digits</label>
                        </div>
    
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                 <input  matInput formControlName="digitMinValue" ktStrictNumberOnlyDirective>
                                <mat-hint>Enter minimum no of digits, blank defaults to no limit(Optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Maximum Digits</label>
                        </div>
    
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                 <input  matInput formControlName="digitMaxValue" ktStrictNumberOnlyDirective>
                                <mat-hint>Enter maximum no of digits, blank defaults to no limit(Optional)</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
            </div>
        </form> 
    </div>
</div>  
