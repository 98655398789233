import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CandidateService } from '../../Services/candidate.service';
//import { Role } from '../../Models/role.model';
import { Jobtype } from '../../Models/job-type-list.model';
import { Indistrytype } from '../../Models/indistry.model';
import { Candidates } from '../../Models/candidates.model';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddressService } from '../../../shared/services/address.service';

@Component({
  selector: 'kt-add-candidate',
  templateUrl: './add-candidate.component.html',
  styleUrls: ['./add-candidate.component.scss']
})
export class AddCandidateComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public addNewUserForm: FormGroup;
  //RoleList: Role[]    //not used
  Jobtype: Jobtype[];
  Indistrytype: Indistrytype[];
  activeUserList: Candidates[]; //Users[];
  public userRoleFilterType: FormControl = new FormControl();
  public reportingHeadIdFilterType: FormControl = new FormControl();
  errorMes:String="";
  disabled:boolean;
  userPwdCheck:boolean;
  password:String;
  fm:string;
  cityList:[];
  public countryNameFilter: FormControl = new FormControl();
  public stateNameFilter: FormControl = new FormControl();
  public cityNameFilter: FormControl = new FormControl();
  public jobTypeFilter: FormControl =new FormControl();
  public indistryPreference: FormControl =new FormControl();
  public indistryPreferenceFilter: FormControl =new FormControl();
  

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public candidateService: CandidateService,
    private eventEmitterService: EventEmitterService,
    private _as:AddressService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.initaddNewUserForm();
    this.getCandidateJobType();
    this.getIndistry();
    this.getAllCities();
    this.getActiveUserList();
    this.disabled=false;
    this.userPwdCheck=false;
    this.setByDefaultValue();
  }

  initaddNewUserForm(){
    this.addNewUserForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required ])],
      lastName: [''],
      mobilePrefix: ['', Validators.compose([Validators.required ])],
      mobileNumber:['', Validators.compose([Validators.required ])],
      email:['',Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      jobType:[],
      indistryPreference:[],
      cityName:[''],
      expectedSalary:[''],
      resume:[''],
      file: { value:'', disabled: false },
      resumeFile:[]
     });
  }

  

  onChange(event: Event) {
    let file = (event.target as HTMLInputElement).files[0];
    //console.log('file upload', file.name)
    this.addNewUserForm.controls['resume'].setValue(file.name);
    this.addNewUserForm.controls['file'].setValue(file.name);
  }

  getCandidateJobType(){
    var localStorageJobType = localStorage.getItem('jobType');
    this.Jobtype = JSON.parse(localStorageJobType);
  }

  getAllCities(){
    this.candidateService.getAllCity(this.selectedProject).subscribe( 
      (result)=>{
        console.log('all city', result);
        this.cityList=result['embedded']; 

      },
      error => {
        console.log(error)
      }
    )
  }

  getIndistry(){
    var localStorageIndistryType = localStorage.getItem('indistryType');
    this.Indistrytype = JSON.parse(localStorageIndistryType);
  }

  setByDefaultValue(){
    this.addNewUserForm.controls['mobilePrefix'].setValue('+91');
  }


  getActiveUserList(){
    this.candidateService.getActiveUserList(this.selectedProject).subscribe(
      (result)=>{
        this.activeUserList=result['responseData']; 
      },
      error => {
        console.log(error)
      }
    )
  }

  AddUser(controls){
    var selectCityId = controls.cityName.value;
    if(selectCityId == ''){
      selectCityId = null;
    }

    var fullname = "";
    var candidateMiddleName = "";
    var firstNameTemp = controls.firstName.value.trim();
    var firstNameTempArray = firstNameTemp.split(/ (.*)/);
    var candidateFirstName = firstNameTempArray[0];

    if(firstNameTempArray[1] != undefined){
      candidateMiddleName = firstNameTempArray[1];
    }

    var lastNameTemp = controls.lastName.value.trim();
    var tempLastSectionFirstName = lastNameTemp.substring(0, lastNameTemp.lastIndexOf(" ") + 1);
    var tempLastSectionLastName = lastNameTemp.substring(lastNameTemp.lastIndexOf(" ") + 1, lastNameTemp.length);

    if(tempLastSectionFirstName != ''){
      candidateMiddleName = candidateMiddleName+' '+tempLastSectionFirstName;
    }

    fullname = firstNameTemp+' '+lastNameTemp;
    
   // if(controls.resume.value == ''){
      const editData = { 
        "fullName": fullname.trim(),
        "firstName": candidateFirstName.trim(),
        "middleName": candidateMiddleName.trim(),
        "lastName": tempLastSectionLastName.trim(), //controls.lastName.value.trim(),
        "email": controls.email.value,
        "mobileNumber": controls.mobileNumber.value,
        "cityId":selectCityId,
        "cityName": "",
        "expectedSalary":controls.expectedSalary.value,
        "preferredJobTypes": controls.jobType.value,
        "preferredIndustries": controls.indistryPreference.value,
        "pincode": 0,
        "source": 'Recruiter',
      };    
      return editData;
    //}else{
      //Form data
      /*const formData = new FormData();
        formData.set("fullName",fullname);
        formData.set("firstName",controls.firstName.value.trim());
        formData.set("middleName","");
        formData.set("lastName",controls.lastName.value.trim());
        formData.set("email",controls.email.value);
        formData.set("mobileNumber",controls.mobileNumber.value);
        formData.set("cityId",selectCityId);
        formData.set("cityName","");
        formData.set("expectedSalary",controls.expectedSalary.value);
        formData.set("jobType",controls.jobType.value);
        formData.set("indistryPreference",controls.indistryPreference.value);
        formData.set("resume",controls.resume.value);
        formData.set("filePath",null);

       console.log(formData);
      return formData;*/
    //}
  }

  Save(){
    this.errorMes="";
    this.disabled=true;
    const controls = this.addNewUserForm.controls;
    
    if(controls.mobileNumber.value == "" && controls.mobilePrefix.value ==null){
      this.addNewUserForm.controls.mobileNumber.clearValidators();
      this.addNewUserForm.controls.mobileNumber.updateValueAndValidity();

      this.addNewUserForm.controls.mobilePrefix.clearValidators();
      this.addNewUserForm.controls.mobilePrefix.updateValueAndValidity();
    }else{
      if(controls.mobileNumber.value!=null || controls.mobilePrefix.value !=null){
  
        this.addNewUserForm.controls.mobileNumber.setValidators([Validators.required]);
        this.addNewUserForm.controls.mobileNumber.updateValueAndValidity();
  
        this.addNewUserForm.controls.mobilePrefix.setValidators([Validators.required]);
        this.addNewUserForm.controls.mobilePrefix.updateValueAndValidity();
  
      }else{
      
        this.addNewUserForm.controls.mobileNumber.clearValidators();
        this.addNewUserForm.controls.mobileNumber.updateValueAndValidity();
  
        this.addNewUserForm.controls.mobilePrefix.clearValidators();
        this.addNewUserForm.controls.mobilePrefix.updateValueAndValidity();
      }
    }

    if (this.addNewUserForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    
    const addData=this.AddUser(controls);
    console.log(addData);
    this.candidateService.saveCandidateData(addData).subscribe(
      data => {
        console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          this.OpenAlert(data['message']);
        }else{
          this.addResume(data['candidateId'], data)
          /*this.activeModal.close();
          this.addNewUserForm.reset();
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('USER_SAVE_SUCCESS');*/
        }
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );
    
  
    //this.addNewUserForm.reset();
  }

  addResume(candidateId: any, cData: any){
    const controls = this.addNewUserForm.controls;   
    if(controls.resume.value != ''){
      //const formData = this.editUserForm.value;
      //console.log(data)
      const formData = new FormData();
        formData.set("file",controls.resumeFile.value);
        formData.set("id",candidateId);

        this.candidateService.addResume(formData, candidateId).subscribe(
          data => {
           // console.log(data);
           if(data['statusCode']==412){
            // this.errorMes=data['message'];
             this.disabled=false;
             //this.OpenAlert(data['message']);
           }else{
            this.activeModal.close();
            this.addNewUserForm.reset();
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult('USER_SAVE_SUCCESS'); //'USER_SAVE_SUCCESS'
            console.log('File upload done')
           }
    
          },
          error =>{
            this.errorMes=error.error.responseData;
            this.activeModal.close();
            this.addNewUserForm.reset();
            this.disabled=false;
            this.eventEmitterService.onModalCloseResult('CANDIDATE_DATA_FILE_UPLOAD_FAILED');
          }
        );
    
      }
      else{
        this.activeModal.close();
        this.addNewUserForm.reset();
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('USER_SAVE_SUCCESS'); //'USER_SAVE_SUCCESS'
      }
  }

  OpenAlert(message){
    const initialNotice = message;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      position: {top: '20px'},
      data: { title: 'Alert', body: initialNotice }
    });
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        //this.srcResult = e.target.result;
      };

      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  Close(){
    this.activeModal.close();
    this.addNewUserForm.reset();
  }

  checkPwdValue(val){
    this.password="";
    if(val==1){
      this.addNewUserForm.controls.password.setValidators([Validators.required]);
      this.addNewUserForm.controls.password.updateValueAndValidity();
    }else{
      this.addNewUserForm.controls.password.clearValidators();
      this.addNewUserForm.controls.password.updateValueAndValidity();
    }
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addNewUserForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isDisabled() : boolean {
  return this.disabled;
  }


  onlyNumber(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.addNewUserForm.controls['mobileNumber'].setValue(onlyNumberValue);
  }

  onlyNumberExpectedSal(inputFieldValue){
    //console.log(inputFieldValue)
    var value = inputFieldValue
    var onlyNumberValue = value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //console.log(onlyNumberValue)
    this.addNewUserForm.controls['expectedSalary'].setValue(onlyNumberValue);
  }

}
