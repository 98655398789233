import { Component, OnInit, ViewEncapsulation, Input, ɵConsole } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NgbModal,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { CustomService } from '../../contact-setting/services/custom.service';
import { AttributeType } from '../../contact-setting/models/attribute-type.model';
import { LooupValues } from '../../contact-setting/models/looup-values.model';
import { ContactListService } from '../../contact-setting/services/contact-list.service';
import { Contact } from '../../contact-setting/models/contact.model';
import { DateAttributes } from '../../contact-setting/models/date-attributes.model';
import { StdLookupAttribute } from '../../contact-setting/models/std-lookup-attribute.model';
import { CustomAttributesListComponent } from '../custom-attributes-list/custom-attributes-list.component';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import {MatChipInputEvent} from '@angular/material/chips';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import { SelectedCustomTypes } from '../../contact-setting/models/selected-custom-types.model';

@Component({
  selector: 'kt-edit-custom-attribute',
  templateUrl: './edit-custom-attribute.component.html',
  styleUrls: ['./edit-custom-attribute.component.scss']
})

export class EditCustomAttributeComponent implements OnInit {
  
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  lookupVal: LooupValues[] = [];

  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  errorMes:String="";
 
  mandatoryContact:boolean;
  mandatoryContactFlag:number;

  stdLookupList: StdLookupAttribute[];
  attributeType:AttributeType;
  resultSet:any;
  displayDateValueDiv: boolean;
  displayMultiSelectDiv: boolean;
  displaySingleSelectDiv:boolean;
  allContactTypeList: Contact[];
  dateAttributes: DateAttributes;
  lookUpValues: LooupValues[];
  lookup:LooupValues;
  standardTypeValue: StdLookupAttribute;
  contactTypes: Contact[];
  contactTyp: Contact;
  contactTypesIds: string;
  customAttributeId: number;
  customAttributeName: string;
  attributeName:String;
  selectedAttributeType:number;
  editCustomVal: CustomAttibute;
  editing:boolean;
  dateValueType;
  futureDateValue:number | string;
  previousDateValue:number | string;
  flag_Value: boolean;
  selectedContactCustomList: SelectedCustomTypes[];
  disabled:boolean;
  duplicate:boolean;

  /** list of banks filtered by search keyword */
  public attributeTypelist: ReplaySubject<AttributeType[]> = new ReplaySubject<AttributeType[]>(1);

  public standardTypelist: ReplaySubject<StdLookupAttribute[]> = new ReplaySubject<StdLookupAttribute[]>(1);
  
  public allContactTypList: ReplaySubject<Contact[]> = new ReplaySubject<Contact[]>(1);
  
  resultSetOfattributeTypelist:AttributeType[];
  resultSetOfstandardTypelist:StdLookupAttribute[];
  resultSetOfallContactTypList:Contact[];

  /** control for the MatSelect filter keyword */
  public attributeFilterType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();
  public contactFilterType: FormControl = new FormControl();

  public editCustomAttributeForm: FormGroup;

   /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public customService: CustomService,
    public contactService: ContactListService) {
       this.unsubscribe = new Subject();
   }

  ngOnInit(): void {
 
    this.editing=true;
    this.initaddCustomAttributeForm();
    this.getAttributeType();
    this.getAllContactTypeList();
    this.editCustomAttributeForm.reset();
    this.displayDateValueDiv=false;
    this.displayMultiSelectDiv=false;
    this.displaySingleSelectDiv=false;
    this.dateAttributes=new DateAttributes();
    this.dateAttributes.stdLookupAttribute= new StdLookupAttribute();
    this.lookUpValues= new Array<LooupValues>();
    this.contactTypes= new Array<Contact>();
    this.contactTypesIds='';
    this.standardTypeValue= new StdLookupAttribute();
    this.disabled=false;
    this.duplicate=false;

    // listen for search field value changes
    this.attributeFilterType.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
     // get the search keyword
      const search = this.attributeFilterType.value;
      this.filterAttributeType(search);
    });

    // listen for search field value changes
    this.standardFilterType.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
     // get the search keyword
      const search = this.standardFilterType.value;
      this.filterStandardType(search);
    });

    // listen for search field value changes
    this.contactFilterType.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
     // get the search keyword
      const search = this.contactFilterType.value;
      this.filterContactType(search);
    });

     this.attributeName=this.editCustomVal.attributeName;
     this.selectedAttributeType=this.editCustomVal.attributeType.id;
     this.editCustomAttributeForm.controls['attributeType'].setValue(this.editCustomVal.attributeType.id);
     
     this.getSelctedContactCustom();
    // this.editCustomAttributeForm.controls['attributeContactType'].setValue([1,2]);
    
     this.attributeType=this.editCustomVal.attributeType;
     if(null!= this.editCustomVal.dateAttributes){
     if(null!= this.editCustomVal.dateAttributes.stdLookupAttribute){
        this.dateValueType='Standard';
        this.editCustomAttributeForm.controls['standardTypeValue'].setValue(this.editCustomVal.dateAttributes.stdLookupAttribute.id);
    
     }else{
        this.dateValueType='Custom';
        this.previousDateValue=this.editCustomVal.dateAttributes.previousDateAllowed;
        this.futureDateValue=this.editCustomVal.dateAttributes.futureDateAllowed;
     }
    }
     this.change(this.selectedAttributeType);
    var flag=this.editCustomVal.mandatoryFlag;
      if(flag==1){
       this.flag_Value=true;
     }else{
      this.flag_Value=false;
      }
     
  }
  
  getSelctedContactCustom(){

    const contactCustomData = {
      'attributeId':this.editCustomVal.id,
      "projectId": this.selectedProject,
      'moduleType':1           
    };

    this.customService.getSelectedContactCustomAttribute(contactCustomData).subscribe(
      (result)=>{
        this.selectedContactCustomList=result;
        if(null!=this.selectedContactCustomList){
          var selectedVal='';
          for (let contactCus of this.selectedContactCustomList){
                if(contactCus.isSelected == "yes" || contactCus.isSelected == "Yes"){
                  selectedVal=selectedVal+','+contactCus.id;
                }
          }
          selectedVal = selectedVal.substr(1);

        var selectedContact = Array.from(selectedVal.split(','),Number);
        this.editCustomAttributeForm.controls['attributeContactType'].setValue(selectedContact);
        }
		},
		error => {
			console.log(error)
    })
  }

  getAttributeType(){
    this.customService.getAtrributeType().subscribe(
		(result)=>{
        this.attributeTypelist.next(result);
        this.resultSetOfattributeTypelist=result;
		},
		error => {
			console.log(error)
    })
  }

  protected filterAttributeType(search) {
     if (!search) {
      this.attributeTypelist.next(this.resultSetOfattributeTypelist);
       return;
     } else {
       search = search.toLowerCase();
     }
     // filter the banks
     this.attributeTypelist.next(
       this.resultSetOfattributeTypelist.filter(res => res.typeName.toLowerCase().indexOf(search) > -1)
     );
  }


  getStandardLookUpList(){
    var attributeId=this.attributeType.id;
    var attributeName=this.attributeType.typeName;
    this.customService.getstdLookUpList(attributeId,attributeName).subscribe(
		(result)=>{
        this.standardTypelist.next(result);
        this.resultSetOfstandardTypelist=result;
		},
		error => {
			console.log(error)
    })
  }

  protected filterStandardType(search) {
     if (!search) {
      this.standardTypelist.next(this.resultSetOfstandardTypelist);
       return;
     } else {
       search = search.toLowerCase();
     }
     // filter the banks
     this.standardTypelist.next(
       this.resultSetOfstandardTypelist.filter(res => res.value.toLowerCase().indexOf(search) > -1)
     );
  }


  getAllContactTypeList(){
    this.contactService.getContact(this.selectedProject).subscribe(
		(result)=>{
        this.allContactTypList.next(result['responseData']);
        this.resultSetOfallContactTypList=result['responseData'];
        this.allContactTypeList=result['responseData'];
		},
		error => {
			console.log(error)
    })
  }

  protected filterContactType(search) {
     if (!search) {
      this.allContactTypList.next(this.resultSetOfallContactTypList);
       return;
     } else {
       search = search.toLowerCase();
     }
     // filter the banks
     this.allContactTypList.next(
       this.resultSetOfallContactTypList.filter(res => res.contactTypeName.toLowerCase().indexOf(search) > -1)
     );
  }

  initaddCustomAttributeForm(){
    this.editCustomAttributeForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
      attributeType: ['', Validators.compose([ Validators.required])],
      dateValueType: [''],
      attributeMultiSelectName:[''],
      attributeSingleName:[],
      attributeContactType:[],
      standardTypeValue:[],
      lookup:[],
      futureDateValue:[],
      previousDateValue:[]
     });
  }

  Save(){
    this.disabled=true;
    if(this.attributeType.id ==2){
      if(this.lookupVal != null && this.lookupVal.length!=0){
          this.editCustomAttributeForm.controls['attributeMultiSelectName'].clearValidators();
          this.editCustomAttributeForm.controls['attributeMultiSelectName'].updateValueAndValidity();
      }
    }

    if(this.attributeType.id ==1){
      if(this.lookupVal != null && this.lookupVal.length!=0){
          this.editCustomAttributeForm.controls['attributeSingleName'].clearValidators();
          this.editCustomAttributeForm.controls['attributeSingleName'].updateValueAndValidity();
      }
    }
    const controls = this.editCustomAttributeForm.controls;
      if (this.editCustomAttributeForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled=false;
        return;
      }
      const addAttributeData = {
        attributeName: controls.attributeName.value,
        dateValueType: controls.dateValueType.value,
        attributeContactType: controls.attributeContactType.value,
        singleValues: controls.attributeSingleName.value,
        ValuesMutli: controls.attributeMultiSelectName.value,
        futureDateValue: controls.futureDateValue.value,
        previousDateValue: controls.previousDateValue.value
      };

      if(this.mandatoryContact == true){
        this.mandatoryContactFlag=1;
      }else{
        this.mandatoryContactFlag=0;
      }
 
      if(this.attributeType.id ==1){
        
      }else if(this.attributeType.id ==2){
        
      }else{
        this.lookUpValues=[];
      }
      
      if(this.attributeType.id ==3){
        if(addAttributeData.dateValueType == 'Standard'){
            this.dateAttributes.stdLookupAttribute.id=this.standardTypeValue.id;
            this.dateAttributes.stdLookupAttribute.value=this.standardTypeValue.value;
            this.dateAttributes.previousDateAllowed=null;
            this.dateAttributes.futureDateAllowed=null;
            if(null!=this.editCustomVal.dateAttributes){
              this.dateAttributes.id=this.editCustomVal.dateAttributes.id;
            }
        }else if(addAttributeData.dateValueType =='Custom'){
            this.dateAttributes.previousDateAllowed=Number(addAttributeData.previousDateValue);
            this.dateAttributes.futureDateAllowed=Number(addAttributeData.futureDateValue);
            this.dateAttributes.stdLookupAttribute=null;
            if(null!=this.editCustomVal.dateAttributes){
              this.dateAttributes.id=this.editCustomVal.dateAttributes.id;
            }
        }else{
            this.dateAttributes=null;       
        }
      }

      if(!(this.contactTypesIds=='')){
      var array = this.contactTypesIds.split(',');
      for(var i=0;i<array.length;i++){
         var contactId= array[i];
          for(let contact of this.allContactTypeList){
            if(contact.id == Number(contactId)){
               this.contactTyp = new Contact();
               this.contactTyp.id=Number(contactId);
               this.contactTyp.contactTypeName=contact.contactTypeName;
               this.contactTypes.push(this.contactTyp);
            }
         }
      } 
      }
      console.log(this.attributeType);
      this.customService
      .editCustomAttribute(this.editCustomVal.id,addAttributeData.attributeName, this.attributeType,this.mandatoryContactFlag,
        this.lookupVal,this.dateAttributes, this.selectedProject)
        .pipe(
          tap(CustomAttibute => {
            console.log(CustomAttibute);
           this.customAttributeId= CustomAttibute['responseData'].attributeId;
           this.customAttributeName= addAttributeData.attributeName;
              if(!(this.contactTypesIds=='')){
              this.customService
                  .addCustomAttributeToContact(this.customAttributeId,this.customAttributeName,this.contactTypes, this.selectedProject)
                      .pipe(
                      tap(Contact => {
                      //  console.log(Contact);
                      }),
                       takeUntil(this.unsubscribe),
                       finalize(() => {
                        this.loading = false;
                        this.activeModal.close();
                        this.editCustomAttributeForm.reset();
                 })
                )
                .subscribe(
                   data => console.log("sucesssfully called CONTACT CUSTOM  API"),
                   error => {
                    this.errorMes=error.error.responseData;
                  }
				  
                );
              }  
          }),
          takeUntil(this.unsubscribe),
          finalize(() => {
            this.loading = false;
            this.activeModal.close();
            this.editCustomAttributeForm.reset();
          })
        )
        .subscribe(
          data => console.log("sucesssfullycalled Custom Attribute API"),
          error => {
            this.errorMes=error.error.responseData;
          }
  
          
        );  
  }

  get addCustomData(){
    return this.editCustomAttributeForm.controls;
  }

  changeStd(data){
   this.standardTypeValue.id=data;
  }

  changeMulti(data){
    this.contactTypesIds=data.toString();
  }

  change(data){
    this.attributeType.id=data;
    if(this.attributeType.id ==3){
      this.displayDateValueDiv=true;
      this.getStandardLookUpList();
      this.editCustomAttributeForm.controls.dateValueType.setValidators([Validators.required]);
      this.editCustomAttributeForm.controls.dateValueType.updateValueAndValidity();
    }else{
      this.displayDateValueDiv=false;
      this.editCustomAttributeForm.controls.dateValueType.clearValidators();
      this.editCustomAttributeForm.controls.dateValueType.updateValueAndValidity();
      this.resetDateValue();
    }
    this.lookupVal=[];
    if(this.attributeType.id ==2){
      this.displayMultiSelectDiv=true;
      this.lookupVal=this.editCustomVal.lookupValues;
      this.editCustomAttributeForm.controls.attributeMultiSelectName.setValidators([Validators.required]);
      this.editCustomAttributeForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }else{
      this.displayMultiSelectDiv=false;
      //this.lookupVal=[];
      this.editCustomAttributeForm.controls.attributeMultiSelectName.clearValidators();
      this.editCustomAttributeForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }

    if(this.attributeType.id ==1){
      this.displaySingleSelectDiv=true;
      this.lookupVal=this.editCustomVal.lookupValues;
      this.editCustomAttributeForm.controls.attributeSingleName.setValidators([Validators.required]);
      this.editCustomAttributeForm.controls.attributeSingleName.updateValueAndValidity();
    }else{
      this.displaySingleSelectDiv=false;
//	this.lookupVal=[];
      this.editCustomAttributeForm.controls.attributeSingleName.clearValidators();
      this.editCustomAttributeForm.controls.attributeSingleName.updateValueAndValidity();
    }

  }

  isDisplayStandard(): boolean{
    if(this.editCustomAttributeForm.controls.dateValueType.value =='Standard'){
      this.editCustomAttributeForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.editCustomAttributeForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    }else{
      this.editCustomAttributeForm.controls.standardTypeValue.clearValidators();
      this.editCustomAttributeForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  isDisplayCustom(): boolean{
    if(this.editCustomAttributeForm.controls.dateValueType.value =='Custom'){
      return true;
    }else{
      return false;
    }
  }

showOptionsEdit(val) {
    this.mandatoryContact=val;
    this.mandatoryContact=false;
    //this.editContactVal.primary=val;
  }
  resetDateValue(){
    this.editCustomAttributeForm.controls['dateValueType'].reset();
  }

  Close(){
    this.activeModal.close();
    this.editCustomAttributeForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editCustomAttributeForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.duplicate=false;
   
    if ((value || '').trim()) {
      for(let fr of this.lookupVal){
        var name= fr.lookupValue;
        if(value.trim()==name){
          this.duplicate=true;
        }
      }
      if(this.duplicate==false){
      this.lookupVal.push({lookupValue: value.trim(),
      isActive: 1, stageId:null});
      }
    }
    
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(lookup: LooupValues): void {
    const index = this.lookupVal.indexOf(lookup);

    if (index >= 0) {
      this.lookupVal.splice(index, 1);
    }
  }

  isDisabled() : boolean {
    return this.disabled;
   }

}

