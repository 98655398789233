import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { companiesModel } from '../models/companies.model';
import { environment} from '../../../../../environments/environment';
import { copmanyTypeListModel} from '../models/companyTypeList.model';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular/lib/angularFrameworkComponentWrapper';
import { ManageActivitiesModel } from '../../manage-activities/models/manage-activities.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http:HttpClient) { }

  getAllCompanyDeatils(projectId:number){
    return this.http.post<companiesModel[]>(environment.baseUrl + '/transactions/api/company/getAllCompanyDetails',{"projectId": projectId})
  }

  getCompanyDetails(companyId, projectId){
    return this.http.post<companiesModel>(environment.baseUrl + '/transactions/api/company/getCompanyDeatils',{
      "projectId": projectId,
      "companyId": companyId
    })
  }


  getCompanyTypeList(projectId:number){
    return this.http.post<copmanyTypeListModel[]>(environment.baseUrl + '/company/getAllCompanyType',{
      "projectId": projectId
    })
  }

  //for add new company popup

  getCompany(projectId:number){
    return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/company/getCompanyLookup?projectId='+projectId);
  }

  getCompanyFilter(projectId:number, teamFlag:boolean){
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/company/getCompanyLookupSelfOrTeam?projectId='+projectId+'&teamFlag='+teamFlag);
  }



  uploadImage(projectId: number, companyId: number, frontImage: File): Observable<any> {
    const formData: any = new FormData();
    formData.append("projectId", projectId);
    formData.append("companyId", companyId);
    formData.append("frontImage", frontImage);

    console.log(formData);
    return this.http.post(environment.baseUrl + '/transactions/api/company/uploadFrontImage', formData,
    {
      reportProgress: true,
      observe: 'events'

    }).pipe(
      catchError(this.errorMgmt)
    ) 
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getAllActivities(projectId: number){
    return this.http.get<ManageActivitiesModel[]>(
      environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId='+projectId);
  }


}


