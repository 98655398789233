<div class="example-preview">
    <div>
        <div class="modal-header d-block">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="modal-title">Offer Candidate</h4>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-4">
                    <label>Name : {{ candidateName }}</label>
                </div>
                <div class="col-md-3">
                    <label >Phone: {{ candidatePhone }}</label>
                </div>
                <div class="col-md-5">
                    <label >Location: {{ candidateAddress }}</label>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-4">
                    <label >Minimum Ctc: {{ candidateMinCtc }}</label>
                </div>
                <div class="col-md-3">
                    <label>Maximum Ctc : {{ candidateMaxCtc }}</label>
                </div>
                
            </div>
        </div>
        <div class="modal-body">
            <mat-horizontal-stepper [linear]="isLinear">
                <mat-step [stepControl]="firstFormGroup">
                  <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Details</ng-template>
                    
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Email id</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100" >
                                <input matInput formControlName="email" >
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">email</mat-icon>
                                <mat-error *ngIf="firstFormGroupIsControlHasError('email', 'required')">Email address cannot be blank</mat-error>    
                                <mat-error *ngIf="firstFormGroupIsControlHasError('email', 'pattern')">Email address format is incorrect</mat-error> 
                                
                              </mat-form-field>                    
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Aadhaar Number</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                <input matInput  formControlName="adharNumber" (input)="onlyNumber($event.target.value)" maxlength="12" minlength="12">
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                <!-- <mat-error *ngIf="isControlHasError('adharNumber', 'required')">Aadhaar Number cannot be blank</mat-error>  -->
                                <mat-error *ngIf="firstFormGroupIsControlHasError('adharNumber', 'required')">Aadhaar Number cannot be blank</mat-error> 
                                <mat-error *ngIf="firstFormGroupIsControlHasError('adharNumber', 'minlength')">Aadhaar Number should be 12 digit</mat-error> 
                                <mat-error *ngIf="firstFormGroupIsControlHasError('adharNumber', 'checkAadhaarNo')">Aadhar number already present, please enter correct Aadhaar number</mat-error> 
                                
                            </mat-form-field>                   
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Date of Birth</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100" >
                                <input matInput  [max]="maxDobDate" [matDatepicker]="pickerdob" formControlName="dateOfBirth">
                                <mat-datepicker-toggle matSuffix [for]="pickerdob" [ngStyle]="{'color':'#3699FF'}"></mat-datepicker-toggle>
                                <mat-datepicker #pickerdob></mat-datepicker>
                                <mat-error *ngIf="firstFormGroupIsControlHasError('dateOfBirth', 'required')">Date cannot be blank</mat-error>
                            </mat-form-field>                    
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Gender</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="gender">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="genderFilter" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let genderdata of genderList| search : genderFilter.value :'name'" [value]="genderdata.value">
                                        {{genderdata.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="firstFormGroupIsControlHasError('gender', 'required')">Data cannot be blank</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Expected Joining Date</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100" >
                                <input matInput [min]="minDate" [max]="maxJoiningDate" [matDatepicker]="picker" formControlName="joiningDate">
                                <mat-datepicker-toggle matSuffix [for]="picker" [ngStyle]="{'color':'#3699FF'}"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="firstFormGroupIsControlHasError('joiningDate', 'required')">Date cannot be blank</mat-error>
                            </mat-form-field>                    
                        </div>
                    </div>

                    <!-- <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Grade</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="gradeName">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="gradeNameFilter" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let grade of gradeList| search : gradeNameFilter.value :'name'" [value]="grade.id">
                                        {{grade.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->

                    <!-- <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Band</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="bandName">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="bandNameFilter" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let band of bandList| search : bandNameFilter.value :'name'" [value]="band.id">
                                        {{band.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Claim Policy</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="claimPolicyName">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="claimPolicyFilter" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let claimPolicy of claimPolicyList| search : claimPolicyFilter.value :'name'" [value]="claimPolicy.policyId">
                                        {{claimPolicy.policyDesc}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="firstFormGroupIsControlHasError('claimPolicyName', 'required')">Claim policy cannot be blank</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Mediclaim Policy</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="mediclaimPolicyName">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="mediclaimPolicyFilter" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let mediclaimPolicy of mediclaimPolicyList| search : mediclaimPolicyFilter.value :'name'" [value]="mediclaimPolicy.policyId" data-deductionAmount="mediclaimPolicy.deductionAmount">
                                        {{mediclaimPolicy.policyName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="firstFormGroupIsControlHasError('mediclaimPolicyName', 'required')">Mediclaim policy cannot be blank</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-12">
                            <span style="color:red">{{errorMes}}</span>
                        </div>
                    </div>
                    
                    <div class="text-right mt-10">
                      <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                      <button mat-button class="btn btn-primary ml-2" (click)="goToNextForm()" matStepperNext>Salary Structure</button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Salary Structure</ng-template>

                    <!-- <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Salary Range (Annual CTC)</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                <input matInput  formControlName="salaryRange" ktStrictNumberOnlyDirective [readonly]=true>
                                <mat-label style="color: rgba(0,0,0,.54);">Salary Range</mat-label>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                <mat-error *ngIf="isControlHasError('totalAnualCtc', 'required')">Value is required</mat-error> 
                            </mat-form-field> 
                        </div>
                    </div> -->
                      
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Salary Details</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="totalAnualCtc" (input)="onlyNumberTotalCtc($event.target.value)" (keyup)="calculateVariable()">
                                        <mat-label style="color: rgba(0,0,0,.54);">Total Annual CTC</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                        <mat-error *ngIf="isControlHasError('totalAnualCtc', 'required')">Value is required</mat-error>
                                        <!-- <mat-error *ngIf="customErrorMessageFlag')">{{customErrorMsg}}</mat-error>  -->
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="fixedAnualCtc" (input)="onlyNumberFixedCtc($event.target.value)" (keyup)="calculateVariable()" [readonly]="lockFixedVariable">
                                        <mat-label style="color: rgba(0,0,0,.54);">Fixed Annual CTC</mat-label>
                                        <mat-icon *ngIf="lockFixedVariable" matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('fixedAnualCtc', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="variableAnualCtc" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Variable Annual CTC</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('variableAnualCtc', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">PF Contribution</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-8">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="pfContribution">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="pfContributionFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let pfContribution of pfContributionList| search : pfContributionFilter.value :'name'" [value]="pfContribution.id">
                                                {{pfContribution.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('pfContribution', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                  
                                </div>
                                <div class="col-md-4">
                                    <button type="button" class="btn btn-primary ml-2" (click)="calculateSalary()">Calculate Salary</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Basic Pay</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyBasicPay" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyBasicPay', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyBasicPay" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyBasicPay', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Bonus</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyBonus" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyBonus', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyBonus" ktStrictNumberOnlyDirective >
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyBonus', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">HRA</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyHRA" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyHRA', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyHRA" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyHRA', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Special Allowance</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlySpecialAllowance" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlySpecialAllowance', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallySpecialAllowance" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallySpecialAllowance', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Gross Pay</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyGrossPay" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyGrossPay', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyGrossPay" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyGrossPay', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">PF contribution-Employer</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyPFcontEmployer" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyPFcontEmployer', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyPFcontEmployer" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyPFcontEmployer', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">GOC</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyGoc" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyGoc', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyGoc" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyGoc', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">PF contribution-Employee</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyPFcontEmployee" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyPFcontEmployee', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyPFcontEmployee" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyPFcontEmployee', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">ESI contribution-Employer</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyEmployerEsi" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyEmployerEsi', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyEmployerEsi" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyEmployerEsi', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">ESI contribution - Employee</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyEmployeeEsi" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyEmployeeEsi', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyEmployeeEsi" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyEmployeeEsi', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [className]="isGratuityShow ? '' : 'd-none'">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Gratuity</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyGratuity" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyGratuity', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyGratuity" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyGratuity', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Insurance Deduction-Employee</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyInsuranceDecuction" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyInsuranceDecuction', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyInsuranceDecuction" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyInsuranceDecuction', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Net Take Home</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="monthlyNetTakeHome" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Monthly</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('monthlyNetTakeHome', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                        <input matInput  formControlName="annuallyNetTakeHome" ktStrictNumberOnlyDirective [readonly]=true>
                                        <mat-label style="color: rgba(0,0,0,.54);">Annually</mat-label>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('annuallyNetTakeHome', 'required')">Value is required</mat-error> 
                                    </mat-form-field>                   
                                </div>
                            </div>
                        </div>
                    </div>

                      <div class="form-group row">
                          <div class="col-md-12">
                              <span style="color:red">{{errorMes}}</span>
                          </div>
                      </div>
              
                    <div class="text-right mt-10">
                      <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                      <button type="button" class="btn btn-secondary ml-2" matStepperPrevious>Previous</button>
                      <button type="button" class="btn btn-primary ml-2" (click)="saveOffer()" [disabled]="hasSalaryErrors">Offer</button>
                    </div>
                  </form>
                </mat-step>
              </mat-horizontal-stepper>
        </div>
    </div>
</div>

<ngx-spinner size="default" type="ball-spin-clockwise" name='send-offer-spinner'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>