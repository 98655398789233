import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse, } from '@angular/common/http';
import { Observable, of ,BehaviorSubject} from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map ,tap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { LoggedInUser } from '../_models/loggedInUser.model';
import { MenuItemModel } from '../../_base/layout/models/menu-item.model';

const API_USERS_URL = 'login/api/users';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'setting/api/roles';


@Injectable()
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private userMenuSubject: BehaviorSubject<any>;
  public userMenu: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.userMenuSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userMenu')));
    this.userMenu = this.userMenuSubject.asObservable();
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  // Authentication/Authorization
  login(username: string, password: string): Observable<any> {
    return this.http.post<User>(environment.baseUrl+'/corporate-recruitment/api/auth/login', {username, password}).pipe(
      map(user => {
        console.log('user-----',user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('currentUser', JSON.stringify(user));
        
        this.currentUserSubject.next(user);
        return user;
     })
    )
  }

  getUserMenu(projectId:any){
    return this.http.get<MenuItemModel>(environment.baseUrl+'/corporate-recruitment/api/menu/assignedWebMenus?projectId='+projectId,{}).pipe(
      map(menu => {
        this.userMenuSubject.next(menu);
        return menu; 
     })
    )
  }

  updateDefaultProject(projectId:any){
    return this.http.post<User>(environment.baseUrl+'/login/api/users/saveDefaultProject?projectId='+projectId,{});
  }

  logout() {
      // remove user from local storage and set current user to null
      localStorage.removeItem('currentUser');
      localStorage.removeItem('userMenu');
      localStorage.removeItem('userProject');
      localStorage.removeItem('selectedProject');

      localStorage.clear();
      sessionStorage.clear();

      this.currentUserSubject.next(null);
      this.userMenuSubject.next(null);
  }

  getUserByToken(): Observable<User> {
    const userToken = localStorage.getItem(environment.authTokenKey);
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
    return this.http.get<User>(API_USERS_URL, {headers: httpHeaders});
  }

  register(user: User): Observable<any> {
    console.log(user);
    return this.http.post<User>(environment.baseUrl+'/login/api/auth/signup', user)
      .pipe(
        map((res: User) => {
          console.log(res);
          return res;
        }),
        catchError(err => {
          return null;
        })
      );
  }

  /**
   * 
   * @param tokenKey 
   */
  validateToken(tokenKey: string): Observable<any> { 
    console.log(tokenKey);
    return this.http.post<User>(environment.baseUrl+'/login/api/auth/validateToken', {'token': tokenKey});
  }

  /**
   * 
   * @param tokenKey 
   */
  validateResetPassToken(tokenKey: string): Observable<any> { 
    console.log(tokenKey);
    return this.http.post<User>(environment.baseUrl+'/login/api/auth/password/validateToken', {'token': tokenKey});
  }

  /**
   * 
   * @param user 
   */
  createPassword(user:User): Observable<any> {
    console.log(user);
    return this.http.post<User>(environment.baseUrl+'/login/api/auth/createPassword', user)
      .pipe(
        map((res: User) => {
          console.log(res);
          return res;
        }),
        catchError(err => {
          return null;
        })
      );
  }

  /**
   * 
   * @param user 
   */
  resetPassword(user:User): Observable<any> {
    console.log(user);
    return this.http.post<User>(environment.baseUrl+'/login/api/auth/password/update', user)
      .pipe(
        map((res: User) => {
          console.log(res);
          return res;
        }),
        catchError(err => {
          return null;
        })
      );
  }

  /** */
  resendToken(email: string): Observable<any> {
    return this.http.post<any>(environment.baseUrl+'/login/api/auth/resendAuthEmail', {'email': email});
  }

  /*
   * Submit forgot password request
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Observable<any> {
    return this.http.post<any>(environment.baseUrl+'/login/api/auth/password/resetPassword', {'email': email});
  }


  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(API_USERS_URL);
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(API_USERS_URL + `/${userId}`);
  }


  // DELETE => delete the user from the server
  deleteUser(userId: number) {
    const url = `${API_USERS_URL}/${userId}`;
    return this.http.delete(url);
  }

  // UPDATE => PUT: update the user on the server
  // tslint:disable-next-line
  updateUser(_user: User): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(API_USERS_URL, _user, {headers: httpHeaders});
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: User): Observable<User> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<User>(API_USERS_URL, user, {headers: httpHeaders});
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, {headers: httpHeaders});
  }

  // Permission
  getAllPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(API_PERMISSION_URL);
  }

  getRolePermissions(roleId: number): Observable<Permission[]> {
    return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
  }

  // Roles
  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(API_ROLES_URL);
    
  }

  getRoleById(roleId: number): Observable<Role> {
    return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
  }

  // CREATE =>  POST: add a new role to the server
  createRole(role: Role): Observable<Role> {
    // Note: Add headers if needed (tokens/bearer)
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Role>(API_ROLES_URL, role, {headers: httpHeaders});
  }

  // UPDATE => PUT: update the role on the server
  updateRole(role: Role): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(API_ROLES_URL, role, {headers: httpHeaders});
  }

  // DELETE => delete the role from the server
  deleteRole(roleId: number): Observable<Role> {
    const url = `${API_ROLES_URL}/${roleId}`;
    return this.http.delete<Role>(url);
  }

  // Check Role Before deletion
  isRoleAssignedToUsers(roleId: number): Observable<boolean> {
    return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
  }

  findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, {headers: httpHeaders});
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
    *
  * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }


  //Get all city list and store in local stogare
  getCityList(projectId:any){
    console.log('get city list');
    return this.http.get(environment.baseUrl + '/candidate-services/api/v1/city/');
  }
}