import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatAll } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AttributeType } from '../../contact-setting/models/attribute-type.model';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { DateAttributes } from '../../contact-setting/models/date-attributes.model';
import { LooupValues } from '../../contact-setting/models/looup-values.model';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor(private http:HttpClient) { }

  getFields(moduleType, projectId: number){
    console.log('Test--2')
    return this.http.get<any[]>(
      environment.baseUrl+'/transactions/api/customType/getFields?projectId='+projectId+'&moduleType='+moduleType);
  }

  addFields(attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
    lookupValues: LooupValues[],dateAttributes:DateAttributes,moduleType:string | number,allowBrowsing:number,instructions:string,
    characterType:number,maxCharacters:string,maxDigits:number,maxImagesAllowed:number,minCharacters:string,minDigits:number,
    numericValidationType:number,fieldSpec:number,displayOrder:number,minNumber:number,maxNumber:number,hasUniqueValues:number,
    parentAttribId:number, projectId: number){

      return this.http.post<CustomAttibute>(environment.baseUrl+'/setting/api/customType/addCustomAttribute',{
        attributeName,"projectId": projectId,
        moduleType,mandatoryFlag,attributeType,lookupValues,dateAttributes,allowBrowsing,instructions,
        characterType,maxCharacters,maxDigits,maxImagesAllowed,minCharacters,minDigits,
        numericValidationType,fieldSpec,displayOrder,minNumber,maxNumber,"status":1,hasUniqueValues,parentAttribId});
   }

  add1Fields(attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
    lookupValues: LooupValues[],dateAttributes:DateAttributes,moduleType:string, projectId: number){
    return this.http.post<CustomAttibute>(environment.baseUrl+'/setting/api/customType/addCustomAttribute',{
      attributeName,"projectId": projectId,
      "moduleType":moduleType,mandatoryFlag,"status": 1,attributeType,lookupValues,dateAttributes});
 }

  editFields(id:number,attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
    lookupValues: LooupValues[],dateAttributes:DateAttributes,moduleType:string | number,allowBrowsing:number,instructions:string,
    characterType:number,maxCharacters:string,maxDigits:number,maxImagesAllowed:number,minCharacters:string,minDigits:number,
    numericValidationType:number,fieldSpec:number,displayOrder:number,minNumber:number,maxNumber:number,status:number|string,
    hasUniqueValues:number,parentAttribId:number, projectId: number){
    return this.http.post<CustomAttibute>(environment.baseUrl+'/setting/api/customType/editCustomAttribute',{
        id,attributeName,"projectId": projectId,
        "moduleType":moduleType,mandatoryFlag,attributeType,lookupValues,dateAttributes,allowBrowsing,instructions,
        characterType,maxCharacters,maxDigits,maxImagesAllowed,minCharacters,minDigits,
        numericValidationType,fieldSpec,displayOrder,minNumber,maxNumber,status,hasUniqueValues,parentAttribId});
   }

  saveSequenceOrder(sequenceData){
      return this.http.post<any[]>(environment.baseUrl+'/setting/api/customType/sequenceFields', sequenceData);
  }

}
