<div class="col-xl-12">
    <div class="col-xl-12">
        <br/>
        <h1 mat-dialog-title>{{data.title}}</h1>
        <hr>
        <div mat-dialog-content>
            <p>{{data.body}}</p>
        </div>
        <hr>
        <div mat-dialog-actions class="float-right">
            <button mat-button (click)="onDismiss()">No</button>
            <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
        </div>
        <br/>
    </div>
</div>