// Angular
import {Component, Input, OnInit} from '@angular/core';
import { CompanyDashboardService } from '../../../../services/company-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-widget1-sales-stat',
  templateUrl: './widget1-sales-stat.component.html',
  styleUrls: ['./widget1-sales-stat.component.scss']
})
export class Widget1SalesStatComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  companyStats: any;
  revenueEarned: number | string;
  order: number;
  estimate: number;
  onSite: number;
  offSite: number;
  win: number;
  nextVisit: string;
  futureActivityDueDate: string;
  lastOrder: string;

  constructor(
    private _cds: CompanyDashboardService,
    private SpinnerService: NgxSpinnerService
  ) {}


  ngOnInit(): void {
    this.SpinnerService.show('dashboard-stat');
    this.fetchComapnyStats(this.selectedProject, this.parentData);
  }

  fetchComapnyStats(projectId: number, companyId: number){
    this._cds.getCompanyStats(projectId, companyId).subscribe(
      res=>{
        this.companyStats = res['responseData'];
        this.revenueEarned = this.companyStats.revenueEarned;
        this.order = this.companyStats.activitiesCount.order;
        this.estimate = this.companyStats.activitiesCount.estimate;
        this.onSite = this.companyStats.activitiesCount.onSite;
        this.offSite = this.companyStats.activitiesCount.offSite;
        this.win = this.companyStats.performance.win;
        this.nextVisit = this.companyStats.activityDates.nextVisit;
        this.futureActivityDueDate = this.companyStats.activityDates.futureActivityDueDate;
        this.lastOrder = this.companyStats.activityDates.lastOrder;
        this.SpinnerService.hide('dashboard-stat');

      },
      err=>{
        console.log(err);
      }
    )
  }

}
