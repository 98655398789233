// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { CompanyPartialsModule } from './company-partials/company-partials.module';
import { CompanyDashboardComponent } from './company-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    CompanyPartialsModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: '',
        component: CompanyDashboardComponent
      },
    ]),
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbProgressbarModule
  ],
  providers: [],
  declarations: [
    CompanyDashboardComponent,
  ],
  entryComponents:[]
})
export class CompanyDashboardModule { }
