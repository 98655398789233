import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { RelationService } from '../../../company-configuration/_subs/services/relation.service';
import { CustomAttibute } from 'src/app/views/pages/contact-setting/models/custom-attibute.model';
import { CompanyService } from '../../../company-configuration/_subs/services/company.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteRelationDialogComponent } from '../_subs/delete-relation-dialog/delete-relation-dialog.component';
import { EditRelationDialogComponent } from '../_subs/edit-relation-dialog/edit-relation-dialog.component';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { EventEmitterService } from '../../services/event-emitter.service';
import { exit } from 'process';

@Component({
  selector: 'kt-relationship-dialog',
  templateUrl: './relationship-dialog.component.html',
  styleUrls: ['./relationship-dialog.component.scss']
})
export class RelationshipDialogComponent implements OnInit {
  errorMes:String="";
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  editCustomVal: CustomAttibute;
  
  relationshipForm:FormGroup;
  
  resultCustom=[];
  filterCustom=[];

  selectedLookupValues=[];

  parentLookupValues=[];

  preSearchparentLookupValues=[];

  selectedParent:string="";

  finalResult=[];

  disabled : BehaviorSubject<Boolean>=new BehaviorSubject<Boolean>(false);

  options= [];

  optionsM:Number;

  current_selected_dependent: string;

  current_selected_parent: string;

  ifSingle:boolean=false;

  isEdit:boolean=false;

  showDeleteButton:boolean=false;

  public parentFieldFilter: FormControl = new FormControl();

  constructor( private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private _rs:RelationService,
    public dialog: MatDialog,
    private companyService: CompanyService,
    private eventEmitterService: EventEmitterService,
    private _cs:CustomAttributesService) {
      
  }

  ngOnInit(): void {
    this.relationshipForm = this.fb.group({
      parentField: ['', Validators.compose([
          Validators.required,
          Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ])
      ],
      dependentField:[{value:this.editCustomVal.attributeName,disabled: true}, Validators.compose([
        Validators.required,
        Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ]),
      ],
      selectedOptions:[],
      selectedParent:[],
      searchDep:[],
      searchParent:[]

     
    });

    
    this.showSelectedLookupValues();

     //get relations
    this._rs.getFieldRelation(this.editCustomVal['moduleId'],this.editCustomVal.id, this.selectedProject).subscribe(
      res=>{
        console.log('res');
        //if we have data 
        if(res['responseData']){
          //determine if add or rdit module

          this.isEdit=true;
          this.showDeleteButton=true;

          this.resultCustom.push(res['responseData'].parentField);
          this.selectedParent=res['responseData'].parentField.attributeName;
          this.parentLookupValues= res['responseData'].parentField.lookupValues;
          this.preSearchparentLookupValues= res['responseData'].parentField.lookupValues;
          this.relationshipForm.controls['parentField'].setValue(res['responseData'].parentField);

          //mapping 
         // var mapping=res['responseData'].lookupValuesRelation[0];
         var mapping: any; 
         var selectedParentData = res['responseData'].lookupValuesRelation;
         for (let key in selectedParentData) {
           if(selectedParentData[key].childLookupValues.length > 0){
                mapping=selectedParentData[key];
                break;
              }
         }

         if(mapping == undefined){
            mapping = selectedParentData[0];
         }
          
          this.relationshipForm.controls['selectedParent'].setValue([mapping.parentLookupId]);
         
          mapping.childLookupValues.forEach(res=>{
            if(res.childAttributeId ==this.editCustomVal.id && res.childLookupValueId==null){
              this.optionsM=1;
            }else{
              this.options.push(res.childLookupValueId);
            }
          })
         
          if(this.ifSingle){
            this.relationshipForm.controls['selectedOptions'].setValue(this.options);       
          }else{
            
             this.relationshipForm.controls['selectedOptions'].setValue(this.optionsM); 
          }         
         
          //creating whole data
          res['responseData'].lookupValuesRelation.forEach(res=>{
          
            res['children']=res['childLookupValues'];
            delete(res['childLookupValues']);
            res['children'].forEach(res2=>{
              res2['isActive']=1;
            })
          })

          
          this.finalResult=res['responseData'].lookupValuesRelation;
          
          console.log(this.finalResult);
        }else{  
          this.isEdit=false;    
          this.showDeleteButton=false;
          this.getCustom();
        }
      }
    )


  }

  getCustom(){
    this._cs.getAllCustomAttributes(this.editCustomVal['moduleId'], this.selectedProject).subscribe(
      result=>{
        
        result['responseData'].forEach(row=>{
          if(row.attributeType.typeName=="Single Select Dropdown" && row.id !=this.editCustomVal.id 
             && row.status == 1)
          {
            this.filterCustom.push(row);
          }
        })

        const data = this.filterCustom;
        data.forEach(row=>{
          var result = this.filterParent(data, row.parentAttributeId);
          if(result == true)
          {
            this.resultCustom.push(row);
          }
        })

      },
      error => {
        console.log(error)
      }
    )  
  }

  filterParent(data, parent){
    var result = true;
    for(var i=0; i < data.length; i++){
        if(this.editCustomVal.hasChildAttribute == false){
          result = true;
          break;
        }
        if(parent == 0){
          result = true;
          break;
        }
        if(data[i].id == parent){
          result = false;
          break;
        }
        if(this.editCustomVal.id == parent){
          result = false;
          break;
        }
    }
    return result;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.relationshipForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  showSelectedLookupValues(){

    if(this.editCustomVal.attributeType.typeName=="Single Select Dropdown" || this.editCustomVal.attributeType.typeName=="Multi Select Dropdown"){
      this.ifSingle=true;
      this.selectedLookupValues= this.editCustomVal.lookupValues;
    }else{
      this.ifSingle=false;
    }
   
  }

  change(data){
    this.selectedParent=this.relationshipForm.controls.parentField.value.attributeName;
    this.parentLookupValues= data.lookupValues;
    
    this.preSearchparentLookupValues= data.lookupValues;

  }

  onSelectedParent(lookup){
    this.options=[];

    console.log(lookup);

    var removeIndex = this.finalResult.map(function(item) { return item.parentLookupId; }).indexOf(lookup);
    if(removeIndex > -1){
      //pre select values
      var lookups=this.finalResult[removeIndex].children;
    
      if(lookups.length>0){
  
        lookups.forEach(res=>{
          console.log(res.childLookupValueId);

          if(res.childAttributeId ==this.editCustomVal.id && res.childLookupValueId==null){
            this.optionsM=1;
          }else{
            this.options.push(res.childLookupValueId);
          }
        })
      
        if(this.ifSingle){
          this.relationshipForm.controls['selectedOptions'].setValue(this.options);       
        }else{
          
           this.relationshipForm.controls['selectedOptions'].setValue(this.optionsM); 
        } 
      
        
      }else{
        if(this.ifSingle){
          this.relationshipForm.controls['selectedOptions'].setValue([]);       
        }else{
          
          this.relationshipForm.controls['selectedOptions'].setValue(''); 
        } 
      }      

    }else{
      
      if(this.ifSingle){
        this.relationshipForm.controls['selectedOptions'].setValue([]);       
      }else{
        
        this.relationshipForm.controls['selectedOptions'].setValue(''); 
      }  

    }
   }


  onSelectedDependent(lookup){
      console.log(lookup);
      this.current_selected_dependent = lookup.option.value;
      this.current_selected_parent=this.relationshipForm.controls['selectedParent'].value[0];

      console.log(this.current_selected_dependent);
      console.log(this.current_selected_parent);

      var childData=[];
      var childObject= {
        "childAttributeId": this.editCustomVal.id,
        "childLookupValueId": this.current_selected_dependent,
        "isActive": 1
      };
      childData.push(
        {
          "childAttributeId": this.editCustomVal.id,
          "childLookupValueId": this.current_selected_dependent,
          "isActive": 1
        }
      )
            
      
      var data={
        "attributeId": this.relationshipForm.controls['parentField'].value.id,
        "parentLookupId": this.current_selected_parent,
        "children": childData
      };

      console.log(this.finalResult);
      //earch fpr existing data
      var removeIndex = this.finalResult.map(function(item) { return item.parentLookupId; }).indexOf(this.current_selected_parent);
        if(removeIndex > -1){
          //if exist
          console.log('index');
          this.finalResult[removeIndex];

          console.log(this.finalResult[removeIndex]);

          if(this.finalResult[removeIndex].children.length>0){
            console.log('lenght is gthn 0');

            var removeIndex2 = this.finalResult[removeIndex].children.map(function(item) { return item.childLookupValueId; }).indexOf(this.current_selected_dependent);
                  
            if(removeIndex2 >-1){
              //exist 
              if(!lookup.option._selected){
                if(this.isEdit){
                  console.log('here');
                  this.finalResult[removeIndex].children[removeIndex2].isActive=0;
                }else{
                  this.finalResult[removeIndex].children.splice(removeIndex2, 1);
                }
              }else{
                if(this.isEdit){
                  console.log('hereelse');
                  this.finalResult[removeIndex].children[removeIndex2].isActive=1;
                }else{
                  console.log('final');
                }
              }

            }else{
              if(lookup.option._selected){
                console.log('e');
                //if true
                this.finalResult[removeIndex].children.push(childObject);
              }
            }
         
          }else{
            if(lookup.option._selected){
              console.log('e');
              //if true
              this.finalResult[removeIndex].children.push(childObject);
            }
          }
          
          console.log('after index');
        }else{
          if(lookup.option._selected){
            console.log('f');
            //if true
            this.finalResult.push(data);
          }
        }

        if(this.isEdit){
          this.finalResult.forEach(res=>{
            res['attributeId']= this.relationshipForm.controls['parentField'].value.id
          })
        }
      console.log(this.finalResult);
    
  }

  onSelectedMandatory(lookup){ 

    console.log(lookup);
    this.current_selected_parent=this.relationshipForm.controls['selectedParent'].value[0];

    console.log(this.current_selected_parent);

    var childData=[];
    var childObject= {
      "childAttributeId": this.editCustomVal.id,
      "childLookupValueId": null,
      "isActive": 1
    };
    childData.push(
      {
        "childAttributeId": this.editCustomVal.id,
        "childLookupValueId": null,
        "isActive": 1
      }
    )
          
    
    var data={
      "attributeId": this.relationshipForm.controls['parentField'].value.id,
      "parentLookupId": this.current_selected_parent,
      "children": childData
    };

    console.log(this.finalResult);
    //earch fpr existing data
    var removeIndex = this.finalResult.map(function(item) { return item.parentLookupId; }).indexOf(this.current_selected_parent);
      if(removeIndex > -1){
        //if exist
        console.log('index');
        this.finalResult[removeIndex];

        console.log(this.finalResult[removeIndex]);

        if(this.finalResult[removeIndex].children.length>0){
          console.log('lenght is gthn 0');

          if(this.isEdit){
            var removeIndex2 = this.finalResult[removeIndex].children.map(function(item) { return item.childAttributeId; }).indexOf(this.editCustomVal.id);
                  
            if(removeIndex2 >-1){
              //exist 
              if(!lookup.source._checked){
                this.finalResult[removeIndex].children[removeIndex2].isActive=0;
              }else{
                this.finalResult[removeIndex].children[removeIndex2].isActive=1;
              }

            }else{
              if(lookup.source._checked){
                console.log('e');
                //if true
                this.finalResult[removeIndex].children.push(childObject);
              }
            }
          }else{
            var removeIndex2 = this.finalResult[removeIndex].children.map(function(item) { return item.childLookupValueId; }).indexOf(this.current_selected_dependent);
                  
            if(removeIndex2 >-1){
              //exist 
              if(!lookup.source._checked){
                if(this.isEdit){
                  console.log('here');
                  this.finalResult[removeIndex].children[removeIndex2].isActive=0;
                }else{
                  this.finalResult[removeIndex].children.splice(removeIndex2, 1);
                }
              }else{
                if(this.isEdit){
                  console.log('hereelse');
                  this.finalResult[removeIndex].children[removeIndex2].isActive=1;
                }else{
                  console.log('final');
                }
              }

            }else{
              if(lookup.source._checked){
                console.log('e');
                //if true
                this.finalResult[removeIndex].children.push(childObject);
              }
            }
          }
       
        }else{
          if(lookup.source._checked){
            console.log('e');
            //if true
            this.finalResult[removeIndex].children.push(childObject);
          }
        }
        
        console.log('after index');
      }else{
        if(lookup.source._checked){
          console.log('f');
          //if true
          this.finalResult.push(data);
        }
      }

      if(this.isEdit){
        this.finalResult.forEach(res=>{
          res['attributeId']= this.relationshipForm.controls['parentField'].value.id
        })
      }
    console.log(this.finalResult);
  
}

  Close(){
    this.activeModal.close();
    this.relationshipForm.reset();
  }

  Save(){   
    if(this.isEdit){
      //modal
      const ref = this.dialog.open(EditRelationDialogComponent, 
        { 
          width: '600px',
          data: {}
        }
      );

      ref.afterClosed().subscribe(result => {
        if(result){
          this.saveData();
        }
      });

    }else{
      //no modal
      this.saveData();

    }
    
  }

  saveData(){
    const controls = this.relationshipForm.controls;
    if (this.relationshipForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    console.log('final array');
    console.log(this.finalResult);
    this._rs.addRelation(this.finalResult).subscribe(
      res=>{
        console.log(res);
        this.activeModal.close('RELATIONSHIP');
        this.eventEmitterService.onModalCloseResult('RELATIONSHIP');
      }
    )

  }

  searchThisForParent(){
    console.log('search');
    var key= this.relationshipForm.controls['searchParent'].value;
    if(key){
      console.log('a');
      this.parentLookupValues= this.parentLookupValues.filter(res => res['lookupValue'].toLowerCase().indexOf(key) > -1)
       
    }else{
      console.log('b');
      console.log(this.preSearchparentLookupValues);
      this.parentLookupValues=this.preSearchparentLookupValues;
    }
  }

  searchThisForDep(){
    console.log('searchD');
    var key= this.relationshipForm.controls['searchDep'].value;
    if(key){
      console.log('a');
      this.selectedLookupValues= this.selectedLookupValues.filter(res => res['lookupValue'].toLowerCase().indexOf(key) > -1)
      
    }else{
      this.showSelectedLookupValues();
    }
  }

  delete(){

    const ref = this.dialog.open(DeleteRelationDialogComponent, {
      width: '600px',
      position: {top: '20px'},
      data: {}
    });

    ref.afterClosed().subscribe(result => {
      if(result){
        var data=[{
          "attributeId": this.editCustomVal.id         
          
        }];   

        console.log(data);
        this._rs.deleteRelation(data).subscribe(
          res=>{
            console.log(res);
      
            this.activeModal.close('RELATIONSHIP');
            this.eventEmitterService.onModalCloseResult('RELATIONSHIP');
          }
        )


      }
    });

  } 
  

}
