<div class="example-preview">
    <div>
        <form [formGroup]="addCustomAttributeForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Add Custom Field</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Name</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input  maxlength="50" formControlName="attributeName">
                            <mat-hint>Maximum 50 Characters</mat-hint>
                            <mat-hint align="end">{{input.value?.length || 0}}/50</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeName', 'required')">Field Name cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('attributeName', 'maxlength')"><strong>Maximum 50 characters</strong></mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label">Field Type</label>
                   </div>
                   <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="attributeType" (selectionChange)="change($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="attributeFilterType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let attributeType of attributeTypelist| async" [value]="attributeType">
                                    {{attributeType.typeName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Select from the list</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeType', 'required')">Field Type is required</mat-error>
                        </mat-form-field>
                   </div>  
                </div>  
                
                <div *ngIf="displaySingleSelectDiv">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Values</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <mat-chip-list #chipList aria-label="Enter Value" required
                            formControlName="attributeSingleName">
                                <mat-chip *ngFor="let lookup of lookupVal" [selectable]="selectable"
                                         [removable]="removable" (removed)="remove(lookup)">
                                        {{lookup.lookupValue}}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="New value..."
                                       formControlName="attributeSingleName"
                                       [matChipInputFor]="chipList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="addOnBlur"
                                       (matChipInputTokenEnd)="add($event)">
                              </mat-chip-list>
                              <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon> 
                              <mat-hint>Type comma seperated text to add values, duplicate values not allowed</mat-hint>
                              <mat-error *ngIf="isControlHasError('attributeSingleName', 'required')">Value is required</mat-error>

                            <!--<mat-label>Enter Value</mat-label>
                            <input  matInput placeholder="Enter Info" formControlName="attributeSingleName">
                            <mat-hint>Type value and click enter to add new, duplicate values not allowed</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeSingleName', 'required')">Value is required</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}"></mat-icon>-->
                        </mat-form-field>                    
                    </div>
                </div>
                </div>
                
                <div *ngIf="displayMultiSelectDiv">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Values</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <mat-chip-list #chipList aria-label="Enter Value"  required
                            formControlName="attributeMultiSelectName">
                                <mat-chip *ngFor="let lookup of lookupVal" [selectable]="selectable"
                                         [removable]="removable" (removed)="remove(lookup)">
                                        {{lookup.lookupValue}}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="New value..."
                                       formControlName="attributeMultiSelectName"
                                       [matChipInputFor]="chipList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="addOnBlur"
                                       (matChipInputTokenEnd)="add($event)">
                              </mat-chip-list>
                              <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon> 
                              <mat-hint>Type comma seperated text to add values, duplicate values not allowed</mat-hint>
                              <mat-error *ngIf="isControlHasError('attributeMultiSelectName', 'required')">Value is required</mat-error>
                           
                            <!--<input matInput placeholder="Enter Value" formControlName="attributeMultiSelectName">
                            <mat-hint>Type value and click enter to add new, duplicate values not allowed</mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeMultiSelectName', 'required')">Value is required</mat-error>
                            <mat-icon matSuffix [ngStyle]="{'color':'blue'}"></mat-icon>-->
                        </mat-form-field>                    
                    </div>
                </div>
                </div>
                <div *ngIf="displayDateValueDiv">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Value Type</label>
                    </div>

                    <div class="col-md-8">
                        <mat-radio-group formControlName="dateValueType" fxLayout="column" fxLayoutGap=".25rem">
                        <mat-radio-button [value]="'Standard'">Standard</mat-radio-button>
                        <mat-radio-button [value]="'Custom'">Custom</mat-radio-button>
                         <!--   <mat-hint>Select the value type for date field</mat-hint>  -->
                         <mat-error class="form-error" *ngIf="isControlHasError('dateValueType', 'required')">Select One</mat-error>
                            </mat-radio-group>
                     </div>    
                </div>
                </div>
            <div *ngIf="isDisplayStandard()">
               <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-15">Value</label>
                   </div>
                   <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-label>Select one</mat-label>
                            <mat-select formControlName="standardTypeValue" (selectionChange)="changeStd($event.value)">
                                <mat-option *ngFor="let standardTypeValue of stdLookupList; let i = index;" [value]="standardTypeValue">
                                    {{standardTypeValue.value}}
                                </mat-option> 
                             </mat-select>
                            <mat-hint>Select standard date field from the list</mat-hint>
                        </mat-form-field>
                   </div>  
                </div>   -->

                 <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label">Value</label>
                   </div>
                   <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="standardTypeValue" (selectionChange)="changeStd($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="standardFilterType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let standardTypeValue of standardTypelist| async" [value]="standardTypeValue.id">
                                    {{standardTypeValue.value}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Select standard date field from the list</mat-hint>
                         </mat-form-field>
                   </div>  
                </div>
            </div>
            <div *ngIf="isDisplayCustom()">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Previous Dates Allowed</label>
                    </div>

                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input  matInput formControlName="previousDateValue"   ktStrictNumberOnlyDirective >
                            <mat-hint>Number of days, blank defaults to no limit (Optional)</mat-hint>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Future Dates Allowed</label>
                    </div>

                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                             <input  matInput formControlName="futureDateValue" ktStrictNumberOnlyDirective>
                            <mat-hint>Number of days, blank defaults to no limit (Optional)</mat-hint>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>
            </div>
                
                <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-15">Applicable Contact Type</label>
                   </div>
                   <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-label>Select one or more</mat-label>
                            <mat-select formControlName="attributeContactType" multiple (selectionChange)="changeMulti($event.value)">
                                <mat-option *ngFor="let allContactType of allContactTypeList" [value]="allContactType.id">
                                    {{allContactType.contactTypeName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Select applicable contact types, blank defaults to All (Optional)</mat-hint>
                        </mat-form-field>
                   </div> 
                </div> -->

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-15">Applicable Contact Types</label>
                   </div>
                   <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="attributeContactType" multiple (selectionChange)="changeMulti($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="contactFilterType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let allContactType of allContactTypList| async" [value]="allContactType.id">
                                    {{allContactType.contactTypeName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Select applicable contact types, blank defaults to All (Optional)</mat-hint>
                         </mat-form-field>
                   </div>  
                </div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Mandatory</label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox #checkbox (change)='showOptions(checkbox.checked)' formControlName="mandatoryCheck">
                            Select if this is a mandatory field
                        </mat-checkbox>
                    </div>
                </div> 
                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"  (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
            </div>
        </form> 
</div>
</div>  