<div class="example-preview">
    <div>
        <form [formGroup]="addNewUserForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Candidate Details</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Name</label>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput placeholder="First Name" formControlName="firstName" ktAutoTrimSpaces>
                            <mat-label style="color: rgba(0,0,0,.54);">First Name</mat-label>
                            <mat-error *ngIf="isControlHasError('firstName', 'required')">First Name cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>                    
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput placeholder="Last Name" formControlName="lastName" ktAutoTrimSpaces>
                            <mat-label style="color: rgba(0,0,0,.54);">Last Name</mat-label>
                            <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint> 
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Mobile Number</label>
                    </div>
                   
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-2">
                                <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                                    <mat-select formControlName="mobilePrefix" >
                                        <mat-option value="+91">+91</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="isControlHasError('mobilePrefix', 'required')">Code cannot be blank</mat-error> 
                                 </mat-form-field>
                           </div>
                           <div class="col-md-10">
                            <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                <!-- <input matInput  formControlName="mobileNumber" ktStrictNumberOnlyDirective maxlength="10" minlength="10"> -->
                                <input matInput  formControlName="mobileNumber" (input)="onlyNumber($event.target.value)" maxlength="10" minlength="10">
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                <mat-error *ngIf="isControlHasError('mobileNumber', 'required')">Mobile Number cannot be blank</mat-error> 
                            </mat-form-field> 
                            </div>
                        </div>
                                          
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Current City</label>
                    </div>
                    <div class="col-md-8">
                                <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                                    <mat-select formControlName="cityName">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityId">
                                            {{city.cityName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>Optional</mat-hint>
                                </mat-form-field>
                    </div>
                   
                    <!-- <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field class="mat-form-field-fluid input-100">
                                    <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryId">
                                            {{country.countryName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>Optional</mat-hint>
                                </mat-form-field>
                            </div>
                             <div class="col-md-4">
                                <mat-form-field class="mat-form-field-fluid input-100">
                                    <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateId">
                                            {{state.stateName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>Optional</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="mat-form-field-fluid input-100">
                                    <mat-select formControlName="cityName">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityId">
                                            {{city.cityName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>Optional</mat-hint>
                                </mat-form-field>
                            </div> 
                        </div>
                                          
                    </div> -->
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Expected Salary</label>
                    </div>
                   
                    <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                <input matInput  formControlName="expectedSalary" (input)="onlyNumberExpectedSal($event.target.value)">
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint> 
                            </mat-form-field>     
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Resume</label>
                    </div>
                   
                    <div class="col-md-8">
                            <mat-form-field appearance="standard" class="resume-section input-100 form-padding-top-5" > 
                                <input matInput formControlName="resume">
                                <input type="file" formControlName="resumeFile"  (change)="onChange($event)" style="opacity: 0; filter:alpha(opacity=0);  position: relative; top: -20px; width:100%"/>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">folder</mat-icon>
                                <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint> 
                            </mat-form-field> 
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Job Type</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                            <mat-select formControlName="jobType" multiple>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="jobTypeFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let job of Jobtype| search : jobTypeFilter.value :'jobTypeName'" [value]="job.id">
                                    {{job.jobTypeName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint>
                         </mat-form-field>
                    </div>  
                </div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Industry Preference</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                            <mat-select formControlName="indistryPreference" multiple>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="indistryPreferenceFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let indistry of Indistrytype| search : indistryPreferenceFilter.value :'industryName'" [value]="indistry.id">
                                    {{indistry.industryName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint>
                         </mat-form-field>
                    </div>  
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Email</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                            <input matInput formControlName="email">
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">email</mat-icon>
                            <!-- <mat-error *ngIf="isControlHasError('email', 'required')">Email address cannot be blank</mat-error>     -->
                           <!-- <mat-error *ngIf="isControlHasError('email', 'email')">Email address format1 is incorrect</mat-error>  -->
                            <mat-error *ngIf="isControlHasError('email', 'pattern')">Email address format is incorrect</mat-error> 
                            <mat-hint style="color: rgba(0,0,0,.54);">Optional</mat-hint>
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>

            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="Save()">Save</button>
            </div>
        </form> 
    </div>
</div>  
