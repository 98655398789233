import { AfterViewInit, Component, ElementRef, OnInit, ViewChild ,ViewEncapsulation} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { CompanyService } from '../../services/company.service';
import { Company } from '../../models/company.model';

@Component({
  selector: 'kt-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCompanyComponent implements OnInit {
  errorMes:String="";
  public addCompanyForm: FormGroup;
  private unsubscribe: Subject<any>;
  loading = false;
  disabled:boolean;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    private add: CompanyService ,
    public activeModal: NgbActiveModal) {
      this.unsubscribe = new Subject();
     }

  ngOnInit(): void {
    this.initaddCompanyForm();
    this.disabled=false;
  }

  initaddCompanyForm(){
    this.addCompanyForm = this.fb.group({
      companyTypeName: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ])
      ]
     });
  }

  Save(){
    this.disabled=true;
    this.errorMes="";
    const controls = this.addCompanyForm.controls;
      if (this.addCompanyForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled=false;
        return;
      }
      const addRedcordData = {
        companyTypeName: controls.companyTypeName.value
      };

    var isActive=1;
    //console.log('comapyyy----'+addRedcordData.companyTypeName);
      this.add
        .addCompanyType(addRedcordData.companyTypeName,isActive, this.selectedProject)
        .pipe(
          tap(company => {
            console.log(company);
            this.loading=false;
            this.activeModal.close();
            this.addCompanyForm.reset();
          }),
          takeUntil(this.unsubscribe),
          finalize(() => {
           
          })
        ).subscribe(
          data => console.log("sucesssfulll called API"),
          error => {
            this.errorMes=error.error.responseData;
            this.disabled=false;
          }
        );
  }

  Close(){
    this.activeModal.close();
    this.addCompanyForm.reset();
  }
  
    isControlHasError(controlName: string, validationType: string): boolean {
      const control = this.addCompanyForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }

  isDisabled() : boolean {
      return this.disabled;
  } 
}
