import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '../../services/address.service';
import { countriesModel } from '../../../company-management/models/countries.model';
import { stateModel } from '../../../company-management/models/state.model';
import { cityModel } from '../../../company-management/models/city.model';
import { UploadImageService } from 'src/app/views/pages/shared/services/upload-image.service';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { CompaniesService } from '../../../company-management/services/companies.service';
import { ContactsService } from '../../../contact-management/services/contacts.service';
import { EventEmitterService } from '../../services/event-emitter.service';
import { companiesModel } from '../../../company-management/models/companies.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { OpportunitiesService } from '../../../opportunities/services/opportunities.service';
import { ProductService  } from '../../../product-management/services/product.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TestBed } from '@angular/core/testing';
import { OpportunityDashboardService } from '../../../opportunity-dashboard/services/opportunity-dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageActivitiesService } from '../../../manage-activities/services/manage-activities.service';
import { last } from 'lodash';

@Component({
  selector: 'kt-add-new-company',
  templateUrl: './add-new-company.component.html',
  styleUrls: ['./add-new-company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNewCompanyComponent implements OnInit {
  isStatusFieldShow:Boolean=false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
 
  imageNameIndexMap = new Map();
  imagesnameArray=[];
  skuItemsArray=[];
  skuList:any;
  images=[];
  //ineherited from modal
  //ineherited from modal
  flag:String;
  addProductError:Boolean=false;
  systemAttributeEstimateArray=[];
  systemAttributecompaniesArray=[];
  systemAttributecontactsArray=[];
  systemAttributeopportunityArray=[];
  systemAttributeproductsArray=[];
  systemAttributeopportunityListArray=[];
  systemAttributecompanyListArray=[];
  systemAttributecontactsListArray=[];
  systemAttributeStatusArray=[];
  moduleType:Number;
  moduleName:String;
  imgURL:any;
  openDivs=[];
  customAttributeValues=[];
  isSubmitted:boolean=true;
  errorMes:string="";
  disabled:Boolean=false;
  maxDate: Date|String;
  minDate: Date|String;
  customAttributesList=[];
  parentPropertyId:Number;
  countriesList:countriesModel[];
  stateList:stateModel[];
  cityList:cityModel[];
  image:any;
  addCompaniesForm :FormGroup;
  companyId:number|string;
  contactId:number;
  opportunityId:number;
  parentData: any;
  activityFlag: boolean;
  moduleObj: any;  /** Object for Customer, Opportunity and Contact */
  systemAttributeOwnerArray=[];
  singularName:string;
  myFormValueChanges$;
  myFormValue$;
  totalSum: number = 0;
  systemAttributeOwnerContactArray=[];
  systemAttributeOwnerCompanyArray=[];
  viewMode = '';
  preview = false;
  selectedArray=[];
  companyInfo: any;
  showUploadImageIcon: boolean = true;
  imageUploadedCounter:number =0;
  customAttributesTestValues:[];
  errorFlag:boolean;
  imageNoUploaded: boolean = false;
  activityName:string;
  activitiesList:any;
  estimateModuleId:string|number;
  DisplayDateFlag:boolean;
  DisplayDateMinFlag:boolean;
  DisplayDateMaxFlag:boolean;

  public singleDropdownFilter: FormControl = new FormControl();

  public multiDropdownFilter: FormControl = new FormControl();

  public countryNameFilter: FormControl = new FormControl();

  public stateNameFilter: FormControl = new FormControl();

  public cityNameFilter: FormControl = new FormControl();

  constructor(
    private _cs:CompaniesService,
    public activeModal: NgbActiveModal,
    private _as:AddressService,
    private _us:UploadImageService,
    private _customAttrService:CustomAttributesService,
    private _contact_service:ContactsService,
   
    private eventEmitterService: EventEmitterService,
    private _ps:ProductService,
    private _os:OpportunitiesService,
    private _route:ActivatedRoute,
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private _ods: OpportunityDashboardService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public _mas: ManageActivitiesService) { }

  ngOnInit(): void {
    if(this.moduleType > 100){
      this.getAllActivitiesList(0);
    }

    this.imageNameIndexMap.set("imageFileArray",[]);
    this.DisplayDateFlag= false;
    this.DisplayDateMinFlag = false;
    this.DisplayDateMaxFlag = false;

    if(this.companyId)
      this.parentData = this.companyId;
    else if(this.contactId)
      this.parentData = this.contactId;
    else
      this.parentData = this.opportunityId;

      if(this.opportunityId !=null){
        this.fetchOpportunityCompany(this.selectedProject,this.opportunityId);
      }

    this.SpinnerService.show("addCompany");
    this.errorFlag=false;

    this.getModuleName();
    //form builder 
    this.initaddCompanyForm();

    this.getAllCountries();

    if(this.activityFlag == true){
      if(this.flag == 'company')
        this.getSelectedModuleDetails(this.companyId, 2);
      if(this.flag == 'contact')
        this.getSelectedModuleDetails(this.contactId, 1);
      if(this.flag == 'opportunity')
        this.getSelectedModuleDetails(this.opportunityId, 3);
    }


    this.getFields();

  }

  getSelectedModuleDetails(mId:number|string, mType:number){
    this._customAttrService.getParticularCompany(mId,mType, this.selectedProject).subscribe(
      data=>{    
        this.moduleObj=data['responseData'][0];
      }
    )
  }

  getAllActivitiesList(editFlag:number){
    this._cs.getAllActivities(this.selectedProject).subscribe(
      data=>{
       this.activitiesList =  data['responseData'];
       for(let act of this.activitiesList){
        if(act.activityId == this.moduleType){
          this.singularName= act.activityName;        
        }
        if(this.viewMode=='AppPreview'){
          if(act.activityName =='Estimate'){
            this.estimateModuleId=act.activityId;           

          }
        }
      }
      },
      err=>{
       
      }
    )
  }

  fetchOpportunityCompany(projectId: number, opportunityId: number){
    this._ods.getOpportunityCompanies(projectId, opportunityId).subscribe(
      res=>{
        this.companyInfo = res['responseData'];
        this.companyId = this.companyInfo.companyId;
      },
      err=>{
        console.log(err);
      }
    )
  }
  
  getModuleName(){
    switch (this.moduleType){
      case 1 :{
        this.moduleName="Contact";
        break;
      }
      case 2 :{
        this.moduleName="Company";
        break;
      }
      case 3 :{
        this.moduleName="Opportunity";
        break;
      }
      case 4 :{
        this.moduleName="Product";
        break;
      }
      default:{
        this.moduleName="Activity";
        break;
      }
    }
  }
  initaddCompanyForm(){

   // if(this.moduleType==104 || this.moduleType==105){
     // this.addCompaniesForm = this.fb.group({  
      //  skuItems: this.fb.array([ this.getSKU()])
      //});
    //}else{
      this.addCompaniesForm = this.fb.group({  
       
      });
    //}
 
  }

  getSKU() {
    
    return this.fb.group({
     sku: ['', Validators.compose([ Validators.required])],
     quantity: ['', Validators.compose([ Validators.required])],
     listPrice: [''],
     sellingPrice: ['', Validators.compose([ Validators.required])],
     discount: [''],
     tax: ['', Validators.compose([ Validators.required])],
     total: ['']
    });
  }

  getControls() {
    return (this.addCompaniesForm.get('skuItems') as FormArray).controls;
  }

  private updateItems(skuItems: any) {
    
    const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
    
    this.totalSum = 0;
    for (let i in skuItems) {
      if(skuItems[i].sku!==''){
        for(let sku of this.skuList){
          if(sku.productId == skuItems[i].sku){
            control.at(+i).get('listPrice').setValue(sku.price, {onlySelf: true, emitEvent: false});
          }
       }
      }

      if((skuItems[i].listPrice!=='' && skuItems[i].sellingPrice!=='') &&
          (skuItems[i].listPrice!==null && skuItems[i].sellingPrice!==null)){

          var discount=Number(skuItems[i].listPrice-skuItems[i].sellingPrice);
          discount=((discount/skuItems[i].listPrice)*100);
          discount=Number(discount.toFixed(2));
          control.at(+i).get('discount').setValue(discount, {onlySelf: true, emitEvent: false});

      }else{
        control.at(+i).get('discount').setValue('', {onlySelf: true, emitEvent: false});
      }

    
    if((skuItems[i].quantity!=='' && skuItems[i].sellingPrice!=='' && skuItems[i].tax!=='')
      && (skuItems[i].quantity!== null && skuItems[i].sellingPrice!==null && skuItems[i].tax!==null)){

      let total = skuItems[i].quantity*(Number(skuItems[i].sellingPrice)+Number((skuItems[i].sellingPrice * (skuItems[i].tax/100))));
      total=Number(total.toFixed(2));
      control.at(+i).get('total').setValue(total, {onlySelf: true, emitEvent: false});
       this.totalSum += total;

      }else{
        let total=0;
        control.at(+i).get('total').setValue('', {onlySelf: true, emitEvent: false});
       this.totalSum += total;
      }

    }
  }

  getAllCompanies(){
    this._cs.getCompany(this.selectedProject).subscribe(
      data=>{
        
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.companyName;
            res.id=res.companyId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
     
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }
     
        if(this.flag=="company"){
          if(this.systemAttributecompaniesArray[0]. attributeType. typeName == "Multi Select Dropdown")
            this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue([Number(this.companyId)]);
          else
            this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
         // this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable(); 
        }
        else if(this.flag=="opportunity"){
          if(this.companyId !=null){
            this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
            this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable();
          }
        }
        else if(this.flag=="contact"){
          if(this.companyId !=null){
            this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
           // this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable();
          }
        }
        else{
          if(this.companyId !=null){
            this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(Number(this.companyId));
          }
        }
      }
    ) 
  }

  getAllContacts(){
    this._contact_service.getAllContactDetails(this.selectedProject).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.contactName;
            res.id=res.contactId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecontactsArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

        if(this.contactId  && this.flag=="contact"){
          this.addCompaniesForm.controls[this.systemAttributecontactsArray[0].combinedAttributeName].setValue([Number(this.contactId)]);
        }

      }
    ) 
  }

  getAllOwner(){
    this._customAttrService.getOwner(this.selectedProject,Number(this.moduleType)).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.fullName;
            res.id=res.id;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeOwnerArray[0].id);
        var user = JSON.parse(localStorage.getItem('currentUser'));
        
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.addCompaniesForm.controls[this.systemAttributeOwnerArray[0].combinedAttributeName].setValue(Number(user.userId));
  
        }

      }
    ) 
  }


  getAllOpportunities(){
   if(this.companyId!=null && this.companyId!='undefined'){
   // this._os.getAllOpportunityDetails(this.flag=="company"? this.opportunityId : null).subscribe(
    this._os.getAllOpportunityDetails2(this.companyId).subscribe(
      data=>{
       
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.opportunityName;
            res.id=res.opportunityId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeopportunityArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.customAttributesList[removeIndex].hasChildAttribute=true;
  
        }

        if(this.opportunityId && this.flag=="opportunity"){
          this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].setValue(Number(this.opportunityId));
          this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].disable(); 

        }

      }
    ) 
   }
  }

  getAllOpportunitiesDependentInputs(id){
    //api to prefill opportunity inputs
    this._os.getOpportunity(id,this.selectedProject).subscribe(res=>{
      
      var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
   
      res['responseData'][0].customAttributeValues.forEach(res1=>{
        
        if(removeIndex >-1 && res1.attributeName=="Company Name" && this.opportunityId){
          
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(res1.value[0].id);   
          this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable(); 
        }
        this.systemAttributeopportunityListArray.forEach(res=>{
          
          
          if(res1.value.length >0){
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
              if(res.systemAttribute.id==26){
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
              }else{                
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id);  
              }          
            }
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
             
              if(res.systemAttribute.id==26){
                
                if(this.opportunityId && this.flag=="opportunity"){

                } else{
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
                }
                
              } else{                
                if(res.attributeType.id==2){
                  var arraynew=[];
                  res1.value.forEach(d=>{
                    arraynew.push(d.id);
                  })
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                
                } else{
                  this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);                  
                }  
              }                          
            }
          }         
        })
      })
    })
  }

  getAllCompanyDependentInputs(id){
    //api to prefill opportunity inputs
    this._cs.getCompanyDetails(id,this.selectedProject).subscribe(res=>{

      res['responseData'][0].customAttributeValues.forEach(res1=>{
      
        this.systemAttributecompanyListArray.forEach(res=>{
    
          if(res1.value.length >0){
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
              if(res.attributeType.id==2){
                var arraynew=[];
                res1.value.forEach(d=>{
                  arraynew.push(d.id);
                })
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
              
              }else{
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id); 
              
              }
                     
              
            }
            if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
              if(res.attributeType.id==2){
                var arraynew=[];
                res1.value.forEach(d=>{
                  arraynew.push(d.id);
                })
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
              
              }else{
                this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);  
              
              }
          
            }
          }                  
        })
      })
    })
  }

  getAllProducts(){
    this._ps.getProductLookup(this.selectedProject).subscribe(
      data=>{
        this.skuList= data['responseData'];     
      }
    ) 
  }

  getAllCountries(){
    this._as.getAllCountries(this.selectedProject).subscribe(
		(result)=>{ 
       this.countriesList=result['responseData']; 
		},
		error => {
			console.log(error)
    })
  }

  changeCountry(countryId){
    //get states
    var id=countryId.split('@',2);
    this.getAllStates(id[1]);
  }

  getAllStates(countryId){
    this._as.getStatesForCountry(countryId).subscribe(
      (result)=>{  
        this.stateList=result['responseData']; 
      },
      error => {
        console.log(error)
      }
    )
  }

  changeState(stateId){
    //get cities
    var id=stateId.split('@',2);
    this.getAllCities(id[1]);
  }

  getAllCities(stateId){
    this._as.getCitiesForState(stateId).subscribe(
      (result)=>{
        this.cityList=result['responseData']; 
      },
      error => {
        console.log(error)
      }
    )
  }

  getAllEstimates(){
    this._customAttrService.getActivityLookup(this.estimateModuleId,this.selectedProject,this.companyId,this.opportunityId).subscribe(
      data=>{
       
        data['responseData'].forEach(res=>{          
          res.lookupValue=res.activityName;
          res.id=res.activityId;          
        })

        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeEstimateArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.customAttributesList[removeIndex].hasChildAttribute=true;
  
        }
      }
    ) 
  }
  getFields(){
    this._customAttrService.getFields(this.moduleType, this.selectedProject).subscribe(
      res=>{
        res['responseData'] = res['responseData'].filter(ele => {
          if(ele.status == 1){

            if(ele.attributeType.id==4 ){

              if( ele.allowBrowsing==1 ){
                return 1;
                
              }else{
                return 0;
              }
              
            }else{
              return 1;
            }
          }
          
        })
        //this.SpinnerService.hide("addCompany");

        res['responseData'].forEach(element=>{
          if(element.systemAttribute==null)
          {
            element.systemAttribute={};
          }

          if(element.attributeType.id==1 && element.systemAttribute['id']!==16 && element.systemAttribute['id']!==15 && element.systemAttribute['id']!==21){
            
            if(element.mandatoryFlag==1){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            if(element.linkedAttributeId != null ){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                  }
                }
              })
            }

          }else{
            if(element.systemAttribute['id']==16 || element.systemAttribute['id']==15 || element.systemAttribute['id']==21){           

              if(element.mandatoryFlag==1){
                
                this.addCompaniesForm.addControl('countryName',  new FormControl('',Validators.compose([ Validators.required])));
                this.addCompaniesForm.addControl('stateName',  new FormControl('',Validators.compose([ Validators.required])));
                this.addCompaniesForm.addControl('cityName',  new FormControl('',Validators.compose([ Validators.required])));
  
              }else{

                this.addCompaniesForm.addControl('countryName',  new FormControl());
                this.addCompaniesForm.addControl('stateName',  new FormControl());
                this.addCompaniesForm.addControl('cityName',  new FormControl());
    
              }

            }
          }

          if(element.attributeType.id==2 && element.systemAttribute.id !==28){
            if(element.mandatoryFlag==1){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            if(element.linkedAttributeId != null ){
              var multiSelectArray=[];
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    if(ele.value.length>0){
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }
                    ele.value.forEach(res=>{
                      multiSelectArray.push(res.id);
                    })
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(multiSelectArray);
                  }
                }
              })
            }

          }

          if(element.attributeType.id==3){
            this.maxDate= new Date();
            this.minDate= new Date();
           
            if(element.dateAttributes){
            
              if(element.dateAttributes.stdLookupAttribute != null){
                this.DisplayDateMinFlag=false;
                this.DisplayDateMaxFlag=false;
                if(element.dateAttributes.stdLookupAttribute.id == 2){
                  this.DisplayDateFlag=true;
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 3){
                  this.DisplayDateFlag=true;
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 1);
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - 1);
              
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 5){
                  this.DisplayDateFlag=true;
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - this.minDate.getDay());
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - this.maxDate.getDay()+6);
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 6){
                  this.DisplayDateFlag=true;
                  var date = new Date();
                  var date = new Date();
                  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
              
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 7){
                  this.DisplayDateFlag=true;
                  var date = new Date();
                  var date = new Date();
                  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                  element.dateAttributes.maxDate= new Date(date.getFullYear(), date.getMonth() + 1, 0);
              
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 8){
                  this.DisplayDateFlag=true;
                  var now = new Date();
                  var start = new Date(now.getFullYear(), 0, 0);
                  var diff = Number(now) - Number(start);
                  var oneDay = 1000 * 60 * 60 * 24;
                  var day = Math.floor(diff / oneDay);
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
               
                }
                else if(element.dateAttributes.stdLookupAttribute.id == 9){
                  this.DisplayDateFlag=true;
                  var now = new Date();
                  var start = new Date(now.getFullYear(), 0, 0);
                  var end = new Date(now.getFullYear(), 11, 31);
                  var diffStart = Number(now) - Number(start);
                  var diffend = Number(end) - Number(now) ;
                  var oneDay = 1000 * 60 * 60 * 24;
                  var day = Math.floor(diffStart / oneDay);
                  var dayEnd = Math.floor(diffend / oneDay);
                  element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                  element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + (dayEnd + 1));
                 }
              } else{
                  if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed == null){
                
                    this.DisplayDateFlag=false;
                    this.DisplayDateMinFlag=false;
                    this.DisplayDateMaxFlag=false;
                  }
                  else if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed != null){
                  
                    this.DisplayDateMaxFlag=true;
                    this.DisplayDateMinFlag=false;
                    this.DisplayDateFlag=false;
                    if(element.dateAttributes.futureDateAllowed){
                      element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                    }
                  }
                  else if(element.dateAttributes.previousDateAllowed != null && element.dateAttributes.futureDateAllowed == null){
                
                    this.DisplayDateMinFlag=true;
                    this.DisplayDateFlag=false;
                    this.DisplayDateMaxFlag=false;
                    if(element.dateAttributes.previousDateAllowed){
                      element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);    
                    }
                  }else{
                   
                    this.DisplayDateFlag=true;
                    this.DisplayDateMinFlag=false;
                    this.DisplayDateMaxFlag=false;
                    if(element.dateAttributes.previousDateAllowed){
                     element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
                    }
        
                    if(element.dateAttributes.futureDateAllowed){
                      element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                    }
                } 
            }
                    
            }else{
             
              this.minDate="";
              this.maxDate="";
            }

            if(element.mandatoryFlag==1){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                //this.addCompaniesForm.controls[element.combinedAttributeName].disable(); 
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }
            if(element.linkedAttributeId != null ){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })
            }


          }

          if(element.attributeType.id==5){
            if(element.mandatoryFlag==1){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            } else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            if(element.linkedAttributeId != null ){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                  }
                }
              })
            }
          }

          if(element.attributeType.id==6){
            if(element.mandatoryFlag==1){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            if(element.linkedAttributeId != null ){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })
            }

          }

          if(element.attributeType.id==7){
            
            const pattern="^(0|[1-9][0-9]*)$";
            if(element.mandatoryFlag==1){
              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.pattern(pattern)])));
            }else{

              this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('',Validators.compose([ Validators.pattern(pattern)])));
            }

            if(element.linkedAttributeId != null ){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })
            }
          }

          if(element.attributeType.id==8){
            if(element.mandatoryFlag==1){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
            }

            if(element.linkedAttributeId != null ){
               this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })
            }

          }

          if(element.attributeType.id==9){
            if(element.mandatoryFlag==1){
              
                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.email])));
            }else{

                this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
            }

            if(element.linkedAttributeId != null ){
              this.moduleObj.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.linkedAttributeId){
                  if(ele.value.length>0){
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })
            }

          }

          //CONDITION FOR COMPANIES LIST
          if(element.systemAttribute.id==11 || element.systemAttribute.id==5 || element.systemAttribute.id==25){
            this.systemAttributecompaniesArray.push(element);  
          }

          //CONDITION FOR CONTACTS LIST
          if(element.systemAttribute.id==6 || element.systemAttribute.id==27){
            this.systemAttributecontactsArray.push(element);  
          }

           //CONDITION FOR OPPORTUNITY LIST
          if(element.systemAttribute.id==26){
            this.systemAttributeopportunityArray.push(element);  
           
           
          }

           //CONDITION FOR PRODUCTS LIST
          if(element.systemAttribute.id==28){
            this.systemAttributeproductsArray.push(element);  
          }

          //other opoportunity inputs
          // if(element.systemAttribute.id==29 ||
          //    element.systemAttribute.id==30 ||
          //    element.systemAttribute.id==31 ||
          //    element.systemAttribute.id==32){
              
          //     this.systemAttributeopportunityListArray.push(element);  
          // }

          if(element.linkedAttributeId != null ){
                            
            if(element.linkedAttributeModuleId==3){             
              this.systemAttributeopportunityListArray.push(element);  
            }
            if(element.linkedAttributeModuleId==2){             
              this.systemAttributecompanyListArray.push(element);  
            }
            if(element.linkedAttributeModuleId==1){             
              this.systemAttributecontactsListArray.push(element);  
            }   
              
          }

          //CONDITION FOR ESTIMATE LIST
          if(element.systemAttribute.id==40){
            this.systemAttributeEstimateArray.push(element);        
          }

          //CONDITION FOR ESTIMATE LIST
          if(element.systemAttribute.id==14 || element.systemAttribute.id==17 ||element.systemAttribute.id==39 ){
            this.systemAttributeStatusArray.push(element);    
            this.isStatusFieldShow=false;       
          }

          //CONDITION FOR COMPANY OWNER LIST
          if(element.systemAttribute.id==34 || element.systemAttribute.id==35 ){
            this.systemAttributeOwnerArray.push(element);   
          }

          if(element.systemAttribute.id==28){
          
            if(element.mandatoryFlag==1){
              this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()],[Validators.required]));
              
            } else{
              this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()]));
            }

            this.myFormValueChanges$ = this.addCompaniesForm.controls['skuItems'].valueChanges;
       
            this.myFormValueChanges$.subscribe(skuItems => this.updateItems(skuItems));
          }
        })
        
        this.customAttributesList=res['responseData'];  

        this.getFieldValues();
    
      },
      error => {
        this.disabled=false;
        this.errorMes=error.error.responseData;

      }
    )
    /*
    setTimeout(()=>{      
      if(this.systemAttributecompaniesArray.length >0){
        
        this.getAllCompanies();

      }

      if(this.systemAttributecontactsArray.length >0){
        
        this.getAllContacts();

      }

      if(this.systemAttributeopportunityArray.length >0){
        
        this.getAllOpportunities();

      }

      if(this.systemAttributeopportunityListArray.length >0){
 
        if(this.opportunityId && this.flag=="opportunity"){
          
          this.getAllOpportunitiesDependentInputs(this.opportunityId);

        }
        else{
          this.systemAttributeopportunityListArray.forEach(rep=>{
            if(rep.systemAttribute.id !==26){
              var divId='div_'+rep.id;
              var division1=<HTMLElement>document.getElementById(divId);
              if(division1){            
                division1.classList.add('hidden');
              }
            }
           
          })        
        }
      }

      if(this.systemAttributecompanyListArray.length >0){
 
        if(this.opportunityId && this.flag=="company"){
          this.getAllCompanyDependentInputs(this.opportunityId);

        }
      }



      if(this.systemAttributeproductsArray.length >0){
           
        this.getAllProducts();

      }

      if(this.systemAttributeEstimateArray.length >0){
        this.getAllEstimates();

      }

      if(this.systemAttributeStatusArray.length >0){
   
        this.setStatus();

      }

      if(this.systemAttributeOwnerArray.length >0){
        
        this.getAllOwner();

      }

      this.SpinnerService.hide("addCompany");
      
    },3000);
    */
    
  }

  getFieldValues(){
    if(this.systemAttributecompaniesArray.length >0){
      this.getAllCompanies();
    }

    if(this.systemAttributecontactsArray.length >0){
      this.getAllContacts();
    }

    if(this.systemAttributeopportunityArray.length >0){
      this.getAllOpportunities();
    }

    if(this.systemAttributeopportunityListArray.length >0){
      if(this.opportunityId && this.flag=="opportunity"){        
        this.getAllOpportunitiesDependentInputs(this.opportunityId);
      } else {
        this.systemAttributeopportunityListArray.forEach(rep=>{
          if(rep.systemAttribute.id !==26){
            var divId='div_'+rep.id;
            var division1=<HTMLElement>document.getElementById(divId);
            if(division1){            
              division1.classList.add('hidden');
            }
          }
         
        })        
      }
    }

    if(this.systemAttributecompanyListArray.length >0){
      if(this.opportunityId && this.flag=="company"){
        this.getAllCompanyDependentInputs(this.opportunityId);
      }
    }

    if(this.systemAttributeproductsArray.length >0){         
      this.getAllProducts();
    }

    if(this.systemAttributeEstimateArray.length >0){
      this.getAllEstimates();
    }

    if(this.systemAttributeStatusArray.length >0){
      this.setStatus();
    }

    if(this.systemAttributeOwnerArray.length >0){      
      this.getAllOwner();
    }

    this.SpinnerService.hide("addCompany");
  }


  setStatus(){
    this.systemAttributeStatusArray.forEach(res=>{  

      var id='div_'+res.id;
      var division1=<HTMLElement>document.getElementById(id);
      
      if(division1){              
        division1.classList.add('hidden');
      }
           
      this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res.lookupValues[0].id); 
      this.addCompaniesForm.controls[res.combinedAttributeName].disable();          
       
    })

  }

  isControlHasError(controlName: string, validationType: string): boolean {

    if(controlName==='sku'){
      var control = this.addCompaniesForm.controls['skuItems'].get('sku');
      if (!control) {
        return false;
      }
    } else{
      var control = this.addCompaniesForm.controls[controlName];
      if (!control) {
        return false;
      }
    }
   
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Close(){
    this.activeModal.close();
    this.addCompaniesForm.reset();
  }

  AddCompany(controls){
    this.customAttributeValues=[];
    //dyniamic fields
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;
      
      if((element.attributeType.id==1 ||
          element.attributeType.id==2 ||
          element.attributeType.id==5 ||
          element.attributeType.id==6 ||
          element.attributeType.id==7 || 
          element.attributeType.id==8 || 
          element.attributeType.id==9) && (element.systemAttribute.id!==16) && (element.systemAttribute.id!==15) && (element.systemAttribute.id!==21) && (element.systemAttribute.id!==28)){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              "name": String(this.addCompaniesForm.controls[id]['value']==null ? '':this.addCompaniesForm.controls[id]['value'].toString().trim())
            }
            
          ]
          
        })
      }else{
       
        if(element.systemAttribute.id==16 || element.systemAttribute.id==15 || element.systemAttribute.id==21){
          var country= this.addCompaniesForm.controls.countryName['value'];
          var state=this.addCompaniesForm.controls.stateName['value'];
          var city = this.addCompaniesForm.controls.cityName['value'];
          var address2='';
          if(country!=null && state!=null && city!=null){
            address2=(country.trim()+'->'+state.trim()+'->'+city.trim());
          } else if(country!=null && state==null && city==null){
            address2=(country.trim());
          } else if(country!=null && state!=null && city==null){
            address2=(country.trim()+'->'+state.trim());
          } else{
           address2='';
          }
                  
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":[
              {
                'name':address2
              }
              
            ]
            
          })
        }
      }

      if(element.attributeType.id==3){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': formatDate(this.addCompaniesForm.controls[id]['value'])
            }                      
          ]          
        })
      }

      if(element.attributeType.id==4){
        if(this.addCompaniesForm.controls[id]){
          var resultArrayOfImages=[];
          console.log("Image control Id : "+this.addCompaniesForm.controls[id]['value']);

          if( this.addCompaniesForm.controls[id]['value'] != null){
            this.addCompaniesForm.controls[id]['value'].forEach(resultImage=>{
              resultArrayOfImages.push(
                {
                  'name':resultImage
                }
              )
            })
          }
          
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":resultArrayOfImages            
          })
        }               
      }

      if(element.systemAttribute.id==28){
            
        const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
        var length=this.addCompaniesForm.controls.skuItems.value.length;

        for(var i=0;i<length;i++){
           
          
          this.skuItemsArray.push(
            {
              "productId": control.at(+i).get('sku').value,
              "price": Number(control.at(+i).get('listPrice').value).toFixed(2),
              "quantity": control.at(+i).get('quantity').value,
              "sellingPrice": Number(control.at(+i).get('sellingPrice').value).toFixed(2),
              "discountPercent": Number(control.at(+i).get('discount').value).toFixed(2),
              "taxPercent": Number(control.at(+i).get('tax').value).toFixed(2),
              "totalPrice": Number(control.at(+i).get('total').value).toFixed(2)
            }
          )  
        }

        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":this.skuItemsArray,
          "systemAttribute":{
            "id": element.systemAttribute.id
          }   
        })
      }

    })

    function formatDate(date) {
      if(date == null || date == "")
        return "";
      else {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;    
    
        return [year, month, day].join('-');
      }
    }
    var addData;
    if(this.moduleType==1 || this.moduleType==2 || this.moduleType==3 || this.moduleType==4 ){
       addData = {
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };  
    }else{
       addData = {
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "moduleType":this.moduleType,
        "customAttributeValues": this.customAttributeValues
  
      };  
    }
  
    return addData;
  }

  Save(){
    this.disabled=true;
    this.errorFlag=false;
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==1){
        var min = element.minDigits;
        var max = element.maxDigits;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
      
        if(min!="" && min!=null){
          
            if(Number(min) > Number(elementValue.toString().length)){
              this.errorFlag=true;
              var message='Enter number greater than '+Number(min) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
        }
        else if(max!="" && max!=null){
         
            if(Number(elementValue.toString().length) > Number(max)){
              this.errorFlag=true;
              var message='Enter number less than '+Number(max) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
        }else if((min!=null && max!=null) && ((min!="0" || max!="0") || (min!=0 || max!=0))){
          if((Number(min) > Number(elementValue.toString().length)) || (Number(elementValue.toString().length) > Number(max))){
            this.errorFlag=true;
            var message='Enter number between '+Number(min) +' and '+Number(max) +' digits for '+element.combinedAttributeName;
            this.OpenAlertValidation(message);
            this.disabled=false;
          }
        }
      }
      }

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==2){
       
        var min = element.minNumber;
        var max = element.maxNumber;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
        //if(min!="" && max!=""){
          if(min!="" && min!=null){
              if(Number(min) > Number(elementValue)){
                this.errorFlag=true;
                var message='Enter value greater than '+Number(min) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          else if(max!="" && max!=null){
              if(Number(elementValue) > Number(max)){
                this.errorFlag=true;
                var message='Enter value less than '+Number(max) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }if(min!=null && max!=null){
            if((Number(min) > Number(elementValue)) || (Number(elementValue) > Number(max))){
              this.errorFlag=true;
              var message='Enter value between '+Number(min) +' and '+Number(max) +' for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
          } 
      }        
    } 
     
    
    // image validation section
    if(element.attributeType.id === 4 && element.mandatoryFlag === 1 ){      
      var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
      if(oldUploadedImageFileArray.length === 0){
        this.errorFlag=true;
        this.imageNoUploaded=true;
      }                 
    }


    })
    if(this.errorFlag){
      return;
    }
    const controls = this.addCompaniesForm.controls;
  

    if (this.addCompaniesForm.invalid) {
      this.addProductError=true;
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    
    if (this.isSubmitted==false) {
      return;
    }
 
    const addData=this.AddCompany(controls);

    this.SpinnerService.show("addCompany");

    this._customAttrService.add(addData,this.moduleType).subscribe(

      data => {
        if(this.flag == 'opportunity' && data['responseData'].contactId != undefined){
          this.mapOportunityWithContact(data['responseData'].contactId, this.opportunityId, this.selectedProject);
        }
        
        this.addCompaniesForm.reset();
        this.passEntry.emit('SUCCESS');
        this.activeModal.close('SUCCESS');
        if(this.activityFlag){

          this.eventEmitterService.onModalCloseResult(this.parentData);
          
        } else{
          this.eventEmitterService.onModalCloseResult('SUCCESS');
        }

        this.SpinnerService.hide("addCompany");
        
      },
      error => {
        this.disabled=false;
        this.errorMes=error.error.responseData.errorMsg;
        this.SpinnerService.hide("addCompany");
        this.eventEmitterService.onModalCloseResult('ERROR');
      }        
    );    
  }

  private mapOportunityWithContact(contactId: any, opportunityId: any, projectId: any){
    this._customAttrService.mapOpportunityContact(contactId, opportunityId, projectId).subscribe(
      data => {
       // console.log(data);
      },
      error => {
        this.errorMes=error.error.responseData.errorMsg;        
      }
    );
  }

  private updateProducts(skuItems: any) {

    const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
    
    const itemsArray=[];
    skuItems.forEach(data=>{
      itemsArray.push(
        {
          "sku":data.productId,
          "quantity": data.quantity,
          "listPrice":  data.price,
          "sellingPrice" : data.sellingPrice,
          "discount" :  data.discountPercent,
          "tax" :  data.taxPercent,
          "total" :  data.totalPrice
        }
      );
    })

    for (let i = 1; i < itemsArray.length; i++) {
      control.push(this.getSKU());
    }

    this.addCompaniesForm.patchValue({skuItems: itemsArray});

  }
  
  invokeChildMethod(lookupvalue,customAttr){ 
    var attributeId=customAttr.id
   
    //if(customAttr.attributeName =='Select Customer'){
    if((customAttr.attributeName =='Select Customer') || (customAttr.attributeName =='Select Company')){
     this.companyId=lookupvalue;
     this.getAllOpportunities();
     this.ngOnInit();
  //   this.companyId=lookupvalue;
    }

    if(customAttr.attributeName =='Select Opportunity'){
      this.opportunityId=lookupvalue;   
      this.getAllEstimates();
    }
  
   if(customAttr.hasChildAttribute){
    if(this.systemAttributeopportunityListArray.length>0){      
        this.systemAttributeopportunityListArray.forEach(rep=>{
        var divId='div_'+rep.id;
        var division1=<HTMLElement>document.getElementById(divId);
        
        if(division1){            
          division1.classList.remove('hidden');
        }
      })
     
      this.getAllOpportunitiesDependentInputs(lookupvalue);
    }
    
    if(this.systemAttributeEstimateArray.length>0){
      //fill sku table
      this._customAttrService.getActivity(lookupvalue, this.selectedProject).subscribe(
        (result)=>{
          
          result['responseData'][0].customAttributeValues.forEach(res=>{
            
            if(res.systemAttribute){
              if(res.systemAttribute.id==28){                
                var actId=result['responseData'][0].activityId;               
                this.updateProducts(res.value);
              }
            }
          }) 
  
        },
        error => {
          console.log(error)
        }
      )
    }
    //cal  api
    this._customAttrService.getFieldRelation(attributeId,lookupvalue).subscribe(
      res=>{
        
        if(res['responseData']){
          res['responseData'] = res['responseData'].filter(ele => {
            return ele.status == 1;
          })

          if(res['responseData'].length>0){
            res['responseData'].forEach(element=>{          
              //serch for key
              var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
              var divId='div_'+element.id;
              
              if(removeIndex >-1){
                
                var removeIndexInside = this.openDivs[removeIndex].value.map(function(item) { return item; }).indexOf(String(divId));
                
                if(removeIndexInside >-1){
                  console.log('if small div exsits');
                  //yes exists then no need to do anything
                }else{
                  
                  this.openDivs[removeIndex].value.push(divId);
  
                  var division1=<HTMLElement>document.getElementById(divId);
                  if(division1){            
                    division1.classList.remove('hidden');
                  } 
                }
              } else {
                
                this.openDivs.push({
                  attributeId:attributeId,
                  value:[
                    divId
                  ] 
                })

                var division1=<HTMLElement>document.getElementById(divId);
                
                if(division1){            
                  division1.classList.remove('hidden');
                }               
          
              }     

              var removeIndex2 = this.customAttributesList.map(function(item) { return item.id; }).indexOf(element.id);
            
              if(removeIndex2 >-1){
                
                this.customAttributesList[removeIndex2].lookupValues=element.lookupValues;
        
              }
            
            })

          } else {
            
            var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
        
            if(removeIndex >-1){
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res=>{
                var division=<HTMLElement>document.getElementById(res);
                if(division){              
                  division.classList.add('hidden');
                }
              })
              //remove key an pairs
              this.openDivs.splice(removeIndex, 1);              

            }            
          }
        }
      }
    )
    }
  }

  

  processFile(event,combinedAttributeName,mandatoryFlag,maxImage){    
    var imageArray= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
    
    if(maxImage === null || maxImage === undefined){
      maxImage = 0;
    }
    if(imageArray >-1){          
      if(this.imagesnameArray[imageArray].value.length < maxImage || (maxImage === 0) )
      {        
        this.processArray(event,combinedAttributeName,mandatoryFlag, maxImage);
      }else{        
        this.errorMes="Max "+maxImage+ " images are allowed";
      }
    } else{      
      this.processArray(event,combinedAttributeName,mandatoryFlag, maxImage);
    } 
  }

  processArray(event,combinedAttributeName,mandatoryFlag, maxImage){
    
    //serach for image count
    var imageDiv=document.getElementById('image'+combinedAttributeName);
    
    // uploaded image count
    var imageArray = this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
    var uploadedImageCount = 0;
    if(imageArray >-1){
    	uploadedImageCount = this.imagesnameArray[imageArray].value.length;
    }
 
    if(mandatoryFlag==1){
      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
    } else{

      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl());
    }

    var totalNumberOfUploadFilesAllowed = maxImage - uploadedImageCount;
    if(maxImage === 0){
      totalNumberOfUploadFilesAllowed = 1;
    }
    var imageFilesFromEvent = event.target.files;
    var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
	
	
    this.imageNameIndexMap.set("combinedAttributeName", combinedAttributeName);
    this.imageNameIndexMap.set("mandatoryFlag", mandatoryFlag);
    this.imageNameIndexMap.set("maxImage", maxImage);
	
	
    if (imageFilesFromEvent && imageFilesFromEvent[0]) {
      var filesAmount = event.target.files.length;
      var numOfFilesAllowed = maxImage - uploadedImageCount;
      if(maxImage === 0){
        numOfFilesAllowed = 1;
      }
      for (let i = 0; ((i < filesAmount) && (numOfFilesAllowed > 0)); i++) {
        numOfFilesAllowed--;
        var reader = new FileReader();   

        reader.onload = (event:any) => {
           //insert data
           ++uploadedImageCount;
           /*
          var data='<span> <img src="'+event.target.result+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
          data = data + moreData;
          */
          
          var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
          			+ '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
          			+ '<img width="90" height="100" src="'+event.target.result+'" alt="image.jpg" class="img-thumbnail">	';
          			 if(maxImage !== 0){
          				data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
          			}
          			data = data + '</div> ';
          
          imageDiv.innerHTML += data;
          this.imageNoUploaded=false;
          
	        let children = document.getElementsByClassName("closeImageButton");
		      for (let i = 0; i < children.length; i++) {
		        children[i].addEventListener("click", (event: Event) => {
		          this.removeImage(event);
		        });
		      }
        	this.imageUploadedCounter = uploadedImageCount + 1;
          if(uploadedImageCount == maxImage){
            this.showUploadImageIcon = false;
          }
        }
        reader.readAsDataURL(event.target.files[i]);       
      }      
    }
        
    for (let i = 0; (i < filesAmount && totalNumberOfUploadFilesAllowed > 0); i++) { 
      totalNumberOfUploadFilesAllowed--; 
    
      oldUploadedImageFileArray.push(event.target.files[i]);
      this.imageNameIndexMap.set("imageFileArray", oldUploadedImageFileArray);
    
      this._us.uploadImage(this.moduleType,event.target.files[i], this.selectedProject).subscribe(
        res => {
          var removeIndex= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
      
          if(removeIndex >-1){          
            this.imagesnameArray[removeIndex].value.push(res['responseData'].name);
    
          } else{
            this.imagesnameArray.push({
              "name":combinedAttributeName,
              "value":[res['responseData'].name]
            })
          }  
          
          var removeIndex2= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
          this.addCompaniesForm.get(combinedAttributeName).setValue(this.imagesnameArray[removeIndex2].value);

        },
        error=>{
          error => console.log("error occured")
        }
      )
    }

    /*
    setTimeout(()=>{
      var removeIndex2= this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
      this.addCompaniesForm.get(combinedAttributeName).setValue(this.imagesnameArray[removeIndex2].value);
    },3000)       
    */
  }

  SaveAndAdd(){
    this.disabled=true;
    this.errorFlag=false;
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==1){
        var min = element.minDigits;
        var max = element.maxDigits;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
      
        if(min!="" && min!=null){
          
            if(Number(min) > Number(elementValue.toString().length)){
              this.errorFlag=true;
              var message='Enter number greater than '+Number(min) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
        }
        else if(max!="" && max!=null){
         
            if(Number(elementValue.toString().length) > Number(max)){
              this.errorFlag=true;
              var message='Enter number less than '+Number(max) +' digits for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
        }else if((min!=null && max!=null) && ((min!="0" || max!="0") || (min!=0 || max!=0))){
          if((Number(min) > Number(elementValue.toString().length)) || (Number(elementValue.toString().length) > Number(max))){
            this.errorFlag=true;
            var message='Enter number between '+Number(min) +' and '+Number(max) +' digits for '+element.combinedAttributeName;
            this.OpenAlertValidation(message);
            this.disabled=false;
          }
        }
      }
      }

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==2){
       
        var min = element.minNumber;
        var max = element.maxNumber;
        var elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
        //if(min!="" && max!=""){
          if(min!="" && min!=null){
              if(Number(min) > Number(elementValue)){
                this.errorFlag=true;
                var message='Enter value greater than '+Number(min) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }
          else if(max!="" && max!=null){
              if(Number(elementValue) > Number(max)){
                this.errorFlag=true;
                var message='Enter value less than '+Number(max) +' for '+element.combinedAttributeName;
                this.OpenAlertValidation(message);
                this.disabled=false;
              }
          }if(min!=null && max!=null){
            if((Number(min) > Number(elementValue)) || (Number(elementValue) > Number(max))){
              this.errorFlag=true;
              var message='Enter value between '+Number(min) +' and '+Number(max) +' for '+element.combinedAttributeName;
              this.OpenAlertValidation(message);
              this.disabled=false;
            }
          } 
      }
    }

    // image validation section
    if(element.attributeType.id === 4 && element.mandatoryFlag === 1 ){      
      var oldUploadedImageFileArray = this.imageNameIndexMap.get("imageFileArray");
      if(oldUploadedImageFileArray.length === 0){
        this.errorFlag=true;
        this.imageNoUploaded=true;
      }                 
    }

    })
    if(this.errorFlag){
      return;
    }

   // this.addTable();
    this.getFields();

    const controls = this.addCompaniesForm.controls;
    if (this.addCompaniesForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    if (this.isSubmitted==false) {
      return;
    }

    const addData=this.AddCompany(controls);
    
    this._customAttrService.add(addData,this.moduleType).subscribe(
      data => {
        this.addCompaniesForm.reset();        
      },
      error => {
        this.disabled=false;
        this.errorMes=error.error.responseData.errorMsg;
      }        
    )
  }

clickImage(id){
  let element:HTMLElement =document.getElementById(id) as HTMLElement;
  element.click();
}

addTable() {
  const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
  control.push(this.getSKU());
}

deleteRow(i: number) {
  const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
  control.removeAt(i);
}

removeImage(clickEvent){
	var imagePositionIndex = clickEvent.target.dataset.imageindex
	var imageAttributeName = clickEvent.target.dataset.attributename;
	var removableImageIndex = imagePositionIndex - 1;

	var imageObjectIndex = this.imagesnameArray.map(function(item) { return item.name; }).indexOf(imageAttributeName);
    if(imageObjectIndex >-1){
    	var imageArray = this.imagesnameArray[imageObjectIndex].value;
    	var imageArrayLength = this.imagesnameArray[imageObjectIndex].value.length;
    	if(imageArrayLength > 0 && removableImageIndex > -1 && removableImageIndex < imageArrayLength){
    	 	this.imagesnameArray[imageObjectIndex].value.splice(removableImageIndex, 1);
    	 	var imageFileArrayFromMap = this.imageNameIndexMap.get("imageFileArray");
    	 	imageFileArrayFromMap.splice(removableImageIndex, 1);
    	 	this.imageNameIndexMap.set("imageFileArray", imageFileArrayFromMap);
    	 	// remove image from UI
    	 	document.getElementById("removableDiv"+imagePositionIndex).outerHTML = "";
    	}else{
    		console.log(imageArrayLength+" is the image array length, unable to remove image from index "+removableImageIndex);
    	}
    }else{
    	console.log("Image object not found with key "+imageAttributeName);
    	console.log(this.imagesnameArray);
    }
    this.removeImageFromUi();
}

removeImageFromUi(){
    var combinedAttributeName = this.imageNameIndexMap.get("combinedAttributeName");
    var mandatoryFlag = this.imageNameIndexMap.get("mandatoryFlag");
    var maxImage = this.imageNameIndexMap.get("maxImage");
    var imageFileArray = this.imageNameIndexMap.get("imageFileArray");
    var imageDiv=document.getElementById('image'+combinedAttributeName);
      imageDiv.innerHTML = "";
    var uploadedImageCount = 0;
    
    if(imageFileArray.length == 0){
    	this.imageUploadedCounter = 0;
      if(mandatoryFlag === 1){
        this.imageNoUploaded=true;
      }      
    }
    
    // uploaded image count
    var imageArray = this.imagesnameArray.map(function(item) { return item.name; }).indexOf(combinedAttributeName);
    if(mandatoryFlag == 1){
      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
    }else{
      this.addCompaniesForm.addControl(combinedAttributeName, new FormControl());
    }

var totalNumberOfUploadFilesAllowed = maxImage - uploadedImageCount;
var imageFilesFromEvent = imageFileArray;
	
    if (imageFilesFromEvent && imageFilesFromEvent[0]) {
      var filesAmount = imageFileArray.length;
      var numOfFilesAllowed = maxImage - uploadedImageCount;
      for (let i = 0; i < imageFileArray.length; i++) {
        var reader = new FileReader();   

        reader.onload = (event:any) => {
           //insert data
           ++uploadedImageCount;
           /*
          var data='<span> <img src="'+event.target.result+'"  height="50" width="90" style="padding: 10px 10px 0px 10px;"> '+(uploadedImageCount)+'/'+maxImage+' </span>';
          
          var moreData = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> <button id="myImgCont'+uploadedImageCount+'" class="closeImageButton" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' type="button">�</button>  </div> ';
          data = data + moreData;
          */

          var data = '<div id="removableDiv'+uploadedImageCount+'" class="artist-collection-photo"> ' 
          + '<div class="remove-img-btn"><button id="myImgCont'+uploadedImageCount+'" data-attributename='+combinedAttributeName+' data-imageindex='+uploadedImageCount+' class="closeImageButton close removeImgButton" type="button">X</button></div> '
          + '<img width="90" height="100" src="'+event.target.result+'" alt="image.jpg" class="img-thumbnail">	';
          if(maxImage !== 0){
			      data =  data + '<div class="img-index"> '+(uploadedImageCount)+'/'+maxImage+' </div> ';
		      }

          data = data + '</div> ';
   
          imageDiv.innerHTML += data;
               
          let children = document.getElementsByClassName("closeImageButton");
          for (let i = 0; i < children.length; i++) {
		        children[i].addEventListener("click", (event: Event) => {
		          this.removeImage(event);
		        });
		      }
          this.imageUploadedCounter = uploadedImageCount + 1;
          if(uploadedImageCount == maxImage){
            this.showUploadImageIcon = false;
          } else{
            this.showUploadImageIcon = true;
          }
		    
        }
        reader.readAsDataURL(imageFileArray[i]);
       
      }      
    }
         
//
}

 
OpenAlertValidation(message){
  const initialNotice = message;
   const dialogRef = this.dialog.open(AlertDialogComponent, {
     width: '600px',
     position: {top: '20px'},
     data: { title: 'Alert', body: initialNotice }
   });

  }
}