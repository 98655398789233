import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageActivitiesService } from '../../../manage-activities/services/manage-activities.service';
import { ManageActivitiesModel } from '../../../manage-activities/models/manage-activities.model';
import { AlertDialogComponent } from '../../../shared/alert-dialog/alert-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-manage-activity-settings',
  templateUrl: './manage-activity-settings.component.html',
  styleUrls: ['./manage-activity-settings.component.scss']
})
export class ManageActivitySettingsComponent implements OnInit {
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes: string = "";
  unsubscribe: Subject<any>;
  disabled:boolean;
  settingActivityForm: FormGroup;
  _onDestroy = new Subject<void>();
  activityModel: ManageActivitiesModel;
  activityId: number;
  allowCompany: boolean;
  allowOpportunity: boolean;
  //allowGeofence: boolean;
  geofence: boolean;
  activityName: string;
  geofenceDistance: number | string;
  isActive: number;
  allowCompanyName: string;
  allowOpportunityName: string;
  captureLiveLocation: boolean;
  opportunityEnabled: false;
  

  constructor( private fb: FormBuilder,
     private activityService: ManageActivitiesService,
     public dialog: MatDialog,
     private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getdynamicFieldNames();
    this.SpinnerService.show('editActivity');
    this.disabled=false;
    this.activityId = this.parentData;
    this.settingActivityForm = this.fb.group({
      activityName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
      allowForCompany: [],
      allowForOpportunity: [],
      geofence: [],
      geofenceDistance:[],
     // geofenceDistance:['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
      status: [],
      captureLiveLocation: []
     });
  //   console.log(this.activityId);
     this.getSelectedActivity(this.activityId, this.selectedProject);
    
  }

  getdynamicFieldNames(){
    this.activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result=>{
      result['responseData'].forEach(
        row => {

          if(row.moduleId==2){
            this.allowCompanyName =row.pluralName;
          } 
          if(row.moduleId==3){
            this.allowOpportunityName =row.pluralName;
            this.opportunityEnabled = row.opportunityEnabled;
          } 
        }
      )
    },
    err=>{
    }
  )
  }

  getSelectedActivity(activityId:any,selectedProject:number){
    this.activityService.getSelectedActivity(activityId,selectedProject).subscribe(
      res=>{
        this.activityModel = res['responseData'];
      //  console.log(this.activityModel);

        this.activityId = this.activityModel.activityId;
        this.activityName = this.activityModel.activityName.trim();
        this.allowCompany = this.activityModel.allowForCompanies == 1 ? true : false;
        this.allowOpportunity = this.activityModel.allowForOpportunities ==1 ? true : false;
        this.geofence = this.activityModel.allowGeofence == 1 ? true : false;
        this.geofenceDistance = this.activityModel.geofenceDistance;
        this.isActive = this.activityModel.active;
        this.captureLiveLocation = this.activityModel.captureLiveLocation == 1 ? true : false;
        
        this.settingActivityForm.controls['status'].setValue(this.activityModel.active.toString());
      },
      err=>{
        console.log(err);
      }
    )

    this.SpinnerService.hide('editActivity');
  }

  

  isDisabled() : boolean {
    return this.disabled;
  }

  Save(){
    this.disabled=true;
    const controls = this.settingActivityForm.controls;

    if(controls.geofence.value == true){
      this.settingActivityForm.controls.geofenceDistance.setValidators([Validators.required]);
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
     } else {
      this.settingActivityForm.controls.geofenceDistance.clearValidators();
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
    }

    if (this.settingActivityForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    this.disabled=false;
    const addSettingsData = {
      "activityId": this.activityId,
      "activityName":controls.activityName.value.trim(),
      "allowForCompanies":controls.allowForCompany.value == true ? 1 :0,
      "projectId": this.selectedProject,
      "allowForOpportunities":controls.allowForOpportunity.value == true ? 1:0,
      "allowGeofence": controls.geofence.value == true ? 1: 0,
      "geofenceDistance": controls.geofenceDistance.value,
      "active": controls.status.value,
      "captureLiveLocation": controls.captureLiveLocation.value == true ? 1 : 0
  };
 // console.log(addSettingsData);
    this.SpinnerService.show('editActivity');
    this.activityService.saveActivity(addSettingsData).subscribe(
      data => {
      //  console.log(data);
      //  this.settingActivityForm.reset();
      //  this.disabled=false;
      this.SpinnerService.hide('editActivity');
      },
      error => {
        console.log('error occured');
        this.SpinnerService.hide('editActivity');
        this.errorMes=error.error.responseData;
        this.disabled=false;
      }); 

      
  }
  

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.settingActivityForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  openDialog() {
    const controls = this.settingActivityForm.controls;
    const checkbox = controls.allowForOpportunity.value;
    
    if(checkbox) {
      const initialNotice = `Please make sure that field ‘Select Opportunity’ is Active in field details.`;
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '600px',
        data: { title: 'Alert', body: initialNotice }
      });
    }
    this.enableOrDisableGeofence();
  }

  addGeofenceField(value){
    this.geofenceDistance="";
    if(value){
      this.settingActivityForm.controls.geofenceDistance.setValidators([Validators.required]);
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
     } else {
      this.settingActivityForm.controls.geofenceDistance.clearValidators();
      this.settingActivityForm.controls.geofenceDistance.updateValueAndValidity();
    }
  }

  ResetForm(){
    this.activityId=this.parentData;
    this.getSelectedActivity(this.activityId, this.selectedProject);
  }


  enableOrDisableGeofence(){     
    const controls = this.settingActivityForm.controls;
    if(controls.allowForCompany.value || controls.allowForOpportunity.value){
      controls.geofence.enable();    
    }else{
      controls.geofence.disable();     
      controls.geofence.reset();
      this.geofence = false;
      this.addGeofenceField(this.geofence);
    }     
  }

}
