import { Component, OnInit, Input, Output, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { RecruitmentService } from '../../services/recruitment.service';
import { EditCellRendererComponent } from '../edit-cell-renderer/edit-cell-renderer.component';
import { UpdateRecruiterComponent } from '../update-recruiter/update-recruiter.component';

import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

import 'ag-grid-enterprise';
import { from } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';  //SSP
import { GridOptions, IGetRowsParams } from 'ag-grid-community';  //SSP

import { RecruitmentDataService } from 'src/app/views/pages/shared/services/recruitment-data.service';


@Component({
  selector: 'kt-recruitment-list',
  templateUrl: './recruitment-list.component.html',
  styleUrls: ['./recruitment-list.component.scss']
})
export class RecruitmentListComponent implements OnInit {
  @ViewChild('myGrid') myGrid : AgGridAngular; //SSP
  //gridOptions: GridOptions //SSP
  
  @ViewChild('myButton') myButton : ElementRef;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  resultCustom: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  rowData: [];
  selectedValue: any;
  paginationPageSize: any;
  domLayout: any;
  searchValue: any;
  gridOptions: any;
  frameworkComponents: any;
  gridReadyFlag = false;
  viewContent:boolean;
  

  modalReference: any;
  errorText = false;
  //editValue: ProductField;

  currentRouteUrl: string;
  rightSearchViewFlag = true;

  teamFlag = false;
  rightTabViewFlag = true;
  swithUserFlag = false;
  activeFlag = 'active';
  rightTeamActiveTabViewFlag = true;
  
  activeInactive = 1;
  selfTeamFlag = 0;

  private subscriptions: Subscription[] = [];

  totalPosition = 0;
  totalOpenPosition = 0;
  cdtsValue = 0;
  avgTatValue:any;
  avgAgeValue:any;

  //SSP
  cacheOverflowSize;
  maxConcurrentDatasourceRequests;
  infiniteInitialRowCount;
  rowBuffer;
  rowModelType
  maxBlocksInCache
  components
  agGridPerPageData: any

  //Test for redirect data object
  sendToChild: string;
  public positionIdData;

  isopencandidateDetailspage:any


  
  constructor(
    private recruitmentService:RecruitmentService,
    private modalService: NgbModal,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private subheaderService: SubheaderService,
    private recruitmentDataService:RecruitmentDataService
    ) {
    this.gridOptions={
      context : {
        componentParent: this
      }
    }
   }

   

  ngOnInit(): void {
    this.getRecruiterList();
   // this.isopencandidateDetailspage = this.getShareData();   //get share data
    //console.log('isopencandidateDetailspage', this.isopencandidateDetailspage)

    

    this.gridOptions.suppressNoRowsOverlay = true;  //SSP
    this.agGridPerPageData = 10;  //SSP

    this.avgTatValue = 0;

    this.teamFlag = false;
    this.activeFlag = 'active';
    this.currentRouteUrl = this.router.url;
    //if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('Recruitment Management');
    //}


    this.defaultColDef = {
      sortable: true,
      resizable: false,
      editable: false,
      filter: true,
      flex: 1,
     // minWidth: 150
    };
    this.domLayout = 'autoHeight';
    this.paginationPageSize = 10;
    

    /*this.gridOptions = {
      //cacheBlockSize: 0,
      paginationPageSize: 10,
      rowModelType:'infinite',
      
    }*/
    
    //SSP
   /* this.cacheOverflowSize = 2
    this.maxConcurrentDatasourceRequests = 2
    this.infiniteInitialRowCount = 0;

    // this.rowModelType = 'serverSide';
    // this.serverSideStoreType = 'partial';

    this.gridOptions = {
      cacheBlockSize: 0,
      paginationPageSize: this.agGridPerPageData,
      rowModelType:'infinite',
      //serverSideStoreType: 'partial',
      context: {
        componentParent:this
      }
    }*/
    //-------------------------------------------

    this.subheaderService.setRightTabConfig(this.rightTabViewFlag, this.teamFlag, false);
    this.subheaderService.setRightTeamActiveConfig(this.rightTeamActiveTabViewFlag, this.activeFlag, false);
    this.subheaderService.enableBack(false);
    this.gridOptions.suppressNoRowsOverlay = true;

    if(this.subheaderService.rightTab$.observers.length <= 1){
      this.subscriptions.push(this.subheaderService.rightTab$.subscribe(rt => {
        this.teamFlag = rt.teamFlag;
        
        if(this.teamFlag == true){  //select team
          this.selfTeamFlag = 1;
        }else{  //select self
          this.selfTeamFlag = 0;
        }

        if(rt.teamClickFlag == true){
          this.getMyOpeningsList();
        }
      }));
    }

    console.log('rightTeamActiveTab',this.subheaderService.rightTeamActiveTab$.observers.length)
    if(this.subheaderService.rightTeamActiveTab$.observers.length <= 1){
      this.subscriptions.push(this.subheaderService.rightTeamActiveTab$.subscribe(rt => {
        console.log('click')
        if(rt.activeFlag == 'active'){
          this.activeInactive = 1;
        }else if(rt.activeFlag == 'inactive'){
          this.activeInactive = 0;
        }else{
          this.activeInactive = 2;
        }

        if(rt.clickFlag == true){
          this.getMyOpeningsList();
        }
      }));
    }

    this.getMyOpeningsList();

  }

  getRecruiterList(){
    var localStorageRecruiterList = localStorage.getItem('recruiterList');

    if(localStorageRecruiterList){
    }else{
      var recruiterListArray = [];
      this.recruitmentService.getRecruiterList().subscribe(
        data => {
          //console.log(data);
          var recruiterData = JSON.stringify(data);
          var tempData = JSON.parse(recruiterData);
          tempData.forEach(function (value) {
            var interviewerName = value.firstName+' '+value.lastName;
            var tempArray = {"id":value.userId, "name":interviewerName};
            recruiterListArray.push(tempArray);
          });

          localStorage.setItem('recruiterList', JSON.stringify(recruiterListArray));  
        },
        error =>{
          localStorage.setItem('recruiterList', JSON.stringify(recruiterListArray)); 
        }
      );
    }
  }

  getShareData(): any{
    //console.log('get share data')
    //return this.recruitmentDataService.fromCandidateDetailsPage;
  }

  onPaginationChanged(event){
    var page = this.gridApi.paginationGetCurrentPage();
    console.log('page', page)
  }

  generateGridHeader(){
    this.columnDefs = [
      {
        headerName: 'Opening',
        field: 'opening',
        minWidth: 150,
        //rowDrag: true,
      },
      {
        headerName: 'City',
        field: 'openingCity'
      },
      {
        headerName: 'Project',
        field: 'openingProjectName',
        minWidth: 200,
      },
      {
        headerName: 'Recruiter',
        field: 'recruiterName',
        minWidth: 100,
      },
      {
        headerName: 'Position',
        field: 'openingPosition',
        aggFunc: params => {
          let sum = 0;
          params.values.forEach(value => sum += value);
          this.totalPosition = sum;
         // return sum;
        }
      },
      {
        headerName: 'Open',
        field: 'open',
        aggFunc: params => {
          let sum = 0;
          params.values.forEach(value => sum += value);
          this.totalOpenPosition = sum;
         // return sum;
        }
      },
      {
        headerName: 'Age',
        field: 'age',
        aggFunc: params => {
          let sum = 0;
          let row = params.values.length;
          params.values.forEach(value => sum += value);
          let average = sum/row;
          if(isNaN(average) == true){
            average = 0;
          }
          this.avgAgeValue = average.toFixed(2);
         // return sum;
        }
      },
      {
        headerName: 'TAT',
        field: 'tat',
        aggFunc: params => {
          let sum = 0;
          let row = params.values.length;
          params.values.forEach(value => sum += value);
          let average = sum/row;
          if(isNaN(average) == true){
            average = 0;
          }
          this.avgTatValue = average.toFixed(2);
         // return sum;
        }
      },
      {
        headerName: 'Cdts',
        field: 'cdts',
        aggFunc: params => {
          let sum = 0;
          params.values.forEach(value => sum += value);
          this.cdtsValue = sum;
         // return sum;
        }
      },
      {
        headerName: 'Rej/Not Joined',
        field: 'rej'
      },
      {
        headerName: 'Selected',
        field: 'selected'
      },
      {
        headerName: 'Offered',
        field: 'offered'
      },
      {
        headerName: 'Joined',
        field: 'joined'
      },
      {
        headerName: 'Action',
        field: '',
        minWidth: 100,
        filter: false,
        sortable: false,
        //cellClass: "text-center",
        cellRendererFramework: EditCellRendererComponent
      }
    ]
  }

  
  getMyOpeningsList(){
    this.SpinnerService.show('recruitment-list');
    this.rowData=[];
    this.recruitmentService.getMyOpeningsList(this.selectedProject, this.selfTeamFlag, this.activeInactive)
    .subscribe(
      (result)=>{
        var posotionsData = result['content'];
        //console.log('posotionsData--', posotionsData)

        let age = 0;
        let rowIndex = 0;
        posotionsData.forEach(
          row => {
            //console.log('render opening ---', row);
            var open = parseInt(row.positions)-parseInt(row.offered)-parseInt(row.joined); 
            
            //row.id = row.budgetCity.id;
            row.opening= row.budgetName;
            row.openingCity = row.cityName;
            row.openingProjectName = row.projectName;
            row.openingPosition = row.positions; 
            //row.fixedCtcPercentage = row.budgetCity.budgetId.fixedCtcPercentage;

            row.open = open;
            row.tat = row.turnAroundTime;
            row.cdts = row.totalCandidates;
            row.rej = row.rejected;
            row.selected = row.shortlisted;
            row.offered = row.offered;
            row.joined = row.joined;
            row.age = row.averageTime;

            row.positionId = row.positionId;
            row.budgetId = row.budgetId;
            row.recruiterId = row.recruiterId;

            
            row.totalInprocessCount = row.totalCandidates-row.rejected-row.offered-row.joined;
            row.totalOfferedJoined = row.offered+row.joined;
            

          }
        )
        
        //if(this.isopencandidateDetailspage == false){
        this.generateGridHeader();
        this.resultCustom = posotionsData;
        this.rowData =  this.resultCustom;

          //this.gridApi.setRowData([]);
        this.gridApi.updateRowData({add: this.rowData});
        this.gridApi.refreshCells();

        //console.log('this.rowData----1', this.rowData)
        //}
        this.SpinnerService.hide('recruitment-list');
        

        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click(),
          this.viewContent = true;
        }, 300);
    },
    error => {
      console.log(error)
      this.SpinnerService.hide('recruitment-list');
    })
  }

  quickSearch(){
    this.gridApi.setQuickFilter(this.searchValue);
  }

  AddProductField() {

  }

  onBtForEachNode() {
    console.log('### api.forEachNode() ###');
    this.gridApi.forEachNode(this.printNode);
  }

  printNode(node, index) {
    if (node.group) {
      console.log(index + ' -> group: ' + node.key);
    } else {
      console.log(
        index + ' -> data: ' + node.data.id + ', ' + node.data.fieldName
      );
    }
  }

  onGridReady(params) {
    console.log('onGridReady');
    //if(this.isopencandidateDetailspage == false){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    //}
    
    //this.gridApi.refreshCells(params);

   /* var dataSource = {
      getRows(params:any) {
          params.successCallback([],100);
      }
    };
    params.api.setDatasource(dataSource);*/
  }

  addWhatsApp(){
    
  }

  openPositionDetails(data){ 
    console.log('candidateData',data);
    //var candidateData = JSON.stringify(data);
    //this.router.navigate(['/recruitement-candidate-details'], { queryParams: { dataObject: candidateData }});
    
    this.positionIdData = data.positionId;
    //this.recruitmentService.shareData = data;
    this.recruitmentDataService.shareData = data;
    //this.gridApi.destroy();
    
    this.router.navigate(['/recruitement-candidate-details'])

  }

  recreateGrid() {
    this.gridApi = false;
    this.rowData = [];
     this.gridApi = undefined;
    /*setTimeout(() => {
      this.gridActivated = true;
    });*/
  }

  UpdateRecruiter(data){
    console.log('update recruiter', data)
    const ref = this.modalService.open(UpdateRecruiterComponent, 
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.positionId= data.positionId;
  }

  triggerClick() {}  


  //Server side pagination---------------
  onGridReady_ssp(params) {
    console.log('call here');

    //SSP
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    var datasource = {
      getRows: (params: IGetRowsParams) =>{
        //startRow : 0,
        var pageNo = params.startRow/this.agGridPerPageData;
            pageNo = pageNo+1
        
        this.recruitmentService.getMyOpeningsList_server_side(pageNo,this.agGridPerPageData,this.selfTeamFlag, this.activeInactive)
        .subscribe( result=> {
          
          var posotionsData = result['content'];
        console.log('posotionsData--', posotionsData)

        let age = 0;
        let rowIndex = 0;
        posotionsData.forEach(
          row => {
            console.log('render opening ---', row);
            var open = parseInt(row.budgetCity.positions)-parseInt(row.offered)-parseInt(row.joined); 
            row.id = row.budgetCity.id;
            row.opening= row.budgetCity.budgetId.budgetName;
            row.openingCity = row.budgetCity.cityId.cityName;
            row.openingProjectName = row.budgetCity.budgetId.project.projectName;
            row.openingPosition = row.budgetCity.positions;
            row.fixedCtcPercentage = row.budgetCity.budgetId.fixedCtcPercentage;

            row.open = open;
            row.tat = row.turnAroundTime;
            row.cdts = row.totalCandidates;
            row.rej = row.rejected;
            row.selected = row.shortListed;
            row.offered = row.offered;
            row.joined = row.joined;

            row.age = row.age;

          }
        )

        //setTimeout(function(){
          console.log('SSP data', posotionsData)
          this.resultCustom = posotionsData;
          this.rowData =  this.resultCustom;
          
          params.successCallback(this.resultCustom, result['totalElements']);
        //},500)
        }
        )
        
      }
    }
    params.api.setDatasource(datasource);
    //this.gridApi.setDatasource(datasource);

  }

}
