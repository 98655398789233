import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class RecruitmentDataService {
   shareData: any;
   fromCandidateDetailsPage = false;
  constructor(private http:HttpClient) { }

}
