<div class="example-preview">
    <div>
        <form [formGroup]="interviewRejectForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Reject {{rejectCandidateName}} from {{positionName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <mat-form-field [hidden]="true">
                <input matInput placeholder="An Input" formControlName="interViewApplicationId">
            </mat-form-field>

            <div class="modal-body">
                
                <div class="form-group row" >
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Rejection Reason</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="interviewRejectReason" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="rejectFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let rejectData of rejectReasonList| search : rejectFilter.value :'name'" [value]="rejectData.name">
                                    {{rejectData.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isControlHasError('interviewRejectReason', 'required')">Reason cannot be blank</mat-error>
                           
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Remarks</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput placeholder="Remarks" formControlName="rejectRemarks" ktAutoTrimSpaces>
                            <mat-label style="color: rgba(0,0,0,.54);">Remarks</mat-label>
                            <mat-error *ngIf="isControlHasError('rejectRemarks', 'required')">Remarks cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="saveInterViewRejectionData()">Save</button>
            </div>
        </form> 
    </div>
</div>