
    <div class="example-preview">
        <form  [formGroup]="addSettingsForm"  autocomplete="off" >           
            <div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Enable Opportunity Feature</label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox (change)="toggleOpportunityFeature($event.checked)" formControlName="enableOpportunityFeature" [(ngModel)]=enableOpportunityFeature></mat-checkbox> <label class="ml-3">Yes</label>
                        <br>
                        <mat-hint class="form-hint" align="start"  style="color: rgba(0,0,0,.54)">Un-check will disable opportunity and all associated features</mat-hint>   
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Enforce Live Location Capture While Adding From App</label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox  formControlName="enforceLiveLocCapture" [(ngModel)]=enforceLiveLocCapture></mat-checkbox> <label class="ml-3">Yes</label>
                        <br>
                        <mat-hint class="form-hint" align="start"  style="color: rgba(0,0,0,.54)">Tick this if you want to enable real time location during addition</mat-hint>   
                    </div>
                </div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Rename Opportunity?</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input2  maxlength="25" formControlName="singularName" [(ngModel)]="singularName" ktAutoTrimSpaces>
                            <mat-hint align="end" style="color: rgba(0,0,0,.54)">{{input2.value?.length || 0}}/25</mat-hint>
                            <mat-error *ngIf="isControlHasError('singularName', 'required')">Rename Opportunity cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('singularName', 'maxlength')"><strong>Maximum 25 characters</strong></mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> 
                        </mat-form-field>                    
                    </div>
                    
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">What would be the plural?</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="input-100" >
                            <input matInput #input  maxlength="25" formControlName="pruralName" [(ngModel)]=pruralName ktAutoTrimSpaces>    
                            <mat-hint align="end" style="color: rgba(0,0,0,.54)"> {{input.value?.length || 0}}/25</mat-hint>
                            <mat-error *ngIf="isControlHasError('pruralName', 'required')">Plural cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('pruralName', 'maxlength')"><strong>Maximum 25 characters</strong></mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> 
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
            </div>
            <div class="row" ktMargin [marginBottom]="'20px'"></div>
    
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" [disabled]='isDisabled()' (click)="Cancel()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
            </div>
            
        </form> 
    </div>

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Fetching Data... </p>  
    </ngx-spinner>

