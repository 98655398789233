<div class="example-preview">
    <div>
        <form [formGroup]="addWhatsAppForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Add Candidate - Whatsapp</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Mobile Number</label>
                    </div>
                   
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-2">
                                <mat-form-field class="mat-form-field-fluid input-100 form-padding-top-15">
                                    <mat-select formControlName="mobilePrefix" >
                                        <mat-option value="+91">+91</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="isControlHasError('mobilePrefix', 'required')">Code cannot be blank</mat-error> 
                                 </mat-form-field>
                           </div>
                           <div class="col-md-10">
                            <mat-form-field appearance="standard" class="input-100 form-padding-top-5" >
                                <input matInput  formControlName="mobileNumber" >
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                <mat-error *ngIf="isControlHasError('mobileNumber', 'required')">Mobile Number cannot be blank</mat-error> 
                            </mat-form-field> 
                            </div>
                        </div>
                                          
                    </div> 


                </div>

                
                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="saveWhatsApp()">Save</button>
            </div>
        </form> 
    </div>
</div>  
