import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-candidate-pool',
  templateUrl: './candidate-pool.component.html',
  styleUrls: ['./candidate-pool.component.scss']
})
export class CandidatePoolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('jobType');
    localStorage.removeItem('indistryType');
  }

}
