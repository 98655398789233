import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecruitmentManagementCandidateComponent } from './recruitment-management-candidate.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NgxMatSelectSearchModule } from '../mat-select-search/public_api';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { InprocessCandidateComponent } from './_sub/inprocess-candidate/inprocess-candidate.component';
import { CandidateWhatsappComponent } from './_sub/candidate-whatsapp/candidate-whatsapp.component';
import { CandidateDirectComponent } from './_sub/candidate-direct/candidate-direct.component';

//For Material Seep From
import {MatStepperModule} from '@angular/material/stepper';
import { EditCellRendererComponent } from './_sub/edit-cell-renderer/edit-cell-renderer.component';
import { InterviewScheduleComponent } from './_sub/interview-schedule/interview-schedule.component';
import { CandidateOfferComponent } from './_sub/candidate-offer/candidate-offer.component';
import { CandidateRejectComponent } from './_sub/candidate-reject/candidate-reject.component';

const routes:Routes=[{
    path:'',component:RecruitmentManagementCandidateComponent
  }]

  @NgModule({
    declarations: [
      RecruitmentManagementCandidateComponent,
      InprocessCandidateComponent,
      CandidateWhatsappComponent,
      CandidateDirectComponent,
      EditCellRendererComponent,
      InterviewScheduleComponent,
      CandidateOfferComponent,
      CandidateRejectComponent
     // RecruitmentListComponent,
     // EditCellRendererComponent,
     // TeamsRecruitmentListComponent,
     // ProductFieldsListComponent,
     // AddProductFieldComponent,
     // EditProductFieldComponent,
      //SettingsComponent
    ],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      SharedModule,
      NgxMatSelectSearchModule,
      NgbModule,
      AgGridModule.withComponents([EditCellRendererComponent]),
      MatStepperModule,
    ],
    exports:[],
    entryComponents:[
      CandidateWhatsappComponent,
      CandidateDirectComponent,
      InterviewScheduleComponent,
      CandidateOfferComponent,
      CandidateRejectComponent
    ]
  })
  export class RecruitmentManagementCandidateModule { }