<!-- <kt-portlet> 
    <kt-portlet-body>
        <mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">           
            <mat-tab label="In Process">
                <ng-template matTabContent>
                   <kt-inprocess-candidate></kt-inprocess-candidate>
                </ng-template>
            </mat-tab>
            <mat-tab label="Offered/Joined" >
                <ng-template matTabContent>
                    <kt-recruitment-offered-joined-list></kt-recruitment-offered-joined-list> 
                </ng-template>
            </mat-tab>
            <mat-tab label="Rejected" >
                <ng-template matTabContent>
                     <kt-recruitment-rejected-list></kt-recruitment-rejected-list> 
                </ng-template>
            </mat-tab>  
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet> -->

<kt-portlet> 
    <kt-portlet-body>
        <kt-inprocess-candidate></kt-inprocess-candidate>
    </kt-portlet-body>
</kt-portlet>
  