import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmitterService } from '../shared/services/event-emitter.service';

@Component({
  selector: 'kt-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss']
})
export class CompanyDashboardComponent implements OnInit {

  companyId: number;
  returnUrl: any;
  selectedProject: number

  constructor(
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
    //this.eventEmitterService.onModalCloseResult(true);

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
		  this.returnUrl = params.returnUrl || '/';
	  });

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
        this.companyId = params.companyId;
        console.log('companyId : '+this.companyId);
    });

  }
}
