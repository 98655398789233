import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../shared/services/event-emitter.service';
import { AuthService } from '../../../../core/auth';
import { User } from 'src/app/core/auth';

@Component({
  selector: 'kt-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss']
})
export class ProjectSelectionComponent implements OnInit {

  userDetail: User;
  projectSelectionForm: FormGroup;
  projectList:[];
  projectSelectFilter: FormControl = new FormControl();
  disabled:boolean;
  defaultProjSet:boolean;
  userProj: any;
  returnUrl: any;
  menuObj:any = {};
  projectId: any;
  formView = false;
  selectionFlag: string;
  defaultMenuFlag = false;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  pluralName: any = {};

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    private auth: AuthService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.defaultProjSet = false;

    this.userProj = this.userDetail.userProject;
    
    if(this.selectionFlag === 'Login') {
        this.selectProject();
    } else {
      this.userDetail.selectedProjectId = this.selectedProject;
        this.switchProject()
    }

  }

  selectProject(){
    if(this.userProj.length > 1 && (this.userDetail.defaultProjectId == null || this.userDetail.defaultProjectId == 0)){
      this.formView = true;
      this.initProjectSelectionForm();
      this.projectList = this.userDetail.userProject;

      if(this.userDetail.defaultProjectId) {
        this.defaultProjSet = true;
        this.projectSelectionForm.controls['selectProject'].setValue(this.userDetail.defaultProjectId);
      }
    } else {
      this.activeModal.close();
      if(this.userDetail.defaultProjectId > 0) {
        this.fetchUserMenu(this.userDetail);
        this.fetchCityList(this.userDetail);
      } else {
        this.userDetail.defaultProjectId = this.userProj[0].project.projectId;
        this.fetchUserMenu(this.userDetail);
        this.fetchCityList(this.userDetail);
      }
    }
  }

  switchProject(){
    this.formView = true;
    this.initProjectSelectionForm();
    this.projectList = this.userDetail.userProject;

    if(this.userDetail.defaultProjectId == this.userDetail.selectedProjectId) {
      this.defaultProjSet = true;
      this.projectSelectionForm.controls['selectProject'].setValue(this.userDetail.defaultProjectId);
    } else {
      this.defaultProjSet = false;
      this.projectSelectionForm.controls['selectProject'].setValue(this.userDetail.selectedProjectId);
    }
  }

  initProjectSelectionForm(){
    this.projectSelectionForm = this.fb.group({
      selectProject: ['', Validators.compose([Validators.required ])],
      defaultSet: []
     });
  }

  Proceed(){
    this.disabled=true;
    const controls = this.projectSelectionForm.controls;
    if (this.projectSelectionForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    const selectedProject = controls.selectProject.value;
    this.userDetail.selectedProjectId = selectedProject;
    if(controls.defaultSet.value == true){
        this.auth.updateDefaultProject(selectedProject).subscribe(
          data => {
            console.log(data);
            this.userDetail.defaultProjectId = selectedProject;
          },
          error => {
            console.log('error occured');
          }
        );
    }
    this.activeModal.close();
    this.disabled=false;
    this.fetchCityList(this.userDetail);
    this.fetchUserMenu(this.userDetail);
    
//    this.eventEmitterService.onModalCloseResult(this.userDetail);

  }

  changeEntity(data){

    if(data == this.userDetail.defaultProjectId){
      this.defaultProjSet = true;
    }else{
      this.defaultProjSet = false;
    }
  }

  Close(){
    this.activeModal.close();
    this.projectSelectionForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.projectSelectionForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  fetchUserMenu(user: User){
    if(user.selectedProjectId)
      this.projectId = user.selectedProjectId;
    else
      this.projectId = user.defaultProjectId;

    //this.fetchCityList(this.userDetail);
    /*
      localStorage.setItem('userMenu', null);
      localStorage.setItem('selectedProject', null);
      localStorage.setItem('pageTitle', null);
      localStorage.setItem('pluralName', null);

      //const userMenu = this.finalizeUserMenu(data['responseData']);
      //this.createPageHeading(userMenu);

      const finalMenu: any = {
                header: {
                  self: {},
                  //items: userMenu
                }
              }

      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('userProject', JSON.stringify(user.userProject));
      //localStorage.setItem('userMenu', JSON.stringify(finalMenu));
      localStorage.setItem('selectedProject', this.projectId);
      localStorage.setItem('pluralName', JSON.stringify(this.pluralName));
      console.log("Past local storage : "+localStorage.getItem('selectedProject'));

      if(this.defaultMenuFlag){
        window.location.href = window.location.origin+"/activity-report";
      } else {
        window.location.href = window.location.origin+"/dashboard";
      } */

    
		return this.auth.getUserMenu(this.projectId).subscribe(
			data=>{
          localStorage.setItem('userMenu', null);
          localStorage.setItem('selectedProject', null);
          localStorage.setItem('pageTitle', null);
          localStorage.setItem('pluralName', null);

          const userMenu = this.finalizeUserMenu(data['responseData']);
					this.createPageHeading(userMenu);

					const finalMenu: any = {
										header: {
											self: {},
											items: userMenu
										}
                  }

					localStorage.setItem('currentUser', JSON.stringify(user));
					localStorage.setItem('userProject', JSON.stringify(user.userProject));
					localStorage.setItem('userMenu', JSON.stringify(finalMenu));
					localStorage.setItem('selectedProject', this.projectId);
          localStorage.setItem('pluralName', JSON.stringify(this.pluralName));
					console.log("Past local storage : "+localStorage.getItem('selectedProject'));

          if(this.defaultMenuFlag){
            window.location.href = window.location.origin+"/activity-report";
          } else {
            window.location.href = window.location.origin+"/dashboard";
          }
        //  this.router.navigateByUrl(this.returnUrl); // Main page

        //this.fetchCityList(this.userDetail);
		}) 
  }
  
  finalizeUserMenu(menuData: any){
		for(let res of menuData) {
			if(res.root == true) {
				res.alignment = 'left';
				res.toggle = 'click';
			}
		}
		return menuData;
  }
  
  /**
	 * 
	 * @param userMenu 
	 */
	createPageHeading(userMenu: any)
	{
		/* First Level */
		for(let res of userMenu){
			if(res.page && res.page != '#'){
			  this.generatePageHeading(res);
			}
			/* Second Level */
			if(res.submenu){
			  for(let res1 of res.submenu){
				if(res1.page && res1.page != '#'){
				  this.generatePageHeading(res1);
				}
				/* Third Level */
				if(res1.submenu){
				  for(let res2 of res1.submenu){
					if(res2.page && res2.page != '#'){
					  this.generatePageHeading(res2);
					}
					/* Forth Level */
					if(res2.submenu){
					  for(let res3 of res2.submenu){
						if(res3.page && res3.page != '#'){
						  this.generatePageHeading(res3);
						}
					  }
					}
				  }
				}
			  }
			}
		  }

		  localStorage.setItem('pageTitle', JSON.stringify(this.menuObj));
	}

	/**
	 * 
	 * @param menu 
	 */
	generatePageHeading(menu:any)
	{
		let jsonVal = {};
    let pageName = menu.page.replace(/\//g, '');

		jsonVal = {
					"page" : {
						"title": menu.title,
						"desc": '',
						"singularName":menu.singularName,
						"pruralName": menu.pruralName
					}
				};
			this.menuObj[pageName] = jsonVal;

      /** Default Menu Set */
      if(pageName == 'activity-report') {
        this.defaultMenuFlag = true;
      }

      if(pageName == 'customers' || pageName == 'contacts' || pageName == 'opportunities'){
        this.pluralName[pageName] = menu.pruralName
      }

	}

  fetchCityList(user: User){
		if(user.selectedProjectId)
      this.projectId = user.selectedProjectId;
    else
      this.projectId = user.defaultProjectId;

		
		 return this.auth.getCityList(this.projectId).subscribe(
			data=>{
				console.log('call get city list');
				localStorage.setItem('cityList', '');
					/*const userMenu = this.finalizeUserMenu(data['responseData']);
					this.createPageHeading(userMenu);
					const finalMenu: any = {
										header: {
											self: {},
											items: userMenu
										}
									}

					this.store.dispatch(new Login({authToken: user.token})); //user.accessToken
					localStorage.setItem('currentUser', JSON.stringify(user));
					localStorage.setItem('userProject', JSON.stringify(user.userProject));
					localStorage.setItem('userMenu', JSON.stringify(finalMenu));
					localStorage.setItem('selectedProject', this.projectId);
					console.log("Past local storage : "+localStorage.getItem('selectedProject'));

					if(this.authFor === 'Create-Password'){
						//this.router.navigateByUrl('/settings-assistant');
						window.location.href = window.location.origin+"/settings-assistant";
					} else {
						this.router.navigateByUrl(this.returnUrl); // Main page
					}*/
			}
		  )
      
	}

}
