import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
//import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RecruitmentCandidateService } from '../../services/recruitment-candidate.service';

@Component({
  selector: 'kt-candidate-reject',
  templateUrl: './candidate-reject.component.html',
  styleUrls: ['./candidate-reject.component.scss']
})
export class CandidateRejectComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public interviewRejectForm: FormGroup;

  rejectReasonList:any;
  public rejectFilter: FormControl = new FormControl();
  disabled:boolean;
  errorMes:String="";
  rejectCandidateName:any;
  positionName:any;
  applicationId: any;

  constructor(
    private _rmcs:RecruitmentCandidateService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.rejectReasonList=[
      {"name":"Candidate did not join on or after the joining date"},
      {"name":"Candidate failed to confirm acceptance of the offer by the due date"},
      {"name":"Declined to accept the offer as issued"},
      {"name":"Company exercising discretion to withdraw the offer"},
      {"name":"Candidate backed out from the process"},
      {"name":"Candidate rejected by interviewer during screening"},
    ]
    
    this.initInterviewRejectForm();   
    this.disabled=false; 
  }

  initInterviewRejectForm(){
    this.interviewRejectForm = this.fb.group({
      interViewApplicationId:[''],
      interviewRejectReason: ['', Validators.compose([Validators.required ])],
      rejectRemarks: ['', Validators.compose([Validators.required ])],
    });
  }

  getRejectReasonData(controls){
    const addData = {    
        "applicationId": this.applicationId,   
        "rejectionReason": controls.interviewRejectReason.value,
        "rejectionRemarks": controls.rejectRemarks.value,
        
      };  
      return addData;
  }

  saveInterViewRejectionData(){
    const controls = this.interviewRejectForm.controls;
    if (this.interviewRejectForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
    const addData=this.getRejectReasonData(controls);
    this._rmcs.saveCandidateReject(addData).subscribe(
      data => {
      //  console.log(data);
        if(data['statusCode']==412){
          this.disabled=false;
          //this.OpenAlert(data['message']);
        }else{
          this.activeModal.close();
          this.interviewRejectForm.reset();
          this.disabled=false;
          this.eventEmitterService.onModalCloseResult('REJECT_CANDIDATE_SUCCESS');
         // this.eventEmitterService.onModalCloseResult(data);
        }
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled=false;
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
    );
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.interviewRejectForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Close(){
    this.activeModal.close();
    this.interviewRejectForm.reset();
  }

}
