<div class="example-preview">
    <div>
        <form [formGroup]="interViewScheduleForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Schedule Interview</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <mat-form-field [hidden]="true">
                <input matInput placeholder="An Input" formControlName="interViewApplicationId">
            </mat-form-field>

            <div class="modal-body">
                
                <div class="form-group row" >
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Interviewer Level</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="interviewerLevel" (selectionChange)="changeInterviewerLevel($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="interviewerLevelFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let interviewerLevelData of interviewerLevelList| search : interviewerLevelFilter.value :'name'" [value]="interviewerLevelData.level">
                                    {{interviewerLevelData.levelValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isControlHasError('interviewerLevel', 'required')">Interviewer level cannot be blank</mat-error>
                            <!-- <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> -->
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row" >
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Interviewer Name</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="interviewerName" [disabled]=true>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="interviewerNameFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let interviewerLevelList of interviewerLevelList| search : interviewerNameFilter.value :'name'" [value]="interviewerLevelList.id">
                                    {{interviewerLevelList.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isControlHasError('interviewerName', 'required')">Interviewer name cannot be blank</mat-error>
                            
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Location</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput placeholder="Interview Location" formControlName="interviewLocation" ktAutoTrimSpaces>
                            <mat-label style="color: rgba(0,0,0,.54);">Location</mat-label>
                            <mat-error *ngIf="isControlHasError('interviewLocation', 'required')">Location cannot be blank</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Date & Time</label>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput [min]="minDate" [matDatepicker]="picker" formControlName="interviewDate" >
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="isControlHasError('interviewDate', 'required')">Date cannot be blank</mat-error>
                        </mat-form-field>   
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput type="time" class="bg-none" formControlName="interviewTime" placeholder="Time" class="schedule-time">
                            <!-- <mat-timepicker #picker></mat-timepicker> -->
                            <mat-error *ngIf="isControlHasError('interviewTime', 'required')">Time cannot be blank</mat-error>
                            <!-- <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">access_time</mat-icon> -->
                        </mat-form-field>   
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="saveInterViewSchedule()">Save</button>
            </div>
        </form> 
    </div>
</div>