import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { contactsModel } from '../models/contacts.model';
import { environment} from '../../../../../environments/environment';
import { contactTypeModel } from '../models/contactType.model';
@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http:HttpClient) { }

  getAllContactDetails(projectId: number){ 
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getContactLookup?projectId='+ projectId);
  }

  addEditContact(data){
    return this.http.post<contactsModel>(
      environment.baseUrl + '/transactions/api/contact/addEditContact',data)
  }

  getAllContactType(projectId: number){ 
    return this.http.post<contactTypeModel[]>(
      environment.baseUrl + '/contact/getAllContactType',{"projectId": projectId})
  }
   
  getAllCompanyDetails(projectId: number){ 
    return this.http.post<any[]>(
      environment.baseUrl + '/transactions/api/company/getAllCompanyDetails',{"projectId": projectId})
  }

  getMyOpeningsList_server_side(pageno:any,dataSize:any, teamFlag: any, activeInactiveFlag: any){
    
    if(teamFlag == 0){  //Self
      return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/positions/?page='+pageno+'&size='+dataSize+'&active='+activeInactiveFlag+'');
    }else{  //team
      return this.http.get(environment.baseUrl + '/recruiter-services/api/v1/positions/team/?page='+pageno+'&size='+dataSize+'&active='+activeInactiveFlag+'');
    }

  }



}
