import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CompanyDashboardService } from '../../../../services/company-dashboard.service';
import { SubheaderService } from '../../../../../../../../app/core/_base/layout';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { EditCompaniesDialogComponent } from '../../../../../shared/custom-edit/edit-companies-dialog/edit-companies-dialog.component';


@Component({
  selector: 'kt-widget4-company',
  templateUrl: './widget4-company.component.html',
  styleUrls: ['./widget4-company.component.scss']
})
export class Widget4CompanyComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('pluralName'));
  headingName = 'Customer';
  companyInfo: any;
  companyName: string;
  companyType: string;
  customAttributes: [];
  errorMessage: string;
  isError = false;

  public isCollapsed = false;

  constructor(
    private _cds: CompanyDashboardService,
    private SpinnerService: NgxSpinnerService,
    private subheaderService: SubheaderService,
    private eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-company');
    this.fetchCompanyInfo(this.selectedProject, this.parentData);

    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.companyResult(data);
      });
    }

    if(this.tabHeading['customers'])
      this.headingName = this.tabHeading['customers'];
      
  }

  fetchCompanyInfo(projectId: number, companyId: number){
    this._cds.getCompanyInfo(projectId, companyId).subscribe(
      res=>{
        this.companyInfo = res['responseData'];
        this.subheaderService.setTitle(this.companyInfo.name);
        this.companyName = this.companyInfo.name;
        this.companyType = this.companyInfo.companyType;
        this.customAttributes = this.companyInfo.customAttributes;
        this.SpinnerService.hide('dashboard-company');
      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-company');
      }
    )
  }

  editCompany() {
    const _saveMessage = `Opportunity  has been modified.`;
   	const ref = this.modalService.open(EditCompaniesDialogComponent,
      {
        centered: false,
        size : 'lg',
        backdrop : 'static'
      }
    );
    
    ref.componentInstance.moduleType=2;
    ref.componentInstance.flag='company';
    ref.componentInstance.editId=this.parentData;
    ref.componentInstance.activityFlag=false;

    ref.result.then((result) => {
      this.companyResult(result);
    });
    
  }

  companyResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-company');
      this.fetchCompanyInfo(this.selectedProject, this.parentData);
    }
  }

}
