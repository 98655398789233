import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { GeneralSettingsComponent } from './general-settings.component';
import { LocalizationComponent } from './_subs/localization/localization.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OpportunitySettingComponent } from './_subs/opportunity-setting/opportunity-setting.component';
import { ContactSettingComponent } from './_subs/contact-setting/contact-setting.component';
import { CustomerSettingComponent } from './_subs/customer-setting/customer-setting.component';


const routes:Routes=[
  {
    path:'',
    component:GeneralSettingsComponent
  }
]

@NgModule({
  declarations: [
    GeneralSettingsComponent,
    LocalizationComponent,
    OpportunitySettingComponent,
    ContactSettingComponent,
    CustomerSettingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    RouterModule.forChild(routes),
    NgxSpinnerModule
  ],
  entryComponents:[
    LocalizationComponent

  ]
})
export class GeneralSettingsModule { }
