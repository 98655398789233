<div class="card card-custom bg-white-100" [ngClass]="cssClasses">
	<!-- Header -->
	<!--<div class="card-header border-0 bg-danger py-5">
		<h3 class="card-title font-weight-bolder text-white">Sales Stat</h3>
		
	</div>-->
	<!-- Body -->
	<div class="card-body p-0 position-relative overflow-hidden">
        <!--begin::Chart-->
        <div class="card-rounded bg-success pt-20 text-center text-white" [style.height]="'200px'">
            <h1>{{revenueEarned}}</h1>
            <h5>Revenue Earned</h5>
        </div>
        <!--end::Chart-->

        <!--begin::Stats-->
        <div class="card-spacer mt-n25">
            <!--begin::Row-->
            <div class="row m-0">
                <div class="col bg-light-success text-success px-6 py-10 rounded-xl mr-2 mb-2 text-center">
                    <div class="font-weight-bold font-size-h6"> {{order}} </div>
                    <div class="font-weight-bold"> Orders </div>
                </div>
                <div class="col bg-light-warning text-warning px-6 py-10 rounded-xl mb-2 text-center">
                    <div class="font-weight-bold font-size-h6"> {{estimate}} </div>
                    <div class="font-weight-bold"> In Progress </div>
                </div>
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row m-0">
                <div class="col bg-light-danger text-danger px-6 py-10 rounded-xl mr-2 mb-2 text-center">
                    <div class="font-weight-bold font-size-h6"> {{onSite}} </div>
                    <div class="font-weight-bold"> On Site </div>
                </div>
                <div class="col bg-light-info text-info px-6 py-10 rounded-xl mb-2 text-center">
                    <div class="font-weight-bold font-size-h6"> {{offSite}} </div>
                    <div class="font-weight-bold"> Off Site </div>
                </div>
            </div>
            <!--end::Row-->
            <div class="card card-border rounded p-2 mb-4" [ngStyle]="{'background-color':'#F2F2F2'}">
                <div class="d-flex align-items-center p-2">
                    <div class="d-flex flex-column flex-grow-1">
                        <span class="text-dark-75 text-primary font-weight-bold font-size-lg mb-4">Win Probability</span>
                        <span class="text-muted font-weight-bold font-size-sm mt-4 mb-4">
                            <ngb-progressbar type="success" [value]="win"></ngb-progressbar>
                        </span>
                        <span class="text-dark-75 text-center font-weight-bold font-size-h6">{{win}}%</span>
                        <span class="text-dark-75 text-center font-size-sm mb-4">Won</span>
                    </div>
                </div>
            </div>

            <div class="col bg-light-warning px-6 py-6 rounded-xl">
                <div class="font-size-h6 text-danger">
                    Next Visit :
                </div>
                <div class="text-dark-50">
                    {{nextVisit}}
                </div>
                
                <div class="font-size-h6 text-danger mt-8">
                    Future Activity Due Date :
                </div>
                <div class="text-dark-50">
                    {{futureActivityDueDate}}
                </div>

                <div class="font-size-h6 text-danger mt-8">
                    Last Visit :
                </div>
                <div class="text-dark-50">
                    {{lastOrder}}
                </div>
            </div>
        </div>
        <!--end::Stats-->
    </div>
</div>

<ngx-spinner size="default" type="ball-spin-clockwise" name='dashboard-stat'>  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>