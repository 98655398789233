import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html',
  styleUrls: ['./edit-cell-renderer.component.scss']
})
export class EditCellRendererComponent implements OnInit {

  data:any;
  params:any;
  displayRelation:boolean;

  constructor() { }

  agInit(params){
    
    this.displayRelation=false;
    this.params = params;
    this.data = params.value;
    if(params.data.fieldSpec ==1 || params.data.fieldSpec ==2){
      this.displayRelation=true;
    }
    if(params.data.moduleType > 100 && params.data.systemAttribute!=null && ((params.data.systemAttribute.id==26) ||
      (params.data.systemAttribute.id==29) || (params.data.systemAttribute.id==30) || (params.data.systemAttribute.id==31) || (params.data.systemAttribute.id==32) ||
      (params.data.systemAttribute.id==40))){
        this.displayRelation=false;
    }
    
  }

  ngOnInit(): void {
  }

  public invokeParentMethod() {
    this.params.context.componentParent.EditCustomField(this.params.data);
  }

  public invokeRelationMethod() {
    this.params.context.componentParent.editRelationship(this.params.data);
  }

}
